import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KmAgreementsComponent } from './km-agreements/km-agreements.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { KmAgreementGuard } from 'app/core/auth/km-agreement.guard';

const routes: Routes = [
  {
    path: 'listing',
    component: KmAgreementsComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, KmAgreementGuard]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KmAgreementsRoutingModule { }
