import { NgModule, APP_INITIALIZER } from '@angular/core';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

// jhipster-needle-angular-add-module-import JHipster will add new module here
import { MainComponent } from './layouts/main/main.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { PageRibbonComponent } from './layouts/profiles/page-ribbon.component';
import { ActiveMenuDirective } from './layouts/navbar/active-menu.directive';
import { ErrorComponent } from './layouts/error/error.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { AuthInterceptor } from './blocks/interceptor/auth.interceptor';
import { LoadingIndicatorService } from './loading-indicator.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { initializer } from './keycloak-init';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {MenubarModule} from 'primeng/menubar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {SplitButtonModule} from 'primeng/splitbutton';
import { CardModule } from 'primeng/card';
import {TooltipModule} from 'primeng/tooltip';
import {SlideMenuModule } from 'primeng/slidemenu';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import {EncryptDecryptInterceptor} from './blocks/interceptor/EncryptDecrypt.interceptor';
import { BackofficeAppRoutingModule } from './app-routing.module';
import { BackofficeSharedModule } from 'app/shared/shared.module';
import { BackofficeCoreModule } from 'app/core/core.module';
import { BackofficeHomeModule } from './home/home.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PagesModule } from './pages/pages.module';
import { ToggleButtonModule } from "primeng/togglebutton";
import { InitiationModule } from './initiation/initiation.module';
import { SvgModule } from './backoffice-assets/svg.module';
import { RequestLoaderInterceptor } from './blocks/interceptor/request-loader.interceptor';
import { QualityCheckModule } from './quality-check/quality-check.module';
import { IrfRealizationModule } from './irf-realization/irf-realization.module';
import { OfflineBillsModule } from './offline-bills/offline-bills.module';
import { AgreementArchiveModule } from './agreement-archive/agreement-archive.module';
import { SfBillingModule } from './sf-billing/sf-billing.module';
import { KmAgreementsModule } from './km-agreements/km-agreements.module';
  
@NgModule({
  imports: [
    NgxSpinnerModule,
    BrowserAnimationsModule,
    OverlayPanelModule,
    BackofficeSharedModule,	
    BackofficeCoreModule,	
    BackofficeHomeModule,
    KeycloakAngularModule,
    MenubarModule,
    InputSwitchModule,
    SplitButtonModule,
    TooltipModule,
    SlideMenuModule,
    ToggleButtonModule,
    BackofficeAppRoutingModule,
    PagesModule,
    CardModule,
    InitiationModule,
    QualityCheckModule,
    IrfRealizationModule,
    SvgModule,
    OfflineBillsModule,
    AgreementArchiveModule,
    SfBillingModule,
    KmAgreementsModule
  ],
  declarations: [MainComponent, NavbarComponent, ErrorComponent, PageRibbonComponent, ActiveMenuDirective, FooterComponent, HeaderComponent,EmptyRouteComponent],
    providers: [
      EncryptDecryptInterceptor,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [LocalStorageService, SessionStorageService, LoadingIndicatorService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncryptDecryptInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestLoaderInterceptor,
      multi: true
    },
   
    { provide: APP_BASE_HREF, useValue: '/backoffice/' }
  ],
  bootstrap: [MainComponent]
})
export class BackofficeAppModule  {}
