import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { PagesService } from 'app/pages/pages.service';
import { ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'backoffice-agreement-details',
  templateUrl: './agreement-details.component.html',
  styleUrls: ['./agreement-details.component.scss'],
  providers: [MessageService]
})
export class AgreementDetailsComponent implements OnInit, AfterViewInit {

  applicationBreadcrumbList = [];
  agreement_details: any;
  irfBillId: any;
  agreement_details1: any;
  apiFail: boolean = false;
  openedFile: any;
  zoomValue: number = 1;
  rotate: number = 0
  displayPreview: boolean = false;
  dataLoader: boolean = false;
  isPdf: boolean = true;
  previewFailed: boolean = false;
  urlSafe: any;
  canvasRotation: number = 0;
  transform: ImageTransform = {};


  fetchedURLAgreementId;
  fetchedURLIrfBillId;

  homeiconapplicationBreadcrumb = { icon: 'pi pi-home', routerLink: ['/backoffice/agreement-archive/byclient'] };

  //staticData JSON variables
  openedFileName: any;

  //splitbutton
  viewButtonitems: MenuItem[];

  constructor(private pagesService: PagesService, private router: Router, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private route: ActivatedRoute) {

  }
  transformedData: any[] = [];

  ngOnInit(): void {


    this.route.paramMap.subscribe(params => {
      this.fetchedURLAgreementId = params.get('agreementId');
      this.fetchedURLIrfBillId = params.get('irfBillId');
    });
    
    this.applicationBreadcrumbList = [
      { label: `Archival Data Listing`, routerLink: ['/agreement-archive/byclient'] },
      { label: `Agreement Details`, routerLink: ['/agreement-archive/agreementdetails', this.fetchedURLAgreementId] },
    ];
    this.viewButtonitems = [
      {
        label: 'View Bill Details',
        command: () => {
          this.goToBillDetailsView();
        }
      },
      { label: 'View Bill Payment Details', 
        command: () => {
          this.goToBillPaymentDetailsView();
        },
      },
      { label: 'View QC Details', 
        command: () => {
          this.navigateQcForm();;
        },
      }
    ];
  }

  ngAfterViewInit(): void {
    this.getAgreementDetail();

  }

  getAgreementDetail(): void {
    this.pagesService.getAgreementDetail(this.fetchedURLAgreementId).subscribe(results => {
      this.agreement_details = results;
      this.irfBillId = this.agreement_details?.irfBillId;
      this.apiFail = false;
    }, (_error) => {
      this.apiFail = true;
    });
  }

  showAgreementDetailsAttachmentPreview(dataany) {
    this.zoomValue = 1;
    this.rotate = 0;

    this.pagesService.getAgreementDetail(this.fetchedURLAgreementId).subscribe((res) => {
      this.agreement_details = res;
      this.apiFail = false;
      this.agreement_details.clientClassificationApprovalFileName
      this.agreement_details.clientClassificationApprovalBase64
      this.displayPreview = true;
      // if(this.agreement_details1 && this.agreement_details1?.originalAttachmentBase64){
      let mime: any;
      let base64: any;
      // if(this.agreement_details1 && this.agreement_details1?.originalAttachmentBase64){

      if (dataany == 'sfAttachment') {
        base64 = this.agreement_details?.approvalAttachmentSfFilePath
        this.openedFileName = this.agreement_details?.approvalAttachmentSfFileName
      }
      if (dataany == 'previousAgreement') {
        base64 = this.agreement_details?.approvalAttachmentPrevFilePath
        this.openedFileName = this.agreement_details?.approvalAttachmentPrevFileName
      }
      if (dataany == 'irfAttachment') {
        base64 = this.agreement_details?.approvalAttachmentIrfFilePath
        this.openedFileName = this.agreement_details?.approvalAttachmentIrfFileName
      }
      if (dataany == 'clientClassification') {
        base64 = this.agreement_details?.clientClassificationApprovalFilePath
        this.openedFileName = this.agreement_details?.clientClassificationApprovalFileName
      }
      //this.isPdf = true;
      mime = 'data:application/pdf;base64,'
      this.urlSafe = `${mime}${base64}`
      //this.previewFailed = true;
      this.dataLoader = false;

    }, (_error) => {
      this.apiFail = true;
      this.displayPreview = false;
      this.previewFailed = true;
    });

  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  goToBillDetailsView(): void {
    this.router.navigate(['/agreement-archive/viewbilldetails', this.irfBillId])
  }

  goToBillPaymentDetailsView(): void {
    
    this.router.navigate(['/agreement-archive/viewbillpaymentdetails', this.irfBillId])
  }

  navigateQcForm(){
    this.router.navigate(['/agreement-archive/qcform', this.fetchedURLAgreementId]);
  }
  
  goToPortfolioDetails(companyCode,agreementID): void{
    this.router.navigate(['/agreement-archive/viewportfoliodetails',companyCode,agreementID])

  }

  onBackClick(): void {
    this.router.navigate(['/agreement-archive/byclient'])
  }

  mandateAttachment(item): void {
    console.log("mandateAttachment", item);

  }

  showAgreementDetailsMulipleAttachmentPreview(filepath, filename) {
    this.zoomValue = 1;
    this.rotate = 0;
    // return;
    this.openedFileName = filename;

    this.pagesService.getAgreementDetail(this.fetchedURLAgreementId).subscribe((res) => {
      this.agreement_details = res;
      this.apiFail = false;
      this.displayPreview = true;
      let mime: any;
      let base64: any;
      base64 = filepath
      let ext = this?.openedFileName?.split('.');
      let ext1 = ext[ext.length - 1];
      // let mime: any;
      this.isPdf = false;
      if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
        mime = 'data:image/png;base64,'
      } else if ("doc" === ext1?.toLowerCase()) {
        mime = "data:application/msword;base64,";
        this.displayPreview = false;
      } else if ("docx" === ext1?.toLowerCase()) {
        mime = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
        this.displayPreview = false;
      } else if ("msg" === ext1?.toLowerCase()) {
        mime = 'data:application/vnd.ms-outlook;base64,';
        this.displayPreview = false;
        // mime = 'data:application/pdf;base64,'
      } else {
        this.isPdf = true;
        mime = 'data:application/pdf;base64,'
      }
      // mime = 'data:application/pdf;base64,'
      this.urlSafe = `${mime}${base64}`
      if ("doc" === ext1?.toLowerCase() || "docx" === ext1?.toLowerCase() || "msg" === ext1?.toLowerCase()) {
        this.downloadFile();
      }
      this.dataLoader = false;
    }, (_error) => {
      this.apiFail = true;
      this.displayPreview = false;
      this.previewFailed = true;
    });

  }
  downloadFile() {
    let linkSource: any;
    linkSource = this.urlSafe;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this.openedFileName;
    downloadLink.click();
  }
}
