import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AccountService } from 'app/core/auth/account.service';
import { BACKOFFICE_SERVER_API_URL, SERVER_API_URL, SERVER_API_INTEGRATION_URL, REPORTING_SERVER_API_URL_Allocation, REPORTING_SERVER_API_URL, REPORTING_SERVER_API_URL_Entity, REPORTING_SERVER_API_URL_CRMIntegaration, USERMANAGEMENT_SERVER_API_URL, NEXERA_SERVER_API_URL, FILEUPLOAD_URL, NEXERA_SERVER_API_URL_OPPORTUNITY, SFINITIATION_URL, SFREALIZATION_URL, SFPUSHGRID_URL, MASTER_SERVICE_URL, IRFPUSHGRID_URL } from 'app/app.constants';
import * as CryptoJS from 'crypto-js';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { delay, take, retryWhen, switchMap, debounceTime } from 'rxjs/operators';
import { BYPASS_LOADER } from 'app/blocks/interceptor/request-loader.interceptor';
import { NoPermissionToAccessComponent } from './no-permission-to-access/no-permission-to-access.component';
import { DialogService } from 'primeng/dynamicdialog';
@Injectable({ providedIn: 'root' })
export class PagesService {

  key: any;
  iv: any;
  private userId: any;
  errorMessageOnSaveService: string;
  constructor(private accountService: AccountService, public http: HttpClient, public encDec: EncryptDecryptInterceptor,private dialogService: DialogService) {
    this.key = CryptoJS.enc.Latin1.parse('Y3Jpc2lsQDIwMjE=');
    this.iv = CryptoJS.enc.Latin1.parse('fedcba9876543210');
    this.accountService.identity().subscribe(account => {
      if (account) {
        this.userId = account.login;
      }
    });
  }

  downloadFile(item: any, urlSafe) {
    const link = document.createElement('a');
    link.href = urlSafe;
    link.download = item?.fileName;
    link.click();
  }

  getsfDiscountReason1DataEditOpportunity(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/DiscountReason`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getsearch1Datauserhierarchylist(): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/findUserSearchList`, { params: { type: 'E' } });
  }

  onExportDownloaduserhierarchylist(): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/findAllUserList`, { params: { type: 'N' } });
  }

  getuserhierarchyDatauserhierarchylist(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/findAllUserList`, { headers: headersList, params: { type: 'E' } });
  }

  getunmappeduserDatauserhierarchylist(): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/findAllUserList`, { params: { type: 'N' } });
  }

  getdesignationDataViewMappedUser(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getDesignationAndTeamList`, { headers: headersList });
  }

  getdesignationDataEditMappedUser(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getDesignationAndTeamList`, { headers: headersList });
  }

  getshadowPortfolioEmployeeNameAndDesignationDataEditMappedUser(empid): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getAllShadowPortfolioUsers`, { params: { employeeid: empid } });
  }

  getimmediateSupervisorDataEditMappedUser(bdDesignation, levelid, employeeId): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getImmediateSupervisorList`, { headers: headersList, params: { bdDesignationId: bdDesignation, levelId: levelid, employeeId: employeeId } });
  }

  getdesignation1DataEditMappedUser(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getDesignationAndTeamList`, { headers: headersList });
  }

  getpaEmployeeNameAndDesignationDataEditMappedUser(levelid, employeeId): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getPAapproversList`, { params: { levelId: levelid, employeeId: employeeId } });
  }

  onccprimebutton1SubmitEditMappedUser(data: any): Observable<any> {
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/saveNexeraEmployee`, data);
  }

  getdesignationDatausermapping(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getDesignationAndTeamList`, { headers: headersList });
  }

  getshadowPortfolioEmployeeNameAndDesignationDatausermapping(empid): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getAllShadowPortfolioUsers`, { params: { employeeid: empid } });
  }

  getimmediateSupervisorDatausermapping(bdDesignation, levelid, employeeId): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getImmediateSupervisorListUnmapped`, { params: { bdDesignationId: bdDesignation, levelId: levelid, employeeId: employeeId } });
  }


  getimeediateSupervisorLevelNameDatausermapping(): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getImmediateSupervisorList`, { params: { levelId: '13', bdDesignationName: 'Associate Director' } });
  }


  getteamDatausermapping(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getDesignationAndTeamList`, { headers: headersList });
  }


  getemployeeNameAndDesignationDatausermapping(levelid, employeeId): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/getPAapproversList`, { params: { levelId: levelid, employeeId: employeeId } });
  }

  onccprimebutton1Submitusermapping(formValues: any, data: any): Observable<any> {
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/saveNexeraEmployee`, formValues);
  }

  getOpsSpocListDatausermapping(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(USERMANAGEMENT_SERVER_API_URL + `api/v1/hrms/opsSpocList`, { headers: headersList });
  }
  excelExport(booleanFlag): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/export?userlist=${booleanFlag}`);
  }

  //Reporting

  getbusinessWonDataGridDataReportingScreenGrid(payload: any): Observable<any> {
    return this.http.post(REPORTING_SERVER_API_URL + `/findAll`, payload);
    // return this.http.post('https://crisilratingsbd-qa.crisil.com/nexera/service/api/v1/reporting' + `/findAll`, payload);
  }
  gettableDataReportingScreenGrid(startDate?: any, endDate?: any): Observable<any> {
    const headersList = new HttpHeaders();
    // return this.http.get('https://crisilratingsbd-qa.crisil.com/nexera/service/api/v1/reporting' + `/findAll`, { params: { startDate: startDate, endDate: endDate }, headers: headersList });
    return this.http.get(REPORTING_SERVER_API_URL + `/findAll`, { params: { startDate: startDate, endDate: endDate }, headers: headersList });
  }
  onReportingScreenDetailsPageLoad(OpportunityId: any, isCOAUser: boolean): Observable<any> {
    const headersList = new HttpHeaders();
    // return this.http.get('https://crisilratingsbd-qa.crisil.com/nexera/service/api/v1/reporting' + `/findById`, { params: { id: OpportunityId, isCOAUser: isCOAUser }, headers: headersList });
    return this.http.get(REPORTING_SERVER_API_URL + `/findById`, { params: { id: OpportunityId, isCOAUser: isCOAUser }, headers: headersList });
  }

  sendIdsForAdditionalPaymentCount(ids: any): Observable<any> {
    return this.http.post(REPORTING_SERVER_API_URL + `/additionalPaymentCount`, ids);
  }

  getsalutationselectDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/Salutation`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getdesignationselectDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/Designation`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getGSTtypeselectDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/GSTType`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getpincodeDataReportingScreenDetails(data?: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findStateAndCityByPinCode/` + data.pinCodetextField, { headers: headersList, }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getAllocationFinYear(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/FinancialYear').pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getExistingpinCodetextFieldDataReportingScreenDetails(data?: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findStateAndCityByPinCode/${data.pinCodetextField}`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getpaymentModeDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/PaymentMode`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  gettdsTypeDataViewOpportunityDetail(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/TDSType`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getbankDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/Bank`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getpaymenttypeDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/PaymentType`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getentityTypeDataCreateEntity(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/CompanyType`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getCompanyType(data): Observable<any> {
    const headersList = new HttpHeaders();
    let payload = this.encDec.encryptWith(data)
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/CRMintegration/findAllDTO?startChars=${payload}`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getbusinessTypeDataEditOpportunity(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/BusinessType`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getBusinessActionDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/BusinessAction`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getSFBusinessAction(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/Actions`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getSFBusinessActionReasons(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/ActionReasons`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getBillFinalised(payload): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(SERVER_API_INTEGRATION_URL + `api/v1/oldCCIntegration/checkInvoiceStatus`, payload, { headers: headersList });
  }

  getCostCenterDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/CostCenter`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );

  }
  getProductDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/Products`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getSfRevisionReasons(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/SfBillRevisionReason`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getTrypartyDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/TriPartyMandate`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getReasonDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/DiscountReason`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getBusinessReportedReasonDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/ReportedReason`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getBusinessRejectedReasonDataReportingScreenDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/RejectedReason`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getPaymentDetailsListDataReportingScreenDetails(id: any, isCOAUser: boolean): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(REPORTING_SERVER_API_URL + `/findById`, { params: { id: id, isCOAUser: isCOAUser }, headers: headersList });
  }

  onSaveBtnSubmitCreateReportingData(formValues: any): Observable<any> {
    return this.http.post(REPORTING_SERVER_API_URL + `/update`, formValues);
  }

  updateSfReporting(payload: any): Observable<any> {
    return this.http.post(REPORTING_SERVER_API_URL + `/SfUpdate`, payload);
  }

  getAttachmentTypeMasterData(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/OpportunityAttachmentType`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getSFAttachmentTypeMasterData(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/SFReportingAttachmentType`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getAllContacts(payload): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(SERVER_API_INTEGRATION_URL + `api/v1/CRMintegration/findAllContactsByCompanyCode`, payload, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  combineAllPDF(data: any): Observable<any> {
    return this.http.post(NEXERA_SERVER_API_URL + 'api/v1/file/mergefiles', data);
  }

  setMessageOnSaveError(msg) {
    this.errorMessageOnSaveService = msg;
  }
  getMessageOnSaveError() {
    const res = this.errorMessageOnSaveService.startsWith(",");
    if (res == true) {
      this.errorMessageOnSaveService = this.errorMessageOnSaveService.slice(1);
    }
    return this.errorMessageOnSaveService;
  }

  getDocList(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/ReportingAttachmentType').pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  sendExcelIds(ids): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(SERVER_API_URL + `api/v1/reporting/exportToExcel`, ids, { headers: headersList });
  }

  sendSFExcelIds(ids): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(SERVER_API_URL + `api/v1/reporting/sf/export`, ids, { headers: headersList });
  }

  generateExcel(excelType?: any, excelTypeName?: any, opportunityIds?: any[], recipientUserId?: any) {
    const headersList = new HttpHeaders();
    return this.http.post(SERVER_API_URL + `api/v1/reporting/sf/export/async`, { excelType: excelType, excelTypeName: excelTypeName, opportunityIds: opportunityIds, recipientUserId: recipientUserId }, { headers: headersList });
  }

  getStatusCompanyPresent(companyCode) {
    const headersList = new HttpHeaders();
    return this.http.get(REPORTING_SERVER_API_URL_Entity + `/findByCRMCompanyCode`, { params: { companyCode: companyCode }, headers: headersList });
  }

  checkDuplicateRefNo(formValues: any): Observable<any> {
    return this.http.post(REPORTING_SERVER_API_URL + `/checkMandateDuplication`, formValues);
  }

  getAllocationCompanyData(payload: any): Observable<any> {
    return this.http.post(REPORTING_SERVER_API_URL_Allocation + '/findByCompanyCode', payload);
  }

  sendAllocationCompanyData(payload: any): Observable<any> {
    return this.http.post(REPORTING_SERVER_API_URL_Allocation + '/newAllocation', payload);
  }

  getAllocationHistoryIrf(start, end) {
    return this.http.get(REPORTING_SERVER_API_URL_Allocation + `/allocationHistory`, { params: { startDate: start, endDate: end } });
  }

  getAllocationHistorySf(payload) {
    return this.http.post(REPORTING_SERVER_API_URL_Allocation + `/sfAllocationHistory`, payload);
  }

  downloadHistoryAllocationIrf(payload: any) {
    return this.http.get(REPORTING_SERVER_API_URL_Allocation + `/allocationHistoryDownload`, { params: { id: payload } });
  }

  downloadHistoryAllocationsf(payload: any) {
    return this.http.get(REPORTING_SERVER_API_URL_Allocation + `/sfAllocationHistoryDownload`, { params: { id: payload } });
  }

  getCompanyGroupFinRisk(payload): Observable<any> {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/CRMintegration/findAllGroupCompanyByCompanyCode', payload);
  }

  getSfFinancialYearData(payload: any): Observable<any> {
    return this.http.post(REPORTING_SERVER_API_URL_Allocation + '/sfAllocationDataByFinancialYear', payload, { context: new HttpContext().set(BYPASS_LOADER, true) });
  }

  processSfFile(payload: any): Observable<any> {
    return this.http.post(REPORTING_SERVER_API_URL_Allocation + '/sfAllocationUpdate', payload);
  }
  convertToPng(data: any) {
    return this.http.post(SERVER_API_URL + '/api/v1/file/convertFilesToPNG', data);
  }

  findPDF(payload: any): Observable<any> {
    return this.http.post(FILEUPLOAD_URL + 'findPdfById', payload, { context: new HttpContext().set(BYPASS_LOADER, true) })
  }

  mergeByIds(payload: any): Observable<any> {
    return this.http.post(FILEUPLOAD_URL + 'mergePdfsById', payload)
  }

  getReportData(data: any) {
    return this.http.post(REPORTING_SERVER_API_URL + `/extractIrfSfReportToexcel`, data, { context: new HttpContext().set(BYPASS_LOADER, true) });
  }

  generateData(data: any) {
    return this.http.post(REPORTING_SERVER_API_URL + `/sf/export/async`, data, { context: new HttpContext().set(BYPASS_LOADER, true) });
  }

  getMeetingTypes(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/MeetingReportTypes').pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getPaymentStatus(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/PaymentFinStatus').pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getCrmCotactById(payload: any) {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/CRMintegration/findContactByContactId', payload)
  }

  updateOpsUser(payload: any) {
    return this.http.post(NEXERA_SERVER_API_URL_OPPORTUNITY + '/updateOpsUserName', payload)
  }

  getSFInitiationGridList(startDate: String, endDate: String, bdSpocUserId?: String, userRole?: String, department?: String) {
    let payload = {
      startDate, endDate, bdSpocUserId, userRole, department
    };
    return this.http.post(SFINITIATION_URL + '/findAll', payload);
    // return this.http.post('https://crisilratingsbd-qa.crisil.com/nexera/service/api/v1/sf/initiation' + '/findAll', payload);
  }

  getSFRealizationGridList(startDate: String, endDate: String, finYear: String) {
    let payload = {
      startDate: startDate || null, endDate: endDate || null, financialYear: finYear || null
    };
    return this.http.post(SFREALIZATION_URL + '/findAll', payload);
  }

  getCompanySearchResults(companyName: string) {
    companyName = this.encDec.encryptWith(companyName);
    return this.http.get(SFREALIZATION_URL + `/findAllCompanyName?startChars=${companyName}`).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getCompanyByEntityId(entityId: string) {
    entityId = this.encDec.encryptWith(entityId);
    return this.http.get(SFREALIZATION_URL + `/findByEntityId?entityId=${entityId}`);
  }

  getAttachmentsByOpportunityIdAndModule(opportunityId: number, moduleName: string) {
    let encOpportunityId = this.encDec.encryptWith(opportunityId);
    let encModuleName = this.encDec.encryptWith(moduleName);
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/attachment/by/module?opportunityId=${encOpportunityId}&moduleName=${encModuleName}`);
  }

  sfInitiationExport(payload: any, fileName: string): Observable<any> {
    const headersList = new HttpHeaders();
    return this.exportExcelUtil(
      SFINITIATION_URL + '/exportSfInitiation',
      payload,
      { headers: headersList, context: new HttpContext().set(BYPASS_LOADER, true) },
      fileName
    );
  }

  sfRealizationExport(payload: any, fileName: string): Observable<any> {
    const headersList = new HttpHeaders();
    return this.exportExcelUtil(
      SFREALIZATION_URL + '/exportSfRealization',
      payload,
      { headers: headersList, context: new HttpContext().set(BYPASS_LOADER, true) },
      fileName
    );
  }

  exportExcelUtil(url: string, payload: any, headers, fileName: string) {
    return this.http.post(url, payload, headers).pipe(switchMap((res: any) => {
      let isFileDownloadable = this.downloadExcel(res.base64, fileName);
      return of(isFileDownloadable || undefined);
    }));
  }

  downloadExcel(base64: string, fileName: string) {
    if (base64 == null) {
      return false;
    }
    let src = `data:application/vnd.ms-excel;base64,${base64}`;
    const link = document.createElement('a');
    link.href = src;
    link.download = fileName
    link.click();
    return true;
  }

  convertToBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  importRealizationExcel(payload: any): Observable<any> {
    return this.http.post(SFREALIZATION_URL + '/import', payload, { headers: new HttpHeaders(), context: new HttpContext().set(BYPASS_LOADER, true) });
  }

  getCaseInitiationStatus() {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/CaseInitiationStatus');
  }

  getCheckData(opportunityId: any) {
    return this.http.get(SFINITIATION_URL + `/checks/apply`, { params: { opportunityId: opportunityId } });
  }

  generateOtp(payload: any): Observable<any> {
    return this.http.post(SFREALIZATION_URL + '/otp/generate', payload);
  }

  validateOtp(sfBillId: any, opportunityPaymentId: any): Observable<any> {
    let payload = {
      sfId: sfBillId,
      opportunityPaymentId: opportunityPaymentId
    };
    return this.http.post(SFREALIZATION_URL + '/otp/validate', payload);
  }

  updateFinanceData(payload: any): Observable<any> {
    return this.http.post(SFREALIZATION_URL + '/update', payload);
  }

  getPaymentStatusTypes(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/PaymentFinStatus');
  }

  getTdsType(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findDataByCombineMaster/TDSType`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  getSfInitiationById(payload: any) {
    return this.http.post(SFINITIATION_URL + `/findById`, payload);
  }

  getSfBdHierarchy(payload: any) {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/findByEmployeeId`, { params: { employeeId: payload } })
  }

  nexeraUserList(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/findAllUserList`, { params: { type: 'E' }, headers: headersList });
  }

  saveInitiationDetails(payload): Observable<any> {
    return this.http.post(SFINITIATION_URL + `/update`, payload);
  }

  getClientAttendees(entityId, userId): Observable<any> {
    const headersList = new HttpHeaders();
    userId = this.encDec.encryptWith(userId);
    entityId = this.encDec.encryptWith(entityId);
    return this.http.get(SERVER_API_URL + `api/v1/meetings/findClientContactByEntity/${entityId}?userId=` + userId, { headers: headersList });
  }

  caseInitationStatus(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/CaseInitiationStatus')
  }

  getRevisionData(payload): Observable<any> {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/revision/findById', payload);
  }

  generateOta(payload): Observable<any> {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/revision/ota/generate', payload)
  }

  findInstrumentList(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/getSingleMasterData/Instrument`, { headers: headersList }).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4)))
    );
  }

  validateOta(payload): Observable<any> {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/revision/ota/validate', payload);
  }

  cancelPendingOta(payload): Observable<any> {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/revision/ota/cancel', payload);
  }

  resendOta(payload): Observable<any> {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/revision/ota/resend', payload);
  }

  getSFPushGridList(startDate: String, endDate: String) {
    let payload = {
      startDate: startDate || null, endDate: endDate || null
    };
    return this.http.post(SFPUSHGRID_URL + '/findAll', payload);
  }

  getPushCompanySearchResults(companyName: string) {
    companyName = this.encDec.encryptWith(companyName);
    return this.http.get(SFPUSHGRID_URL + `/findAllCompanyName?startChars=${companyName}`).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getCompanyBySfBillId(sfBillId: string) {
    sfBillId = this.encDec.encryptWith(sfBillId);
    return this.http.get(SFPUSHGRID_URL + `/findBySfBillId?sfBillId=${sfBillId}`);
  }

  getAgreementDetails(payload: any): Observable<any> {
    return this.http.post(SERVER_API_URL + 'api/v1/sfbillagreements/findBySfBillId', payload);
  }

  //SERVER_API_INTEGRATION_URL

  pushInvoiceToFusion(payload: any) {
    return this.http.post(SFPUSHGRID_URL + '/pushToFusion?invoiceName=yge7dWiAvoHyQE+cAlTXhg==', payload);
  }

  pushCNToFusion(payload: any) {
    return this.http.post(SFPUSHGRID_URL + '/pushToFusion?invoiceName=ekP+wZp1REHJKriZyDKzBQ==', payload);
  }

  pushRevisionToFusion(payload: any) {
    return this.http.post(SFPUSHGRID_URL + '/pushToFusion?invoiceName=3bhB2vPqDDNfeKmmEl0/RQ==', payload);
  }

  // getInvoiceDetailsForActionables(opprId: string): Observable<any> {
  //   const encryptedOpprId = this.encDec.encryptWith(opprId);
  //   return this.http.get(SERVER_API_URL + '/api/v1/invoice/history?opportunityId=' + encryptedOpprId);
  // }

  getInvoiceHistoryForActionables(opprId : string, opprType? : any) : Observable<any> {
    const encryptedOpprId = this.encDec.encryptWith(opprId);
    return this.http.get(SERVER_API_URL + `api/v1/invoice/history${opprType === "IRF" ? `/irf` : ``}?opportunityId=` + encryptedOpprId);
  }

  getInvoiceDocForActionables(invoiceNo: string, opprType? : any) {
    const encryptedInvoiceNo = this.encDec.encryptWith(invoiceNo);
    return this.http.get(SERVER_API_URL + `api/v1/invoice/generate${opprType === "IRF" ? `/irf` : ``}?invoiceNumber=` + encryptedInvoiceNo);
  }
 
  //   // for local connection
  // getIRFPreviwDetails(OpportunityId: string) {
  //   const encryptedOpportunityId= this.encDec.encryptWith(OpportunityId);
  //   return this.http.get('http://localhost:8401/api/v1/irfpreview/irfPreviewDetails?opportunityId=' + encryptedOpportunityId);
  // }

  getIRFPreviwDetails(OppId: string) {
    const encryptedOpportunityId = this.encDec.encryptWith(OppId);
    return this.http.get(SERVER_API_URL + 'api/v1/irfpreview/irfPreviewDetails?opportunityId=' + encryptedOpportunityId);
  }

  //Row feedback api
  getRowFeedBack(agreementId: string, companyCode: String): Observable<any> {
    const encrypteagreementId = this.encDec.encryptWith(agreementId);
    const encryptecompanyCode = this.encDec.encryptWith(companyCode);
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/ROWIntegration/mandate/details?agreementId=${encrypteagreementId}&companyCode=${encryptecompanyCode}`)
  }

  getRptDetails(paylaod: any): Observable<any> {
    const encPayload = this.encDec.encryptWith(paylaod);
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/CRMintegration/findRptByCompanyCode?companyCode=${encPayload}`)
  }

  getCombineMaster(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/CombineMasters');
  }

  getSfFeeRule(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/SfFeeRule');
  }

  getClientType(payload): Observable<any> {
    const encPayload = this.encDec.encryptWith(payload);
    return this.http.get(SERVER_API_URL + `api/v1/reporting/findClientTypeByCompanyCode?companyCode=${encPayload}`)
  }
  // IRF INITIATION LISTING -

  findAllIrfInitiation(payload: any) {
    // const encPayload = this.encDec.encryptWith(payload);
    const headersList = new HttpHeaders();

    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irfinitiation/findAll`, payload, { headers: headersList });

  }

  getCompanySearchResultsIrfInitiation(companyName: string) {
    let payload = { name: companyName };
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irfinitiation/findCompanyName`, payload).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  clientTypeMaster(): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + 'api/v1/irfinitiation/getAllclientType');
  }

  productExposure(payload): Observable<any> {
    return this.http.post(SERVER_API_URL + 'api/v1/reporting/getProductExposure', payload);
  }

  getOpprStatusForInitiation() {
    return this.http.get(MASTER_SERVICE_URL + `api/masters/getIRFInitiationOpprStatus`);
  }

  // ALTERNATE BILLING DETAILS - 

  // grid findAll - 
  findAllAlternateBillingData(payload: any) {
    const headersList = new HttpHeaders();

    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/alternateBilling/findAll`, payload, { headers: headersList });

  }

  // detailed view - findById
  findByIdAlternateBillingData(payload: any) {
    const headersList = new HttpHeaders();

    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/alternateBilling/findById`, payload, { headers: headersList });

  }

  saveAlternateBillingDetail(payload: any) {
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/alternateBilling/update`, payload);
  }

  getCompanySearchResultsAlternateBilling(searchValue) {
    let payload = { companyName: searchValue };
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/alternateBilling/globalSearch`, payload).pipe(
      debounceTime(3000));
  }

  downloadExtractAlternateBilldata(payload: any) {
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/alternateBilling/export`, payload);
  }

  // getArchivalData(payload): Observable<any> {
  //   const headersList = new HttpHeaders();
  //   return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getByClientMandateList?lastYear=`,payload);
  // }

  getArchivalData(payload) {
    
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getByClientMandateList`,payload);
  }

  // getArchivalData() {
  //   return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getByClientMandateList?lastYear=NryQOQ4SOgqy4vceI8kjGw==&viewAll=`);
  // }

  getArchivalDataGlobalSearch(payload) {
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getByClientMandateByCompany`,payload);
  }


  getAgreementDetail(agreementId) {
    const encrypteagreementId = this.encDec.encryptWith(agreementId);
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getArchivalAgreementDetails?agreementId=${encrypteagreementId}`);
  }

  getPortfolioManagerList(companyCode,agreementId) {
    const encCompanyCode = this.encDec.encryptWith(companyCode)
    const encrypteagreementId = this.encDec.encryptWith(agreementId);
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getPortfolioManagerList?companyCode=${encCompanyCode}&agreementId=${encrypteagreementId}`);
  }

  getArchivalIrfBillDetails(irfBillId) {
    const encrypteirfBillId = this.encDec.encryptWith(irfBillId);
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getArchivalIrfBillDetails?irfBillId=${encrypteirfBillId}`);
  }

  getArchivalIrfBillPaymentDetails(irfBillId) {
    const encrypteirfBillId = this.encDec.encryptWith(irfBillId);
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getArchivalIrfBillPaymentDetails?irfBillId=${encrypteirfBillId}`);
  }

  // getArchivalIrfBillDetails() {
  //   return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getArchivalIrfBillDetails?irfBillId=P2Ex4qdxqozWYAeXUo3ztg==`);
  // }

  // getArchivalIrfBillPaymentDetails() {
  //   return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getArchivalIrfBillPaymentDetails?irfBillId=P2Ex4qdxqozWYAeXUo3ztg==`);
  // }


  downloadExtractQCReport(payload: any){
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/qualityCheck/extractQcReportExtract`, payload);
  }

  downloadGenerateQCReport(payload: any){
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/qualityCheck/extractQcReportGenerate`, payload);
  }

  getMirCostCenterExtract(payload: any){
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/report/extract/MIRReport`, payload);
  }

  getMirCostCenterGenerate(payload: any){
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/report/export/async`, payload);
  }

  getQcActions(modules, qcStatus, agreementId): Observable<any>{
    const encAgreement = this.encDec.encryptWith(agreementId)
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfqc/actions?agreementId=${encAgreement}&parentModules=${modules}&qcFieldStatus=${qcStatus}`);
  }

  qcResubmit(payload): Observable<any> {
    return this.http.post(BACKOFFICE_SERVER_API_URL + 'api/v1/irfqc/resubmit', payload);
  }

  getAuditLog(agreementId) {
    const encAgreement = this.encDec.encryptWith(agreementId)
    return this.http.get( BACKOFFICE_SERVER_API_URL + `api/v1/irfqc/findLogByAgreementId?agreementId=${encAgreement}`);
  }
  getIrfPushPullGridListData(startDate: String, endDate: String, status?: String, companyCode?: String){
    let payload = {
      startDate : startDate || null, endDate : endDate || null, status : status || null, companyCode : companyCode || null,
    };
    
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irf/pushpull/findAll`, payload);
  }
  
  // pushInvoiceIrfPushPull(payload:any){
  //   return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v2/pushInvoiceIRF',payload);
  // }
  
  pushInvoiceIrfPushPull(cases: any, invoiceType){
    let type = this.encDec.encryptWith(invoiceType);
    let payload = {
      ofaPushRequestListIds : cases, 
      irfRequest : "true"
    }
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irf/pushpull/pushToFusion?invoiceName=${type}`,payload);
  }

  getGlobalSearchCompanyResults(payload: any) {
    // companyName = this.encDec.encryptWith(companyName);
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irf/pushpull/globalSearch`,payload).pipe(
      retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getStatusCounts() {
    // companyName = this.encDec.encryptWith(companyName);
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irf/pushpull/getCount`);
  }
  
  getExportIrfPushPull(payload): Observable<any> {
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irf/pushpull/export`,  payload);
  }
  
  getIrfMandateAttachments(oppId:any): Observable<any>{
    return this.http.get(FILEUPLOAD_URL+ `files/irfPushPull/${oppId}`);
  }

  getAssignmentData(payload: any): Observable<any> {
    const encPay = this.encDec.encryptWith(payload);
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/getAssignmenDetials?agreementId=${encPay}`);
  }
  
  getQcFormDetails(payload): Observable<any> {
    const encPay = this.encDec.encryptWith(payload);
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfarchivalscreen/findQcDetailsByAgreementId?agreementId=${encPay}`);
  }

  singleFileUpload(payload): Observable<any> {
    return this.http.post(NEXERA_SERVER_API_URL + 'api/v1/attachment/upload', payload);
  }

  deleteFileById(paylaod): Observable<any> {
    const encPayload = this.encDec.encryptWith(paylaod);
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/attachment/delete?fileId=${encPayload}`);
  }

  deleteFileByModuleIdName(paylaod): Observable<any>{
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/attachment/delete/by/module?moduleId=ISzprReolxJjcIqUzeIbfg==&moduleName=jkSy/+GS5aICopubUabPsg==`);
  }

  deleteMultipleFiles(payload): Observable<any>{
    return this.http.post(NEXERA_SERVER_API_URL + `api/v1/attachment/delete/multiple`, payload);
  }

  getFinancialYear():Observable<any>{
    const headersList = new HttpHeaders();
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+'api/v1/masters/financialyear', { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getSfEntityStatus():Observable<any>{
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_INTEGRATION_URL+'api/v1/integration/findDataByCombineMaster/SF Entity Status', { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getAssignmentFamily():Observable<any>{
    const headersList = new HttpHeaders();
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+'api/v1/masters/assignmentfamily', { headers: headersList }).pipe(retryWhen(errors => errors.pipe(delay(1000), take(4))));
  }

  getBDOwnerNames(payload:any) :Observable<any>{
    let filterPayload = {
      nameStartChar: payload
    }
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/hrms/getHrmsEmployeeDetails`, filterPayload);
  }

  sfBillReport(paylaod) :Observable<any> {
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/sfbilling/track/export`, paylaod);
  }
  
  checkInvalidFileContent(event, callback, n = 3, invalidBytes = [77, 90, 144], ...otherParam) { // default -> .exe file bytes
    let file = event.currentFiles[0] || event.files[0];
    const blob = file?.slice(0, n);
    invalidBytes = invalidBytes.slice(0, n);
    const reader = new FileReader();
   
    reader.onload = function(ev : any) {
      const bytes = new Uint8Array(ev?.target?.result);
      let allInvalidBytesMatch = true;
      for(let i = 0; i < n && allInvalidBytesMatch; i++)
        allInvalidBytesMatch = allInvalidBytesMatch && bytes[i] === invalidBytes[i];

      if(allInvalidBytesMatch)  // all invalid bytes have matched, hence this file is invalid type
        callback(false);
      else callback(event, ...otherParam);
    };
   
    // Handle read errors (optional but recommended)
    reader.onerror = function(event) {
      console.error("Error reading file:", reader.error);
      callback(undefined); // Assume false if there was an error reading
    };
   
    reader.readAsArrayBuffer(blob);
  }

  showDialog(): void {
    this.dialogService.open(NoPermissionToAccessComponent, {
      contentStyle: { 'max-height': '500px', "overflow": "auto" },
      header: 'Access Denied!',
      closable: false,
      closeOnEscape: false,
      styleClass: 'sm'
    });
  }

  getFeeCapFinancialYear(){
    return this.http.get(USERMANAGEMENT_SERVER_API_URL+'api/v1/masters/feecap/financialyear');
  }

  saveOtherDepartmentUsers(payload):Observable<any>{
    return this.http.post(USERMANAGEMENT_SERVER_API_URL + `api/v1/hrms/other/user/update`, payload);
  }

  getPartialCnAmount(sfBillId:any,companyCode:any): Observable<any>{
    const encryptedSfBillId = this.encDec.encryptWith(sfBillId);
    const ecryptedCompanyCode = this.encDec.encryptWith(companyCode);
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/sf/partial/cn/cnAmount?sfBillId='+encryptedSfBillId+'&companyCode='+ecryptedCompanyCode);
  }

  partialCnExport(){
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/sf/partial/cn/export');
  }
  
  partialCnImport(paylaod){
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/sf/partial/cn/import', paylaod);
  }

  pushSfBillToHoldUnHold(paylaod){
    return this.http.post(SERVER_API_URL + 'api/v1/sf/pushpull/update/status', paylaod);
  }
  
  getCaseCloseStatusCheck(payload):Observable<any>{
    return this.http.post(SERVER_API_INTEGRATION_URL+'api/v1/ROWIntegration/caseClose/status', payload);
  }

  updateIrfInvoiceStatus(payload: any): Observable<any> {
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irf/pushpull/update/status`, payload);
  }

  miscDocMailAfterQCActioned(payload: any): Observable<any> {
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irfqc/MiscDocMailAfterQCActioned`, payload);
  }
}
