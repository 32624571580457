import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_INTEGRATION_URL } from 'app/app.constants';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';

@Injectable({
  providedIn: 'root'
})
export class KmAgreementsService {

  constructor(public http: HttpClient, private encDec: EncryptDecryptInterceptor) { }

  getKmAgreements(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/row/km/documents');
  }

  getKmDocumentFile(documentId:any){
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/row/km/document/file?documentId='+this.encDec.encrypt(documentId));
  }
}
