import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { Table } from 'primeng/table';
import { Location } from '@angular/common';
import { KmAgreementsService } from '../km-agreements.service';
import * as XLSX from 'xlsx';
import { ExportExcelService } from 'app/shared/util/export-excel.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { FormBuilder } from '@angular/forms';
import { PagesService } from 'app/pages/pages.service';

@Component({
  selector: 'backoffice-km-agreements',
  templateUrl: './km-agreements.component.html',
  styleUrls: ['./km-agreements.component.scss'],
  providers: [DialogService, MessageService]
})
export class KmAgreementsComponent implements OnInit{
  @ViewChildren('mainGrid') mainGrid;
  @ViewChild('mainGrid') mainGrid1: Table;
  
  filterDisplayRow: boolean = true;
  kmAgreementList:any[];
  titleList:any;
  uploadedList:any;
  apiFail = false;
  firstLoad = false;
  ccprimebreadcrumbList = [
    { label: `KM Ratings BD Agreements`, routerLink: ['/km-agreements/listing'] },
  ];
  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  selectAllCompanyName: boolean = false;
  selectAllUploadedBy: boolean = false;
  kmAgreementForm = this.fb.group({
    companyNameFilter:[null as any,[]],
    uploadedByFilter:[null as any,[]]
  });

  showEmptyDocState: boolean = false;
  showDocListModal: boolean = false;
  allAgreements: any = [];
  docList: any = [];
  thumbnail: any;

  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;
  zipThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/zipThumbnail.svg`;

  homeKmBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  constructor(private cd: ChangeDetectorRef,private _location: Location, private apiService: KmAgreementsService,private exportToExcel:ExportExcelService,private messageService: MessageService,private encDec: EncryptDecryptInterceptor,private fb: FormBuilder, private pageService: PagesService){}
  ngOnInit(): void {
    this.getKmAgreementDetails();
  }

  // method to toggle filter icon, and reset table on filter clear
  toggleFilter(table: Table) {
    this.filterDisplayRow = !this.filterDisplayRow;
    this.cd.detectChanges();
    if (this.filterDisplayRow === false) {
      table?.reset();
      if (this.mainGrid?.first?.filters) { // reset all filters
        Object.values(this.mainGrid.first.filters).map(item => {
          (<any>item).value = null;
          return item;
        });
      }
      this.kmAgreementForm.get('companyNameFilter').reset();
      this.kmAgreementForm.get('uploadedByFilter').reset();
      this.selectAllCompanyName = false;
      this.selectAllUploadedBy = false;

    }
  }

  exportExcel(){
    
    let agreementSelectedListTable = this.mainGrid.first.filteredValue;
    if(agreementSelectedListTable == null){
      agreementSelectedListTable = this.mainGrid.first._value;
    }
    let srNo = 0;
    let agreementSelectedList = agreementSelectedListTable;
    agreementSelectedList.forEach(agreement =>{
      srNo =srNo + 1;
      agreement.srNo = srNo;

    });

    const exportExcelDataList = agreementSelectedList.map(({ id, actualFileName, titleByList, uploadedByList,...rest }) => ({
      "Srno":rest.srNo,
      "Title": rest.title,
      "Uploaded On": this.fomatedDate(rest.uploadedOn),
      "Uploaded By": rest.uploadedBy,
      "Description": rest.description 
    })); 

    const date = new Date(); // current date and time 
    const day = date.toLocaleString('en-IN', { day: '2-digit' });
    const month = date.toLocaleString('en-IN', { month: 'short' });
    const year = date.toLocaleString('en-IN', { year: '2-digit' });
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0'); 

    const formattedDate = `KM agreement ${day}${month}${year} ${hour}${minute}${second}.xlsx`; 
    
    this.exportToExcel.exportToExcel(exportExcelDataList,formattedDate);
  }

  fomatedDate(uploadedOn:any){
    let uploadedDate= new Date(uploadedOn);
    const day = uploadedDate.toLocaleString('en-IN', { day: '2-digit' });
    const month = uploadedDate.toLocaleString('en-IN', { month: 'short' });
    const year = uploadedDate.toLocaleString('en-IN', { year: 'numeric' });
    return `${day}-${month}-${year}`;
  }

  onBackClick() {
    this._location.back();
  }

  getKmAgreementDetails(){
    this.apiService.getKmAgreements().subscribe(res=>{
      if(res != null){
        this.allAgreements = res;
        this.setKmGridData(res);
      } 
      this.apiFail = false;
    },(err)=>{
      this.apiFail = true;
    });
  }

  setKmGridData(agreements:any){
    let agreementList = agreements;
    this.titleList = new Set();
    this.uploadedList = new Set();
    let uniqueList: any = []
    uniqueList.push(agreementList[0]);
    agreementList.filter((obj, i) => {
      obj.thumb = this.getThumbnail(obj?.contentType)
      let index = uniqueList.findIndex(x => x.titleId == obj.titleId);
      if(index == -1){
        uniqueList.push(obj);
      }
    });
    this.kmAgreementList = uniqueList;

    this.kmAgreementList.forEach(item => {
      this.titleList.add(item.title);
      item.titleByList = {name: item.title}
      this.uploadedList.add(item.uploadedBy);
      item.uploadedByList = {name: item.uploadedBy};
      item.uploadedOn = this.formatDate(item.uploadedOn);
    });
    
    this.titleList = Array.from(this.titleList).map(item =>({name:item}));
    this.uploadedList = Array.from(this.uploadedList).map(item =>({name:item}));

    this.firstLoad = true;
  }

  formatDate(uploadedOn: string){
    let data = uploadedOn.split('T');
    const date = new Date(data[0]); // current date and time 
    const day = date.toLocaleString('en-IN', { day: '2-digit' });
    const month = date.toLocaleString('en-IN', { month: 'short' });
    const year = date.toLocaleString('en-IN', { year: '2-digit' });
    let days = `${day}-${month}-${year}`;
    return `${days}`;
  }

  downloadAttachment(agreementDetails: any){
    this.apiService.getKmDocumentFile(agreementDetails.fileId).subscribe(document=>{
      let ext = document["name"].split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          //'pdf', 'xls', 'xlsx', 'doc', 'docx', 'png', 'tif', 'jpg', 'gif' ,'zip', 'ZIP'
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            mime = 'data:image/png;base64,'
          } else if ( "msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
          } else if ( ['xls', 'xlsx'].includes(ext1?.toLowerCase())) {
            mime = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
          } else if(['doc', 'docx'].includes(ext1?.toLowerCase())){
            mime = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,';
          } else if(['tif', 'tiff'].includes(ext1?.toLowerCase())){
            mime = 'data:image/tiff;base64,'
          } else if(['gif'].includes(ext1?.toLowerCase())){
            mime = 'data:image/gif;base64,'
          } else if(['zip','ZIP'].includes(ext1?.toLowerCase())){
            mime = 'data:application/zip;base64,'
          }
          else {
            mime = 'data:application/pdf;base64,'
          }
          let urlSafe = `${mime}${document["documentBase64"]}`;

          this.downloadFile(document, urlSafe);
          
          this.messageService.add({ severity: 'success', summary: 'Document Downloaded Successfully' });
          this.apiFail = false;
    }, (err)=>{
      
      if(err.status.toString().startsWith("40")){
        this.messageService.add({ severity: 'error', detail: JSON.parse(this.encDec.decrypt(err?.error?.data)).body });
      } else {
        this.apiFail = true;
      }
      
    });
    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
  }

  downloadFile(item: any, urlSafe) {
    const link = document.createElement('a');
    link.href = urlSafe;
    link.download = item?.name;
    link.click();
  }

  applyFilterCompanyName(event: any) {
    if (event.checked) {
      this.selectAllCompanyName = event.checked;
      this.kmAgreementForm.get('companyNameFilter').reset();
      this.kmAgreementForm.patchValue({companyNameFilter: this.titleList});
      if(this.mainGrid1){
        this.mainGrid1.filter(this.kmAgreementForm.get('companyNameFilter')?.value, 'titleByList', 'in');
      }
    } else{
      this.selectAllCompanyName = event.checked;
      this.kmAgreementForm.get('companyNameFilter').reset();
      this.kmAgreementForm.patchValue({companyNameFilter:null});
    }
  }

  checkCompanyStatusAllSelection(event){
    if ((event !== null || event !== undefined) && (event.length == this.titleList.length)) {
      this.selectAllCompanyName = true;
    } else {
      this.selectAllCompanyName = false;
    }
  }

  applyFilterUploadedBy(event: any) {
    if (event.checked) {
      this.selectAllUploadedBy = event.checked;
      this.kmAgreementForm.get('uploadedByFilter').reset();
      this.kmAgreementForm.patchValue({uploadedByFilter: this.uploadedList});
      if(this.mainGrid1){
        this.mainGrid1.filter(this.kmAgreementForm.get('uploadedByFilter')?.value, 'uploadedByList', 'in');
      }
    } else{
      this.selectAllUploadedBy = event.checked;
      this.kmAgreementForm.get('uploadedByFilter').reset();
      this.kmAgreementForm.patchValue({uploadedByFilter:null});
    }
  }

  checkUploadedByAllSelection(event){
    if ((event !== null || event !== undefined) && (event.length == this.uploadedList.length)) {
      this.selectAllUploadedBy = true;
    } else {
      this.selectAllUploadedBy = false;
    }
  }

  viewttachments(titleId){
    this.docList = this.allAgreements.filter(x => x.titleId == titleId);
    this.showDocListModal = true;
    this.showEmptyDocState = this.docList?.length == 0;
  }

  getThumbnail(type) {
    if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg' || type == 'image/gif' || type == 'image/tiff') {
      return this.imgThumb;
    } else if (type == 'application/pdf') {
      return this.pdfThumb;
    } else if (type.startsWith('application/vnd.openxmlformats') || type.startsWith('application/msword')) {
      return this.wordThumb;
    } else if(type == 'application/x-zip-compressed') {
      return this.zipThumb;
    } else {
      return this.mailThumb;
    }
  }

}
