import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, ViewChildren, AfterViewInit, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { Table, TableCheckbox } from 'primeng/table';
import { PagesService } from '../pages.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { QcDetailsComponent } from 'app/quality-check/qc-details/qc-details.component';
import * as FileSaver from 'file-saver';
import { MessageService } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { ImageTransform } from 'ngx-image-cropper';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'backoffice-irf-push-pull-grid',
  templateUrl: './irf-push-pull-grid.component.html',
  styleUrls: ['./irf-push-pull-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class IrfPushPullGridComponent implements OnInit, AfterViewInit {
  @ViewChildren('mainGrid') mainGrid;
  @ViewChild('checkboxRef', { static: false }) checkboxRef!: TableCheckbox;
  @ViewChild('globalCalendar') globalCalendar: any;
  detailsPopUp: DynamicDialogRef = new DynamicDialogRef;
  ccprimebreadcrumbList = [
    { label: `IRF Invoices Listing`, routerLink: ['/pages/invoices/irf'] },
  ];
  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  loadingStatus = false;
  pendingCasesCount: any = 0;
  pendingInvoiceCount: any = 0;
  pendingCNCount: any = 0;
  casesSelected: any = 0;
  filterDisplay: boolean = true;
  showLoader: boolean = false;
  invoiceDataList: any[] = [];
  clientTypeList = [];
  clientTypeList2 = [
    {
      label: "CRISIL_ADV_MH",
      value: "CRISIL_ADV_MH"
    }, {
      label: "CRISIL_ADV_MH_CM",
      value: "CRISIL_ADV_MH_CM"
    }, {
      label: "CRISIL_RR",
      value: "CRISIL_RR"
    }, {
      label: "CRISIL_RR_CM",
      value: "CRISIL_RR_CM"
    }, {
      label: "CRL_ADV_MH_NR 1",
      value: "CRL_ADV_MH_NR 1"
    }, {
      label: "CRL_CM_ADV_MH_NR",
      value: "CRL_CM_ADV_MH_NR"
    }, {
      label: "CRL_RR_NR",
      value: "CRL_RR_NR"
    }, {
      label: "CRL_RR_CM_NR",
      value: "CRL_RR_CM_NR"
    }
  ];
  clientTypeIDList2 = ["CRISIL_ADV_MH", "CRISIL_ADV_MH_CM", "CRISIL_RR", "CRISIL_RR_CM", "CRL_ADV_MH_NR 1", "CRL_CM_ADV_MH_NR", "CRL_RR_NR", "CRL_RR_CM_NR"];
  noOfAgreementsList = [];
  invoiceStatusList = [];
  invoiceStatusList2 = [
    {
      label: "Pending",
      value: "Pending"
    }, {
      label: "WIP",
      value: "WIP"
    }, {
      label: "Processed",
      value: "Processed"
    }, {
      label: "Error",
      value: "Error"
    }, {
      label: "Hold",
      value: "Hold"
    }, {
      label: "Rejected",
      value: "Rejected"
    }
  ];
  selectedStatus: any;
  transcationTypeList = [
    {
      label: "INVOICE",
      value: "INVOICE"
    }, {
      label: "CM",
      value: "CM"
    }, {
      label: "REVISION",
      value: "REVISION"
    }
  ];
  transcationTypeIDList = ["INVOICE", "CM", "REVISION",];
  finRiskTypeList = [
    {
      label: "High Risk",
      value: "High Risk"
    }, {
      label: "Medium Risk",
      value: "Medium Risk"
    }, {
      label: "Low Risk",
      value: "Low Risk"
    }
  ];
  finRiskTypeIDList = ["High Risk", "Medium Risk", "Low Risk"];
  invoiceNoList = [];
  originalBilledAmountList = [];
  proposedBilledAmountList = [];
  amountRealizedList = [];
  cnNoList = [];
  errorStatusList = [];
  irfBillIdList = [];
  revisionReasons = [];
  isChecked: boolean = true;
  irfPullPushForm = this.fb.group({
    status: ['Pending', []],
    globalCalendar: [null as any, []],
    globalSearchInput: [null, Validators.minLength(3)],
    customerTransactionTypeName: [null as any, []],
    rptOverAll: [null as any, []],
    finRiskName: [null as any, []],
    transcationTypeName: [null as any, []],
    reprocessComment: [null, [Validators.maxLength(200), Validators.minLength(5)]],
  });
  disableProcessBtn: boolean = true;
  irfBillDataList = [];
  selectIRFBillIdList = [];
  gridLoader: boolean = false;
  apiFail: boolean = false;
  queryirfBillId: any;
  showCross: boolean = false;
  showEntityLoader: boolean = false;
  companyNameList: any[];
  emptyMsgFlag: boolean = false;
  emptyMsgVal: string = 'No Company Found';
  minGlobalStartTime = new Date();
  maxGlobalEndTime = new Date();
  globalStartTime = null;
  globalEndTime = null;
  apiFailStatusSelection: boolean = false;
  datefilterSelected: boolean = true;
  fileUploadfileUpload1: any;
  uploadFile: any = {};
  attachmentResult: any = [];
  returnUploaded: any;
  documentList: any;
  oppGridDetials: any;
  //status grid
  customOptions = [
    { label: 'View Case Count', value: 'option1', disabled: true }
  ];
  selectedOption: any;
  statusCountRawData: [] = [];
  statusCountTransformedData: { [key: string]: { [key: string]: number | null } } = {};
  testStatuscount = null;
  statusCount;
  //global expandable search
  searchCompanyPopUp = false;
  companyNameSearchValue: any;
  globalSearchSuggestions: any[] = [];
  globalSearchSuggestionsCode: any[] = [];
  globalSearchInput: FormControl = new FormControl(null);
  globalSearchedCompanyCode: string = null;
  //editable&selectable row
  editing: boolean;
  reprocessComments: FormControl = new FormControl('', [Validators.maxLength(200), Validators.minLength(5)]);
  //attachment mandate
  mandate: any;
  openedFile: any;
  zoomValue: number = 1;
  displayPreview: boolean;
  dataLoader: boolean = false;
  apiList: any = [];
  urlSafe: any;
  previewFailed: boolean = false;
  //process check for adv, rr, cm and revision
  checkInvoiceNumber;
  statusCheckForINVNo: boolean = false;
  statusCheckForINVNoShowModal: boolean = false;
  statusCheckForINVNoMsg: string;
  checkStatusForList: any;
  //scope check
  checkScope: boolean = false;
  //maximum checkbox selections popup
  showMaximumSelectionPopup: boolean = false;
  //errorcases
  showErrorCaseCommentInputModal: boolean = false;
  errorCaseComment = "";
  selectAllcustomerTransactionType: boolean = false;
  @ViewChild('irfCustomerTransactionType') irfCustomerTransactionType: MultiSelect;
  selectAllfinRisk: boolean = false;
  @ViewChild('irffinRisk') irffinRisk: MultiSelect;
  selectAlltranscationType: boolean = false;
  @ViewChild('irftranscationType') irftranscationType: MultiSelect;
  @ViewChild('mainGrid') mainGrid1: Table;
  showHoldButton: boolean = true;
  showUnholdButton: boolean = false;
  showRejectButton: boolean = true;
  disableHoldButton: boolean = true;
  disableRejectButton: boolean = true;
  disableUnholdButton: boolean = true;
  showRejectCommentPopup: boolean = false;
  disableRejectAddButton: boolean = true;
  @ViewChild('fileuploadbtn') fileuploadbtn: FileUpload;
  rotate: number = 0;
  canvasRotation: number = 0;
  //upload document
  currentFileEvent: any;
  fileType = 'IRF Push Pull Rejection';
  fileTypeId: any;
  imgChangeEvt: any;
  imageBlob: any;
  uploadedFileName: any;
  returnBase64: any;
  displayModal: boolean = false;
  uploadLoader: boolean = false;
  croppedImageBase64: any;
  deleteFile: boolean = false;
  deleteFileData: any;
  transform: ImageTransform = {};
  uploadedFileIds: any = null;
  uploadedAttachmentsCallList: any;
  attachmentList: any;
  isPdf: boolean = false;
  previewLoader: boolean = false;
  currentPdf: any;
  disableRejectedSaveBtn: boolean = true;
  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;
  attachmentResponse: any;
  attachmentType: any = {};
  @ViewChild('fileuploadbtn', { static: false })
  fileuploadbtn1: any;
  isCaseClose: boolean = false;
  isCaseCloseStatusPopup: boolean = false;
  caseClosedMsgPopup: string = "";
  rejectedCommentDetails: any = null;
  exceededMaxLength: boolean = false;

  constructor(private _location: Location, private fb: FormBuilder, private pagesService: PagesService, private dialogService: DialogService, private renderer: Renderer2, private cd: ChangeDetectorRef, private encDec: EncryptDecryptInterceptor, private messageService: MessageService, private datepipe: DatePipe) { }
  ngAfterViewInit(): void {
    if (this.irfPullPushForm.get('status').value !== null) {  // 2 - default table filter on load of screen
      this.mainGrid.first.filter(this.irfPullPushForm.get('status').value, 'status', 'equals');
      this.checkbuttonDisabled(this.irfPullPushForm.get('status').value);
    }
  }

  ngOnInit(): void {
    this.getPushGridList();
    this.getCombineMaster();
    let month = this.minGlobalStartTime.getMonth();
    let year = this.minGlobalStartTime.getFullYear();
    let prevMonth = month;
    let prevYear = year - 1;
    this.minGlobalStartTime.setMonth(prevMonth);
    this.minGlobalStartTime.setFullYear(prevYear);
    let scopes: string = this.encDec.decrypt(localStorage.getItem('scopes'));
    if (scopes.includes("SF_REALIZATION_CREATE")) {
      this.checkScope = true;
    } else {
      this.checkScope = false;
    }
    this.fetchProcessedCases();
  }

  homeBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}/#/nexera/dashboard/home`;
    }
  }

  showFilter(table: Table) {
    this.filteredInvoicedateData = undefined;
    this.filterDisplay = !this.filterDisplay;
    this.cd.detectChanges();
    if (this.filterDisplay === false) {
      table?.reset();
      if (this.mainGrid?.first?.filters) { // reset all filters
        Object.values(this.mainGrid.first.filters).map(item => {
          (<any>item).value = null;
          return item;
        });
      }
      this.irfPullPushForm.patchValue({ status: "Pending" });
      this.irfPullPushForm.get('customerTransactionTypeName').reset();
      this.irfPullPushForm.patchValue({ customerTransactionTypeName: [] });
      this.irfPullPushForm.get('finRiskName').reset();
      this.irfPullPushForm.patchValue({ finRiskName: [] });
      this.irfPullPushForm.get('transcationTypeName').reset();
      this.irfPullPushForm.patchValue({ transcationTypeName: [] });
      this.getPushGridList();
    }
    this.unSelectHeaderCheckbox();
    this.checkcustomerTransactionTypeAllSelection(this.irfPullPushForm.get('customerTransactionTypeName')?.value);
    this.checkfinRiskAllSelection(this.irfPullPushForm.get('finRiskName')?.value);
    this.checktranscationTypeAllSelection(this.irfPullPushForm.get('transcationTypeName')?.value);
    this.checkbuttonDisabled(this.irfPullPushForm.get('status')?.value);
  }

  onBackClick() {
    this._location.back();
  }

  setListData(sfCaseDetails: Array<any>) {
    this.invoiceDataList = sfCaseDetails;
  }
  addSfBillData(irfBillData: any, isChecked: boolean) {
    let sfBillStatus = irfBillData.status;
    this.checkbuttonDisabled(sfBillStatus);
    if (sfBillStatus == 'Pending' || sfBillStatus == 'Error' || sfBillStatus == 'WIP' || sfBillStatus == 'Hold') {
      const index = this.irfBillIdList.indexOf(irfBillData);
      if (index === -1) {
        if (isChecked) {
          this.irfBillIdList.push(irfBillData);
          this.casesSelected += 1;
        } else if (!isChecked) {
          this.irfBillIdList.splice(index, 1);
          if (this.casesSelected > 0) {
            this.casesSelected -= 1;
          }
        }
        if (irfBillData) {
          let querySelectorData = "#checkboxRef_" + irfBillData?.invoiceNumber + " " + `input[type="checkbox"]`
          let checkboxEle = <HTMLInputElement>document.querySelector(querySelectorData);
          if (checkboxEle) {
            let selectAllCheckbox = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box') as HTMLElement;
            let selectAllCheckboxIcon = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box span');
            if (checkboxEle.checked == true) {
              selectAllCheckbox.click();
            }
          }
        }
      } else {
        if (!isChecked) {
          this.irfBillIdList.splice(index, 1);
          if (this.casesSelected > 0) {
            this.casesSelected -= 1;
          }
        }
      }
      if (this.irfBillIdList.length > 0) {
        if (this.irfPullPushForm.get('status')?.value !== 'Hold')
          this.disableProcessBtn = false;
      } else {
        this.disableProcessBtn = true;
      }
    }
    if (irfBillData.status === "Error" || irfBillData.status === "Rejected") {
      let rowComment = document.getElementById("selectedComment" + irfBillData.id) as HTMLTextAreaElement;
      console.log(rowComment.disabled);
      if (rowComment.disabled == false) {
        rowComment.disabled = true;
      } else
        rowComment.disabled = false
    }
    this.checkboxStatusButtonCheck(this.irfBillIdList);
  }

  trackByFn(index: number, item: any): any {
    return item.id; // Use a unique identifier for tracking
  }


  check(e, index) {
    console.log(e, index);
  }

  async processInvoice(actionStatus) {
    if (this.irfBillIdList.length != 0) {
      let processCreateInvoiceData = [];
      let processCreditMemoData = [];
      let irffInvoiceSet = new Set();
      irffInvoiceSet.add(this.invoiceDataList?.map(data_ =>
      ({
        invNumber: data_?.invoiceNumber,
        id: data_?.id,
        updatedComment: data_?.reprocessComment
      })
      ));
      this.gridLoader = true;
      this.selectIRFBillIdList = this.irfBillIdList;
      if (actionStatus === 'process' && !this.disableProcessBtn) {
        this.isCaseClose = await this.checkCaseClose(actionStatus, this.irfBillIdList);
        if (this.isCaseClose == false) {
          this.irfBillIdList.forEach(data => {
            let dynamicId = "selectedComment" + data.id
            let rowId = document.getElementById(dynamicId) as HTMLTextAreaElement;
            if (rowId?.value)
              rowId.innerHTML = rowId?.value;
              this.checkInvoiceNumber = data.invoiceNumber;
              const transationType = data.transationType;
              this.disableProcessBtn = true;
            if (data.customerTransactionType.includes('RR')) {
              this.checkFormat(data);
            } else {
              this.statusCheckForINVNo = true;
            }
            if (this.statusCheckForINVNo) {
              if (data.status === "Pending" || data.status === "WIP") {
                if (transationType === "CM") {
                  let invoiceData = {
                    "selectedInvoiceId": data.invoiceNumber,
                    "reprocessComment": rowId?.value || null
                  }
                  processCreditMemoData.push(invoiceData);
                } else {
                  let invoiceData = {
                    "selectedInvoiceId": data.invoiceNumber,
                    "reprocessComment": rowId?.value || null
                  }
                  processCreateInvoiceData.push(invoiceData);
                }
                this.statusCheckForINVNo = false;
                this.gridLoader = false;
              } else if (data.status === "Error" || rowId?.value != null) {
                if (rowId?.value === "") {
                  if (this.showErrorCaseCommentInputModal === true) {
                    this.showErrorCaseCommentInputModal = true;
                  } else {
                    this.showErrorCaseCommentInputModal = !this.showErrorCaseCommentInputModal;
                  }
                  this.errorCaseComment = "Re-process comment is mandatory to process Error cases.";
                  if (this.irfPullPushForm.get('status')?.value !== 'Hold')
                    this.disableProcessBtn = false;
                  this.gridLoader = false;
                } else if (rowId?.value.length < 5) {
                  if (this.showErrorCaseCommentInputModal === true) {
                    this.showErrorCaseCommentInputModal = true;
                  } else {
                    this.showErrorCaseCommentInputModal = !this.showErrorCaseCommentInputModal;
                  }
                  this.errorCaseComment = "Re-process comment needs to be minimum 5 charaters to process Error cases.";
                  if (this.irfPullPushForm.get('status')?.value !== 'Hold')
                    this.disableProcessBtn = false;
                  this.gridLoader = false;
                } else {
                  if (transationType === "CM") {
                    let invoiceData = {
                      "selectedInvoiceId": data.invoiceNumber,
                      "reprocessComment": rowId?.value || null
                    }
                    processCreditMemoData.push(invoiceData);
                  } else {
                    let invoiceData = {
                      "selectedInvoiceId": data.invoiceNumber,
                      "reprocessComment": rowId?.value || null
                    }
                    processCreateInvoiceData.push(invoiceData);
                    this.gridLoader = false;
                  }
                  this.statusCheckForINVNo = false;
                }
              }
            }
          });
          if (processCreateInvoiceData?.length > 0) {
            this.pagesService.pushInvoiceIrfPushPull(processCreateInvoiceData, "createinvoice").subscribe((results: any) => {
              console.log(results);

              this.getPushGridList(null, null, this.irfPullPushForm?.value?.status, null);
              this.createInvoiceDataList(this.selectIRFBillIdList);

              this.messageService.clear();
              this.messageService.add({ severity: 'success', summary: 'Data Processed' });
              this.irfBillDataList = [];
              this.irfBillIdList = [];
              this.casesSelected = 0;
              this.disableProcessBtn = true;
              setTimeout(() => {
                this.getPushGridList();
                this.checkbuttonDisabled(this.irfPullPushForm.get('status')?.value);
                this.getCountStatusGrid();
                this.messageService.clear();
                this.gridLoader = false;
                this.disableProcessBtn = true;
                this.unSelectHeaderCheckbox();
              }, 4000);
            }, (_error) => {
              this.gridLoader = false;
              this.apiFail = true;
            });
          }
          if (processCreditMemoData?.length > 0) {
            this.pagesService.pushInvoiceIrfPushPull(processCreditMemoData, "creditmemo").subscribe((results: any) => {
              console.log(results);
              this.getPushGridList(null, null, this.irfPullPushForm?.value?.status, null);
              this.createInvoiceDataList(this.selectIRFBillIdList);
              this.messageService.clear();
              this.messageService.add({ severity: 'success', summary: 'Data Processed' });
              this.irfBillDataList = [];
              this.irfBillIdList = [];
              this.casesSelected = 0;
              this.disableProcessBtn = true;
              setTimeout(() => {
                this.getPushGridList();
                this.checkbuttonDisabled(this.irfPullPushForm.get('status')?.value);
                this.getCountStatusGrid();
                this.messageService.clear();
                this.gridLoader = false;
                this.disableProcessBtn = true;
                this.unSelectHeaderCheckbox();
              }, 4000);
            }, (_error) => {
              this.gridLoader = false;
              this.apiFail = true;
            });
          }
        }
        else {
          this.isCaseCloseStatusPopup = true;
          this.caseClosedMsgPopup = "One or more selected case is Case Closed. Cannot process the case.";
          this.gridLoader = false;
        }
      } else if (actionStatus === 'reject' && !this.disableRejectButton) {
        this.isCaseClose = await this.checkCaseClose(actionStatus, this.irfBillIdList);
        if (this.isCaseClose == false) {
          this.isCaseCloseStatusPopup = true;
          this.caseClosedMsgPopup = "Case is not actioned as Case Closed.";
        } else {
          this.showRejectCommentPopup = true;
        }
        this.gridLoader = false;
      } else if (actionStatus === 'hold' && !this.disableHoldButton) {
        this.updateStatus('hold', this.irfBillIdList);
        this.gridLoader = false;
      } else if (actionStatus === 'unhold' && !this.disableUnholdButton) {
        this.updateStatus('unhold', this.irfBillIdList);
        this.gridLoader = false;
      }
      this.fetchProcessedCases();
    }
  }

  async checkCaseClose(status, list): Promise<boolean> {
    const aggNumberList = [...new Set(list.map(item => item.agreementId))];
    let payload = {
      "agreementId": aggNumberList
    }
    try {
      const responseStatusCheck = await this.pagesService.getCaseCloseStatusCheck(payload).toPromise();
      const isStatusClosed = responseStatusCheck.some(item => item.isStatusclosed === 'Yes');
      return isStatusClosed;
    } catch (error) {
      console.error(error);
      this.gridLoader = false;
      this.apiFail = true;
      return false;
    }
  }

  checkFormat(selectedItem: any) {
    let fetchedValue;
    fetchedValue = this.checkStatusForList.find((invoice) => invoice.irfBillId === selectedItem.irfBillId && invoice.transationType === selectedItem.transationType);
    if (fetchedValue != undefined && fetchedValue.customerTransactionType.includes("ADV")) {
      this.statusCheckForINVNo = true;
      this.unSelectHeaderCheckbox();
    } else {
      this.statusCheckForINVNo = false;
      if (this.irfPullPushForm.get('status')?.value !== 'Hold')
        this.disableProcessBtn = false;
      this.unSelectHeaderCheckbox();
      this.statusCheckForINVNoShowModal = true;
      this.statusCheckForINVNoMsg = "Agreement ID " + selectedItem.agreementId + " cannot be pushed, as CRISIL_ADV_MH is still not processed.";
    }
  }

  updateStatus(status, list) {
    const invNumberList = [...new Set(list.map(item => item.invoiceNumber))];
    let payload = {
      "isHold": status === 'hold' ? true : false,
      "isReject": status === 'reject' ? true : false,
      "isUnhold": status === 'unhold' ? true : false,
      "invNumList": invNumberList.length >= 1 ? invNumberList : null,
      "attachmentId": this.uploadedFileIds != null ? this.uploadedFileIds : null,
      "rejectionComment": this.rejectedCommentDetails != null ? this.rejectedCommentDetails : null
    }
    this.pagesService.updateIrfInvoiceStatus(payload).subscribe({
      next: (data: any) => {
        this.uploadedFileIds = null;
        this.rejectedCommentDetails = null;
        this.irfBillDataList = [];
        this.irfBillIdList = [];
        this.documentList = null;
        this.disableRejectAddButton = true;
        this.casesSelected = 0;
        this.disableProcessBtn = true;
        this.unSelectHeaderCheckbox();
        this.checkboxStatusButtonCheck(this.irfBillIdList);
      },
      complete: () => {
        this.getPushGridList();
        this.checkbuttonDisabled(this.irfPullPushForm.get('status')?.value);
        this.gridLoader = false;
        this.apiFail = false;
      },
      error: (err) => {
        this.gridLoader = false;
        this.apiFail = true;
      }
    });
    this.getCountStatusGrid();
  }

  checkMaxLengthRejectedComment(event) {
    const target = event.target.value;
    const maxLength = 1000;
    if (target.length <= maxLength) {
      this.exceededMaxLength = false;
    } else {
      this.exceededMaxLength = true;
    }
  }

  rejectComment() {
    if (!(this.disableRejectAddButton || this.exceededMaxLength)) {
      this.updateStatus('reject', this.irfBillIdList);
      this.showRejectCommentPopup = false;
    }
  }

  selectAllRows() {
    if (this.irfBillDataList != null && this.irfBillDataList.length != 0 && this.irfBillDataList != undefined) {
      this.irfBillIdList = this.irfBillDataList;
      this.casesSelected = this.irfBillIdList.length;
    } else {
      this.irfBillDataList = [];
      this.irfBillIdList = [];
      this.casesSelected = 0;
      this.disableProcessBtn = true;
    }
    if (this.irfBillIdList != null && this.irfBillIdList.length != 0 && this.irfBillIdList != undefined) {
      if (this.irfPullPushForm.get('status')?.value !== 'Hold')
        this.disableProcessBtn = false;
    } else {
      this.disableProcessBtn = true;
    }
    this.checkboxStatusButtonCheck(this.irfBillIdList);
  }

  unSelectHeaderCheckbox() {
    this.invoiceDataList.forEach(data => {
      let querySelectorData = "#checkboxRef_" + data?.invoiceNumber + " " + `input[type="checkbox"]`
      let checkboxEle = <HTMLInputElement>document.querySelector(querySelectorData);
      if (checkboxEle) {
        let selectAllCheckbox = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box') as HTMLElement;
        if (checkboxEle.checked == true) {
          selectAllCheckbox.click();
        }
      }
    });
    this.irfBillDataList = [];
    this.irfBillIdList = [];
    this.checkboxStatusButtonCheck(this.irfBillIdList);
    this.casesSelected = 0;
    this.disableProcessBtn = true;
    let querySelectorData = document.getElementsByClassName("mainheaderCheckbox");
    for (let i = 0; i < querySelectorData.length; i++) {
      let selectAllCheckbox = querySelectorData[i].querySelector('.p-checkbox-box') as HTMLElement;
      if (selectAllCheckbox.getAttribute("aria-checked") === "true") {
        selectAllCheckbox.click();
      }
    }
  }

  getPushGridList(startDate?: string, endDate?: string, status?: string, companyCode?: string) {
    status = this.irfPullPushForm?.value?.status;
    if (status !== null || (startDate !== null && endDate !== null && status !== null)) {
      if (status !== null) {
        this.apiFailStatusSelection = false;
        this.gridLoader = true;
        this.pagesService.getIrfPushPullGridListData(startDate, endDate, status, companyCode).subscribe({
          next: (irfCaseDetails: Array<any>) => {
            this.setListData(irfCaseDetails);
            this.irfBillDataList = [];
            this.irfBillIdList = [];
            this.casesSelected = 0;
            this.disableProcessBtn = true;
          },
          complete: () => {
            this.gridLoader = false;
            this.apiFail = false;
            this.getCountStatusGrid();

          },
          error: (err) => {
            this.gridLoader = false;
            this.apiFail = true;
          }
        })
      }
    } else {
      if (status === null || (startDate === null && endDate === null && status === null)) {
        this.apiFailStatusSelection = false;
      } else {
        this.apiFailStatusSelection = true;
        this.onClearGlobalDate();
      }
    }
  }

  onClearSearch(): void {
    this.getPushGridList();
    this.checkbuttonDisabled(this.irfPullPushForm.get('status')?.value);
  }

  filterGlobalTableByDate() {
    const startTime = this.irfPullPushForm?.value?.globalCalendar[0]?.split("/").reverse().join("-");
    const endTime = this.irfPullPushForm?.value?.globalCalendar[1]?.split("/").reverse().join("-");
    if (this.globalEndTime) {
      this.globalStartTime = startTime;
      this.globalEndTime = undefined;
      this.datefilterSelected = true;
    } else {
      this.globalStartTime = startTime;
      this.globalEndTime = endTime;
      if (this.globalEndTime) {
        this.datefilterSelected = false;
      } else {
        this.datefilterSelected = true;
      }
    }
    if (this.globalStartTime && this.globalEndTime) {
      this.globalCalendar.overlayVisible = false;
      this.queryirfBillId = null;
      this.showCross = false;
      this.showEntityLoader = false;
      this.getPushGridList(this.globalStartTime, this.globalEndTime, this.irfPullPushForm?.value?.status);
      this.checkbuttonDisabled(this.irfPullPushForm.get('status')?.value);
    }
  }

  onClearGlobalDate() {
    this.globalEndTime = undefined;
    this.globalStartTime = undefined;
    this.irfPullPushForm.patchValue({ globalCalendar: null });
    if (this.globalStartTime && this.globalEndTime) {
      this.getPushGridList(this.globalStartTime, this.globalEndTime);
      this.checkbuttonDisabled(this.irfPullPushForm.get('status')?.value);
    }
    this.datefilterSelected = true;
  }

  openDetailsPopup(rowData: any) {
    this.detailsPopUp = this.dialogService.open(QcDetailsComponent, {
      contentStyle: { "height": "100vh", "overflow": "hidden", "padding-top": "0" },
      closeOnEscape: false,
      closable: true,
      data: rowData,
      width: '90%',
      header: rowData.companyName
    });
    this.detailsPopUp.onClose.subscribe((response: any) => {
      let selectedCase = response;
      if (selectedCase) {
        let querySelectorData = "#checkboxRef_" + selectedCase?.invoiceNumber + " " + `input[type="checkbox"]`
        let checkboxEle = <HTMLInputElement>document.querySelector(querySelectorData);
        if (checkboxEle) {
          let selectAllCheckbox = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box') as HTMLElement;
          let selectAllCheckboxIcon = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box span');
          if (checkboxEle.checked == false) {
            selectAllCheckbox.click();
          }
        }
      }
    });
  }

  createInvoiceDataList(selectIRFBillIdList: any[]) {
    this.invoiceDataList.forEach((invoice) => {
      let index = selectIRFBillIdList.findIndex(s => s.invoiceNumber == invoice.invoiceNumber);
      if (index != -1) {
        invoice.status = "WIP";
      }
    });
    this.setListData(this.invoiceDataList);
    if (this.irfPullPushForm.get('status').value !== null) {  // 2 - default table filter on load of screen
      this.mainGrid.first.filter(this.irfPullPushForm.get('status').value, 'status', 'equals');
    }
  }

  showErrorMsg(data: any) {
    if (data?.errorStatus != null && data?.errorStatus != '' && data?.errorStatus != undefined) {
      let invoiceDetailsId = "tooltipOpp_" + data.invoiceNumber;
      document.getElementById(invoiceDetailsId)?.setAttribute('data-tooltip', data?.errorStatus);
      this.renderer.addClass(document.body, 'overflow-scroll');
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
  }

  exportExcel() {
    let excelpayload = {
      startDate: this.globalStartTime,
      endDate: this.globalEndTime,
    };
    let fileName = 'IRF_Push_Pull_List.csv';
    this.pagesService.getExportIrfPushPull(excelpayload).subscribe((res) => {
      const byteCharacters = atob(res.base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "text/csv" });
      FileSaver.saveAs(blob, fileName);
    });
  }

  checkStatus(e) {
    this.selectedStatus = e;
    this.irfBillDataList = [];
    this.irfBillIdList = [];
    this.casesSelected = 0;
    this.disableProcessBtn = true;
    this.checkbuttonDisabled(this.selectedStatus);
    this.afterCheckStatusFunction();
  }

  afterCheckStatusFunction() {
    if (this.companyNameSearchValue !== undefined) {
      this.applyCompanySearch()
    } else {
      this.getPushGridList(this.globalStartTime, this.globalEndTime, this.selectedStatus);
      this.checkbuttonDisabled(this.irfPullPushForm.get('status')?.value);
    }
  }

  getCountStatusGrid() {
    this.pagesService.getStatusCounts().subscribe((results: any) => {
      this.statusCountTransformedData = {};
      this.statusCount = results;
      this.statusCountRawData = results;
      if (this.statusCountRawData) {
        this.statusCountRawData.forEach((item: any) => {
          const customerTransactionType = item.customerTransactionType?.replace(/\s/g, '');
          const status = item.status;
          const count = item.count;
          if (!this.statusCountTransformedData[customerTransactionType]) {
            this.statusCountTransformedData[customerTransactionType] = {};
          }
          this.statusCountTransformedData[customerTransactionType][status] = count;
          this.testStatuscount += 1;
        });
      }
    });
  }

  openMandate(opID) {
    this.pagesService.getIrfMandateAttachments('37282').subscribe({
      next: (data: any) => {
        this.mandate = data;
      },
      error: (err) => {
      }
    })
  }

  showAttachmentPreview(item) {
    this.openedFile = item;
    let ext = item.fileName?.split('.')[1];
    if (ext.includes('msg')) {
      return;
    }
    this.zoomValue = 1;
    this.rotate = 0;
    if (item?.fileTypeid) {
      this.displayPreview = true;
      this.dataLoader = true;
      if (item && item?.file) {
        let ext = item?.fileName?.split('.');
        let ext1 = ext[ext.length - 1];
        let mime: any;
        if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
          this.isPdf = false;
          mime = 'data:image/png;base64,'
        } else {
          this.isPdf = true;
          mime = 'data:application/pdf;base64,'
        }
        this.urlSafe = `${mime}${item.file}`
        if (!this.isPdf) {
          let el = document.getElementById('imagePrev');
          setTimeout(() => {
            if(el)
              el.style.transform = 'rotate(0deg) scale(1)';
          }, 100);
        }
        this.dataLoader = false;
      } else {
        this.displayPreview = false;
        this.previewFailed = true;
      }
    } else {
      let tempArray: any = [];
      if (item.fileContentType == "application/pdf") {
        this.displayPreview = true;
        this.dataLoader = true;
        let data = '';
        if (item.file.startsWith('data:application/pdf;base64')) {
          data = item.file;
        } else {
          data = `data:application/pdf;base64,${item.file}`;
        }
        this.urlSafe = data;
        this.isPdf = true;
        this.dataLoader = false;
      } else {
        let obj: any = {};
        if (item.fileContentType.startsWith('application/vnd') || item.fileContentType == 'application/msword') {
          obj = {
            fileType: 'doc',
            fileName: item.fileName,
            attachmentType: item.fileTypeName,
            fileData: item.file.split(',')[1]
          }
        } else {
          obj = {
            fileType: item.fileContentType.split('/')[1],
            fileName: item.fileName,
            attachmentType: item.fileTypeName,
            fileData: item.file
          }
        }
        tempArray.push(obj);
        this.displayPreview = true;
        this.dataLoader = true;
      }
    }
  }
  processFile(opID) {
    let fetchedMandate;
    this.pagesService.getIrfMandateAttachments(opID).subscribe((res) => {
      fetchedMandate = res;
      if (fetchedMandate.length != 0) {
        const fileType41 = res.find(fileType => fileType.fileTypeid === '41');
        if (fileType41) {
          this.showAttachmentPreview(fileType41);
        } else {
          const fileType40 = res.find(fileType => fileType.fileTypeid === '40');
          if (fileType40) {
            this.showAttachmentPreview(fileType40);
          } else {
          }
        }
      } else {
        this.messageService.add({ severity: 'warn', summary: 'There are no Mandate Attachments available' });
        setTimeout(() => {
          this.messageService.clear();
        }, 4000);
      }
    }, (_error) => {
      this.displayPreview = false;
      this.previewFailed = true;
    })
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

  downloadFile() {
    let linkSource: any;
    if (this.openedFile?.fileContentType?.includes('image')) {
      let mime = this.openedFile?.file?.split(',')[0];
      linkSource = `${mime},${this.urlSafe?.split(',')[1]}`;
    } else {
      linkSource = this.urlSafe;
    }
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this?.openedFile?.fileName.split('.')[0];
    downloadLink.click();
  }

  showSearchCompanyPopUp() {
    if (!this.gridLoader && !this.loadingStatus)
      this.searchCompanyPopUp = true;
  }

  searchCompanyPopupClosed() {
    this.searchCompanyPopUp = false;
  }

  applyCompanySearch() {
    this.pagesService.getIrfPushPullGridListData(this.globalStartTime, this.globalEndTime, null, this.companyNameSearchValue?.companyCode).subscribe({
      next: (irfCaseDetails: Array<any>) => {
        this.setListData(irfCaseDetails);
        this.gridLoader = false;
        this.apiFail = false;
      },
      error: (err) => {
        this.gridLoader = false;
        this.apiFail = true;
      }
    })
    this.searchCompanyPopUp = false;
  }


  onChangeCompanySelect(e: any): void {
    console.log("event", e);
    this.showCross = true;
  }

  loadCompanyList(event: any): any {
    if (event.query.length >= 3) {
      this.showEntityLoader = true;
      let payload = {
        "companyName": event.query
      }
      this.pagesService.getGlobalSearchCompanyResults(payload).subscribe(
        (results: any) => {
          const responseData = results;
          this.showEntityLoader = false;
          this.companyNameList = [];
          if (responseData && responseData.length > 0) {
            this.companyNameList = responseData;
          } else {
            this.companyNameList = [];
            this.emptyMsgFlag = true;
          }
        }, (_err) => {
          this.companyNameList = []
          this.showEntityLoader = false;
          this.emptyMsgFlag = true;
        });
    } else {
      this.companyNameList = [];
    }
  }

  resetCompanySearch() {
    this.getPushGridList(this.globalStartTime, this.globalEndTime, "Pending", null);
    this.checkbuttonDisabled(this.irfPullPushForm.get('status')?.value);
    this.companyNameSearchValue = undefined;
    this.searchCompanyPopUp = false;
  }

  filteredInvoicedateData: any[];
  checkdateFilter(event, field) {
    console.log("filteredDate", event, field);
    const filteredValue = event;
    const formatfilteredValue = this.datepipe.transform(filteredValue, 'yyyy-MM-dd');
    this.filteredInvoicedateData = this.invoiceDataList.filter(item => {
      if (formatfilteredValue) {
        const date = new Date(item.invoiceDate);
        return date.toISOString().includes(formatfilteredValue);
      }
      return true;
    })
  }

  onClearColumnFilterDate() {
    this.filteredInvoicedateData = undefined;
    let status = this.irfPullPushForm?.value?.status;
    this.getPushGridList(this.globalStartTime, this.globalEndTime, status, null);
    this.checkbuttonDisabled(this.irfPullPushForm.get('status')?.value);
  }

  hideMaximumSelectionPopup() {
    this.showMaximumSelectionPopup = !this.showMaximumSelectionPopup;
  }

  hideErrorCaseCommentInputModal() {
    this.showErrorCaseCommentInputModal = !this.showErrorCaseCommentInputModal;
  }

  fetchProcessedCases() {
    if (this.companyNameSearchValue?.companyCode != null)
      this.pagesService.getIrfPushPullGridListData(null, null, null, this.companyNameSearchValue?.companyCode).subscribe((irfCaseDetails: Array<any>) => {
        this.checkStatusForList = irfCaseDetails;
      });
    else
      this.pagesService.getIrfPushPullGridListData(null, null, "Processed", null).subscribe((irfCaseDetails: Array<any>) => {
        this.checkStatusForList = irfCaseDetails;
      });
  }

  applyFiltercustomerTransactionType(event: any) {
    if (event.checked) {
      this.selectAllcustomerTransactionType = event.checked;
      this.irfPullPushForm.get('customerTransactionTypeName').reset();
      this.irfPullPushForm.patchValue({ customerTransactionTypeName: this.clientTypeIDList2 });
      if (this.mainGrid1) {
        this.mainGrid1.filter(this.irfPullPushForm.get('customerTransactionTypeName')?.value, 'customerTransactionType', 'in');
      }
    } else {
      this.selectAllcustomerTransactionType = event.checked;
      this.irfPullPushForm.get('customerTransactionTypeName').reset();
      this.irfPullPushForm.patchValue({ customerTransactionTypeName: [] });
      this.irfCustomerTransactionType.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }

  checkcustomerTransactionTypeAllSelection(eValue) {
    if (eValue !== null || eValue !== undefined) {
      this.selectAllcustomerTransactionType = false;
    } else if (eValue.length == this.clientTypeIDList2.length) {
      this.selectAllcustomerTransactionType = true;
    } else {
      this.selectAllcustomerTransactionType = false;
    }
  }

  applyFilterfinRisk(event: any) {
    console.log("event", event);
    if (event.checked) {
      this.selectAllfinRisk = event.checked;
      this.irfPullPushForm.get('finRiskName').reset();
      this.irfPullPushForm.patchValue({ finRiskName: this.finRiskTypeIDList });
      if (this.mainGrid1) {
        this.mainGrid1.filter(this.irfPullPushForm.get('finRiskName')?.value, 'finRisk', 'in');
      }
    } else {
      this.selectAllfinRisk = event.checked;
      this.irfPullPushForm.get('finRiskName').reset();
      this.irfPullPushForm.patchValue({ finRiskName: [] });
      this.irffinRisk.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }

  checkfinRiskAllSelection(eValue) {
    if (eValue !== null || eValue !== undefined) {
      this.selectAllfinRisk = false;
    } else if (eValue.length == this.finRiskTypeIDList.length) {
      this.selectAllfinRisk = true;
    } else {
      this.selectAllfinRisk = false;
    }
  }

  applyFiltertranscationType(event: any) {
    if (event.checked) {
      this.selectAlltranscationType = event.checked;
      this.irfPullPushForm.get('transcationTypeName').reset();
      this.irfPullPushForm.patchValue({ transcationTypeName: this.transcationTypeIDList });
      if (this.mainGrid1) {
        this.mainGrid1.filter(this.irfPullPushForm.get('transcationTypeName')?.value, 'transationType', 'in');
      }
    } else {
      this.selectAlltranscationType = event.checked;
      this.irfPullPushForm.get('transcationTypeName').reset();
      this.irfPullPushForm.patchValue({ transcationTypeName: [] });
      this.irftranscationType.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }

  checktranscationTypeAllSelection(eValue) {
    if (eValue !== null || eValue !== undefined) {
      this.selectAlltranscationType = false;
    } else if (eValue.length == this.transcationTypeIDList.length) {
      this.selectAlltranscationType = true;
    } else {
      this.selectAlltranscationType = false;
    }
  }

  checkbuttonDisabled(status) {
    if (status === "Pending") {
      this.disableHoldButton = true;
      this.disableRejectButton = true;
      this.disableUnholdButton = false;
      this.showUnholdButton = false;
      this.showHoldButton = true;
      this.showRejectButton = true;
    } else if (status === "Hold") {
      this.disableUnholdButton = true;
      this.disableHoldButton = false;
      this.disableRejectButton = true;
      this.showUnholdButton = true;
      this.showHoldButton = false;
      this.showRejectButton = true;
    } else if (status === "Rejected") {
      this.disableRejectButton = false;
      this.disableUnholdButton = false;
      this.disableHoldButton = false;
      this.showUnholdButton = false;
      this.showHoldButton = false;
      this.showRejectButton = false;
    } else {
      this.disableRejectButton = false;
      this.disableUnholdButton = false;
      this.disableHoldButton = true;
      this.showUnholdButton = false;
      this.showHoldButton = true;
      this.showRejectButton = false;
    }
  }

  checkboxStatusButtonCheck(list) {
    list.filter((item) => {
      if (item.status === "Pending") {
        if (list.length == 1) {
          this.disableRejectButton = false;
          this.disableHoldButton = false;
        } else if (list.length > 1) {
          this.disableRejectButton = true;
          this.disableHoldButton = false;
        } else {
          this.disableRejectButton = true;
          this.disableHoldButton = true;
        }
      } else if (item.status === "Hold") {
        if (list.length == 1) {
          this.disableRejectButton = false;
          this.disableUnholdButton = false;
        } else if (list.length > 1) {
          this.disableRejectButton = true;
          this.disableUnholdButton = false;
        } else {
          this.disableRejectButton = true;
          this.disableUnholdButton = true;
        }
      }
    });
    if (list.length == 0) {
      this.disableRejectButton = true;
      this.disableHoldButton = true;
      this.disableUnholdButton = true;
    }
  }


  fileuploadbtnFunction() {
    if (this.disableRejectAddButton) {
      this.fileuploadbtn.choose();
    }
  }


  onFileUplaod(event: any): void {
    this.canvasRotation = 0;
    this.currentFileEvent = event.currentFiles[0];
    if (event.files[0].type === "image/svg+xml" || event.files[0].type === "image/webp" || event.files[0].type.includes("html") || event.files[0].type === "xhtml" || event.files[0].type === "csv" || event.files[0].type === "txt" || event.files[0].type === "gif" || event.files[0].type === "application/exe" || event.files[0].type === "zip" || event.files[0].type === ".oft" || event.files[0].type === "application/x-msdownload") {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      this.dismissMessageService();
      return;
    }
    if (this.currentFileEvent == undefined) {
      this.messageService.add({ severity: 'error', summary: 'File size cannot exceed 20 MB.' });
      this.dismissMessageService();
      return;
    }
    let ext = this.currentFileEvent?.name?.split('.');
    if (ext[ext.length - 1] == 'jfif') {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      this.dismissMessageService();
      return;
    }
    this.imgChangeEvt = event.originalEvent;
    if (event.currentFiles[0]?.type == 'image/jpeg' || event.currentFiles[0]?.type == 'image/jpg' || event.currentFiles[0]?.type == 'image/png') {
      this.displayModal = true;
    } else {
      this.displayModal = false;
    }

    this.uploadLoader = true;
    for (const file of event.files) {
      if (file.type.includes('image')) {
        this.imageBlob = new Blob([file], { type: file.type });
        this.uploadedFileName = file.name;
      } else {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.currentFiles[0]);
        fileReader.onload = () => {
          this.returnBase64 = fileReader.result;
          this.onImgUpload();
        };
      }
    }
    if (event.currentFiles.length >= 1) {
      this.documentList = event.currentFiles;
      this.disableRejectAddButton = false;
    }
  }

  dismissMessageService() {
    this.documentList = undefined;
    this.disableRejectedSaveBtn = true;
    setTimeout(() => {
      this.messageService.clear()
    }, 3000);
  }

  onImgUpload() {
    if (this.dataURLtoFile && this.croppedImageBase64) {
      const file = this.dataURLtoFile(this.croppedImageBase64, this.uploadedFileName);
      this.fileuploadbtn1.files.push(file);
    }
    this.uploadFile = {};
    if (this.displayModal) {
      const displayData = this.getUploadedObjDisplay(this.fileType);
      this.documentList = displayData;
      this.uploadFile = displayData;
      this.uploadSingleFile(displayData);
    } else {
      this.returnUploaded = this.getUploadedObj(this.fileType);
      this.documentList = this.returnUploaded;
      this.uploadFile = this.returnUploaded;
      this.uploadSingleFile(this.returnUploaded);
    }
    if (this.documentList) {
      if (this.documentList.fileName.includes('.msg')) {
        this.documentList.fileTypeName = this.fileType;
        this.documentList.fileName = this.uploadFile.fileName;
        this.documentList.thumbNail = this.getThumbnail(this.uploadFile.fileContentType);
      }
    }
    this.displayModal = false;
    this.disableRejectAddButton = false;
  }

  uploadSingleFile(data) {
    this.uploadLoader = false;
    this.pagesService.singleFileUpload(data).subscribe((response) => {
      if (data.fileName == this.documentList?.fileName) {
        this.uploadedFileIds = response?.fileId;
        this.cd.detectChanges()
      }
    }, (_err) => {
      let obj = this.documentList?.find((d) => d?.fileName == data?.fileName);
      obj.file = '';
      obj.deleteId = null;
      obj.isDisabled = false;
      obj.fileName = '';
      obj.thumbNail = '';
      this.messageService.add({ severity: 'error', summary: 'something went wrong please try again!' });
    });
  }

  getAttachments(oppId) {
    this.pagesService.getAttachmentsByOpportunityIdAndModule(oppId, "SfBilling").subscribe((res) => {
      const fetchedData = res;
      this.documentList = res[0];
      this.attachmentList = res;
    });
  }

  getUploadedObjDisplay(fileType): any {
    let data = this.returnBase64;
    if (data.startsWith('data:application/pdf;base64,')) {
      data = data.replace('data:application/pdf;base64,', '');
    } else if (data.startsWith('data:image/png;base64,')) {
      data = data.replace('data:image/png;base64,', '');
    } else if (data.startsWith('data:application/doc;base64,')) {
      data = data.replace('data:application/doc;base64,', '');
    }
    return {
      userId: this.encDec.decrypt(localStorage.getItem('empid')),
      moduleName: 'IrfPushPull',
      moduleId: this.irfBillIdList[0].invoiceNumber,
      fileName: this.fileuploadbtn1.files[this.fileuploadbtn1.files.length - 1].name,
      file: data,
      fileContentType: this.fileuploadbtn1.files[this.fileuploadbtn1.files.length - 1].type,
      fileSize: (this.fileuploadbtn1.files[this.fileuploadbtn1.files.length - 1].size).toString(),
      fileTypeid: this.fileTypeId,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  getUploadedObj(fileType): any {
    return {
      userId: this.encDec.decrypt(localStorage.getItem('empid')),
      moduleName: 'IrfPushPull',
      moduleId: this.irfBillIdList[0].invoiceNumber,
      fileName: this.currentFileEvent?.name,
      file: this.returnBase64,
      fileContentType: this.currentFileEvent?.type,
      fileSize: (this.currentFileEvent?.size)?.toString(),
      fileTypeid: this.fileTypeId,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  getThumbnail(type) {
    if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') {
      return this.imgThumb;
    } else if (type == 'application/pdf') {
      return this.pdfThumb;
    } else if (type.startsWith('application/vnd.openxmlformats') || type.startsWith('application/msword')) {
      return this.wordThumb;
    } else {
      return this.mailThumb;
    }
  }

  dataURLtoFile(dataurl: any, filename: any): any {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  imageCropEvent(event: any): any {
    fetch(event.objectUrl)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.returnBase64 = reader.result;
          this.croppedImageBase64 = this.returnBase64;
        };
        reader.readAsDataURL(blob);
      });
  }

  onDeletePopup(item) {
    this.deleteFile = true;
    this.deleteFileData = item;
    if (this.deleteFileData.fileId == null) {
      this.deleteFileData.deleteId = this.uploadedFileIds
    } else if (this.deleteFileData.fileId != null) {
      this.deleteFileData.deleteId = item.fileId
    }
  }
  onDelete() {
    this.pagesService.deleteMultipleFiles([this.deleteFileData?.deleteId]).subscribe((res) => {
      if (res?.status == "success") {
        if (this.documentList?.type == this.deleteFileData.type) {
          this.documentList = null;
          this.disableRejectAddButton = true;
          this.uploadedFileIds = null;
        }
      }
    }, (_err) => {
      this.apiFail = true;
      this.disableRejectAddButton = true;
    });
    this.deleteFile = false;
  }

  showAttachmentPreviewReject(item) {
    this.openedFile = item;
    this.currentPdf = undefined;
    this.zoomValue = 1;
    this.rotate = 0;
    item.fileId = this.uploadedFileIds;
    if (item?.fileId) {
      this.previewLoader = true;
      let payload = {
        id: item.fileId
      }
      this.pagesService.findPDF(payload).subscribe((res) => {
        if (res && res?.file) {
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          let isPreviewable = true;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else if ("msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
            isPreviewable = false;
          }
          else if (['xls', 'xlsx'].includes(ext1?.toLowerCase())) {
            mime = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
            isPreviewable = false;
          }
          else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.attachmentResponse = res;
          this.urlSafe = `${mime}${res.file}`
          if (!this.isPdf) {
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              if(el)
                el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.displayPreview = isPreviewable;
          if (!isPreviewable) {
            this.downloadRejectedFile(res, this.urlSafe);
          }
          this.previewLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      });
    }
  }
  downloadRejectedFile(item: any, urlSafe) {
    const link = document.createElement('a');
    link.href = urlSafe;
    link.download = item?.fileName;
    link.click();
  }

  cropCancel() {
    this.displayModal = false;
    this.documentList = null;
    this.disableRejectAddButton = true;
  }

  closeRejectPopUp(item) {
    if(this.showRejectCommentPopup){
      this.showRejectCommentPopup = false;
      if (item) {
        this.deleteFileData = item;
        if (this.deleteFileData.fileId == null) {
          this.deleteFileData.deleteId = this.uploadedFileIds
        } else if (this.deleteFileData.fileId != null) {
          this.deleteFileData.deleteId = item.fileId
        }
        this.onDelete();
      }
      this.rejectedCommentDetails = null;
      this.disableRejectAddButton = true;
      this.exceededMaxLength = false;
    }
  }

  rotateLeft() {
    this.canvasRotation--;
  }

  rotateRight() {
    this.canvasRotation++;
  }

  getCombineMaster() {
    this.pagesService.getCombineMaster().subscribe((res) => {
      if (res?.length > 0) {
        this.attachmentType = res?.find(x => x.name == "AttachmentType" && x.value == 'IrfPushPullRejection');
      }
      console.log("this.attachmentType", this.attachmentType);

      if (this.attachmentType?.id) {
        this.fileTypeId = this.attachmentType?.id
      }
    });
  }

  downloadGridRejectAttachment(fileId) {
    let payload = {
      id: fileId
    }
    this.pagesService.findPDF(payload).subscribe((res) => {
      if (res && res?.file) {
        let ext = res?.fileName?.split('.');
        let ext1 = ext[ext.length - 1];
        let mime: any;
        let isPreviewable = true;
        if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
          this.isPdf = false;
          mime = 'data:image/png;base64,'
        } else if ("msg" === ext1?.toLowerCase()) {
          mime = 'data:application/vnd.ms-outlook;base64,';
          isPreviewable = false;
        }
        else if (['xls', 'xlsx'].includes(ext1?.toLowerCase())) {
          mime = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
          isPreviewable = false;
        }
        else {
          this.isPdf = true;
          mime = 'data:application/pdf;base64,'
        }
        this.attachmentResponse = res;
        this.urlSafe = `${mime}${res.file}`
        if (!this.isPdf) {
          let el = document.getElementById('imagePrev');
          setTimeout(() => {
            if(el)
              el.style.transform = 'rotate(0deg) scale(1)';
          }, 100);
        }
        this.previewLoader = false;
      } else {
        this.previewFailed = true;
      }
      this.downloadRejectedFile(this.attachmentResponse, this.urlSafe)
    }, (_error) => {
      this.previewFailed = true;
    });
  }

  closeCaseCloseStatusPopup() {
    this.isCaseCloseStatusPopup = false;
    this.caseClosedMsgPopup = '';
    this.irfBillDataList = [];
    this.irfBillIdList = [];
    this.casesSelected = 0;
    this.disableProcessBtn = true;
    this.unSelectHeaderCheckbox();
    this.checkboxStatusButtonCheck(this.irfBillIdList);
  }
}
