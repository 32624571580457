import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AttachmentModelComponent } from '../attachment-model/attachment-model.component';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { ImageTransform } from 'ngx-image-cropper';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import {switchMap, filter} from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'backoffice-sf-initiation-details',
  templateUrl: './sf-initiation-details.component.html',
  styleUrls: ['./sf-initiation-details.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class SfInitiationDetailsComponent implements OnInit {

  readonly pdfMime = 'data:application/pdf;base64,';
  activeIndex: any;
  disableSfDiscountReason = true;
  activeAttachmentIndex: number = 0;
  errorPaymentInvoice: boolean = false;
  errorAttachments: boolean = false;
  paymentModel: boolean = false;
  paymentModeList: any = [];
  bankList: any = [];
  paymentTypeList: any = [];
  tdsTypeList: any = [];
  filterTdsTypeList: any = [];
  gstContact: any;
  gstType: any;
  gstNumber: any;
  balanceAmount: number = 0;
  actualAmount: number;
  amountCollected: number = 0;
  appliedAmountExceeds: boolean = false;
  disabledTdsAmount: boolean = false;
  selectedTdsValue: any;
  grossAmount: number = 0;
  gstMisMatch: boolean = false;
  disableNoPayment: boolean = false;
  paymentErrorMsg: boolean = false;
  disableAddBtn: boolean = false;
  disabledPaymentMsg = '';
  disabledRevisionMsg = '';
  erroMsg: string = '';
  activePayment: number = 0;
  disabledData: any = {};
  currentPayment: any;
  initPaymentError: any;
  paymentTypeListFiltered: any = [];
  paymentTypeListFiltered2: any = [];
  sfDiscountReasonList = [];
  submitted: boolean = false;
  showAttachPopup: boolean = false;
  attachmentTypeList: any = [];
  documents: any = [];
  paymentModeNumber: any = [];
  dynamicDialogdialogRef: DynamicDialogRef = new DynamicDialogRef();
  editAttachmentPopup: boolean = false;
  isEdited: boolean = false;
  editAttachmentValue: any = {};
  initiationData: any;
  chequeNeeded: boolean = false;
  chequePayCount: number = 0;
  chequeAttachCount: number = 0;
  bdHierarchy: any = null;
  pageLoader: boolean = false;
  apiFail: boolean = false;
  empId: any;
  data: any;
  rptStatus: any = {};
  nexeraUsersList: any = [];
  isOppNexera: boolean = false;
  disableContact: boolean = false;
  userRole: any;
  disableActualExpected: boolean = false;
  previewLoader: boolean = false;
  displayPreview: boolean = false;
  isPdf: boolean = false;
  previewFailed: boolean = false;
  zoomValue: number = 0;
  rotate: number = 0;
  urlSafe: any;
  canvasRotation: number = 0;
  transform: ImageTransform = {};
  paymentContact: any = {};
  paymentContactList: any = [];
  paymentContactDisplayList: any = [];
  contactDetailsMissing: boolean = false;
  oppStatus: string;
  openedFile: any;
  reportingStatusList: any = [];
  initiationStatusList: any = [];
  initiationStatus: string;
  fullPayNeeded: boolean = false;
  payDetailsMissing: boolean = false;
  initiationReadScope: boolean = false;
  scopeList: any;
  sfActualFeesMissing: boolean = false;
  disabledSaveBtn: boolean = false;
  proceedCN: boolean = false;
  validationPopup: boolean = false;
  disableTdsType: boolean = false;
  deleteFile: boolean = false;
  errorMsg: string;
  fullPayRealz: any = {};
  payStatus: any = {};
  discountType: any;
  dateChecked: any;
  showTable: boolean = false;
  crmContacts: any;
  disabledCrmApplyBtn: boolean = true;
  disableActual: boolean = true;
  calendarVal = '0';
  sFDiscountMessage: string;
  sfShowMessage: boolean;
  reasonRequiredSF: boolean;
  crmContactPopup: boolean;
  selectedCrmContact: any;
  crmSingleContact: any;
  showSfCell: boolean = false;
  cancelInvoiceSelected: boolean = false;
  currentInvAmt: any;
  revisedInvAmt: any = 0;
  isNexeraOpp: any;
  invoiceGenerated: boolean = false;
  approverPopup: boolean = false;
  disableRevisionTab: boolean = false;
  instrumentList: any = [];
  productList: any = [];
  disableAll: boolean = false;
  enableAddAttach: boolean = false;
  docCount: number = 0;
  deleteFileData: any;
  paymentAdded: boolean = false;
  isPdfInvoice = false;
  invoiceDocDetails: any = [];
  urlPdf: string;
  cachedInvoiceDoc : {[key: string] : any};
  currentPdf : any;
  showOldInvoice = false;
  showInvoiceListModal = false;
  showEmptyInvoiceState = false;
  verifySaveBtn: boolean = true;
  rptoverall: any;
  rptInCC: any;
  rptInCrm: any;

  invoiceDetailsform = this.fb.group({
    financialYear: [null, []],
    invoiceNumber: [null, []],
    invoiceDate: [null as any, []],
    originalBillAmt: [null as any, []],
    sfActualExpected: [null, []],
    sfDiscountAmount: [null as any, []],
    sfDiscountPercentage: [null as any, []],
    sfDiscountReason: [null as any, []],
    collectedPendingRealz: [null as any, []],
    realized: [null as any, []],
    rejectedBounced: [null as any, []],
    pendingCollected: [null as any, []],
  });

  contactDetailsform = this.fb.group({
    salutation: [{value: null, disabled: true}, []],
    firstName: [{value: null, disabled: true}, []],
    lastName: [{value: null, disabled: true}, []],
    designation: [{value: null, disabled: true}, []],
    mobileNo: [{value: null, disabled: true}, []],
    landlineNo: [{value: null, disabled: true}, []],
    email1: [{value: null, disabled: true}, []],
    email2: [{value: null, disabled: true}, []],
    email3: [{value: null, disabled: true}, []],
    gstType: [{value: null, disabled: true}, []],
    gstNo: [{value: null, disabled: true}, []],
    panNo: [{value: null, disabled: true}, []],
    address1: [{value: null, disabled: true}, []],
    address2: [{value: null, disabled: true}, []],
    address3: [{value: null, disabled: true}, []],
    address4: [{value: null, disabled: true}, []],
    state: [{value: null, disabled: true}, []],
    city: [{value: null, disabled: true}, []],
    pincode: [{value: null, disabled: true}, []],
    contactVerified: [{value: null, disabled: true}, []]
  });

  paymentForm = this.fb.group({
    payments: this.fb.array([])
  });

  addpaymentForm = this.fb.group({
    paymentMode: ['', [Validators.required]],
    neftCheque: ['', [Validators.required]],
    bank: ['', [Validators.required]],
    paymentDate: ['', [Validators.required]],
    contact: ['' as any],
    paymentType: ['', [Validators.required]],
    tdsType: ['', [Validators.required]],
    amountCollected: ['', [Validators.required]],
    grossAmount: ['' as any],
    tdsAmount: ['' as any, [Validators.required]],
    comment: [''],
    finRemark: [''],
    status: [''],
    bankClearingDate: [''],
    initiatedPayment: ['']
  });

  editAttachmentForm = this.fb.group({
    fileTypeName: [null, [Validators.required]],
    paymentMode: [null, [Validators.required]],
  });

  thirdPartyForm = this.fb.group({
    thirdPartyApproval: [null, []],
    thirdPartyApprovalComment: [null, []],
  });

  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;


  rptPopup: boolean = false;
  revisionDetials: any = {};
  invoiceContact: any = {};
  disablePaymentTab: boolean = false;
  disableAddPayment = false;
  agreementDetails: any = [];
  MappingFrozen = false;
  isUnintiated: boolean = false;
  showInitiationBtn: boolean = true;
  showRevisionBtn: boolean = false;
  revInvoiceDate: any;
  noteToManager: any;
  hierarchyList: any = [];
  approverName: any;
  disableSendOta: boolean = true;
  otaDetails: any;
  isOtaPending: boolean = false;
  attachmentLength: any;
  allChangesPopup: boolean = false;
  sendType: string = null;
  isOfaReq: boolean = false;
  otaValue: number;
  selectDefaultContact: boolean = false;
  partyApprovalList = [{name: "Yes"}, {name: "No"}];
  thirdPartyPayMissing: boolean = false;
  partyCommentM: boolean = false;
  disablethirdPartyComment: boolean = false;
  thirdPartyAttachPresent: boolean;
  rptAttchmentPresent: boolean;
  partyApprovalSelected: boolean = true;
  invAmtEmpty: boolean = false;
  emptyAgreement: boolean = false;
  // BS to get invoide data for particular opportunity
  getInvoiceData$ = new BehaviorSubject(false);
  paymentContactCrmId: any;
  sfOriginalTotal: number;
  ibpMandatory: boolean = false;
  ibpAttachPresent: boolean = false;
  latestInvoice: any[] = [];

  isSaveClicked: boolean = false;
  salutationList: any = [];
  designationList: any = [];
  gstTypeList: any = [];
  revisionReasons: any = [];

  errorContactDetails: boolean = false;
  crmContactPopupOpeningFor: string = "";
  initiationCrmContact: any;
  crmContactId: string;
  crmContactNotSelectedPopup: boolean = false;
  initiationSubmited: boolean = false;
  uploadLoader: boolean = false;
  isRptApprovalRecd: string = "No";
  uploadedFileIds: any = [];
  partialCnAmount: any;
  invoiceAmount: any;
  pcnDocDetails: any = [];
  partialCnModel: boolean;
  showPcnEmpty: boolean;

  constructor(
    private pageService: PagesService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private encDec: EncryptDecryptInterceptor,
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
    private messageService: MessageService
  ) { }

  get payments() {
    return this.paymentForm.controls["payments"] as FormArray;
  }

  ngOnInit(): void {
    this.getAllMasters();
    this.data = this.config.data;
    this.userRole = this.encDec.decrypt(localStorage.getItem('role'));
    this.empId = this.encDec.decrypt(localStorage.getItem('empid'));
    this.pageLoader = true;
    this.scopeList = this.encDec.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('SF_MANUALINIT_CREATE') == -1){
      this.initiationReadScope = true;
    } else {
      this.initiationReadScope = false;
    }
    this.pageService.nexeraUserList().subscribe((res) => {
      res?.employeeDetails.forEach((e) => {
        this.nexeraUsersList.push({
          name: e.employeeName,
          id: e.employeeId
        });
      });
    });
    this.getsfDiscountReasonList();
    this.cd.detectChanges();
    setTimeout(() => {
      this.getInitiationData();
    }, 600);
    this.getUserHierarchy();

    // get invoice data, once initiation data is available
    this.getInvoiceData$.pipe(
        filter(res => !!res), 
        switchMap((res : any)=> 
          this.pageService.getInvoiceHistoryForActionables(res?.opportunityId)))
        .subscribe((res) => {
          if(res && res.length > 0){
            res.forEach(item =>{
              if(item.transactionType == 'PCN'){
                this.pcnDocDetails.push({...item, invoiceDate : moment.utc(item.invoiceDate).format("DD-MMM-YYYY")});
              } else{
                this.invoiceDocDetails.push({...item, invoiceDate : moment.utc(item.invoiceDate).format("DD-MMM-YYYY")});
              }

            });
            
            
            this.latestInvoice = res.filter((r) => {
              return r.isCurrentInvoice;
            });
            this.invoiceDetailsform.patchValue({
              invoiceNumber: this.latestInvoice[0]?.finalInvoiceNumber,
              invoiceDate: this.datePipe.transform(this.latestInvoice[0]?.invoiceDate, 'dd-MM-yyyy')
            });
          } else {
            this.showEmptyInvoiceState = true;
          }
    });
    let ele = document.getElementsByClassName("p-dialog-header-close-icon");
    ele[0].addEventListener("click", () => {
      this.deleteMultipleFile();
    });
  }

  getInitiationData() {
    let payload = { "id": this.data.id };
    this.pageService.getSfInitiationById(payload).subscribe(async (res: any) => {
      this.initiationData = res;
      let sfBillId = this.initiationData?.billId;
      let sfCompanyCode = this.initiationData?.companyCode;
      this.pageService.getPartialCnAmount(sfBillId, sfCompanyCode).subscribe(resCn => {
        this.partialCnAmount = resCn?.cnAmount;
        this.invoiceAmount = resCn?.totalSurvFee;

        this.getInvoiceData$.next(res);
        this.rptInCC = this.initiationData?.rptStatus?.rptInCC;
        this.rptInCrm = this.initiationData?.rptStatus?.rptInCrm;
        this.getRptOverallStatus(this.initiationData.rptStatus);
        this.documents = res?.attachments ? res?.attachments : [];
        if(this.documents != null && this.documents != undefined && this.documents.length >0){
          let partialCnDocument = this.documents.filter(doc => doc.fileTypeid == 162); 
          if(partialCnDocument != null && partialCnDocument != undefined && partialCnDocument.length >0){
            partialCnDocument.sort((a, b) => a.fileId - b.fileId);

            partialCnDocument = partialCnDocument.map((document, index) => {
              if (document.fileTypeid == 162) {
                document.fileTypeName = `Partial CN ${index + 1}`;
              }
              return document;
            }); 
          }
          let nonPartialCnDocuments = this.documents.filter(doc => doc.fileTypeid != 162); 
          this.documents = [...nonPartialCnDocuments,...partialCnDocument];
        }
        
        
        this.attachmentLength = this.documents.length;
        this.oppStatus = res?.opportunityStatus;
        let collectedPendingRealzAmt: number = 0;
        let realizedAmt: number = 0;
        let rejectBouncedAmt: number = 0;
        let pendingCollectedAmt: number = 0;
        let sfAmount: number = 0;
        this.getRevisionDetails(this.initiationData?.billId);
        this.getPartialCnData(sfBillId, sfCompanyCode);
        if (res?.payments?.length > 0) {
          this.disableActualExpected = true;
          if (res.invoiceDetails?.sfActualExpected == 0 || !res.invoiceDetails?.sfActualExpected) {
            sfAmount = res.invoiceDetails?.originalBillAmount;
          } else {
            sfAmount = res.invoiceDetails?.sfActualExpected;
          }
          const sortedPaymentDetails = res?.payments.sort((a, b) => a.id - b.id);
          res.payments = sortedPaymentDetails.map(payment => ({ ...payment }));
          res?.payments?.forEach((pay, i) => {
            let formatDate = null;
            if (pay.clearingDate) {
              let clearingDate = new Date(pay.clearingDate);
              formatDate = this.datePipe.transform(clearingDate, 'dd-MM-yyyy');
            }

            let date = null;
            if (pay.paymentDate) {
              let date1 = new Date(pay.paymentDate);
              date = this.datePipe.transform(date1, 'dd-MM-yyyy');
            }
            let form = this.fb.group({
              paymentMode: new FormControl(pay.paymentMode, [Validators.required]),
              neftCheque: new FormControl(pay.chequeNo, [Validators.required]),
              bank: new FormControl(pay.bank, [Validators.required]),
              paymentDate: new FormControl(date, [Validators.required]),
              paymentType: new FormControl(pay.paymentType, [Validators.required]),
              tdsType: new FormControl(pay.tdsType, [Validators.required]),
              tdsAmount: new FormControl(pay.tdsAmount, [Validators.required]),
              amountCollected: new FormControl(pay.amount, [Validators.required]),
              grossAmount: new FormControl(pay.grossAmount, [Validators.required]),
              finRemark: new FormControl(null, []),
              paymentStatus: new FormControl(this.payStatus[pay.paymentStatusId], []),
              bankClearingDate: new FormControl(formatDate, []),
              isInitiated: new FormControl(pay.isInitiated, []),
              paymentId: pay.id,
              createdBy: pay.createdBy,
              comment: pay.comment,
              paymentStatusId: pay.paymentStatusId
            });

            if (pay.paymentType != 'No Payment' && pay.isInitiated != 'Yes') {
              this.isUnintiated = true;
            }

            if (pay.paymentType == 'No Payment') {
              form.get('paymentMode').setValidators([]);
              form.get('neftCheque').setValidators([]);
              form.get('bank').setValidators([]);
              form.get('paymentDate').setValidators([]);
              form.get('paymentType').setValidators([]);
              form.get('tdsType').setValidators([]);
              form.get('tdsAmount').setValidators([]);
              form.get('amountCollected').setValidators([]);
              form.get('grossAmount').setValidators([]);
              form.updateValueAndValidity();
            }
            this.payments.push(form);
            if (pay.paymentStatusId == 1 || pay.paymentStatusId == null) {
              collectedPendingRealzAmt += Number(pay.grossAmount);
            }
            if (pay.paymentStatusId == 2) {
              realizedAmt += Number(pay.grossAmount);
            }
            if (pay.paymentStatusId == 3 || pay.paymentStatusId == 4) {
              rejectBouncedAmt += Number(pay.grossAmount);
            }
            //partial cn code
            let tempPay = 0;
            if (this.invoiceAmount != null && this.invoiceAmount != undefined) {
              if (this.partialCnAmount != null && this.partialCnAmount != undefined) {
                tempPay = ((Number(this.invoiceAmount) - Number(realizedAmt)) - Number(collectedPendingRealzAmt) - Number(this.partialCnAmount));
              } else {
                tempPay = ((Number(this.invoiceAmount) - Number(realizedAmt)) - Number(collectedPendingRealzAmt));
              }
            } else {
              tempPay = ((Number(sfAmount) - Number(realizedAmt)) - Number(collectedPendingRealzAmt));
            }

            pendingCollectedAmt = tempPay > 100 ? tempPay : 0;
            if (pendingCollectedAmt > 100) {
              this.balanceAmount = pendingCollectedAmt;
            } else {
              this.balanceAmount = 0;
            }
            if (pay.tdsType == 'No TDS') {
              this.payments.at(i).get('tdsAmount').disable();
            }
          });

          if (((this.oppStatus == "Won - Payment Recd" || this.oppStatus == "Report Only" || this.oppStatus == "Report & Invoice") && res.isManuallyInitiated != 'Y') || (this.balanceAmount <= 100)) {
            this.disableAddBtn = true;
          } else {
            this.disableAddBtn = false;
          }

          this.payments.controls.forEach((control) => {
            if (control.value.toDisable == 'Yes') {
              control.disable();
            }
          });
          this.currentPayment = this.payments.at(this.activePayment);
          this.disableContact = true;
          this.gstContact = `${res?.entityDetails?.gstContatFirstName} ${res?.entityDetails?.gstContatLastName}`;
          this.paymentContact = {
            id: res.payments[0]?.contact?.id,
            name: `${res?.entityDetails?.gstContatFirstName} ${res?.entityDetails?.gstContatLastName}`
          }
          this.gstType = res?.entityDetails?.gstType;
          this.gstNumber = res?.entityDetails?.gstNumber;
        } else {
          this.disableContact = false;
          this.disabledSaveBtn = true;
          if (this.invoiceAmount != null && this.invoiceAmount != undefined) {
            this.balanceAmount = this.invoiceAmount;
          } else {
            this.balanceAmount = res.invoiceDetails?.sfActualExpected;
          }

        }

        if (res?.payments?.length == 1 && res?.payments[0]?.paymentTypeId == 88) {
          this.disabledSaveBtn = true;
          if (this.invoiceAmount != null && this.invoiceAmount != undefined) {
            if (this.partialCnAmount != null && this.partialCnAmount != undefined) {
              this.balanceAmount = this.invoiceAmount - this.partialCnAmount;
            } else {
              this.balanceAmount = this.invoiceAmount;
            }
          } else {
            this.balanceAmount = res.invoiceDetails?.sfActualExpected;
          }

        }

        if (res?.payments?.length > 0 && res?.payments[0]?.paymentTypeId == 88) {
          let index = this.paymentTypeListFiltered2.findIndex(p => p.id == 88);
          if (index !== -1) {
            this.paymentTypeListFiltered2 = this.paymentTypeListFiltered2.filter((p, idx) => idx !== index);
          }
        }
        this.disabledSaveBtn = !this.isUnintiated;
        let sfDiscountAmount = Number(res.invoiceDetails?.sfActualExpected) - Math.round(Number(res.invoiceDetails?.originalBillAmount));
        let sfDiscountPercentage = sfDiscountAmount * 100 / Math.round(res.invoiceDetails?.originalBillAmount);

        this.invoiceDetailsform.patchValue({
          financialYear: res.invoiceDetails?.financialYear,
          originalBillAmt: res.invoiceDetails?.originalBillAmount,
          sfActualExpected: res.invoiceDetails?.sfActualExpected,
          sfDiscountAmount: Number(res.invoiceDetails?.sfActualExpected) == 0 ? null : Number(sfDiscountAmount).toFixed(2),
          sfDiscountPercentage: Number(sfDiscountPercentage).toFixed(2),
          sfDiscountReason: res?.invoiceDetails?.sfDiscountReason,
          collectedPendingRealz: collectedPendingRealzAmt,
          realized: realizedAmt,
          rejectedBounced: rejectBouncedAmt,
          pendingCollected: pendingCollectedAmt
        });
        this.thirdPartyForm.patchValue({
          thirdPartyApproval: res.thirdPartyApproval,
          thirdPartyApprovalComment: res.thirdPartyApprovalComment,
        });
        if (res.thirdPartyApproval == 'Yes') {
          this.partyCommentM = true;
        }
        if (res?.payments?.length > 0 && res.thirdPartyApproval) {
          this.disablethirdPartyComment = true;
        }
        this.thirdPartyAttachPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === '3rd party approval attachment');
        this.ibpAttachPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === 'invoice before payment approval');
        this.rptAttchmentPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === 'rpt approval');


        this.patchContactDetailsForm(res?.entityDetails?.entityContactDetails);
        this.crmContactId = res?.crmContactId;
        this.getCrmContactByCrmContactId(this.crmContactId);

        this.actualAmount = res.invoiceDetails?.sfActualExpected;
        this.pageService.getClientAttendees(res?.entityId, res?.nexeraUserId).subscribe((res) => {
          if (res && res?.length > 0) {
            this.paymentContactList = res;
            res?.find((r: any) => {
              if (r.isDefault === true || r.isDefault === "true") {
                this.paymentContactDisplayList.push({
                  name: `${r.firstName} ${r.lastName}`,
                  id: r.id
                });
                this.paymentContactCrmId = r.contactId;
              }
            });
          }
        });

        this.pageService.getSfBdHierarchy(res?.nexeraUserId).subscribe((res: any) => {
          if (!res.finalMapping) {
            this.bdHierarchy = null;
          } else {
            this.bdHierarchy = res.finalMapping;
          }
        });

        this.initiationStatus = this.initiationStatusList.find(i => i.id == res?.currentStatus)?.name;
        if (this.initiationStatus == undefined) {
          this.initiationStatus = "Pending";
        }

        this.invoiceDetailsform.patchValue({
          sfActualExpected: this.data.isNexeraOpportunity == "Non-Nexera" && !res?.payments?.length ? parseInt(res.invoiceDetails?.originalBillAmount, 10) : res.invoiceDetails?.sfActualExpected,
        });
        let event = { target: { value: this.invoiceDetailsform.value.sfActualExpected } }
        this.sfActualEntered(event);
        this.apiFail = false;
      });
      
      
    }, (_err) => {
      this.pageLoader = false;
      this.apiFail = true;
      this.disabledSaveBtn = true;
    });
  }

  validations() {
    this.isSaveClicked = true;
    
    this.chequePayCount = 0;
    this.chequeAttachCount = 0;
    let fullPayCount = 0;
    if (this.documents?.length > 0) {
      this.documents.forEach(d => {
        if (d.fileTypeName?.toLowerCase() == 'cheque' || d.fileTypeName?.toLowerCase() == 'deposit slip') {
          this.chequeAttachCount += 1;
        }
      });
      this.thirdPartyAttachPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === '3rd party approval attachment');   
      this.ibpAttachPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === 'invoice before payment approval');      
      this.rptAttchmentPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === 'rpt approval');   
    }

    this.payments.controls.forEach((control) => {
      if (control?.value?.paymentMode?.toLowerCase() == 'cheque') {
        this.chequePayCount += 1;
      }

      if (control?.value.paymentType == 'Full Payment') {
        fullPayCount += 1
      }
    });

    if (fullPayCount == 0 && this.balanceAmount <= 0) {
      this.fullPayNeeded = true;
    } else {
      this.fullPayNeeded = false;
    }

    this.erroMsg = '';
    this.chequeNeeded = this.chequePayCount > this.chequeAttachCount ? true : false;  //error check
    this.errorAttachments = this.chequeNeeded ? true : false;
    if (this.paymentForm.status == 'INVALID' || this.fullPayNeeded) {
      this.errorPaymentInvoice = true;
      this.erroMsg += 'Payment & Invoice, '
    } else {
      this.errorPaymentInvoice = false;
      this.erroMsg = '';
    }

    this.payDetailsMissing = this.paymentForm.status == 'INVALID' ? true : false;

      if(this.rptoverall=='Yes'){
        this.rptPopup=true;
        this.attachmentTypeList.forEach(element => {
          if (element.type == 'RPT Approval') {
            element.is_mandatory = 'M';
          }
        });
      }
    
    if(this.chequeNeeded || (!this.thirdPartyAttachPresent && this.thirdPartyForm.controls.thirdPartyApproval?.value == 'Yes')||(!this.rptAttchmentPresent&& this.rptoverall=='Yes') || (this.ibpMandatory && !this.ibpAttachPresent)){
      this.erroMsg += 'Attachments';
    }
    if(this.errorPaymentInvoice || this.chequeNeeded || (!this.thirdPartyAttachPresent && this.thirdPartyForm.controls.thirdPartyApproval?.value == 'Yes')||(!this.rptAttchmentPresent&& this.rptoverall=='Yes') || (this.ibpMandatory && !this.ibpAttachPresent)){
      this.validationPopup = true;
    } else {
      if(!this.crmContactId){
        this.crmContactNotSelectedPopup = true;
        this.errorContactDetails = true;
      }
      else {
        this.validationPopup = false;
        this.crmContactNotSelectedPopup = false;
        this.errorContactDetails = false;
        this.saveInitiation();
      }
    }
  }

  patchContactDetailsForm(contactDetails) {
    if(contactDetails!=null) {
      this.contactDetailsform.patchValue({
        salutation: contactDetails.salutation,
        firstName: contactDetails.firstName,
        lastName: contactDetails.lastName,
        designation: contactDetails.designation,
        mobileNo: contactDetails.mobileNumber,
        landlineNo: contactDetails.landlineNumber,
        email1: contactDetails.email[0],
        email2: contactDetails.email[1],
        email3: contactDetails.email[2],
        gstType: contactDetails.gstType,
        gstNo: contactDetails.gstNumber?.toUpperCase(),
        panNo: contactDetails.panNumber?.toUpperCase(),
        pincode: contactDetails.pincode,
        city: contactDetails.city,
        state: contactDetails.state,
        address1: contactDetails.addressLine1,
        address2: contactDetails.addressLine2,
        address3: contactDetails.addressLine3,
        address4: contactDetails.addressLine4,
      });
    }
  }

  getCrmContactByCrmContactId(crmContactId:string) {
    if(crmContactId) {
      let reqBody = {contactId: crmContactId};
      this.pageService.getCrmCotactById(reqBody).subscribe((resp) => {
        this.initiationCrmContact = resp;
        this.crmContactId = crmContactId;
        this.setCrmContactEmailsForInitiation();
      });
    }
  }

  setCrmContactEmailsForInitiation() {
    if(this.initiationCrmContact != null || this.initiationCrmContact != undefined){
      let emails: string[] = [];

      if(this.initiationCrmContact?.email) {
        emails = this.initiationCrmContact?.email?.split(',');
        while(emails.length<3) {
          emails.push("");
        }
    
        this.initiationCrmContact.emails=emails;
      }
    }
  }


  saveInitiation() {
    let payload = this.createPayload();
    this.pageLoader = true;
    this.initiationSubmited = true;
    this.pageService.saveInitiationDetails(payload).subscribe((res) => {
      this.pageLoader = false;
      this.ref.close(res?.responseMsg);
    }, (err) => {
      this.pageLoader = false;
      this.deleteMultipleFile();
      this.ref.close(err?.error?.data);
    });
  }

  tabChange(event) {
    this.activeIndex = event.index;
    setTimeout(() => {
      this.showTable = this.activeIndex == 4 ? true : false;
    }, 100);
    if(this.disabledPaymentMsg && event.index == 2)
      this.messageService.add({key : 'revisionAckMsg', severity: 'warn', sticky: true, summary: this.disabledPaymentMsg});
    else if (this.disabledRevisionMsg && event.index == 4)
      this.messageService.add({key : 'revisionTabAckMsg', severity: 'warn', sticky: true, summary: this.disabledRevisionMsg})
    else this.messageService.clear();
  }

  createPayload() {
    let payment = [];
    this.documents.forEach((doc) => {
      delete doc.code;
    });
    let payload: any = {
      id: this.initiationData?.opportunityId,
      opsUserId: this.empId,
      isRptApprovalReceived: this.isRptApprovalRecd,
      rptApprovalDocDetails: null,
      opportunityStatus: this.reportingStatusList.find(r => r.code == "REPORT_AND_INVOICE").name,
      opportunityStatusId: this.reportingStatusList.find(r => r.code == "REPORT_AND_INVOICE").id,
      opportunityInitiationStatus: this.initiationStatusList.find(i => i.code == 'PI')?.id,
      thirdPartyApproval : this.thirdPartyForm.controls.thirdPartyApproval?.value,
      thirdPartyApprovalComment : this.thirdPartyForm.value?.thirdPartyApprovalComment ?? null,
      isManuallyInitiated: 'Y',
      invoiceDetails: {
        sfActualExpected: this.invoiceDetailsform.value?.sfActualExpected,
        sfDiscount: this.invoiceDetailsform.value.sfDiscountAmount,
        sfDiscountPercentage: this.invoiceDetailsform.value.sfDiscountPercentage,
        sfDiscountReason: this.invoiceDetailsform.value.sfDiscountReason,
        sfDiscountReasonId: this.sfDiscountReasonList.filter(sfdiscountreason => sfdiscountreason.name === this.invoiceDetailsform.value.sfDiscountReason)[0]?.id,

      },
      attachments: this.documents,
      // paymentContactId: this.paymentContactCrmId
      paymentContactId: this.crmContactId,
      rptStatus:{}
    }

    payload.rptStatus ={
      rptInCC: this.rptInCC,
      rptInCrm: this.rptInCrm
    }
    this.payments.getRawValue().forEach(p => {
      let singlePay: any = {}
      let noPaymentFound = false;
      let fullPaymentFound = false;
      let partPaymentFound = false;
      if (p.paymentType == 'Part Payment') {
        partPaymentFound = true;
        payload.opportunityInitiationStatus = this.initiationStatusList.find(i => i.code == 'PI')?.id;
      }
      if (p.paymentType == 'Full Payment') {
        fullPaymentFound = true;
        payload.opportunityInitiationStatus = this.initiationStatusList.find(i => i.code == 'FI')?.id;
      }
      if (p.paymentType == 'No Payment') {
        noPaymentFound = true;
        singlePay.isInitiated = 'No';
      } else {
        singlePay.isInitiated = 'Yes';
      }
      if (p.paymentType != 'No Payment' && p.isInitiated == 'No') {
        payload.isManuallyInitiated = 'N';
      }

      if(noPaymentFound && !fullPaymentFound && !partPaymentFound){
        payload.opportunityInitiationStatus = this.initiationStatusList.find(i => i.code == 'P')?.id;
      }

      if (p.paymentDate) {
        if (typeof (p.paymentDate) == 'string') {
          let date = p.paymentDate?.split('-')?.reverse()?.join('-');
          singlePay.paymentDate = date;
        } else {
          let date = new Date(p.paymentDate);
          let formatDate = this.datePipe.transform(date, 'yyyy-MM-dd');
          singlePay.paymentDate = formatDate;
        }
      } else {
        singlePay.paymentDate = p.paymentDate;
      }

      if (p.createdBy) {
        singlePay.createdBy = p.createdBy;
      } else {
        singlePay.createdBy = this.empId;
      }
      singlePay.modifiedBy = null;
      singlePay.paymentType = p?.paymentType;
      singlePay.paymentTypeId = this.paymentTypeList.find(x => x.property == p.paymentType)?.id;
      singlePay.paymentMode = p?.paymentMode;
      singlePay.paymentModeId = p.paymentType != 'No Payment' ? this.paymentModeList.find(x => x.property == p.paymentMode)?.id : null;
      singlePay.bankId = p.paymentType != 'No Payment' ? this.bankList.find(x => x.name == p.bank)?.id : null;
      singlePay.bank = p?.bank;
      singlePay.chequeNo = p?.neftCheque;
      singlePay.amount = p?.amountCollected;
      singlePay.grossAmount = p?.grossAmount;
      singlePay.tdsTypeId = p.paymentType != 'No Payment' ? this.tdsTypeList.find(x => x.property == p.tdsType)?.id : null;
      singlePay.tdsType = p?.tdsType;
      singlePay.tdsAmount = p?.tdsAmount;
      singlePay.isPaymentDeleted = 'No';
      singlePay.comment = p?.comment;
      singlePay.id = p?.paymentId;
      let formattedDate = null;
      if(p.bankClearingDate){

        if (typeof (p.bankClearingDate) == 'string') {
          let date = p.bankClearingDate?.split('-')?.reverse()?.join('-');
          singlePay.clearingDate = date;
        } else {
          let date = new Date(p.bankClearingDate);
          let formatDate = this.datePipe.transform(date, 'yyyy-MM-dd');
          singlePay.clearingDate = formatDate;
        }
        // const [month, day, year] = p.bankClearingDate.split('-');
        // const date = new Date(`${year}-${month}-${day}`);
        // formattedDate = date.toISOString().split('T')[0];
        // let bankClearingDate = new Date(p.bankClearingDate);
        // formattedDate = this.datePipe.transform(bankClearingDate, 'yyyy-MM-dd');
      }
      // singlePay.clearingDate = formattedDate;
      singlePay.paymentStatusId = p.paymentType == 'No Payment' ? 1 : p?.paymentStatusId;
      singlePay.contact = {
        id: this.paymentContact?.id,
        firstName: null,
        lastName: null,
        email: null,
        designation: null
      }
      payment.push(singlePay);
    });
    payload.payments = payment;
    let fileObj = this.documents?.find(u => u.fileTypeid == 16);
    if(fileObj){
      payload.rptApprovalDocDetails = fileObj;
    }
    return payload;
  }


  getRevisionDetails(billId) {
    let paylaod = {
      sfBillId: billId
    }

    this.pageService.getRevisionData(paylaod).subscribe((res) => {
      if (res && Object.keys(res)?.length > 0) {
        this.invoiceGenerated = true;
        this.revisionDetials = res;
        this.agreementDetails = res?.agreementDetails;
        this.invoiceContact = res?.previousContactDetails;
        this.currentInvAmt = res?.oldSfTotal;
        this.otaDetails = res?.otaDetails;
        this.revInvoiceDate = res?.lastInvoiceDate;
        this.discountType = res?.invoiceRevisionType?.toLowerCase();
        this.dateChecked = res?.isDateChange == 'Y';
        this.isOtaPending = res?.otaDetails?.status == 'Pending' ? true : false;
        if(res?.isOFAActive || res?.isHold || res?.isInvoiceCancelled || this.discountType?.toLowerCase() == 'cancel'){
          this.disableAll = true;
          this.showSfCell = false;
          this.noteToManager = res?.noteToManager;
          this.showInitiationBtn = false;
          this.showRevisionBtn = true;
          this.disablePaymentTab = true;
        } else {
          this.disableAll = false;
        }
        if (this.isOtaPending) {
          this.showRevisionBtn = true;
          this.showInitiationBtn = false;
          this.crmSingleContact = res?.contactDetails;
          if(this.crmSingleContact != null){
            this.crmSingleContact.gstNumber = res?.contactDetails?.gstNo;
            this.crmSingleContact.address1 = res?.contactDetails?.addressLine1;
            this.crmSingleContact.address2 = res?.contactDetails?.addressLine2;
            this.crmSingleContact.pin = res?.contactDetails?.pincode;
          }
          let invAmt = res?.newSfTotal;
          this.revisedInvAmt = invAmt?.toLocaleString("en-IN");
          this.noteToManager = res?.noteToManager;
        } else {
          this.agreementDetails?.forEach((a) => {
            a.oldSurveillanceFee = a.surveillanceFee;
            a.surveillanceFee = null;
          });
        }
        
        this.agreementDetails.map((a) => {
          a.sfMethodAmount = a.sfMethodAmount?.toLocaleString("en-IN");
          a.oldSurveillanceFee = a.oldSurveillanceFee?.toLocaleString("en-IN");
          a.surveillanceFee = a.surveillanceFee?.toLocaleString("en-IN");
          a.productName = this.productList.find(p => p.id == a.productId)?.product_name;
          a.instrumentName = this.instrumentList.find(i => i.id == a.instrumentId)?.name;
          let methdAmt = Number(a.sfMethodAmount);
          a.sfMethodAmount = methdAmt.toLocaleString("en-IN");
          a.instrumentAmount = (Number(a.instrumentAmount) / 10000000).toFixed(2);
          a.outstandingQuantum = (Number(a.outstandingQuantum) / 10000000).toFixed(2);
          if (this.isOtaPending) {
            let survfee = null;
            if(a.surveillanceFee == null || a.surveillanceFee == undefined){
              a.surveillanceFee = 0;
              survfee = 0;
            } else {
              survfee = this.convertStringtoNum(a.surveillanceFee)
            }
            a.discountAmount = ((survfee) - this.convertStringtoNum(a.oldSurveillanceFee)).toLocaleString("en-IN");
          } else {
            a.discountAmount = null;
          }
        });
        if(this.agreementDetails.length == 1){
          this.sfOriginalTotal = this.agreementDetails[0].sfOriginalFee;
        } else {
          let sum = 0;
          this.agreementDetails.forEach((a) => {
            sum += a.sfOriginalFee;
          });
          this.sfOriginalTotal = sum;
        }
        setTimeout(() => {
          this.showTable = true;
        }, 1000);
        this.currentInvAmt = this.currentInvAmt.toLocaleString("en-IN");
        if (this.discountType?.toLowerCase() == 'cancel') {
          this.crmSingleContact = null;
          this.dateChecked = false;
          this.disableActual = true;
          this.cancelInvoiceSelected = true;
          this.revisedInvAmt = 0;
          this.showInitiationBtn = false;
          this.showRevisionBtn = true;
        }

        this.disableRevisionTab = this.isUnintiated
        if (this.disableRevisionTab) {
          this.showInitiationBtn = true;
          this.showRevisionBtn = false;
        }

      } else {
        this.disableRevisionTab = true;
        this.showInitiationBtn = true;
        this.showRevisionBtn = false;
        this.invoiceGenerated = false;
      }

      // set message for user trying to do revision with 0 payments OR non Report-Invoice opportunity
      if(this.payments?.value?.length === 0) {
        this.disableAll = true;
        this.disabledRevisionMsg = "Please save atleast one payment, before revision"
      } else if (this.oppStatus !== "Report & Invoice") {
        this.disableAll = true;
        this.disabledRevisionMsg = "Opportunity is not 'Report and invoice', please save this opportunity first by filling in mandatory fields";
      } else if(this.showRevisionBtn) {
        // set message in case payment add is inactive
        if(res?.isOFAActive || this.isOtaPending) {
          this.disableAddBtn = true;
          this.disabledPaymentMsg = 'Revision in progress';
        } else if (res?.isHold) {
          this.disableAddBtn = true;
          this.disabledRevisionMsg = this.disabledPaymentMsg = 'Previous Revision is on Hold';
        } else if (res?.isInvoiceCancelled) {
          this.disableAddBtn = true;
          this.disabledPaymentMsg = 'Invoice cancelled';
        } else this.disabledPaymentMsg = undefined;
      } else {
        this.disabledPaymentMsg = undefined;
        this.disabledRevisionMsg = undefined;
      }

      this.apiFail = false;
      this.pageLoader = false;
    }, (_err) => {
      this.invoiceGenerated = false;
      this.disableRevisionTab = true;
      this.showInitiationBtn = true;
      this.showRevisionBtn = false;
      this.apiFail = true;
      this.pageLoader = false;
      this.disabledSaveBtn = true;
    });
  }

  otaEntered(){
    this.disablePaymentTab = true;
    this.showInitiationBtn = false;
    this.showRevisionBtn = true;
    this.invalidOta = false;
    this.verifySaveBtn = false;
  }

  sfActualEntered(event) {
    if (this.payments?.value?.length == 0) {
      this.balanceAmount = Number(event.target.value);
      let idealAmount = this.invoiceDetailsform.get('originalBillAmt').value,
        sfDiscountAmount = Number(event.target.value) - Math.round(idealAmount),
        sfDiscountPercentage = sfDiscountAmount * 100 / Math.round(idealAmount);

      let sfDiscountResponse = {
        sfDiscountAmount: Number(sfDiscountAmount).toFixed(2),
        sfDiscountPercentage: Number(sfDiscountPercentage).toFixed(2),
      };

      this.invoiceDetailsform.patchValue(sfDiscountResponse);

      if (sfDiscountAmount < 0 && sfDiscountAmount != 0) {
        this.reasonRequiredSF = true;
        this.disableSfDiscountReason = false;
        this.invoiceDetailsform.get('sfDiscountReason').setValidators([Validators.required]);
      } else {
        this.disableSfDiscountReason = true;
        this.invoiceDetailsform.patchValue({ sfDiscountReason: null });
        this.invoiceDetailsform.get('sfDiscountReason').removeValidators([Validators.required]);
      }
      if (event.target.value == null || event.target.value == "") {
        this.invoiceDetailsform.patchValue({ sfDiscountAmount: null, sfDiscountReason: null });
        this.reasonRequiredSF = false;
        this.disableSfDiscountReason = true;
      }
    }
    this.sfFeesComparision();
  }

  sfFeesComparision() {
    this.sFDiscountMessage = '';
    let sfActualFees = this.invoiceDetailsform.value.sfActualExpected;
    if (sfActualFees != "" && sfActualFees != 0 && !this.invoiceDetailsform.get('sfDiscountAmount').errors?.pattern) {
      this.sfShowMessage = true;
      if (Number(sfActualFees) < this.invoiceDetailsform.value.originalBillAmt) {
        this.sFDiscountMessage = "Invoice will be generated as a Discount."
      }
      if (Number(sfActualFees) > this.invoiceDetailsform.value.originalBillAmt) {
        this.sFDiscountMessage = "Invoice will be generated as Upward Revision. Please attach supporting docs."
      }
      if (Number(sfActualFees) == this.invoiceDetailsform.value.originalBillAmt) {
        this.sFDiscountMessage = "Invoice will be generated for ₹" + this.invoiceDetailsform.value.originalBillAmt;
      }
    } else {
      this.sfShowMessage = false;
    }
  }

  paymentPopup() {
    const isDefaultPresent = this.paymentContactList.some(obj => obj.isDefault === "true" || obj.isDefault === true);
    if(isDefaultPresent){
    this.addpaymentForm.reset();
    this.disableNoPayment = false;
    this.paymentModel = true;
    this.paymentErrorMsg = false;
    this.fullPayNeeded = false;
    this.fullPayRealz = null;
    if (this.invoiceDetailsform.value.sfActualExpected == 0 || !this.invoiceDetailsform.value.sfActualExpected) {
      this.sfActualFeesMissing = true;
    } else {
      this.sfActualFeesMissing = false;
    }
    if(this.thirdPartyForm.controls.thirdPartyApproval?.value == 'Yes' && !this.thirdPartyForm.value.thirdPartyApprovalComment){
      this.thirdPartyPayMissing = true;
    } else {
      this.thirdPartyPayMissing = false;
    }

    // tds already accounted for
    this.fullPayRealz = this.payments?.value?.find(p => p.paymentType == "Full Payment" && (p.paymentStatusId == 1 || p.paymentStatusId == 2));

    if (this.disableContact) {
      if (!this.paymentContact.name) {
        this.paymentContact.name = this.paymentContact.firstName + ' ' + this.paymentContact.lastName;
      }
      this.paymentContactDisplayList = [{
        name: this.paymentContact.name,
        id: this.paymentContact.id
      }];
      this.addpaymentForm.patchValue({
        contact: { id: this.paymentContact.id, name: this.paymentContact.name }
      });
    }
    if (this.payments.value.length == 0 && !this.disableContact) {
      this.gstType = null;
    }
    }else{
      this.selectDefaultContact = true;
    }    
  }

  bdForCaseClicked(event) {
    this.pageService.getSfBdHierarchy(event?.value).subscribe((res: any) => {
      this.bdHierarchy = res.finalMapping;
    });
  }

  tabOpened(event) {
    this.activePayment = event.index;
    this.currentPayment = this.payments.at(this.activePayment);
    if (this.currentPayment?.value?.paymentType == 'No Payment') {
      this.disableControls();
    } else {
      this.markTouched();
    }
  }

  addPayment() {
    if (this.balanceAmount < this.addpaymentForm.value.grossAmount) {
      this.paymentErrorMsg = true;
      this.erroMsg = 'Gross amount cannot be greater then balance.'
    } else {
      this.paymentErrorMsg = false;
      this.fullPayNeeded = false;
      this.erroMsg = '';
      let gross;
      if (!this.fullPayRealz) {
        gross = this.addpaymentForm.value.grossAmount;
      } else {
        gross = Number(this.addpaymentForm.value.grossAmount) - Number(this.addpaymentForm.value.tdsAmount);
      }
      let form = this.fb.group({
        paymentMode: this.addpaymentForm.value.paymentMode,
        neftCheque: this.addpaymentForm.value.neftCheque,
        bank: this.addpaymentForm.value.bank,
        paymentDate: this.addpaymentForm.value.paymentDate,
        paymentType: this.addpaymentForm.value.paymentType,
        tdsType: this.addpaymentForm.value.tdsType,
        tdsAmount: this.addpaymentForm.value.tdsAmount,
        amountCollected: this.addpaymentForm.value.amountCollected,
        grossAmount: this.addpaymentForm.value.grossAmount,
        comment: this.addpaymentForm.value.comment,
        finRemark: null,
        paymentStatus: null,
        bankClearingDate: null,
        isInitiated: null
      });
      let balanceAmt = Number(this.balanceAmount) - Number(gross);
      if (this.addpaymentForm.value.paymentType == 'Part Payment' && balanceAmt == 0) {
        this.fullPayNeeded = true;
        return;
      } else {
        this.fullPayNeeded = false;
        this.payments.push(form);
        this.disablethirdPartyComment = true;
        if(this.addpaymentForm.value.paymentType == 'No Payment'){
          let index = this.paymentTypeListFiltered2.findIndex(p => p.id == 88);
          if (index !== -1) {
            this.paymentTypeListFiltered2 = this.paymentTypeListFiltered2.filter((p, idx) => idx !== index);
          }
          this.ibpMandatory = true;
        }
        this.thirdPartyForm.controls.thirdPartyApproval.disable();
        this.paymentModel = false;
        this.paymentAdded = true;
        this.balanceAmount = Number(this.balanceAmount) - Number(gross);
        if (!this.disableContact) {
          this.gstContact = `${this.paymentContact.firstName} ${this.paymentContact.lastName}`;
          let contact = this.paymentContactList.find((p => p.id == this.paymentContact.id));
          this.gstType = contact.gstType;
          this.gstNumber = contact.gstNumber;
          this.patchContactDetailsForm(contact);
        }
        this.disableContact = true;
        this.disabledSaveBtn = false;
        this.disableActualExpected = true;
        this.disableRevisionTab = true;
        this.showInitiationBtn = true;
        this.showRevisionBtn = false;
        if (this.balanceAmount <= 100 || this.addpaymentForm.value.paymentType == 'Full Payment') {
          this.disableAddBtn = true;
        }
      }
    }
  }

  paymentValidations() {
    if (!this.disableContact) {
      this.gstContact = `${this.paymentContact.firstName} ${this.paymentContact.lastName}`;
      let contact = this.paymentContactList.find((p => p.id == this.paymentContact.id));
      this.gstType = contact.gstType;
      this.gstNumber = contact.gstNumber;
      this.patchContactDetailsForm(contact);
    }
    this.disableContact = true;
    this.disabledSaveBtn = false;
    this.disableActualExpected = true;
    if (this.balanceAmount <= 100) {
      this.disableAddBtn = true;
    }
  }

  getAllMasters() {
    this.getSalutationList();
    this.getDesignationList();
    this.getGSTtypeList();
    this.getPaymentStatus();
    this.getPaymentModeList();
    this.getBankList();
    this.getPaymentTypeList();
    this.getTdsTypeList();
    this.getAttachmentTypeList();
    this.getReportingStatus();
    this.caseInitationStatus();
    this.getInstruments();
    this.getProducts();
    this.getRevisionReasons();
  }

  tdsSelected(event) {
    if (this.paymentModel) {
      this.selectedTdsValue = event.value;
      if (event.value == 'Manual TDS') {
        this.disabledTdsAmount = false;
      } else {
        this.disabledTdsAmount = true;
      }
    } else {
      if (event.value == 'Manual TDS') {
        this.currentPayment.get('tdsAmount').enable();
      } else {
        this.currentPayment.get('tdsAmount').disable();
      }
      if (event.value == 'No TDS') {
        this.currentPayment.get('tdsAmount').patchValue(0);
      }
    }
    this.tdsCalulation();
  }

  paymentTypeChanged(event) {
    this.addpaymentForm.controls.tdsType.reset();
    this.addpaymentForm.controls.amountCollected.reset();
    if (event.value == 'Part Payment') {
      let noTds = this.tdsTypeList.filter((t) => {
        return t.property == "No TDS";
      });
      this.filterTdsTypeList = noTds;
      this.disableNoPayment = false;
      this.disableTdsType = false;
      this.grossCalculation(this.amountCollected, this.addpaymentForm.value.tdsAmount);
    } else if (event.value == 'Full Payment') {
      this.grossCalculation(this.amountCollected, this.addpaymentForm.value.tdsAmount);
      this.filterTdsTypeList = this.tdsTypeList;
      this.disableNoPayment = false;
      if (this.fullPayRealz) {
        this.disableTdsType = true;
        this.selectedTdsValue = this.fullPayRealz?.tdsType;
        this.addpaymentForm.patchValue({ tdsType: this.fullPayRealz?.tdsType, tdsAmount: 0 });
        this.disabledTdsAmount = true;
      } else {
        this.disableTdsType = false;
      }
    } else if (event.value == 'No Payment') {
      this.disableNoPayment = true;
      this.addpaymentForm.reset();
      this.addpaymentForm.controls.paymentType.patchValue('No Payment');
      this.addpaymentForm.get('contact')?.setValidators([Validators.required]);
      this.addpaymentForm.controls.amountCollected.clearValidators();
      this.addpaymentForm.controls.bank.clearValidators();
      this.addpaymentForm.controls.neftCheque.clearValidators();
      this.addpaymentForm.controls.paymentDate.clearValidators();
      this.addpaymentForm.controls.paymentMode.clearValidators();
      this.addpaymentForm.controls.tdsAmount.clearValidators();
      this.addpaymentForm.controls.tdsType.clearValidators();
      this.addpaymentForm.controls.amountCollected.updateValueAndValidity();
      this.addpaymentForm.controls.bank.updateValueAndValidity();
      this.addpaymentForm.controls.neftCheque.updateValueAndValidity();
      this.addpaymentForm.controls.paymentDate.updateValueAndValidity();
      this.addpaymentForm.controls.paymentMode.updateValueAndValidity();
      this.addpaymentForm.controls.tdsAmount.updateValueAndValidity();
      this.addpaymentForm.controls.tdsType.updateValueAndValidity();
      this.addpaymentForm.controls.contact.updateValueAndValidity();
      this.gstType = null;
      this.contactDetailsMissing = false;
    }
  }

  markTouched() {
    this.currentPayment.controls.paymentMode.markAsDirty();
    this.currentPayment.controls.paymentMode.markAsTouched();
    this.currentPayment.controls.neftCheque.markAsDirty();
    this.currentPayment.controls.bank.markAsDirty();
    this.currentPayment.controls.paymentDate.markAsDirty();
    this.currentPayment.controls.tdsType.markAsDirty();
    this.currentPayment.controls.amountCollected.markAsDirty();
    this.currentPayment.controls.tdsAmount.markAsDirty();
  }

  disableControls() {
    this.currentPayment.get('paymentMode').disable();
    this.currentPayment.get('neftCheque').disable();
    this.currentPayment.get('bank').disable();
    this.currentPayment.get('paymentDate').disable();
    this.currentPayment.get('tdsType').disable();
    this.currentPayment.get('tdsAmount').disable();
    this.currentPayment.get('amountCollected').disable();
  }

  enableContorls() {
    this.currentPayment.get('paymentMode').enable();
    this.currentPayment.get('neftCheque').enable();
    this.currentPayment.get('bank').enable();
    this.currentPayment.get('paymentDate').enable();
    this.currentPayment.get('tdsType').enable();
    this.currentPayment.get('tdsAmount').enable();
    this.currentPayment.get('amountCollected').enable();
    this.markTouched();
  }

  filterTdsList() {
    let paymentType = this.currentPayment?.value?.paymentType;
    if (paymentType == 'Part Payment') {
      let noTds = this.tdsTypeList.filter((t) => {
        return t.property == "No TDS";
      });
      return noTds;
    } else if (paymentType == 'Full Payment') {
      return this.tdsTypeList;
    }
  }

  amountCollectedEntered(event) {
    let value = event.target.value
    this.amountCollected = Number(value);
    this.grossAmount = 0;
    if (value == 0 || !value) {
      this.addpaymentForm.patchValue({ grossAmount: 0 });
    } else {
      this.grossCalculation(value, this.addpaymentForm.value.tdsAmount);
    }
  }

  tdsEntered(event) {
    this.grossCalculation(this.amountCollected, Number(event))
  }

  grossCalculation(amount?, tds?) {
    let gross = 0;
    if (this.addpaymentForm.value.paymentType == "Full Payment") {
      if (this.fullPayRealz) {
        tds = 0;
      }
    }
    if (this.gstType == "GST Normal" || this.gstType == "GST NA") {
      gross = Number(Math.round((parseInt(amount) + Number(tds)) / 1.18));
    } else {
      gross = Number(Math.round((parseInt(amount) + Number(tds))));
    }
    this.addpaymentForm.patchValue({ grossAmount: gross });
  }

  tdsCalulation() {

    if (this.invoiceAmount != null && this.invoiceAmount != undefined) {
      if (this.partialCnAmount != null && this.partialCnAmount != undefined) {
        this.actualAmount = Number(this.invoiceAmount) - Number(this.partialCnAmount);
      } else {
        this.actualAmount = Number(this.invoiceAmount);
      }
    } else {
      this.actualAmount = parseInt(this.invoiceDetailsform.value.sfActualExpected);
    }
    
    if (this.gstType == "GST NA" || this.gstType == "GST Normal") {
      let tds = 0;
      if (this.addpaymentForm.value.paymentType == "Full Payment") {
        if (this.selectedTdsValue == "TDS on Gross + Tax") {
          tds = Math.round(((this.actualAmount * 0.18) + this.actualAmount) * 0.10);
        } else if (this.selectedTdsValue == "TDS on Gross") {
          tds = Math.round(this.actualAmount * 0.10);
        } else if (this.selectedTdsValue == "Manual TDS") {
          this.disabledTdsAmount = false;
          this.addpaymentForm.controls.tdsAmount.reset();
        } else if (this.selectedTdsValue == 'No TDS') {
          this.addpaymentForm.patchValue({ tdsAmount: 0 });
          this.disabledTdsAmount = true;
        }
        this.addpaymentForm.patchValue({ tdsAmount: tds });
      } else {
        this.addpaymentForm.patchValue({ tdsAmount: 0 });
      }
      this.grossCalculation(this.amountCollected, tds);
    } else {
      if (this.selectedTdsValue == "TDS on Gross") {
        let tds = Math.round(this.actualAmount * 0.10);
        this.addpaymentForm.patchValue({ tdsAmount: tds });
        this.grossCalculation(this.amountCollected, this.addpaymentForm.value.tdsAmount);
      } else if (this.selectedTdsValue == "TDS on Gross + Tax") {
        this.gstMisMatch = true;
        this.addpaymentForm.controls.paymentType.reset();
        this.addpaymentForm.controls.tdsType.reset();
        this.addpaymentForm.controls.tdsAmount.reset();
      } else if (this.selectedTdsValue == 'No TDS') {
        this.addpaymentForm.patchValue({ tdsAmount: 0 });
        this.disabledTdsAmount = true;
      } else if (this.selectedTdsValue == "Manual TDS") {
        this.disabledTdsAmount = false;
        this.addpaymentForm.controls.tdsAmount.reset();
      }
      this.grossCalculation(this.amountCollected, this.addpaymentForm.value.tdsAmount);
    }
  }

  contactSelected(event) {
    this.paymentContact = this.paymentContactList.find(p => p.id == event.value.id);
    if (this.paymentContact.salutation && this.paymentContact.firstName && this.paymentContact.lastName && this.paymentContact.designation
      && this.paymentContact.mobileNumber && this.paymentContact.email[0] && this.paymentContact.pincode &&
      this.paymentContact.state && this.paymentContact.city && (((this.paymentContact.gstType == "GST Normal" || this.paymentContact.gstType == "SEZ") && this.paymentContact.gstNumber) ||
        ((this.paymentContact.gstType != "GST Normal" && this.paymentContact.gstType != "SEZ") && this.paymentContact.panNumber))) {
      this.contactDetailsMissing = false;
    } else {
      this.contactDetailsMissing = true;
      return;
    }
    this.gstType = this.paymentContact.gstType;
  }

  getCrmData(crmContactPopupOpeningFor:string) {
    let payload = {
      "companyCode": this.initiationData?.companyCode
    }
    this.pageLoader = true;
    this.pageService.getAllContacts(payload).subscribe((res) => {
      this.crmContacts = res;
      this.pageLoader = false;
      this.openCrmContactPopup(crmContactPopupOpeningFor);
      this.disabledCrmApplyBtn = true;
    });
  }

  openCrmContactPopup(crmContactPopupOpeningFor: string = "revision") {
    this.crmContactPopupOpeningFor = crmContactPopupOpeningFor.toLowerCase();
    this.crmContactPopup = true;
  }

  crmContactSelected() {
    let crmContact = this.crmContacts.find(c =>
      c.contactId == this.selectedCrmContact
    );

    if(this.crmContactPopupOpeningFor=="initiation") {
      this.initiationCrmContact = crmContact;
      this.crmContactId = crmContact.contactId;
      this.errorContactDetails = false;
      this.setCrmContactEmailsForInitiation();
    }
    else {
      this.crmSingleContact = crmContact;

      this.enableAddAttach = true;
      this.disablePaymentTab = true;
      this.showInitiationBtn = false;
      this.showRevisionBtn = true;
    }
    this.crmContactPopup = false;
    this.crmContactPopupOpeningFor = '';
  }

  crmContactClicked() {
    this.disabledCrmApplyBtn = false;
  }

  discountSelected() {
    this.showSfCell = this.discountType == 'agreement';
    this.cancelInvoiceSelected = this.discountType == 'cancel';
    this.disablePaymentTab = true;
    this.showInitiationBtn = false;
    this.showRevisionBtn = true;
    this.enableAddAttach = true;
    this.invAmtEmpty = false;
    this.agreementDetails.forEach((a) => {
      a.surveillanceFee = null;
      a.discountAmount = null;
    });

    if (this.discountType == 'cancel') {
      this.crmSingleContact = null;
      this.dateChecked = false;
      this.disableActual = true;
      this.revisedInvAmt = 0;
      this.agreementDetails.forEach((a) => {
        a.surveillanceFee = 0;
      });
    } else {
      this.revisedInvAmt = null;
    }

    if (this.discountType == 'agreement') {
      this.disableActual = true;
    }

    if (this.discountType == 'overall') {
      this.disableActual = false;
      this.revisedInvAmt = null;
    }
  }

  dateChanged(event) {
    if (event.checked) {
      this.messageService.add({ severity: 'success', summary:'Date of invoice is subject to OFA sync.'});
    }
    this.disablePaymentTab = true;
    this.enableAddAttach = true;
    this.showInitiationBtn = false;
    this.showRevisionBtn = true;
    this.sendOtaValidation();
  }

  revisedSfEntered(event, id) {
    const regex = /^(?!0\d)\d*(\.\d{0,2})?$/; // Regular expression to allow numbers with decimal point
    const inputValue = event.target.value;
    if (event.target.value.length == 0) return;
    if (!regex.test(inputValue)) {
      event.target.value = ''; // Clear the input value if it doesn't match the pattern
    } else {
      let agreement = this.agreementDetails.find(a => a.id == id);
      let survFee = agreement.surveillanceFee;
      if (typeof (agreement.surveillanceFee) == 'string') {
        survFee = this.convertStringtoNum(agreement.surveillanceFee);
      }

      let oldsurv = this.convertStringtoNum(agreement.oldSurveillanceFee)
      let discAmt = (survFee - oldsurv);
      agreement.discountAmount = discAmt.toLocaleString("en-IN");
    }
  }

  convertStringtoNum(string) {
    if (typeof (string) === 'string') {
      let arr = string.split(',');
      let num = arr.join('');
      return Number(num);
    } else {
      return string;
    }
  }

  fixSf() {
    let totalRevisedCal: number = 0;
    this.agreementDetails.forEach((a) => {
      //remove last character if its '.'
      if (!a.surveillanceFee) {
        return;
      }
      let data = a?.surveillanceFee?.toString();
      if (data[data?.length - 1] == '.') {
        let num = data.substring(0, data.length - 1);
        a.surveillanceFee = Number(num);
      } else {
        a.surveillanceFee = Number(a.surveillanceFee);
      }
      totalRevisedCal += Number(a.surveillanceFee.toFixed(2));
    });
    this.revisedInvAmt = totalRevisedCal.toFixed(2);
  }

  fixRevisedAmt(event) {
    const regex = /^(?!0\d)\d*(\.\d{0,2})?$/; // Regular expression to allow numbers with decimal point
    const inputValue = event.target.value;
    if (event.target.value.length == 0) return;
    if (!regex.test(inputValue)) {
      event.target.value = ''; // Clear the input value if it doesn't match the pattern
      this.revisedInvAmt = null;
    } else {
      if(event.target.value == 0){
        event.target.value = '';
        this.revisedInvAmt = null;
        return;
      }
      let data = this.revisedInvAmt?.toString();
      if (data[data.length - 1] == '.') {
        let num = data.substring(0, data.length - 1);
        this.revisedInvAmt = Number(num);
      } else {
        this.revisedInvAmt = Number(this.revisedInvAmt);
      }
      this.invAmtEmpty = false;
    }
  }

  calculateAgreementDisc() {
    let revInvAmt = 0;
    let division = 0;
    revInvAmt = this.convertStringtoNum(this.revisedInvAmt);
    if(this.sfOriginalTotal != 0){
      division = (revInvAmt / this.sfOriginalTotal);
    }
    let sum = 0;
    if (revInvAmt > 0) {
      this.agreementDetails.forEach((a, i) => {
        // multiply and add all numbers except the last
        let survFee = this.convertStringtoNum(a.sfOriginalFee)
        if (i != this.agreementDetails.length - 1) {
          a.surveillanceFee = Number(division * survFee).toFixed(2);
          sum += Number(a.surveillanceFee);
        }
        if (i == this.agreementDetails.length - 1) {
          a.surveillanceFee = Number(revInvAmt - sum).toFixed(2);
        }
        let discAmt = Number(a.surveillanceFee - survFee);
        a.discountAmount = discAmt.toLocaleString("en-IN");
      });
    }
  }

  openApproverPopup(type) {
    if(this.convertStringtoNum(this.revisedInvAmt) >= 0 && (this.revisedInvAmt != null && this.revisedInvAmt != undefined)){
      this.sendType = type;
      this.approverName = null;
      if(this.revisedInvAmt == 0 && this.discountType === 'agreement') {  // atleast one agreement must have value > 0
        this.approverPopup = false;
        this.emptyAgreement = true;
        return;
      }
      this.approverPopup = true;
      this.invAmtEmpty = false;
    } else {
      this.approverPopup = false;
      if(this.discountType == 'overall'){
        this.invAmtEmpty = true;
      }
      this.emptyAgreement = this.discountType == 'agreement' ? true : false;
    }
  }

  sendOta() {
    this.approverPopup = false;
    this.pageLoader = true;
    let payload = this.createRevpayload();
    if(this.sendType == 'send'){
      this.sendFirstOta(payload);
    } else {
      this.reSendOta(payload);
    }
    this.sendType = null;
  }

  sendFirstOta(payload){
    this.pageService.generateOta(payload).subscribe((res) => {
      this.pageLoader = false;
      this.showSfCell = false;
      this.messageService.add({ severity: 'success', summary:'OTA sent successfully.'});
      this.isOtaPending = true;
      this.getRevisionDetails(this.initiationData?.billId);
      this.ref.close();
    }, (_err) => {
      this.pageLoader = false;
      this.messageService.add({ severity: 'error', summary:'Something went wrong! please try again.'});
    }); 
  }

  reSendOta(payload){
    this.pageService.resendOta(payload).subscribe((res) => {
      this.pageLoader = false;
      this.showSfCell = false;
      this.messageService.add({ severity: 'success', summary:'OTA sent successfully.'});
      this.isOtaPending = true;
      this.getRevisionDetails(this.initiationData?.billId);
    }, (_err) => {
      this.pageLoader = false;
      this.messageService.add({ severity: 'error', summary:'Something went wrong! please try again.'});
    })
  }

  createRevpayload(){
    if (this.invoiceContact?.pincode != this.crmSingleContact?.pin || this.invoiceContact?.gstNo !== this.crmSingleContact?.gstNumber ||
      this.invoiceContact?.gstType !== this.crmSingleContact?.gstType || this.invoiceContact?.city !== this.crmSingleContact?.city ||
      this.invoiceContact?.state !== this.crmSingleContact?.state || this.dateChecked || ((this.discountType != undefined) && (this.discountType != null))) {
      this.isOfaReq = true;
    } else {
      this.isOfaReq = false;
    }

    let payload = {
      ofaFlag: this.isOfaReq ? 'Y' : 'N',
      userId: this.empId,
      invoiceDate: this.revInvoiceDate,
      opportunityId: this.initiationData?.opportunityId,
      unapprovedSFBillDetails: {
        id: this.revisionDetials?.id,
        sfBillId: this.initiationData?.billId,
        invoiceNo: this.revisionDetials?.invoiceNo,
        newTotalSurvellienceFee: this.convertStringtoNum(this.revisedInvAmt),
        oldTotalsurvellienceFee: this.convertStringtoNum(this.currentInvAmt),
        invoiceRevisionType: this.discountType?.toUpperCase(),
        isDateChange: this.dateChecked ? 'Y' : 'N'
      },
      unapprovedSFBillAgreementDetailsList: [],
      unapprovedSfContactDetails: null,
      previousContactDetails: null,
      otpEmailDTO: {
        refSfBillDetailsId: this.revisionDetials?.id,
        companyName: this.initiationData?.companyName,
        financialYear: this.initiationData?.invoiceDetails?.financialYear,
        changeType: this.isOfaReq ? 'OFA Sync' : 'Non-OFA Sync',
        approverUserId: this.approverName,
        noteToManager: this.noteToManager,
        userId: this.empId
      },
      attachmentsDataList: this.documents
    }

    if(this.crmSingleContact){
      payload.unapprovedSfContactDetails = {
        refSfBillDetailsId: this.revisionDetials?.id,
        crmContactId: this.crmSingleContact?.contactId,
        firstName: this.crmSingleContact?.firstName,
        lastName: this.crmSingleContact?.lastName,
        salutation: this.crmSingleContact?.salutation,
        designation: this.crmSingleContact?.designation,
        pinCode: this.crmSingleContact?.pin,
        state: this.crmSingleContact?.state,
        city: this.crmSingleContact?.city,
        gstNumber: this.crmSingleContact?.gstNumber,
        gstType: this.crmSingleContact?.gstType,
        address1: this.crmSingleContact?.address1,
        address2: this.crmSingleContact?.address2,
        address3: this.crmSingleContact?.address3,
        email: this.crmSingleContact?.email,
        mobileNumber: this.crmSingleContact?.mobileNum,
        landlineNumber: this.crmSingleContact?.phoneNum,
        panNumber: this.crmSingleContact?.pan,
      }
    } else {
      payload.unapprovedSfContactDetails = null;
    }

    payload.previousContactDetails = this.invoiceContact;

     let agreements = this.agreementDetails.map((a) => {
      return {
        agreementId: a.agreementId,
        refSfBillDetailsId: this.revisionDetials?.id,
        productId: a.productId,
        productName: this.productList.find(p => p.id == a.productId)?.product_name,
        instrumentId: a.instrumentId,
        instrumentName: this.instrumentList.find(i => i.id == a.instrumentId)?.name,
        oldTotalsurvellienceFee: this.convertStringtoNum(a.oldSurveillanceFee),
        newTotalSurvellienceFee: this.convertStringtoNum(a.surveillanceFee) || 0,
        outstandingQuantum: a.outstandingQuantum,
      }
    });
    if(this.discountType == undefined || this.discountType == null){
      payload.unapprovedSFBillDetails.newTotalSurvellienceFee = this.convertStringtoNum(this.currentInvAmt);
      agreements.forEach(a => {
        a.newTotalSurvellienceFee = a.oldTotalsurvellienceFee;
      });
    }
    payload.unapprovedSFBillAgreementDetailsList = agreements;;
    return payload;
  }

  noteToManagerEntered() {
    this.disablePaymentTab = true;
    this.sendOtaValidation();
  }

  getInvContact(){
    return {
      firstName: this.invoiceContact?.firstName,
      lastName: this.invoiceContact?.lastName,
      salutation: this.invoiceContact?.salutation,
      designation: this.invoiceContact?.designation,
      pincode: this.invoiceContact?.pincode,
      state: this.invoiceContact?.state,
      city: this.invoiceContact?.city,
      gstNo: this.invoiceContact?.gstNo,
      gstType: this.invoiceContact?.gstType,
      addressLine1: this.invoiceContact?.addressLine1,
      addressLine2: this.invoiceContact?.addressLine2,
      addressLine3: this.invoiceContact?.addressLine3,
      addressLine4: this.invoiceContact?.addressLine4,
      email: this.invoiceContact?.email,
      mobileNumber: this.invoiceContact?.mobileNo,
      landlineNumber: this.invoiceContact?.landlineNo,
      panNumber: this.invoiceContact?.panNumber,
      crm_contact_id: this.invoiceContact?.crmContactId
    }
  }

  sendOtaValidation(){
    let miscDoc = this.documents.find(d => d.fileTypeName?.toLowerCase()?.includes('misc'));
    if (this.noteToManager?.length >= 3  && !this.isOtaPending && miscDoc) {
      this.disableSendOta = false;
    } else {
      this.disableSendOta = true;
    }
  }

  showAllChangesPopup(){
    this.allChangesPopup = true;
  }

  invalidOta: boolean = false;
  verifyOta() {
    this.pageLoader = true;
    let payload = {
      revisionId: this.revisionDetials?.id,
      sfBillId: this.initiationData?.billId,
      otaKey: this.otaValue,
      ownedBy: this.initiationData?.ownedBy,
      userId: this.empId
    }

    this.pageService.validateOta(payload).subscribe((res) => {
      if(res?.Otp_Status?.toLowerCase() === 'ota does not match'){
        this.invalidOta = true;
      } else {
        this.invalidOta = false;
      }
      
      if(res?.Otp_Status?.toLowerCase() === 'data saved successfully'){
        this.ref.close();
      }
      this.pageLoader = false;
    },(_err)=> {
      this.apiFail = true;
      this.pageLoader = false;
    })
  }

  cancelAllChange() {
    this.pageLoader = true;
    this.allChangesPopup = false;
    let payload: any = {
      revisionId: this.revisionDetials?.id,
      sfBillId: this.initiationData?.billId,
      otaKey: null,
      userId: this.empId
    }
    this.disabledPaymentMsg = undefined;
    this.pageService.cancelPendingOta(payload).subscribe((res) => {
      this.pageLoader = false;
      this.isOtaPending = false;
      this.deleteMultipleFile();
      this.ref.close(null);
    }, (_err) => {
      this.pageLoader = false;
      this.messageService.add({ severity: 'error', summary: 'Something went wrong! please try again.' });
    });
  }

  attachmentPopup() {
    this.dynamicDialogdialogRef = this.dialogService.open(AttachmentModelComponent, {
      contentStyle: { 'max-height': '550px', 'overflow': 'visible' },
      header: 'Upload Attachment',
      closable: true,
      data: { list: this.attachmentTypeList, payments: this.payments.value, editValue: this.editAttachmentValue, type : '.msg' },
      styleClass: 'sm',
    });
    this.dynamicDialogdialogRef.onClose.subscribe(response => {
      if (response && !this.isEdited) {
        response[0].moduleId = this.initiationData?.opportunityId;
        response[0].code = `${response[0]?.fileTypeName}_${this.docCount}`;
        // if(response[0].fileTypeid == 30){
        //   this.isRptApprovalRecd = "Yes";
        //   response[0].file = null;
        // }
        let data = response[0].file;
        if(data != null && data != undefined){
          if (data.startsWith('data:application/pdf;base64,')) {
            data = data.replace('data:application/pdf;base64,', '');
          } else if (data.startsWith('data:image/png;base64,')) {
            data = data.replace('data:image/png;base64,', '');
          } else if (data.startsWith('data:application/doc;base64,')) {
            data = data.replace('data:application/doc;base64,', '');
          }
        }
        
        response[0].file = data;
        this.documents.push(response[0]);
        this.uploadSinglefile(response[0]);
        this.sendOtaValidation();
        this.docCount++;
        setTimeout(() => {
          response[0].file = null;
          
          this.sendOtaValidation();
        }, 400);
      } else if (response && this.isEdited) {
        this.isEdited = false;
        this.editAttachmentValue = null;
        let doc = this.documents[response.index];
        doc.fileTypeName = response.value?.fileTypeName?.type;
        doc.fileTypeid = response.value?.fileTypeName?.id;
        doc.paymentMode = response.value?.paymentMode;
      }
      this.thirdPartyAttachPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === '3rd party approval attachment');
      this.ibpAttachPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === 'invoice before payment approval');
      this.rptAttchmentPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === 'rpt approval');   
    });
  }

  addAttachment() {
    this.editAttachmentValue = null;
    this.attachmentPopup()
  }

  editAttachment(item, i) {
    this.editAttachmentValue = {
      item: item,
      index: i
    }
    this.isEdited = true;
    this.attachmentPopup();
  }

  onDeletePopup(item){
    this.deleteFile = true;
    this.deleteFileData = item;
    this.sendOtaValidation();
  }

  onDelete(){
    this.pageService.deleteFileById(this.deleteFileData?.deleteId).subscribe((res) => {
      if(res?.status == "success"){
        if(this.deleteFileData?.fileTypeid == "30"){
          this.isRptApprovalRecd = 'No';
        }
        if(res?.status == "success"){
          let index = this.documents.findIndex(item => item.deleteId == this.deleteFileData?.deleteId);
          if(index != -1){
            this.documents.splice(index, 1);
          }
          let index1 = this.uploadedFileIds.findIndex((r) => r == this.deleteFileData?.deleteId);
          this.uploadedFileIds.splice(index1, 1);
        }
      }
    }, (_err) => {
      this.apiFail = true;
    });
    this.deleteFile = false;
    this.thirdPartyAttachPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === '3rd party approval attachment');
    this.ibpAttachPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === 'invoice before payment approval');
    this.rptAttchmentPresent = this.documents.some(d => d?.fileTypeName.toLowerCase() === 'rpt approval');   
  }

  showAttachmentPreview(item){
    this.openedFile = item;
    this.isPdfInvoice = false;
    this.currentPdf = undefined;
    this.zoomValue = 1;
    this.rotate = 0;

    if (item?.fileId) {
      this.displayPreview = true;
      this.previewLoader = true;
      let payload = {
        id: item.fileId
      }
      this.pageService.findPDF(payload).subscribe((res) => {
        if (res && res?.file) {
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          let isPreviewable = true;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else if ( "msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
            isPreviewable = false;
          } else if ( ['xls', 'xlsx'].includes(ext1?.toLowerCase())) {
            mime = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
            isPreviewable = false;
          }  else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.urlSafe = `${mime}${res.file}`
          if (!this.isPdf) {
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.displayPreview = isPreviewable;
          if(!isPreviewable) {
            this.pageService.downloadFile(res, this.urlSafe);
          }
          this.previewLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      });
    }
  }

  showInvoiceModal(showOldInvoice : boolean) {
    this.showOldInvoice = showOldInvoice;
    this.showInvoiceListModal = true;
    this.showEmptyInvoiceState = !(
      (showOldInvoice && this.invoiceDocDetails.some(item => !item.isCurrentInvoice)) || 
      (!showOldInvoice && this.invoiceDocDetails.some(item => item.isCurrentInvoice))
    );
  }

  showCreditNote(showCreditNote: boolean){
    this.partialCnModel = true;
    if(this.pcnDocDetails != null && this.pcnDocDetails != undefined && this.pcnDocDetails.length >0){
      this.showPcnEmpty= true;
    } 
    
    
  }

  previewAttachment(attachData: any) {
    let invoiceNo = attachData.invoiceNumber;
    this.currentPdf = attachData;
    
    this.zoomValue = 1;
    this.rotate = 0;
    this.isPdfInvoice = true;
    this.isPdf = true;

    if(this.cachedInvoiceDoc?.[invoiceNo]) {
      this.currentPdf = attachData;
      this.urlSafe = this.pdfMime + this.cachedInvoiceDoc?.[invoiceNo]?.url;
      this.displayPreview = true;
      return;
    }
    this.previewLoader = true;
    this.pageService.getInvoiceDocForActionables(invoiceNo).subscribe((res : any) => {
      if(res) {
        this.cachedInvoiceDoc = {...this.cachedInvoiceDoc, [res?.invoiceNumber] : {url : res?.invoiceBase64, name : res?.invoiceName}};
        this.urlSafe = this.pdfMime + this.cachedInvoiceDoc?.[res?.invoiceNumber]?.url;
        this.currentPdf = res;
        this.displayPreview = true;
      }
      this.previewLoader = false;
    }, (err) => {
      this.previewLoader = false;
      this.apiFail = true;
      this.displayPreview = false;
      this.isPdfInvoice = false;
      this.isPdf = false;

    });
  }

  // method to download invoice file
  downloadInvoice(invoiceNo : string) {
    if(this.cachedInvoiceDoc?.[invoiceNo]) {
      this.downloadPdfUrl(this.cachedInvoiceDoc?.[invoiceNo]?.url, this.cachedInvoiceDoc?.[invoiceNo]?.name);
      return;
    }
    this.previewLoader = true;
    this.pageService.getInvoiceDocForActionables(invoiceNo).subscribe((res : any) => {
      if(res) {
        this.previewLoader = false;
        this.downloadPdfUrl(res?.invoiceBase64, res?.invoiceName);
        this.cachedInvoiceDoc = {...this.cachedInvoiceDoc, [res?.invoiceNumber] : {url : res?.invoiceBase64, name : res?.invoiceName}}
      }
    }, (err) => {
      this.previewLoader = false;
      this.apiFail = true;
    });
    
  }

  downloadPdfUrl(url, name) {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = this.pdfMime + url;
    downloadLink.target = '_self';
    downloadLink.download = name;
    downloadLink.click(); 
    this.messageService.add({key : 'invoiceAckMsg', severity: 'success', detail: 'Invoice downloaded successfully' });
    setTimeout(() => {
        this.messageService.clear('invoiceAckMsg');
    }, 2000);
  }

  downloadFile(){
    let linkSource: any;
    if (this.openedFile?.fileContentType?.includes('image')) {
      let mime = 'data:image/png;base64';
      linkSource = `${mime},${this.urlSafe?.split(',')[1]}`;
    } else {
      linkSource = this.urlSafe;
    }
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this?.openedFile?.fileName?.split('.')[0] || this.invoiceDocDetails?.invoiceName;
    downloadLink.click(); 
  }

  closeInitationPopup(event?) {
    this.deleteMultipleFile();
    this.ref.close(event);
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  onPayModeChange(event){
    this.addpaymentForm.controls.neftCheque.reset();
    const chequeNo_id = document.getElementById("chequeNo_id");
    if (event.value == "Cheque") {
      chequeNo_id.setAttribute("maxlength", `${6}`);
    } else {
      chequeNo_id.setAttribute("maxlength", `${100}`);
    }
  }

  numberOnlyCheque(event) {
    let text = event.key;
    if(this.addpaymentForm.value.paymentMode == "Cheque"){
      return /^[0-9]*$/.test(text);
    }else{
      return /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]+$/.test(text);
    }  
  }

  numberOnly(event) {
    let text = event.key;
    return /^[0-9]*$/.test(text);
  }

  getPaymentModeList(): void {
    this.pageService.getpaymentModeDataReportingScreenDetails().subscribe(results => {
      this.paymentModeList = results;
    });
  }

  getBankList(): void {
    this.pageService.getbankDataReportingScreenDetails().subscribe((results: any) => {
      this.bankList = results.sort((a,b)=> a.id-b.id);
    });
  }

  getPaymentTypeList(): void {
    this.pageService.getpaymenttypeDataReportingScreenDetails().subscribe(results => {
      this.paymentTypeList = results;
      this.paymentTypeListFiltered = results;
      this.paymentTypeListFiltered2 = results;
    });
  }

  getInstruments() {
    this.pageService.findInstrumentList().subscribe((res) => {
      this.instrumentList = res;
    });
  }

  getProducts() {
    this.pageService.getProductDataReportingScreenDetails().subscribe((res) => {
      this.productList = res;
    });
  }

  getTdsTypeList(): void {
    this.pageService.getTdsType().subscribe(results => {
      this.tdsTypeList = results;
      this.filterTdsTypeList = results;
    });
  }

  getAttachmentTypeList(): void {
    this.pageService.getAttachmentTypeMasterData().subscribe((results) => {
      this.attachmentTypeList = results.filter((r) => {
        if (!r.type.toLowerCase().includes('mandate')) {
          return r;
        }
      });
    });
    if (!this.rptoverall || this.rptoverall=='No') {
      this.attachmentTypeList.forEach(element => {
        if (element.type == 'RPT Approval') {
          element.is_mandatory = 'O';
        }
      });
    } 
  }

  getReportingStatus() {
    this.pageService.getSFBusinessAction().subscribe(results => {
      this.reportingStatusList = results;
    });
  }

  getSalutationList(): void {
    this.pageService.getsalutationselectDataReportingScreenDetails().subscribe(results => {
      this.salutationList = results;
    });
  }

  getDesignationList(): void {
    this.pageService.getdesignationselectDataReportingScreenDetails().subscribe(results => {
      this.designationList = results;
    });
  }

  getGSTtypeList(): void {
    this.pageService.getGSTtypeselectDataReportingScreenDetails().subscribe(results => {
      this.gstTypeList = results;
    });
  }

  getPaymentStatus() {
    this.pageService.getPaymentStatus().subscribe(result => {
      let obj = result?.reduce((acc, obj) => {
        acc[obj.id] = obj.name;
        return acc;
      }, {});
      this.payStatus = obj
    });
  }

  caseInitationStatus() {
    this.pageService.caseInitationStatus().subscribe((res) => {
      this.initiationStatusList = res;
    });
  }

  getsfDiscountReasonList(): void {
    this.pageService.getsfDiscountReason1DataEditOpportunity().subscribe(results => {
      this.sfDiscountReasonList = results;
    });
  }

  getRevisionReasons(): void {
    this.pageService.getSfRevisionReasons().subscribe(results => {
      this.revisionReasons = results;
    });
  }

  getUserHierarchy() {
    this.pageService.getSfBdHierarchy(this.empId).subscribe((res: any) => {
      let data = JSON.parse(res.upwardHierarchyUsers);
      let list = data?.uppwardhierarchy.splice(1);
      this.hierarchyList = list.filter((l) => {
        if(!l.levelName?.toLowerCase()?.startsWith('sd')){
          return l;
        }
      });
    })
  }

  partyApprovalChanged(event){
    this.partyApprovalSelected = true;
    if(event.value == 'Yes'){
      this.partyCommentM = true;
      this.disablethirdPartyComment = false;
      this.thirdPartyForm.get('thirdPartyApprovalComment')?.setValidators([Validators.required, Validators.minLength(1)]);
    } else {
      this.partyCommentM = false;
      this.thirdPartyForm.controls.thirdPartyApprovalComment.reset();
      this.thirdPartyForm.get('thirdPartyApprovalComment')?.clearValidators();
    }
    this.thirdPartyForm.get('thirdPartyApprovalComment')?.updateValueAndValidity();
  }

  getRptOverallStatus(rptStatus){
    this.rptoverall= (rptStatus?.rptInCC=='Yes'|| rptStatus?.rptInCrm =='Yes')?'Yes': 'No';
  }

  deleteMultipleFile(){
    this.pageService.deleteMultipleFiles(this.uploadedFileIds).subscribe((res) => {
    });
  }
  
  uploadSinglefile(data){
    console.log("FileUpload Data: ",data)
    this.pageService.singleFileUpload(data).subscribe((response) => {
      if(response != null && response != undefined){
        console.log("Fileupload Resposne Data: ",response);
        let obj = this.documents?.find((d) => d?.code == data?.code);
        obj.deleteId = response?.fileId;
        obj.fileId = response?.fileId;
        obj.pdfName = response?.pdfName;
        this.uploadedFileIds.push(response?.fileId);
      }
      
    }, (_err) => {
      let obj = this.documents?.find((d) => d?.code == data?.code);
      obj.file = '';
      obj.fileId = null;
      obj.fileName = '';
      obj.thumbNail = '';
      this.apiFail = true;
    });
  }

  getPartialCnData(sfBillId,companyCode){
    console.log("Partial CN API called");
    this.pageService.getPartialCnAmount(sfBillId,companyCode).subscribe(res=>{
      this.partialCnAmount = res?.cnAmount;
      this.invoiceAmount = res?.totalSurvFee;
    });
  }
}
