import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InitiationService } from '../initiation.service';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { ImageTransform } from "ngx-image-cropper";
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { Subject, forkJoin, of } from 'rxjs';
import { PagesService } from 'app/pages/pages.service';
import { Router } from '@angular/router';
import {switchMap} from 'rxjs/operators';
import * as moment from 'moment';
import { AttachmentModelComponent } from 'app/pages/attachment-model/attachment-model.component';

@Component({
  selector: 'backoffice-irf-initiation-details',
  templateUrl: './irf-initiation-details.component.html',
  styleUrls: ['./irf-initiation-details.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class IrfInitiationDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly pdfMime = 'data:application/pdf;base64,';
  // #start -feecap variables
  feecapTagList = [{
    property: 'Yes'
  }, {
    property: 'No'
  }];

  feeCapDisable = false;
  feeCapData: any;

  // #start - Mutual fund related variables
  mfList = [];
  amcNameList = [];
  amcAPI$ = new Subject();
  showMF = false;
  selectedAMCCode;
  selectedMFParentId;
  // #end

  apiCallList: any = [];
  crmContacts: any = [];
  clientTypeList: any = [];
  secondaryOppList: any = [];
  documentList = [];
  attachmentResult: any = [];
  combineMasterData: any = [];
  currencyList: any = [];
  reasonList: any = [];
  hierarchyList: any = [];
  businessTypeList: any = [];
  opeTypeList: any = [];
  mandateTypeList: any = [];
  priorityQcList: any = [];
  caseClassList: any = [];
  proposedSplitList: any = [];
  allUserList: any = [];
  errorMessageSave: any = [];
  productServiceList: any = [];
  initiationTypeList: any = [];
  uploadedDocs: any = [];
  scopeList: any = [];
  opsReasonList: any = [];

  activeIndex: number = 0;

  errorClientClass: boolean = false;
  errorEntityContact: boolean = false;
  errorFeeCap: boolean = false;
  errorRevision = false;
  errorOtherKey: boolean = false;
  errorAttachment: boolean = false;
  errorRevisionMsgs = new Set();
  finalRevErrorMsg = "";
  errorBusinessAction: boolean = false;
  errorCombo: boolean = false;
  errorAction: boolean = false;
  invalidOta: boolean = false;
  approverPopup: boolean = false;
  pageLoader: boolean = true;
  apiFail: boolean = false;
  feeCapMissing: boolean = true;
  crmContactPopup: boolean = false;
  disabledCrmApplyBtn: boolean = false;
  disableAgreeClass: boolean = false;
  deleteFile: boolean = false;
  disableReason: boolean = true;
  enableOta: boolean = false;
  enableSplitOta: boolean = false;
  enableOtaAttachmentMsg: boolean = false;
  disableOta: boolean = true;
  enableSendOtaBtn: boolean = false;
  enableCancelOtaBtn: boolean = false;
  enableResubmit: boolean = false;
  showSubmit: boolean = true;
  verifyAndSave = false;
  disableBusinessAction: boolean = false;
  disableComment: boolean = false;
  showOpeAmt: boolean = false;
  disableInvVal: boolean = true;
  invalidInvVal: boolean = false;
  invalidOpeAmt: boolean = false;
  splitTypeSelected: boolean = false;
  disableSplit: boolean = false;
  team1Err: boolean = false;
  errorMsgPopup: boolean = false;
  otaErrorMsgPopup: boolean = false;
  showFailureModel: boolean = false;
  isInitiated: boolean = false;
  showOtherKeyOta: boolean = false;
  disableSaveBtn: boolean = false;
  disableAllFileds: boolean = false;
  initiationReadScope: boolean = false;
  caseCloseScope: boolean = false;
  crmContactFetched: boolean = false;
  isSecondOpp: boolean = false;

  crmCompanyContact: any = {};
  crmTriContact: any = {};
  companyContact: any = {};
  triContact: any = {};
  entityClientClass: any = {};
  triClientClass: any = {};
  entityCrmContact: any = {};
  triCrmContact: any = {};
  secondaryOpp: any = {};
  secondaryOpp1: any = {};
  uploadFile: any = {};
  irfDetails: any = {};
  fileUploadfileUpload1: any = {
    files: []
  };
  otaRequestPayload: any = {};
  cancelOtaRequestPayload: any = {};

  contactChecked: any;
  approverName: any;
  proposedRadio: any;
  priorityRadio: any;
  otaValue: any;
  selectedCrmContact: any;
  crmContactType: any;
  entityEmail1: any;
  entityEmail2: any;
  entityEmail3: any;
  triEmail1: any;
  triEmail2: any;
  triEmail3: any;
  triClass: any;
  oppGridDetials: any;
  returnUploaded: any;
  empId: any;
  empName: any;
  oppOwnerId: any;
  bdHierarchy: any;
  bdHierarchyData: any;
  team2HierarchyData: any;
  team2Hierarchy: any;
  timer: any;
  agreementId: any;
  product: any;
  qcStatus: any;

  deleteFileData: any;
  attachToDelete: any = [];
  team1ErrTxt: string;
  errorMessage: string = "";

  maxDateLimit = new Date();

  // attachment variables
  displayPreview: boolean = false;
  previewLoader: boolean = false;
  previewFailed: boolean = false;
  isPdf: boolean = true;
  openedFile: any = {};
  urlSafe: any;
  zoomValue: number = 1;
  rotate: number = 0;

  //file upload related variables
  canvasRotation = 0;
  currentFileEvent: any;
  fileType: any;
  fileTypeId: any;
  imgChangeEvt: any = "";
  displayModal: boolean;
  imageBlob: any;
  uploadedFileName: any;
  returnBase64: any;
  transform: ImageTransform = {};
  disableComboTab: boolean = true;
  croppedImageBase64: any;
  invoiceCategoryList: any[] = [{name: 'New', key: 'New'}, {name: 'Old', key: 'Old'}, {name: 'CM', key: 'CM'}];
  selectedInvoiceCategory: any = this.invoiceCategoryList[0];
  invoiceDocList = [];  
  cachedInvoiceDoc : {[key: string] : any};
  disableInvoice = true;
  disableRevision = true;
  disableRevisionGlobal = false;
  allChangesPopup = false;
  revisionaApproverPopup = false;
  REV_ATTCH_ERROR_MSG = "Please add an attachment for Revision";
  REV_ALL_FIELDS_MSG = "Enter all mandatory fields";
  checkOpportunityDetails: any;
  
  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;

  entityDetailsForm = this.fb.group({
    initType: [118 as any],
    triNormal: [null],
    tdsNo: [null],
    triCrm: [null],
    crmCode: [null],
    tdsNum: [null]
  });
  
  revisionForm = this.fb.group({
    id : [],
    billDetail : [null, [Validators.required, Validators.minLength(3)]],
    oldBillDetail : [],
    isCancel : [false],
    currentInvoiceVal : [],
    revisedInvoiceVal : [],
    reasonForrevision : [null, [Validators.required]],
    noteToManager : [null, [Validators.required, Validators.minLength(3)]],
    oldContact : [],
    newContact : [],
    revisionInProgress : [],
    attachment : [null, Validators.required],
    irfBillId : [],
    invoiceNo : [],
    userId : [this.initiationService.empId],
    opprOwnerId : [],
    oppOwnerName :  [],
    ota : []
  })

  reasonForRevision = [];
  mandateValidityList = [];

  comboDetailsForm = this.fb.group({
    secondOpp: [null, []],
    productSelected: [null],
    mainOppName: [null],
    mainProduct: [null],
    mainIrfFee: [null],
    mainSfFee: [null],
    comboOppName: [null],
    comboProduct: [null],
    comboIrfFee: [null],
    comboTotalIrfFee: [null],
    comboSfFee: [null],
    comboTotalSfFee: [null],
    comboBdUser: [null],
    comboReportedOn: [null]
  });

  triContactForm = this.fb.group({
    salutation: [null],
    firstName: [null],
    lastName: [null],
    designation: [null],
    mobile: [null],
    landline: [null],
    email1: [null],
    email2: [null],
    email3: [null],
    gstType: [null],
    gstNo: [null],
    panNo: [null],
    pinCode: [null],
    city: [null],
    state: [null],
    address1: [null],
    address2: [null],
    address3: [null],
    address4: [null],
  });

  contactForm = this.fb.group({
    salutation: [null],
    firstName: [null],
    lastName: [null],
    designation: [null],
    mobile: [null],
    landline: [null],
    email1: [null],
    email2: [null],
    email3: [null],
    gstType: [null],
    gstNo: [null],
    panNo: [null],
    pinCode: [null],
    city: [null],
    state: [null],
    address1: [null],
    address2: [null],
    address3: [null],
    address4: [null],
  });

  otherKeyForm = this.fb.group({
    masterService: [null],
    subService: [null],
    ratableUnit: [null],
    mandateDate: [null as any, [Validators.required]],
    clientSignedDate: [null as any, [Validators.required]],
    crisilSignedDate: [null as any, [Validators.required]],
    sentToClient: [null as any, [Validators.required]],
    recievedFromClient: [null],
    mandateType: [null, [Validators.required]],
    feedbackQc: [null],
    priorityQc: [null],
    currency: [null],
    invoiceVal: [null],
    opeType: [null, [Validators.required]],
    caseClass: [null, [Validators.required]],
    sfMandateValidity: [null, [Validators.required]],
    opeAmount: [null, []],
    split: [null],
    irfActualFee: [null],
    proposedSplit: [null, []],
    team1: [null, []],
    splitTeam1: [null],
    splitTeam2: [null],
    team2Owner: [null, []],
    comment: [null, []],
    ota:[null, []],
    outstandingAmount: [{ value: '', disabled: true }, [Validators.required]],
    inprogressAmount: [{ value: '', disabled: true }, [Validators.required]],
    previousLimit: [{ value: '' as any, disabled: false }, [Validators.required]],
    currentLimit: [{ value: '' as any, disabled: true }, [Validators.required]],
    applicableAmount: [{ value: '' as any, disabled: true }, [Validators.required]],
    amcName : [null as any, []],
    amcCode : [null as any],
    mfSchemeName : [null as any, []],
    mfParentId : [null],
    // outstandingAmount: [{ value: '', disabled: true }, [Validators.required]],
    // inprogressAmount: [{ value: '', disabled: true }, [Validators.required]],
    // previousLimit: [{ value: '', disabled: false }, [Validators.required]],
    // currentLimit: [{ value: '', disabled: true }, [Validators.required]],
    // applicableAmount: [{ value: '', disabled: true }, [Validators.required, Validators.min(1)]]
    effectiveMisDate: [{ value: '' as any, disabled: true }],
  });

  limitDetails: any;
  previousLimitList: any[] = [];
  isSaveClicked: boolean = false;

  agreementClass: FormControl = new FormControl('');
  clientClass: FormControl = new FormControl('');
  feeCapForm = this.fb.group({
    tagMandate : [null as any, [Validators.required]]
  })

  businessActionForm = this.fb.group({
    businessAction: [null as any, [Validators.required]],
    reason: [null, []],
    opsReaseon:[null as any,[]],
    comment: [null],
    ota: [null]
  });
  sendSplitOta: boolean;
  sendCaseCloseOta: boolean;
  otaExpiryTime: any;
  otaExpiryAction: boolean = false;
  businessAction: any;
  comboProductId: any;
  attachmentRejected: boolean = false;
  productName: any;
  currentPdf: any;
  crmSingleContact;
  invoiceContact;
  crmRevisionContact: boolean;
  showEmptyInvoiceState: boolean = false;
  revisionService$ = new Subject();
  doRevision: boolean = false;
  showRevisionAddButton = false;
  revisionInProgress: boolean;
  status: string = "";
  ofaFlag: string = "";
  runtimeCancel = false
  showRevAttachmentAlertPopUp: boolean;
  dynamicDialogdialogRef: DynamicDialogRef = new DynamicDialogRef();
  revisionAttachmentList: any;
  revisionFileItem: any;
  oppOwnerName: any;
  showRevOTA: boolean;
  getOpprOwnerName$ = new Subject();
  showRevInvalidAlertPopUp: boolean = false;
  irfApproverList = [];
  disableRevisionTab: boolean = true;
  disableTabMessage: string;
  getInvoiceHistory$ = new Subject();
  caseProduct: any;
  crmRevisionContactSelected: boolean = false;

  get otherKeyformControls() {
    return this.otherKeyForm.controls;
  }

  isIrfInitiated: any;
  splitRevenueOta: any;
  caseCloseOta: any;
  otaResponse: any = {};
  initiationSavePayload: any = {};
  secondaryOpportunityId: any;
  refNo: any;
  showRefNo: boolean = false;
  splitTeam1Id: any;
  splitTeam2Id: any;

  qcActionList: any;
  qcRejectMap: any = {};
  isQcRejected: boolean = false;
  qcStatusList: any = [];
  enableEntityCrm: boolean =false
  enabletriCrm: boolean =false
  otaAttachment:any = [];
  migratedDocs: any = [];
  uploadedFileIds: any = [];
  uploadLoader: boolean = false;

  constructor(private router: Router, public initiationService: InitiationService, private fb: FormBuilder, public config: DynamicDialogConfig, private messageService: MessageService, private encDec: EncryptDecryptInterceptor, private pageService: PagesService, private dialogService: DialogService, private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.empId = this.encDec.decrypt(localStorage.getItem('empid'));
    this.empName = this.encDec.decrypt(localStorage.getItem('empName'));
    this.loaderCheck();
    this.getCurrencyList();
    this.getOpsReasonList();
    this.getCaseCloseReasonList();
    this.getUserHierarchys();
    this.getBusinessType();
    this.getMandateValidityList();
    this.oppGridDetials = this.config?.data;
    this.scopeList = this.encDec.decrypt(localStorage.getItem('scopes'));
    let scope = this.scopeList.split(',');
    if (scope.indexOf('INITIATION_CREATE') == -1) {
      this.initiationReadScope = true;
      this.disableSaveBtn = true;
    } else {
      this.initiationReadScope = false;
    }
    if (scope.indexOf('CASE_CLOSE_CREATE') == -1) {
      this.caseCloseScope = false;
      this.businessTypeList = [{ "id": 132, "name": "IrfBusinessAction", "property": "Initiated" }];
    } else {
      this.caseCloseScope = true;
    }
    let payload = {
      opportunityId: this.oppGridDetials?.opportunityId,
      companyCode: this.oppGridDetials?.companyCode,
      fromIrfPushPull: this.oppGridDetials?.fromIrfPushPull,
      tripartyCompanyCode: this.oppGridDetials?.triPartyCompanyCode
    }
    // get invoice list
    this.getInvoiceHistory$.pipe(switchMap(() => this.pageService.getInvoiceHistoryForActionables(this.oppGridDetials?.opportunityId, "IRF")
    ))
    .subscribe((res) => {
      if(res?.length > 0) {
        this.invoiceDocList = res.map(item => ({...item, invoiceDate : moment.utc(item.invoiceDate).format("DD-MMM-YYYY")}));
        this.showEmptyInvoiceState = false;
        this.disableRevisionTab = false;
      } else { 
        this.disableInvoice = true;
        this.showEmptyInvoiceState = true;
      }
    });

    // get oppr owner name
    this.getOpprOwnerName$.pipe(switchMap((agreementId) => {
        return this.pageService.getSfBdHierarchy(agreementId);
    })).subscribe((res : any) => {
      this.oppOwnerName = res?.employeeName;
    });

    this.initiationService.showLoader('getIrfInitationDetails');
    this.initiationService.showLoader('combineMaster');
    const irfDetailsApi = this.initiationService.getIrfInitationDetails(payload);
    
    const combieMasterApi = this.initiationService.combineMaster();
    this.apiCallList.push(forkJoin([irfDetailsApi, combieMasterApi]).pipe().subscribe(([irfDetails, comboDetails]) => {
      this.irfDetails = irfDetails;
      this.combineMasterData = comboDetails;
      this.oppOwnerId = irfDetails?.opportunityContactDetailsDTO?.opportunityOwnerId;
      this.getOpprOwnerName$.next(this.oppOwnerId);

      this.isSecondOpp = irfDetails?.secondaryOpportunity;
      this.opeTypeList = comboDetails.filter((c) => {
        return c.name == 'OpeType';
      });
      this.mandateTypeList = comboDetails.filter((c) => {
        return c.name == 'Mandate Type';
      });
      this.priorityQcList = comboDetails.filter((c) => {
        return c.name == 'Priority For QC';
      });
      this.caseClassList = comboDetails.filter((c) => {
        return c.name == 'CaseClassification';
      });
      this.proposedSplitList = comboDetails.filter((c) => {
        return c.name == 'Proposed Split';
      });
      this.initiationTypeList = comboDetails.filter((c) => {
        return c.name == 'Initiation Type';
      });

      this.qcStatusList = comboDetails?.filter((r) => {
        if(r.name == "QC Status"){
          return r
        }
      });

      let mandateStatus = irfDetails?.agreementStatus;
      if(!this.caseCloseScope || (mandateStatus != null && mandateStatus != undefined && mandateStatus != "In Progress" && mandateStatus != " ")){
        this.businessTypeList = [{ "id": 132, "name": "IrfBusinessAction", "property": "Initiated" }];
      }

      this.isQcRejected = this.irfDetails?.opportunityAgreementDetails?.qcStatus == 134;
      this.qcStatus = this.qcStatusList.find(q => q.id == this.irfDetails?.opportunityAgreementDetails?.qcStatus)?.value;
      // patch if not initiatied
      // if(irfDetails?.opportunityContactDetailsDTO?.businessTypeId == 10){
      //   this.otherKeyForm.get('opeType').addValidators(Validators.required);
      //   this.otherKeyForm.get('opeType').updateValueAndValidity();
      // }
      this.otherKeyForm.patchValue({
        priorityQc: 127,
        irfActualFee: irfDetails?.opportunityContactDetailsDTO?.irfActualFees,
        invoiceVal: this.irfDetails?.opportunityContactDetailsDTO?.irfActualFees,
      });

      this.getSingleHierarchy(this.oppOwnerId);
      if (irfDetails != null && irfDetails != undefined) {
        this.companyContact = irfDetails?.opportunityContactDetailsDTO?.companyContactDetails;
        this.triContact = irfDetails?.opportunityContactDetailsDTO?.tripartyCompanyContactDetails;
        this.entityClientClass = irfDetails?.clientClassificationCompanyDTO;
        this.triClientClass = irfDetails?.tripartyClientClassificationDTO;
        if(irfDetails?.feecapData == null || (irfDetails?.opportunityAgreementDetails == null && irfDetails?.feecapData?.status === "INACTIVE") ) {
          this.feeCapData = undefined;
          this.feeCapMissing = true;
        } else if (irfDetails?.feecapData) {
          
          this.feeCapData = [irfDetails?.feecapData];
          if(irfDetails?.opportunityAgreementDetails?.isFeeCap) {
            this.feeCapForm.patchValue({tagMandate : (irfDetails?.opportunityAgreementDetails?.isFeeCap) == 'Y' ? 'Yes' : 'No'});
          }
          this.feeCapMissing = false;
          
        }
        this.checkOpportunityDetails = irfDetails?.opportunityAgreementDetails;
        if (irfDetails?.opportunityAgreementDetails != null && this.errorFeeCap == false)
          this.feeCapDisable = true;

        this.contactForm.patchValue({
          salutation: this.companyContact?.salutation,
          firstName: this.companyContact?.firstName,
          lastName: this.companyContact?.lastName,
          designation: this.companyContact?.designation,
          mobile: this.companyContact?.mobileNumber,
          landline: this.companyContact?.landlineNumber,
          email1: this.companyContact?.email[0],
          email2: this.companyContact?.email[1],
          email3: this.companyContact?.email[2],
          gstType: this.companyContact?.gstType,
          gstNo: this.companyContact?.gstNumber,
          panNo: this.companyContact?.panNumber,
          pinCode: this.companyContact?.pincode,
          city: this.companyContact?.city,
          state: this.companyContact?.state,
          address1: this.companyContact?.addressLine1,
          address2: this.companyContact?.addressLine2,
          address3: this.companyContact?.addressLine3,
          address4: this.companyContact?.addressLine4,
        });

        if (this.checkValue(this.triContact?.id)) {
          this.triContactForm.patchValue({
            salutation: this.triContact?.salutation,
            firstName: this.triContact?.firstName,
            lastName: this.triContact?.lastName,
            designation: this.triContact?.designation,
            mobile: this.triContact?.mobileNumber,
            landline: this.triContact?.landlineNumber,
            email1: this.triContact?.email[0],
            email2: this.triContact?.email[1],
            email3: this.triContact?.email[2],
            gstType: this.triContact?.gstType,
            gstNo: this.triContact?.gstNumber,
            panNo: this.triContact?.panNumber,
            pinCode: this.triContact?.pincode,
            city: this.triContact?.city,
            state: this.triContact?.state,
            address1: this.triContact?.addressLine1,
            address2: this.triContact?.addressLine2,
            address3: this.triContact?.addressLine3,
            address4: this.triContact?.addressLine4,
          });
        }

        this.isIrfInitiated = irfDetails?.opportunityContactDetailsDTO?.isIrfInitiated;
        let reason = null;
        let comment = null;
        this.apiFail = false;
        this.clientTypeMaster();
        if(this.irfDetails?.opportunityAgreementDetails?.billType === 120) {
          // combo case
          this.disableRevisionGlobal = true;
          this.disableTabMessage = "Revision can't occur for combo case";
        } 
        if(this.initiationReadScope){
          this.disableRevisionGlobal = true; 
        }
        if(irfDetails?.opportunityContactDetailsDTO?.productId == 8 || irfDetails?.opportunityContactDetailsDTO?.productId == 10) {
          this.showMF = true;
          if(irfDetails?.opportunityAgreementDetails?.amcCode) {
            this.selectedAMCCode = irfDetails?.opportunityAgreementDetails?.amcCode;
            this.selectedMFParentId = irfDetails?.opportunityAgreementDetails?.mfParentId;
            
            this.otherKeyForm.patchValue({mfParentId : this.selectedMFParentId});
            this.otherKeyForm.patchValue({amcCode : this.selectedAMCCode});
            
          } else {
            this.otherKeyForm.get('amcName').addValidators(Validators.required);
            this.otherKeyForm.get('amcName').updateValueAndValidity();
            
            this.otherKeyForm.get('mfSchemeName').addValidators(Validators.required);
            this.otherKeyForm.get('mfSchemeName').updateValueAndValidity();
          }

          this.amcAPI$.next(this.oppGridDetials?.companyCode);
        }

        if(this.isIrfInitiated || this.entityDetailsForm.get('initType').value == 119) {
          this.resetLimitValidators();
        }
          
        this.apiCallList.push(this.initiationService.productServices(irfDetails?.opportunityContactDetailsDTO?.productId).subscribe((res) => {
          if (res && res.length > 0) {
            this.productServiceList = res[0];
            this.otherKeyForm.patchValue({
              masterService: res[0]?.masterServiceName,
              subService: res[0]?.subServiceName,
              ratableUnit: res[0]?.ratableUnitName,
            });

            if(this.entityDetailsForm.get('initType').value != 119) {
              this.getLimitDetails();
            }
          }
        }));

        irfDetails?.attachments?.forEach((d) => {
          if(d.fileTypeName?.toLowerCase() != "migrated doc"){
            this.uploadedDocs.push(d)
          }
          if(d.fileTypeName?.toLowerCase() == "migrated doc"){
            this.migratedDocs.push(d)
          }
        });
        
        //patch all data if initiated
        if (this.isIrfInitiated == 'Y') {
          let entityEmail = irfDetails?.opportunityContactDetailsDTO?.companyContactDetails?.email;
          let entityTriEmail = irfDetails?.opportunityContactDetailsDTO?.tripartyCompanyContactDetails?.email;
          let oppContactDetails = irfDetails?.opportunityContactDetailsDTO;
          let agreementDetails = irfDetails?.opportunityAgreementDetails;
          let splitRevenueData = irfDetails?.splitRevenue;
          
          this.contactChecked = true;
          this.agreementId = agreementDetails?.agreementId;
          if(this.agreementId) {
            this.getInvoiceHistory$.next();
            this.caseProduct = irfDetails?.opportunityContactDetailsDTO?.productId;
            // dont call revision API for MA product
            // if(irfDetails?.opportunityContactDetailsDTO?.productId === 154 || irfDetails?.opportunityContactDetailsDTO?.productId === 157) {
            //   this.disableRevision = true;
            // } else
            this.revisionService$.next();
            this.disableInvoice = false;
          } else this.disableInvoice = true;

          this.disableBusinessAction = true;
          if(this.isQcRejected){
            if(this.isSecondOpp){
              this.disableSaveBtn = true;
            } else {
              this.disableSaveBtn = false;
            }
          } else {
            this.disableSaveBtn = true;
          }

          this.disableComment = true;
          this.comboTabSelected();
          this.productName = this.irfDetails?.opportunityContactDetailsDTO?.product;

          if(agreementDetails?.billType == 120){
            this.disableComboTab = false;
            this.getSecondOppList();
            this.comboDetailsForm.patchValue({
              secondOpp: irfDetails?.comboProductDetails?.id
            });
          }

          this.comboProductId = this.irfDetails?.comboProductDetails?.id;
          this.entityDetailsForm.patchValue({
            initType: agreementDetails?.billType,
            tdsNo: agreementDetails?.tdsNumber,
            tdsNum: agreementDetails?.tripartyTdsNumber
          });

          if(irfDetails?.opportunityContactDetailsDTO?.companyCrmContactId){
            this.patchCrm('normal');
          }

          if(irfDetails?.opportunityContactDetailsDTO?.triPartyCompanyCrmContactId){
            this.patchCrm('tri');
          }

          if(agreementDetails?.ope == 94 || agreementDetails?.ope == 95){
            this.showOpeAmt = true;
          }
          
          this.otherKeyForm.patchValue({
            mandateDate: agreementDetails?.mandateDate ? new Date(agreementDetails?.mandateDate) : null,
            clientSignedDate: agreementDetails?.clientSignedDate ? new Date(agreementDetails?.clientSignedDate) : null,
            crisilSignedDate: agreementDetails?.crisilSignedDate ? new Date(agreementDetails?.crisilSignedDate) : null,
            sentToClient: agreementDetails?.mandateSentClientDate ? new Date(agreementDetails?.mandateSentClientDate) : null,
            recievedFromClient: agreementDetails?.mandateReceivedClientDate ? new Date(agreementDetails?.mandateReceivedClientDate) : null,
            mandateType: agreementDetails?.mandateType,
            priorityQc: agreementDetails?.priorityForQC,
            invoiceVal: this.irfDetails?.opportunityAgreementDetails?.invoiceValue,
            currency: this.irfDetails?.opportunityAgreementDetails?.currencyId,
            opeType: agreementDetails?.ope,
            caseClass: agreementDetails?.caseClassificationId,
            opeAmount: agreementDetails?.opeAmount,
            feedbackQc: agreementDetails?.feedbackForQC,
            sfMandateValidity: this.irfDetails?.opportunityAgreementDetails?.sfMandateValidityId,
            effectiveMisDate: agreementDetails?.effectiveMisDate ? new Date(agreementDetails?.effectiveMisDate) : null,

          });

          this.getAllHierarchy();

          if(splitRevenueData[0]?.isSplit?.trim() == 'true'){
            this.splitTeam1Id = splitRevenueData[0]?.id;
            this.splitTeam2Id = splitRevenueData[1]?.id;
            this.splitTypeSelected = true;
            this.disableSplit = true;
            this.otherKeyForm.patchValue({
              split: true,
              irfActualFee: oppContactDetails?.irfActualFees,
              proposedSplit: splitRevenueData[0]?.splitType,
              team1: splitRevenueData[0]?.splitValue,
              team2Owner: splitRevenueData[1].teamOwnerId,
              comment: agreementDetails?.comment
            });

            let hiearachy1 = [];
            let hiearachy2 = [];
            splitRevenueData[0]?.teamDetails?.forEach((t) => {
              hiearachy1.push(t.employeeName)
            });
            splitRevenueData[1]?.teamDetails?.forEach((t) => {
              hiearachy2.push(t.employeeName)
            });

            this.bdHierarchy = hiearachy1.join(' -> ');
            this.team2Hierarchy = hiearachy2.join(' -> ');
          }
          this.calculateSplit(splitRevenueData[0]?.splitType);

          this.businessActionForm.patchValue({
            businessAction: 132
          });

          if(this.oppGridDetials?.opportunityStatus == "Initiated" && this.caseCloseScope){
            this.disableBusinessAction = false;
          }

          let opportunityStatusId = irfDetails?.opportunityContactDetailsDTO?.opportunityStatusID;
          // QC reject logic
          if(this.isQcRejected && !this.isSecondOpp && opportunityStatusId != 131){
            this.enableResubmit = true;
            this.showSubmit = false;
          }
          const modules = this.encDec.encryptWith('Initiation,Attachment,Other');
          const qcStatus = this.encDec.encryptWith('Rejected');
          this.apiCallList.push(this.initiationService.getQcActions(modules, qcStatus, this.agreementId).subscribe((res) => {
            if(res?.qcStatusId != 135 && !this.isSecondOpp && opportunityStatusId!= 131){ // if qc is not resubmitted
              this.qcActionList = res;
              if(res?.qcStatusId == 134){
                this.attachmentRejected = true;
                this.enableAllAttach();
              }
              if(this.errorFeeCap){
                this.feeCapDisable = false;
              }else{
                if(this.checkOpportunityDetails != null){
                  this.feeCapDisable = true;
                }else{
                  this.feeCapDisable = false;
                }
              }
              this.qcActionList.qcActions.forEach((q) => {
                if(q.checklistField.parentModuleName != "Attachment"){
                  this.qcRejectMap[q.checklistField.fieldName] = true;
                }
                if(q.checklistField?.parentModuleName?.toLowerCase() == "attachment"){
                  if(q?.qcFieldStatus?.toLowerCase() == 'rejected'){
                    this.attachmentRejected = true;
                    this.enableAllAttach();
                  }
                }
              });
              if(this.qcRejectMap['designation'] || this.qcRejectMap['emailId'] || this.qcRejectMap['clientContactName'] 
              || this.qcRejectMap['gstCategory'] || this.qcRejectMap['gstNo'] || this.qcRejectMap['pan'] || this.qcRejectMap['address'] ){
                this.enableEntityCrm = true;
                this.errorEntityContact = true;
              }
              
              if(this.qcRejectMap['triDesignation'] || this.qcRejectMap['triEmailId'] || this.qcRejectMap['triContactDetails'] 
              || this.qcRejectMap['triGstCategory'] || this.qcRejectMap['triGstNo'] || this.qcRejectMap['triPan'] || this.qcRejectMap['triAddress'] ){
                this.enabletriCrm = true;
                this.errorEntityContact = true;
              }
  
              if(this.qcRejectMap['agreementClassification'] || this.qcRejectMap['clientClassification']){
                this.errorClientClass = true;
              }
  
              if(this.qcRejectMap['crisilSignedDate'] || this.qcRejectMap['clientSignedDate'] || this.qcRejectMap['sentToClientDate'] || this.qcRejectMap['mandateReceivedDate'] 
              || this.qcRejectMap['opeType'] || this.qcRejectMap['opeAmount'] || this.qcRejectMap['mandateOption'] || this.qcRejectMap['mandateDate'] || this.qcRejectMap['sfMandateValidity']){
                this.errorOtherKey = true;
              }

              if(this.qcRejectMap['amcName'] || this.qcRejectMap['mutualFundSchemeName']) {
                this.amcAPI$.next(this.oppGridDetials?.companyCode);
                this.errorOtherKey = true;
              }
              
              if(this.qcRejectMap['mandateFeeCao']){
                this.errorFeeCap=true;
              }
              console.log("this.qcRejectMap",this.qcRejectMap);
              console.log("this.qcRejectMap",this.qcRejectMap['mandateFeeCao']);
            }
          }));
          
          
        }

        if(irfDetails?.opportunityContactDetailsDTO?.opportunityStatusID == 131){
          this.businessActionForm.patchValue({
            businessAction: 131,
            reason: irfDetails?.opportunityContactDetailsDTO?.reasonID,
            comment: irfDetails?.opportunityContactDetailsDTO?.comment,
            opsReaseon: irfDetails?.opportunityContactDetailsDTO?.opsReasonId
          });

          this.disableAllFileds = true;
          this.disableSplit = true;
          this.disableBusinessAction = true;
          this.disableReason = true;
          this.disableComment = true;
          this.disableSaveBtn = true;
        }
        this.caseCloseOta = irfDetails?.caseCloseOta;
        this.getDocList();
        if (this.caseCloseOta != null && this.caseCloseOta != undefined && this.caseCloseOta?.opportunityId != null) {
          // irfBusinessAction = "131";
          this.otaExpiryTime = this.caseCloseOta?.revisedValues?.otaExpireTime;
          reason = this.caseCloseOta?.revisedValues?.reasonId;
          comment = this.caseCloseOta?.noteToManager;
          this.disableReason = true;
          this.disableBusinessAction = true;
          this.businessActionForm.get('comment').disable();
          this.disableOta = false;
          let opsReasonId = this.caseCloseOta?.revisedValues?.opsReasonId;
          this.businessActionForm.patchValue({
            businessAction: 131,
            reason: reason,
            comment: comment,
            opsReaseon: opsReasonId
          });
          this.refNo = this.caseCloseOta?.refNo;
          this.sendCaseCloseOta = true;
          this.showRefNo = false;
          this.enableOta = true;
          this.disableOta = false;
          this.enableCancelOtaBtn = true;
          this.disableAllFileds = true;
          this.disableSaveBtn = false;
        }

        this.splitRevenueOta = irfDetails?.splitRevenueOta;
        if(this.splitRevenueOta != null && this.splitRevenueOta != undefined && this.splitRevenueOta?.opportunityId){
          this.disableAllFileds = true;
          this.enableSplitOta = true;
          this.refNo = this.splitRevenueOta?.refNo;
          this.showRefNo = true;
          this.enableCancelOtaBtn = true;
          this.disableSaveBtn = false;
          let splitRevenueData = this.splitRevenueOta?.revisedValues?.splitRevenue;
          this.disableBusinessAction = true;
          this.sendSplitOta = true;
          this.getAllHierarchy();
          if(splitRevenueData[0]?.isSplit){
            this.splitTypeSelected = true;
            this.otherKeyForm.patchValue({
              split: true,
              irfActualFee: this.splitRevenueOta?.revisedValues?.irfActual,
              proposedSplit: splitRevenueData[0]?.splitType,
              team1: splitRevenueData[0]?.splitValue,
              team2Owner: splitRevenueData[1].teamOwnerId,
              comment: this.splitRevenueOta?.noteToManager
            });
            this.team2Selected(splitRevenueData[1].teamOwnerId);
          }
          this.calculateSplit(splitRevenueData[0]?.splitType);
        }
      }
      
    }, (_err) => {
      this.apiFail = true;
      this.pageLoader = false;
      this.initiationService.showLoader('clear');
    }, () => {
      this.pageLoader = false;
    }));

    this.apiCallList.push(this.amcAPI$.pipe(
        switchMap((companyCode:string) => this.initiationService.getAMCName(companyCode))).subscribe({
        next : (response : Array<any>) => {
          if(response?.length > 0) {
            this.amcNameList = [];
            if(!this.selectedAMCCode)  // first time AMCCODE
              response.forEach(item => {
                this.amcNameList.push({label : item.amcName, value : item.amcName, code : item.amcCode });
              });
            else {
              // already saved amcCode before
              response = response.filter(item => item.amcCode === this.selectedAMCCode);
              response.forEach(item => {
                this.amcNameList.push({label : item.amcName, value : item.amcName, code : item.amcCode });
              });
              this.getMFScheme({value : this.selectedAMCCode}); // fetch mf scheme name
              if(this.amcNameList?.length > 0)
                this.otherKeyForm.patchValue({amcName : this.amcNameList[0]})
            }
          }
        },
        error : (err) => {
          this.messageService.add({ severity: 'error', summary: 'Can`t fetch AMC Names' });
          setTimeout(() => {
            this.messageService.clear();
          }, 4000);
        }
    }));

    this.apiCallList.push(this.revisionService$.pipe(
      // get reason for revision master, revision attachment types
      switchMap(() => forkJoin([this.pageService.getSfRevisionReasons(), this.pageService.getDocList()])), 
      switchMap((res) => {
        this.reasonForRevision = res[0];
        this.revisionAttachmentList = res[1]?.filter(i => i.attachment_activity === 139);
        return this.initiationService.getRevisionById(this.agreementId, this.oppGridDetials?.opportunityId);
      })).subscribe({
        // get final revision data
      next : (revData : any) => {
        if(revData?.advIRFBillId) {
          this.disableRevisionTab = false;
          this.disableRevision = false;
          if(!this.enableSplitOta && !this.enableOta)
            this.refNo = revData?.refNo;
          if(!!this.refNo)
            this.showRefNo = true;
          this.revisionForm.patchValue({
            id : revData?.revId,
            billDetail : revData?.lineDescription,
            oldBillDetail : revData?.lineDescription,
            isCancel : !!revData?.isCancel,
            currentInvoiceVal : revData?.isCancel ? "0" : revData?.currentValue,
            revisedInvoiceVal : revData?.isCancel ? "0" : null,
            reasonForrevision : revData.reasonForRevisionId != null ? 
                                    this.reasonForRevision.find(item => item.id === revData.reasonForRevisionId)?.id : (revData.reasonForRevision != null ? 
                                        this.reasonForRevision.find(item => item.reason_description === revData.reasonForRevision)?.id : 
                                    null),
            noteToManager : revData?.noteToManager,
            oldContact : this.mapContact(revData?.oldContact),
            newContact : this.mapContact(revData?.newContact),
            revisionInProgress : revData?.revisionInProgress,
            irfBillId : revData?.advIRFBillId,
            invoiceNo : revData?.invoiceNo,
            opprOwnerId : this.oppOwnerId,
            oppOwnerName :  this.oppOwnerName
          });
          if(revData.attachments?.length) {
            this.documentList = [...this.documentList, ...revData.attachments];
            this.revisionAttachmentList = this.revisionAttachmentList.filter(item => {
              let docObj = this.documentList.find(item2 => item2.fileTypeName === item.type);
              if(docObj)  return false;
              return true;
            });
          }
          this.revisionAttachmentList.sort((a, b) => {
            if(a.id < b.id) { return -1; }
            if(a.id > b.id) { return 1; }
            return 0;
          });
          this.revisionInProgress = revData?.revisionInProgress;
          this.status = revData?.status;
          this.ofaFlag = revData?.ofaFlag;
          if(this.revisionInProgress || revData?.isCancel) {
            this.disableRevision = true;
            this.showRevOTA = false;
            this.doRevision = false;
          }
          // set reason for rev
          // disable all, if isRevProgress || isCancel
          if(revData?.otaDetails?.status === "Pending") {
            this.revisionForm.get('ota').addValidators([Validators.required, Validators.minLength(6)]);
            this.revisionForm.get('ota').markAsUntouched();
            this.showRevOTA = true;
            this.disableRevision = true;
            this.enableCancelOtaBtn = true;
            this.verifyAndSave = true;
            this.showSubmit = false;
            this.enableSendOtaBtn = false;
            this.doRevision = true;
          } else {
            this.revisionForm.get('ota').removeValidators([Validators.required, Validators.minLength(6)]);
            this.showRevOTA = false;
            if(!this.enableSplitOta && !this.enableOta) {
              this.verifyAndSave = false;
              this.showSubmit = true;
              this.enableCancelOtaBtn = false;
            }
          
          }
        } else this.disableRevisionTab = true;

       if (this.enableSplitOta) {
          this.disableRevisionGlobal = true;
          this.disableTabMessage = "Split revenue is in progress";
        } else if (this.enableOta) {
          this.disableRevisionGlobal = true;
          this.disableTabMessage = "Case close is in progress";
        } else if (this.irfDetails?.opportunityContactDetailsDTO?.opportunityStatus === "Case Close") {
          if(!this.revisionInProgress) {
          this.disableRevisionGlobal = true;
          this.disableTabMessage = "Case is closed, revision can't be done";
          }
        }
        if (revData?.isCancel && !this.revisionInProgress) {
          this.disableRevisionGlobal = true;
          this.disableTabMessage = "Cancelled Invoice";
        }
        if (revData?.revisionInProgress == true && revData?.status === 'H' && revData?.ofaFlag === 'H') {
          this.disableRevisionGlobal = true;
          this.disableTabMessage = "Previous Revision is on Hold";
        } else if (revData?.revisionInProgress == true && revData?.status === 'R' && revData?.ofaFlag === 'R') {
          this.disableRevisionGlobal = true;
          this.disableTabMessage = "Previous Revision is Rejected";
        }

        if(this.disableRevisionGlobal) {
          this.disableRevision = true;
        }

      },
      error : (err) => {
        this.apiFail = true;
      }
    }));
    if(this.oppGridDetials?.fromIrfPushPull == true){
      this.disableAllFileds = true;
    }

    let ele = document.getElementsByClassName("p-dialog-header-close-icon");
    ele[0].addEventListener("click", () => {
      this.deleteMultipleFile();
    });

    
  }

  ngAfterViewInit() {
    this.entityDetailsForm.patchValue({
      triNormal: this.oppGridDetials?.triPartyCompanyCode ? 'Tri Party' : 'Normal',
      triCrm: this.oppGridDetials?.triPartyCompanyName,
      crmCode: this.oppGridDetials?.triPartyCompanyCode,
    });
  }

  patchCrm(type) {
    let code = type == 'normal' ? this.oppGridDetials?.companyCode : this.oppGridDetials?.triPartyCompanyCode;
    let payload = {
      "companyCode": code
    }
    this.apiCallList.push(this.initiationService.getAllContacts(payload).subscribe((res) => {
      this.crmContacts = res;
      if(type == 'normal'){
        this.entityCrmContact = this.crmContacts.find(c => c.contactId == this.irfDetails?.opportunityContactDetailsDTO?.companyCrmContactId);
      } else {
        this.triCrmContact = this.crmContacts.find(c => c.contactId == this.irfDetails?.opportunityContactDetailsDTO?.triPartyCompanyCrmContactId);
      }
    }, (_err) => {
      this.apiFail = true;
    }));
  }

  getLimitDetails() {
    let companyCode = this.irfDetails.opportunityContactDetailsDTO?.companyCode;
    let producName = this.irfDetails.opportunityContactDetailsDTO?.productCode;
    let masterServiceName = this.productServiceList.masterServiceName;
    let subServiceName = this.productServiceList.subServiceName;
    let ratableUnitName = this.productServiceList.ratableUnitName;
    let masterServiceId = this.productServiceList.masterServiceId;
    let subServiceId = this.productServiceList.subServiceId;
    let ratableUnitId = this.productServiceList.ratableUnitId;
    let instrumentId = this.irfDetails.opportunityContactDetailsDTO?.instrumentId;

    if(masterServiceId && subServiceId && ratableUnitId) {
      this.initiationService.getLimitDetails(companyCode, producName, masterServiceId, subServiceId, ratableUnitId, this.agreementId, instrumentId).subscribe(response => {
        if (response.statusCode == 200) {
          response.data["applicableAmount"] = this.irfDetails.opportunityContactDetailsDTO?.quantum;
          if(this.isIrfInitiated) {
            response.data["currentLimit"] = this.irfDetails.opportunityAgreementDetails.currentLimit;
            if(this.irfDetails.opportunityAgreementDetails.previousLimit) {
              response.data["selectedPreviousLimit"] = {label: this.irfDetails.opportunityAgreementDetails.previousLimit, amount: null, type: ''};
            }
            if(this.irfDetails.prevOpportunityId) {
              this.initiationService.getCaseDetails(this.irfDetails.prevOpportunityId).subscribe(caseResponse => {
                response.data["previousCaseApplicableAmount"] = caseResponse.opportunity.quantum;
                response.data["inProgressAmount"] = caseResponse.currentLimit;
                this.handleLimitDetailsResponse(response);
              });
            }
            else {
              this.handleLimitDetailsResponse(response);
            }
          }
          else {
            if(response.data.opportunityId) {
              this.initiationService.getCaseDetails(response.data.opportunityId).subscribe(caseResponse => {
                response.data["previousCaseApplicableAmount"] = caseResponse.opportunity.quantum;
                response.data["inProgressAmount"] = caseResponse.currentLimit;
                this.handleLimitDetailsResponse(response);
              });
            }
            else {
              this.handleLimitDetailsResponse(response);
            }
          }
        }
        else {
          this.closeInitiationPopup("error", true, response.message);
        }
      },
      (_error) => {},
      () => {});
    }
    
  }

  handleLimitDetailsResponse(response: any) {

    this.limitDetails = {
      "outstandingAmount": response.data.outstandingAmount>0?response.data.outstandingAmount:0,
      "previousCaseApplicableAmount": response.data.previousCaseApplicableAmount>0?response.data.previousCaseApplicableAmount:0,
      "inProgressAmount": response.data.inProgressAmount>0?response.data.inProgressAmount:0,
      "mandateStatus": response.data.mandateStatus,
      "prevInprogressAgreementId": response.data.agreementId,
      "selectedPreviousLimit": response.data.selectedPreviousLimit,
      "currentLimit": response.data.currentLimit,
      "applicableAmount": response.data.applicableAmount
    };

    this.previousLimitList = [];
    this.otherKeyForm.get('outstandingAmount').setValue(this.limitDetails.outstandingAmount?.toFixed(2));
    this.otherKeyForm.get('inprogressAmount').setValue(this.limitDetails.inProgressAmount?.toFixed(2));
    this.otherKeyForm.get('applicableAmount').setValue(this.limitDetails.applicableAmount?.toFixed(2));

    /*if case already initiated and previous limit is already selected */
    if (this.limitDetails.selectedPreviousLimit) {
      this.previousLimitList = [this.limitDetails.selectedPreviousLimit];
      this.otherKeyForm.get('previousLimit').setValue(this.limitDetails.selectedPreviousLimit);
      this.otherKeyForm.get('previousLimit').disable();
      this.otherKeyForm.get('currentLimit').setValue(this.limitDetails.currentLimit?.toFixed(2));
    } else {
      if(this.limitDetails.inProgressAmount) {
        this.previousLimitList.push({label: ("In Progress - "+this.limitDetails.inProgressAmount), amount: this.limitDetails.inProgressAmount, type: 'INPROGRESS'});
      }
      if(this.limitDetails.outstandingAmount) {
        this.previousLimitList.push({label: ("Accepted Outstanding - "+this.limitDetails.outstandingAmount), amount: this.limitDetails.outstandingAmount, type: 'OUTSTANDING'});
      }
      if(!this.limitDetails.outstandingAmount && !this.limitDetails.inProgressAmount) {
        this.previousLimitList.push({label: '0', amount: 0, type: 'NA'});
      }
    }
  }

  onPreviousLimitChange() {
    this.setCurrentLimitAmount();
  }

  setApplicableAmount() {
    this.otherKeyForm.get('applicableAmount').setValue((this.otherKeyForm.get('currentLimit').value - this.otherKeyForm.get('previousLimit').value.amount).toFixed(2));
  }

  setCurrentLimitAmount() {
    this.otherKeyForm.get('currentLimit').setValue(((+this.otherKeyForm.get('applicableAmount').value) + this.otherKeyForm.get('previousLimit').value.amount).toFixed(2));
  }

  closeInitiationPopup(status: string = 'success', showMsg?: boolean, message?: string) {
    let closeResponse: any = {
      status: status,
      showMsg: showMsg,
      message: message
    }
    this.deleteMultipleFile();
    this.ref.close(closeResponse);
  }

  saveInitiation() {
    if(this.businessActionForm?.value?.businessAction != 131){
      this.isSaveClicked = true;
      //remove previous limit required on alternate case.
      if(this.entityDetailsForm.get('initType').value == 119) {
        this.otherKeyForm.get('previousLimit').clearValidators();
        this.otherKeyForm.get('previousLimit').updateValueAndValidity();
      }
      if (this.otherKeyformControls?.split?.value) {
        this.otherKeyForm.markAllAsTouched();
        this.markFormDirty(this.otherKeyForm);
      } else {
        this.otherKeyformControls.mandateDate.markAsDirty();
        this.otherKeyformControls.clientSignedDate.markAsDirty();
        this.otherKeyformControls.crisilSignedDate.markAsDirty();
        this.otherKeyformControls.mandateType.markAsDirty();
        this.otherKeyformControls.sentToClient.markAsDirty();
        this.otherKeyformControls.caseClass.markAsDirty();
        this.otherKeyformControls.previousLimit.markAsDirty();
        this.otherKeyformControls.sfMandateValidity.markAsDirty();
        this.otherKeyformControls.opeType.markAsDirty();
        // if(this.irfDetails?.opportunityContactDetailsDTO?.businessTypeId == 10){
        // } 
        if(this.showOpeAmt){
          this.otherKeyformControls.opeAmount.markAsDirty();
        }
      }
      this.businessActionForm.markAllAsTouched();
      this.feeCapForm.markAllAsTouched();
      this.markFormDirty(this.feeCapForm);
      if (this.entityDetailsForm.value.initType == '120' && this.oppGridDetials?.opportunityStatus != "Initiated" && Object.keys(this.secondaryOpp).length == 0) {
        this.errorCombo = true;
        this.errorMessageSave.push('Combo Product');
      } else {
        this.errorCombo = false;
        let index = this.errorMessageSave.findIndex(e => e == 'Combo Product');
        if (index != -1) {
          this.errorMessageSave.splice(index, 1);
        }
      }

      if (this.entityDetailsForm.value.triNormal == 'Normal') {
        if (this.entityCrmContact != null && this.entityCrmContact != undefined && Object.keys(this.entityCrmContact)?.length == 0) {
          this.errorEntityContact = true;
          let index = this.errorMessageSave.findIndex(e => e == 'Entity Contacts');
          if (index == -1) {
            this.errorMessageSave.push('Entity Contacts');
          }
        } else {
          if (this.contactChecked) {
            this.errorEntityContact = false;
            let index = this.errorMessageSave.findIndex(e => e == 'Entity Contacts');
            if (index != -1) {
              this.errorMessageSave.splice(index, 1);
            }
          } else {
            this.errorEntityContact = true;
            let index = this.errorMessageSave.findIndex(e => e == 'Entity Contacts');
            if (index == -1) {
              this.errorMessageSave.push('Entity Contacts');
            }
          }
        }
      } else {
        if (this.entityCrmContact != null && this.entityCrmContact != undefined && this.triCrmContact != null && this.triCrmContact != undefined &&  (Object.keys(this.triCrmContact).length == 0 || Object.keys(this.entityCrmContact).length == 0)) {
          this.errorEntityContact = true;
          let index = this.errorMessageSave.findIndex(e => e == 'Entity Contacts');
          if (index == -1) {
            this.errorMessageSave.push('Entity Contacts');
          }
        } else {
          if (this.contactChecked) {
            this.errorEntityContact = false;
            let index = this.errorMessageSave.findIndex(e => e == 'Entity Contacts');
            if (index != -1) {
              this.errorMessageSave.splice(index, 1);
            }
          } else {
            this.errorEntityContact = true;
            let index = this.errorMessageSave.findIndex(e => e == 'Entity Contacts');
            if (index == -1) {
              this.errorMessageSave.push('Entity Contacts');
            }
          }
        }
      }
      if (this.otherKeyForm.invalid || this.invalidOpeAmt || this.invalidInvVal || (this.entityDetailsForm.get('initType').value != 119 && (this.otherKeyForm.get('applicableAmount').value==null || this.otherKeyForm.get('applicableAmount').value<0))) {
        this.errorOtherKey = true;
        let index = this.errorMessageSave.findIndex(e => e == 'Other Key Details');
        if (index == -1) {
          this.errorMessageSave.push('Other Key Details');
        }
      } else {
        this.errorOtherKey = false;
        let index = this.errorMessageSave.findIndex(e => e == 'Other Key Details');
        if (index != -1) {
          this.errorMessageSave.splice(index, 1);
        }
      }

      if (this.otherKeyForm.invalid || this.invalidOpeAmt || this.invalidInvVal || this.clientSignedError || 
        this.crisilSignedError  ||this.sentToClientError  ||this.recievedFromClientError || (this.entityDetailsForm.get('initType').value != 119 && (this.otherKeyForm.get('applicableAmount').value==null || this.otherKeyForm.get('applicableAmount').value<0))) {
        this.errorOtherKey = true;
        let index = this.errorMessageSave.findIndex(e => e == 'Other Key Details');
        if (index == -1) {
          this.errorMessageSave.push('Other Key Details');
        }
      } else {
        this.errorOtherKey = false;
        let index = this.errorMessageSave.findIndex(e => e == 'Other Key Details');
        if (index != -1) {
          this.errorMessageSave.splice(index, 1);
        }
      }
      if (this.feeCapForm.invalid && this.errorFeeCap) {
        this.errorFeeCap = true;
        let index = this.errorMessageSave.findIndex(e => e == 'Fee Cap');
        if (index == -1)
          this.errorMessageSave.push('Fee Cap');
      } else {
        this.errorFeeCap = false;
        let index = this.errorMessageSave.findIndex(e => e == 'Fee Cap');
        if (index != -1) {
          this.errorMessageSave.splice(index, 1);
        }
      }

      let tempArray = [];
      this.documentList.forEach((d) => {
        if (d.is_mandatory == 'M' && d.file == '') {
          tempArray.push(d)
        }
      });

      if (tempArray?.length > 0) {
        this.errorAttachment = true;
        let index = this.errorMessageSave.findIndex(e => e == 'Attachment Details');
        if (index == -1) {
          this.errorMessageSave.push('Attachment Details');
        }
      } else {
        this.errorAttachment = false;
        let index = this.errorMessageSave.findIndex(e => e == 'Attachment Details');
        if (index != -1) {
          this.errorMessageSave.splice(index, 1);
        }
      }

      if (this.agreementClass?.value == null || this.agreementClass?.value == undefined) {
        this.errorClientClass = true;
        let index = this.errorMessageSave.findIndex(e => e == 'Client Classification');
        if (index == -1) {
          this.errorMessageSave.push('Client Classification');
        }
      } else {
        this.errorClientClass = false;
        let index = this.errorMessageSave.findIndex(e => e == 'Client Classification');
        if (index != -1) {
          this.errorMessageSave.splice(index, 1);
        }
      }

      if ((this.businessActionForm.value?.businessAction == null || this.businessActionForm.value?.businessAction == undefined) || (this.businessActionForm.value?.businessAction == 131 && (this.businessActionForm.value?.reason == null || this.businessActionForm.value?.reason == undefined))) {
        this.errorAction = true;
        let index = this.errorMessageSave.findIndex(e => e == 'Action');
        if (index == -1) {
          this.errorMessageSave.push('Action');
        }
      } else {
        this.errorAction = false;
        let index = this.errorMessageSave.findIndex(e => e == 'Action');
        if (index != -1) {
          this.errorMessageSave.splice(index, 1);
        }
      }

      if (this.errorEntityContact || this.errorCombo || this.errorOtherKey || this.errorAttachment || this.errorAction || this.errorFeeCap) {
        this.errorMsgPopup = true;
        this.disableBusinessAction = false;
        this.disableAllFileds = false;
        this.disableComment = false;
      }
    } else {
      this.errorAction = false;
      let index = this.errorMessageSave.findIndex(e => e == 'Action');
      if (index != -1) {
        this.errorMessageSave.splice(index, 1);
      }
    }

    if (this.businessActionForm?.value?.businessAction != 131) {
      if (this.errorEntityContact || this.errorCombo || this.errorOtherKey || this.errorAttachment || this.errorAction || this.errorFeeCap || this.errorClientClass || this.clientSignedError ||
        this.crisilSignedError || this.sentToClientError || this.recievedFromClientError) {
        this.errorMsgPopup = true;
      }

      if (!this.errorEntityContact && !this.errorCombo && !this.errorOtherKey && !this.errorAttachment && !this.errorAction && !this.errorFeeCap && !this.errorClientClass && !this.clientSignedError &&
        !this.crisilSignedError  && !this.sentToClientError  && !this.recievedFromClientError) {
        this.createAndSaveInitiation();
        this.initiationSavePayload = null;
      }
    } else{
      this.createAndSaveInitiation();
    }
  }

  createAndSaveInitiation() {
    this.createSavePayload();
    this.initiationService.saveInitiation(this.initiationSavePayload).subscribe((res) => {
      if (res?.SUCCESS == "Data saved successfully") {
        this.uploadedFileIds = [];
        this.ref.close(res?.SUCCESS);
      }
    }, (_err) => {
      this.deleteMultipleFile();
      this.messageService.add({ severity: 'error', summary: 'Something went wrong, please try again.' });
    });
  }

  markFormDirty(form: FormGroup) {
    form.markAsDirty();
    Object.keys(form.controls).forEach(formControlName => {
      form.get(formControlName).markAsDirty();
    });
  }

  getCrm(type, isRevision ?: any) {
    this.crmContactType = type;
    this.crmRevisionContact = !!isRevision;
    let code = type == 'normal' ? this.oppGridDetials?.companyCode : this.oppGridDetials?.triPartyCompanyCode;
    let payload = {
      "companyCode": code
    }
    this.pageLoader = true;
    this.apiCallList.push(this.initiationService.getAllContacts(payload).subscribe((res) => {
      this.pageLoader = false;
      this.crmContacts = res;
      this.crmContactPopup = true;
      this.disabledCrmApplyBtn = true;
    }, (_err) => {
      this.pageLoader = false;
      this.apiFail = true;
    }));
  }

  crmContactClicked() {
    this.disabledCrmApplyBtn = false;
  }

  crmContactSelected() {
    let singleContact = this.crmContacts.find(c =>
      c.contactId == this.selectedCrmContact
    );
    this.selectedCrmContact = null;
    this.crmContactFetched = true;
    if(this.crmRevisionContact && singleContact){
      this.crmRevisionContactSelected = true;
    } else{
      this.crmRevisionContactSelected = false;
    }
    if (this.crmContactType == 'normal') {
      this.entityCrmContact = singleContact;
      if(this.entityCrmContact?.email != undefined && this.entityCrmContact?.email != null){
        let emails = this.entityCrmContact?.email?.split(',')
        let email = Array.isArray(emails);
        this.entityDetailsForm.patchValue({ tdsNo: this.entityCrmContact?.tdsNumber });
        if (email) {
          this.entityEmail1 = emails[0]?.toLowerCase();
          this.entityEmail2 = emails[1]?.toLowerCase();
          this.entityEmail3 = emails[2]?.toLowerCase();
        } else {
          this.entityEmail1 = emails[0]?.toLowerCase();
        }
      }
    } else {
      this.triCrmContact = singleContact;
      this.entityDetailsForm.patchValue({ tdsNum: this.triCrmContact?.tdsNumber });
      if(this.triCrmContact?.email != undefined && this.triCrmContact?.email != null){
        let emails = this.triCrmContact?.email?.split(',')
        let email = Array.isArray(emails);
        if (email) {
          this.triEmail1 = emails[0]?.toLowerCase();
          this.triEmail2 = emails[1]?.toLowerCase();
          this.triEmail3 = emails[2]?.toLowerCase();
        } else {
          this.triEmail1 = emails[0]?.toLowerCase();
        }
      }
    }
    if (this.entityDetailsForm.value.triNormal == 'Normal') {
      if (Object.keys(this.entityCrmContact).length != 0){
        this.crmContactFetched = true;
      }
    } else {
      if (Object.keys(this.entityCrmContact).length != 0 && Object.keys(this.triCrmContact).length != 0) {
        this.crmContactFetched = true;
      } else {
        this.crmContactFetched = false;
      }
    }

    this.crmContactType = null;
    this.crmContactPopup = false;

    if(this.crmRevisionContactSelected) {
      const newContact = {
        salutation: singleContact?.salutation,
        firstName: singleContact?.firstName,
        lastName: singleContact?.lastName,
        designation: singleContact?.designation,
        addressLine1: singleContact?.address1,
        addressLine2 : singleContact?.address2,
        addressLine3: singleContact?.address3,
        addressLine4 : singleContact?.address4,
        pincode :singleContact?.pincode || singleContact?.pin,
        city : singleContact?.city,
        state : singleContact?.state,
        gstNo : singleContact?.gstNo || singleContact?.gstNumber,
        gstType : singleContact?.gstType,
        crmContactId: singleContact?.crmContactId || singleContact?.contactId,
        mobileNo : singleContact?.mobileNo || singleContact?.mobileNumber || singleContact?.mobileNum,
        landlineNo : singleContact?.landlineNo || singleContact?.landlineNumber || singleContact?.phoneNum,
        email : singleContact?.email,
        panNumber : singleContact?.panNumber || singleContact?.panNo || singleContact?.pan,
      }
      this.revisionForm.patchValue({newContact});
    }
  }

  comboTabSelected(){
    if(this.irfDetails?.comboProductDetails?.secondaryOpportunityId){
      let payload = {
        companyCode: this.oppGridDetials?.companyCode,
        opportunityOwener: this.irfDetails?.opportunityContactDetailsDTO?.opportunityOwnerId,
        opportunityId: this.irfDetails?.comboProductDetails?.secondaryOpportunityId,
      }
      this.pageLoader = true;
      this.apiCallList.push(this.initiationService.secondaryOppDetails(payload).subscribe((res) => {
        this.pageLoader = false;
        this.secondaryOppList = res;
        if(this.secondaryOppList.length != 0){
          this.secondaryOpp1 = this.secondaryOppList[0]
          this.comboDetailsForm.patchValue({
            mainOppName: this.irfDetails?.opportunityContactDetailsDTO?.opportunityName,
            mainProduct: this.oppGridDetials?.product,
            mainIrfFee: this.irfDetails?.opportunityContactDetailsDTO?.irfActualFees,
            mainSfFee: this.irfDetails?.opportunityContactDetailsDTO?.sfActualFees,
    
            comboIrfFee: this.secondaryOpp1?.irfFee,
            comboSfFee: this.secondaryOpp1?.sfFee,
            comboBdUser: this.secondaryOpp1?.bdUser,
            comboReportedOn: this.secondaryOpp1?.reportedDate,
            comboOppName: this.secondaryOpp1?.opportunityName, 
            secondOpp: this.irfDetails?.comboProductDetails?.secondaryOpportunityId,
            comboProduct: this.secondaryOpp1?.product,
            productSelected: this.secondaryOpp1?.product,
            comboTotalIrfFee: this.irfDetails?.comboProductDetails?.totalIrfActualFee,
            comboTotalSfFee: this.irfDetails?.comboProductDetails?.totalSfActualFee,
          });
          this.otherKeyForm.patchValue({
            irfActualFee: this.irfDetails?.comboProductDetails?.totalIrfActualFee,
          });
          this.calculateSplit(this.irfDetails?.splitRevenue[0]?.splitType);
          this.secondaryOpportunityId = this.secondaryOpp1.opportunityId;
          
        }
      },(_err) => {
        this.pageLoader = false;
        this.apiFail = true;
      }));
    }
  }

  onTabChange(event) {
    const tabName = event?.originalEvent?.currentTarget?.innerText;
    if(event.index==1) {

     if(this.oppGridDetials?.opportunityStatus == "Initiated" ||this.oppGridDetials?.opportunityStatus == "Case Close" ){
      this.comboTabSelected();
     }
    }

    // tab validation-message for revision
    if(tabName == "Revision") {
      if(this.disableRevisionGlobal && this.disableTabMessage) {
        this.messageService.add({key : 'tabAckMsg', severity: 'warn', sticky: true, summary: this.disableTabMessage});
      }
    } else this.messageService.clear();

  }

  initiationTypeSelected() {
    //118 - normal
    //119 - alternat
    //120 - combo
    if (this.entityDetailsForm.value.initType == 118) {
      this.disableComboTab = true;
      this.errorCombo = false;
      this.comboDetailsForm.get('secondOpp').clearValidators();
      this.comboDetailsForm.get('secondOpp').updateValueAndValidity();
      this.otherKeyForm.patchValue({
        irfActualFee: this.irfDetails?.opportunityContactDetailsDTO?.irfActualFees,
      });
    }

    if (this.entityDetailsForm.value.initType == 119) {
      this.disableComboTab = true;
      this.errorCombo = false;
      this.comboDetailsForm.get('secondOpp').clearValidators();
      this.comboDetailsForm.get('secondOpp').updateValueAndValidity();
      this.otherKeyForm.patchValue({
        irfActualFee: this.irfDetails?.opportunityContactDetailsDTO?.irfActualFees,
      });
    }

    if (this.entityDetailsForm.value.initType == 120) {
      this.disableComboTab = false;
      this.comboDetailsForm.patchValue({
        mainOppName: this.oppGridDetials?.name,
        mainProduct: this.oppGridDetials?.product,
        mainIrfFee: this.irfDetails?.opportunityContactDetailsDTO?.irfActualFees,
        mainSfFee: this.irfDetails?.opportunityContactDetailsDTO?.sfActualFees,
      });
      //this.getSecondOppList();
      this.comboSelected();
      this.otherKeyForm.patchValue({
        irfActualFee: Number(this.secondaryOpp?.irfFee) + Number(this.irfDetails?.opportunityContactDetailsDTO?.irfActualFees)
      });
    }

    if(this.entityDetailsForm.get('initType').value == 119) {
      this.resetLimitValidators();
    }
    else {
      this.otherKeyForm.get('outstandingAmount').addValidators([Validators.required]);
      this.otherKeyForm.get('inprogressAmount').addValidators([Validators.required]);
      this.otherKeyForm.get('previousLimit').addValidators([Validators.required]);
      this.otherKeyForm.get('currentLimit').addValidators([Validators.required]);
      this.otherKeyForm.get('applicableAmount').addValidators([Validators.required]);
      this.otherKeyForm.get('previousLimit').enable();
      this.otherKeyForm.updateValueAndValidity();
      this.getLimitDetails();
    }
  }

  resetLimitValidators() {
    this.otherKeyForm.get('outstandingAmount').reset();
    this.otherKeyForm.get('outstandingAmount').clearValidators();
    this.otherKeyForm.get('inprogressAmount').reset();
    this.otherKeyForm.get('inprogressAmount').clearValidators();
    this.otherKeyForm.get('previousLimit').reset();
    this.otherKeyForm.get('previousLimit').clearValidators();
    this.otherKeyForm.get('previousLimit').disable();
    this.otherKeyForm.get('currentLimit').reset();
    this.otherKeyForm.get('currentLimit').clearValidators();
    this.otherKeyForm.get('applicableAmount').reset();
    this.otherKeyForm.get('applicableAmount').clearValidators();
  }

  getSecondOppList(){
    let payload = {
      companyCode: this.oppGridDetials?.companyCode,
      //opportunityOwener: this.oppGridDetials?.nexeraUser,
      opportunityOwener: this.irfDetails?.opportunityContactDetailsDTO?.opportunityOwnerId,
      opportunityId: this.oppGridDetials?.opportunityId
    }
    this.pageLoader = true;
    this.apiCallList.push(this.initiationService.secondaryOppDetails(payload).subscribe((res) => {
      this.pageLoader = false;
      this.secondaryOppList = res;
    }, (_err) => {
      this.pageLoader = false;
      this.apiFail = true;
    }));
  }

  normalSelected(){
    this.disableComboTab = true;
  }
  alternateSelected(){
    this.disableComboTab = true;
  }

  comboSelected(){
    this.disableComboTab = false;
    this.comboDetailsForm.patchValue({
      mainOppName: this.irfDetails?.opportunityContactDetailsDTO?.opportunityName,
      mainProduct: this.oppGridDetials?.product,
      mainIrfFee: this.irfDetails?.opportunityContactDetailsDTO?.irfActualFees,
      mainSfFee: this.irfDetails?.opportunityContactDetailsDTO?.sfActualFees,
     
    });
    let payload = {
      companyCode: this.oppGridDetials?.companyCode,
      opportunityOwener: this.irfDetails?.opportunityContactDetailsDTO?.opportunityOwnerId,
      opportunityId: this.oppGridDetials?.opportunityId
    }
    this.pageLoader = true;
    this.apiCallList.push(this.initiationService.secondaryOppDetails(payload).subscribe((res) => {
      this.pageLoader = false;
      this.secondaryOppList = res;
    },(_err) => {
      this.pageLoader = false;
      this.apiFail = true;
    }));
  }

  secondOppSelected(event) {
    this.secondaryOpp = this.secondaryOppList.find((opp) => opp?.opportunityId == event?.value)
    this.comboDetailsForm.patchValue({
      comboIrfFee: this.secondaryOpp?.irfFee,
      comboSfFee: this.secondaryOpp?.sfFee,
      comboBdUser: this.secondaryOpp?.bdUser,
      comboReportedOn: this.secondaryOpp?.reportedDate,
      comboOppName: this.secondaryOpp?.opportunityName,
      comboProduct: this.secondaryOpp?.product,
      productSelected: this.secondaryOpp?.product,
      comboTotalIrfFee: Number(this.secondaryOpp?.irfFee) + Number(this.irfDetails?.opportunityContactDetailsDTO?.irfActualFees),
      comboTotalSfFee: this.secondaryOpp?.sfFee + this.irfDetails?.opportunityContactDetailsDTO?.sfActualFees,
    });

    this.otherKeyForm.patchValue({
      irfActualFee: Number(this.secondaryOpp?.irfFee) + Number(this.irfDetails?.opportunityContactDetailsDTO?.irfActualFees)
    });

    this.secondaryOpportunityId = this.secondaryOpp.opportunityId;
  }

 

  agreeClassChanged() {
    let obj = this.documentList.find(d => d.id == 44);
    if (this.clientClass?.value != this.agreementClass?.value?.clientTypeName) {
      obj.is_mandatory = 'M'
    } else {
      obj.is_mandatory = 'O'
    }
  }

  clientTypeClicked() {
    if (this.isInitiated) {
      return;
    } else {
      window.location.href = `${DOMAIN_ROUTE}#/masters/client-classification/grid`;
    }
  }

  clientTypeMaster() {
    this.initiationService.showLoader('getClientType');
    this.apiCallList.push(this.initiationService.getClientType().subscribe((res) => {
      this.clientTypeList = res?.SUCCESS;
      if (this.clientTypeList?.length > 0) {
        // if client and agreemtn class both empty user has to select it from master to initiatie the case
        let tempClientClass = this.clientTypeList.find(f => f.id == this.entityClientClass?.clientTypeId);
        this.triClass = this.clientTypeList.find(f => f.id == this.triClientClass?.clientTypeId)?.clientTypeName;
        this.clientClass.patchValue(tempClientClass?.clientTypeName);
        if(this.isIrfInitiated){
          let agreeClass = this.clientTypeList.find(f => f.id == this.irfDetails?.opportunityAgreementDetails?.agreementClientClassificationId);
          this.agreementClass.patchValue(agreeClass);
        } else {
          this.agreementClass.patchValue(tempClientClass);
        }
        if (this.clientClass?.value == null || this.clientClass?.value == undefined) {
          this.disableAgreeClass = true;
          this.clientTypeList = [];
        }
      }
    }, (_err) => {
      this.clientTypeList = [];
    }, () => {
      this.initiationService.hideLoader('getClientType');
    }));
  }

  clientSignedError: boolean = false;
  crisilSignedError: boolean = false;
  sentToClientError: boolean = false;
  recievedFromClientError: boolean = false;
  dateSelected() {
    let mandateDate = this.otherKeyForm.value.mandateDate;
    let clientSignedDate = this.otherKeyForm.value.clientSignedDate;
    let crisilSignedDate = this.otherKeyForm.value.crisilSignedDate;
    let sentToClient = this.otherKeyForm.value.sentToClient;
    let recievedFromClient = this.otherKeyForm.value.recievedFromClient;

    if (this.checkValue(sentToClient) && this.checkValue(clientSignedDate)) {
      if (new Date(sentToClient) > new Date(clientSignedDate)) {
        this.clientSignedError = true;
      } else {
        this.clientSignedError = false;
      }
    }

    if (this.checkValue(mandateDate) && this.checkValue(crisilSignedDate)) {
      if (new Date(crisilSignedDate) < new Date(mandateDate)) {
        this.crisilSignedError = true;
      } else {
        this.crisilSignedError = false;
      }
    }

    if (this.checkValue(clientSignedDate) && this.checkValue(sentToClient)) {
      if (new Date(clientSignedDate) < new Date(sentToClient)) {
        this.sentToClientError = true;
      } else {
        this.sentToClientError = false;
      }
    }

    if (this.checkValue(recievedFromClient) && this.checkValue(clientSignedDate)) {
      if (new Date(recievedFromClient) < new Date(clientSignedDate)) {
        this.recievedFromClientError = true;
      } else {
        this.recievedFromClientError = false;
      }
    }
  }

  opeTypeChanged(event) {
    // 94 - fixed
    // 95 - maximum limit
    if (event.value == 94 || event.value == 95) {
      this.showOpeAmt = true;
      this.otherKeyForm.get('opeAmount').addValidators([Validators.required]);
    } else {
      this.showOpeAmt = false;
      this.otherKeyForm.get('opeAmount').clearValidators();
    }
    this.otherKeyForm.get('opeAmount').updateValueAndValidity();
  }

  currencyChanged(event) {
    let obj = this.documentList.find(d => d.id == 46);
    if (event?.value == 1) {
      if (obj) {
        obj.is_mandatory = 'O';
      }
      this.disableInvVal = true;
      this.otherKeyForm.patchValue({ invoiceVal: this.irfDetails?.opportunityContactDetailsDTO?.irfActualFees});
    } else {
      if (obj) {
        obj.is_mandatory = 'M';
      }
      this.disableInvVal = false;
      this.otherKeyForm.patchValue({ invoiceVal: null });
    }
  }

  invoiceValEntered() {
    let regex = /^(?!$)\d{0,50}(?:\.\d{1,2})?$/;
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.invalidInvVal = !regex.test(this.otherKeyForm.value.invoiceVal);
    }, 1000);
  }

  opeAmtEntered() {
    let regex = /^(?!$)\d{0,50}(?:\.\d{1,2})?$/;
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.invalidOpeAmt = !regex.test(this.otherKeyForm.value.opeAmount);
    }, 1000);
  }

  splitClicked() {
    this.messageService.clear();
    if(this.revisionInProgress) {
      this.messageService.add({key : 'tabAckMsg', severity: 'warn', sticky: true, summary: "Revision in progress"});
    } else if (this.enableOta) {
      this.messageService.add({key : 'tabAckMsg', severity: 'warn', sticky: true, summary: "Case close in progress"});
    } else if (this.irfDetails?.opportunityContactDetailsDTO?.opportunityStatus === "Case Close") {
      this.messageService.add({key : 'tabAckMsg', severity: 'warn', sticky: true, summary: "Case has been closed"});
    }

    if(this.revisionInProgress || this.enableOta || this.irfDetails?.opportunityContactDetailsDTO?.opportunityStatus === "Case Close") {
      this.otherKeyForm.patchValue({split : false});
      return ;
    }
    let obj = this.documentList.find(d => d.id == 45);
    if (this.otherKeyformControls?.split?.value) {
      this.getAllHierarchy();
      this.getSingleHierarchy(this.oppOwnerId);
      obj.is_mandatory = 'M';
      obj.isDisabled = false;
      this.showOtherKeyOta = true;
      this.otherKeyForm.get('team1').addValidators([Validators.required]);
      this.otherKeyForm.get('proposedSplit').addValidators([Validators.required]);
      this.otherKeyForm.get('team2Owner').addValidators([Validators.required]);
      this.otherKeyForm.get('comment').addValidators([Validators.required]);
      this.enableSendOtaBtn = true;
      this.enableResubmit = false;
      this.showSubmit = true;
      this.disableSaveBtn = true;
    } else {
      this.showOtherKeyOta = false;
      this.otherKeyForm.get('team1').clearValidators();
      this.otherKeyForm.get('proposedSplit').clearValidators();
      this.otherKeyForm.get('team2Owner').clearValidators();
      this.otherKeyForm.get('comment').clearValidators();
      this.otherKeyForm.patchValue({
        proposedSplit: null
      });
      obj.is_mandatory = 'O';
      this.enableSendOtaBtn = false;
      this.splitTypeSelected = false;
      if(this.isQcRejected && !this.isSecondOpp){
        this.showSubmit = false;
        this.enableResubmit = true;
      }
      this.disableSaveBtn = false;
    }
    this.otherKeyForm.updateValueAndValidity();
    this.otherKeyForm.get('team1').updateValueAndValidity();
    this.otherKeyForm.get('proposedSplit').updateValueAndValidity();
    this.otherKeyForm.get('team2Owner').updateValueAndValidity();
    this.otherKeyForm.get('comment').updateValueAndValidity();
  }

  splitTypeClicked() {
    this.splitTypeSelected = true;
    this.otherKeyForm.patchValue({
      team1: null,
      splitTeam1: null,
      splitTeam2: null
    });
    this.team1Err = false;
  }

  team1Entered() {
    //129 - percent
    //130 - value
    let team1Val = this.otherKeyformControls?.team1?.value;
    if (this.otherKeyformControls?.proposedSplit?.value == 129) {
      let regex = /^[1-9][0-9]?$/;
      this.team1Err = !regex.test(team1Val);
      if (this.team1Err) {
        this.team1ErrTxt = 'Please enter a valid number';
        this.otherKeyForm.patchValue({
          splitTeam1: null,
          splitTeam2: null
        });
      } else {
        this.team1ErrTxt = null;
        this.calculateSplit(129);
      }
    } else {
      let regex = /^(?!$)\d{0,50}(?:\.\d{1,2})?$/;
      this.team1Err = !regex.test(team1Val);
      if (this.team1Err || Number(team1Val) < 1) {
        this.team1Err = true;
        this.team1ErrTxt = 'Please enter a valid number';
        this.otherKeyForm.patchValue({
          splitTeam1: null,
          splitTeam2: null
        });
      } else {
        if (Number(team1Val) > Number(this.otherKeyformControls?.irfActualFee?.value)) {
          this.team1Err = true;
          this.team1ErrTxt = 'Value cannot be greater than Irf Actual Fees';
          this.otherKeyForm.patchValue({
            splitTeam1: null,
            splitTeam2: null
          });
        } else {
          this.team1Err = false;
          this.team1ErrTxt = null;
          this.calculateSplit(130);
        }
      }
    }
  }

  calculateSplit(type) {
    //129 - percent
    //130 - value
    if (type == 129) {
      let pTeam1 = (Math.round((Number(this.otherKeyformControls?.team1?.value) / 100) * Number(this.otherKeyformControls?.irfActualFee?.value)));
      let pTeam2 = (Number(this.otherKeyformControls?.irfActualFee?.value) - Number(pTeam1));
      this.otherKeyForm.patchValue({
        splitTeam1: pTeam1,
        splitTeam2: pTeam2
      });
    } else {
      let pTeam1 = Number(this.otherKeyformControls?.team1?.value);
      let pTeam2 = (Number(this.otherKeyformControls?.irfActualFee?.value) - Number(pTeam1));
      this.otherKeyForm.patchValue({
        splitTeam1: pTeam1,
        splitTeam2: pTeam2
      });
    }
  }

  getSingleHierarchy(userId) {
    this.apiCallList.push(this.initiationService.getUserHierarchy(userId).subscribe((res) => {
      this.bdHierarchyData = JSON.parse(res?.upwardHierarchyUsers)?.uppwardhierarchy;
      this.bdHierarchy = res?.finalMapping?.split('->').join(' -> ');
    }));
  }

  getAllHierarchy() {
    this.pageLoader = true;
    this.initiationService.getAllUserHirearchy().subscribe((res) => {
      this.pageLoader = false;
      this.allUserList = res?.employeeDetails?.filter((e) =>
        !this.bdHierarchyData?.some((b) => this.equalObjs(b.employeeid, e.employeeId)));
    }, (_err) => {
      this.pageLoader = false;
      this.apiFail = true;
    });
  }

  team2Selected(owner) {
    this.apiCallList.push(this.initiationService.getUserHierarchy(owner).subscribe((res) => {
      this.team2Hierarchy = res?.finalMapping?.split('->').join(' -> ');
      this.team2HierarchyData = JSON.parse(res?.upwardHierarchyUsers)?.uppwardhierarchy;
    }));
  }

  equalObjs(id1, id2) {
    return id1 === id2;
  }


  getDocList() {
    this.initiationService.showLoader('docList');
    let uploadedDocIds: any = [];
    let docList = [];
    this.uploadedDocs?.forEach((u) => {
      uploadedDocIds.push(u.fileTypeid);
    });

    this.apiCallList.push(this.initiationService.docList().subscribe((res) => {
      if (res.length > 0) {
        res.forEach((r) => {
          if (r.attachment_activity == 113) {
            docList.push({
              "type": r.type,
              "is_mandatory": r.is_mandatory,
              "id": r.id,
              "file": false,
              "fileName": '',
              "thumbNail": '',
              "fileContentType": '',
              "fileId": null,
              "isDisabled": this.isIrfInitiated == 'Y',
              "code": `${r.type}_${r.id}`
            });
          }
        });
       
        uploadedDocIds.forEach((u) => {
          let index = docList.findIndex( x => x.id == u);
          if(index != -1){
            docList.splice(index, 1);
          }
        });
        this.uploadedDocs.forEach((u) => {
          if (u.fileContentType === 'application/pdf') {
            u.file = 'data:application/pdf;base64,' + u.file;
          } else if (u.fileContentType === 'image/png' || u.fileContentType === 'image/jpeg') {
            u.file = 'data:image/png;base64,' + u.file;
          }

          docList.push({
            "type": u.fileTypeName,
            "is_mandatory": 'O',
            "id": u.fileTypeid,
            "file": u.file,
            "fileName": u.fileName,
            "thumbNail": this.getThumbnail(u.fileContentType),
            "fileContentType": u.fileContentType,
            "fileId": u.fileId,
            "isDisabled": true,
            "code": `${u.fileTypeName}_${u.fileTypeid}`,
            "attachmentId":u.fileId,
            "userId":u.userId
          });
        });
        this.documentList = docList;
      }
    }, (_err) => {

    }, () => {
      this.initiationService.hideLoader('docList');
    }));
  }

  getCurrencyList() {
    this.apiCallList.push(this.initiationService.currencyMaster().subscribe((res) => {
      if (res && res.length > 0) {
        this.currencyList = res;
        //bind if opp status is reported only
        this.otherKeyForm.patchValue({ currency: 1 });
      }
    }));
  }


  onFileUplaod(event: any, type: any, code: any, id: any): void {
    this.canvasRotation = 0;
    this.currentFileEvent = event.currentFiles[0];
    if (this.currentFileEvent == undefined) {
      this.messageService.add({ severity: 'error', summary: 'File size cannot exceed 20 MB.' });
      return;
    }
    let ext = this.currentFileEvent?.name?.split('.');
    if (ext[ext.length - 1] == 'jfif') {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      return;
    }
    if (["image/svg+xml", "image/webp", "xlxs", "xls", "html", "xhtml", "txt", "gif", "application/exe", "zip", ".oft"].includes(this.currentFileEvent.type)) {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      return;
    }
    this.fileType = type;
    this.fileTypeId = id;
    this.imgChangeEvt = event.originalEvent;
    if (event.currentFiles[0]?.type == 'image/jpeg' || event.currentFiles[0]?.type == 'image/jpg' || event.currentFiles[0]?.type == 'image/png') {
      this.displayModal = true;
    } else {
      this.displayModal = false;
    }
    this.uploadLoader = true;
    for (const file of event.files) {
      if (file.type.includes('image')) {
        this.imageBlob = new Blob([file], { type: file.type });
        this.uploadedFileName = file.name;
      } else {
        //Added
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.currentFiles[0]);
        fileReader.onload = () => {
          this.returnBase64 = fileReader.result;
          this.onImgUpload();
        };
      }
    }
  }

  onImgUpload() {
    if (this.dataURLtoFile && this.croppedImageBase64) {
      const file = this.dataURLtoFile(this.croppedImageBase64, this.uploadedFileName);
      this.fileUploadfileUpload1.files.push(file);
    }
    this.uploadFile = {};
    if (this.displayModal) {
      const displayData = this.getUploadedObjDisplay(this.fileType);
      this.attachmentResult.push(displayData);
      this.uploadFile = displayData;
      this.uploadSingleFile(displayData);
    } else {
      this.returnUploaded = this.getUploadedObj(this.fileType);
      this.attachmentResult.push(this.returnUploaded);
      this.uploadFile = this.returnUploaded;
      this.uploadSingleFile(this.returnUploaded);
    }

    this.documentList.filter((d) => {
      if (d.type == this.fileType && d.id == this.fileTypeId) {
        d.file = this.fileType;
        d.fileName = this.uploadFile.fileName;
        d.thumbNail = this.getThumbnail(this.uploadFile.fileContentType);
      }
    });
    this.displayModal = false;
  }

  getUploadedObjDisplay(fileType): any {
    let data = this.returnBase64;
    if (data.startsWith('data:application/pdf;base64,')) {
      data = data.replace('data:application/pdf;base64,', '');
    } else if (data.startsWith('data:image/png;base64,')) {
      data = data.replace('data:image/png;base64,', '');
    } else if (data.startsWith('data:application/doc;base64,')) {
      data = data.replace('data:application/doc;base64,', '');
    }

    return {
      userId: this.encDec.decrypt(localStorage.getItem('empid')),
      moduleName: 'IrfInitiation',
      moduleId: this.oppGridDetials?.opportunityId,
      fileName: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].name,
      file: data,
      fileContentType: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].type,
      fileSize: (this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].size).toString(),
      fileTypeid: this.fileTypeId,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  getUploadedObj(fileType): any {
    return {
      userId: this.encDec.decrypt(localStorage.getItem('empid')),
      moduleName: 'IrfInitiation',
      moduleId: this.oppGridDetials?.opportunityId,
      fileName: this.currentFileEvent?.name,
      file: this.returnBase64,
      fileContentType: this.currentFileEvent?.type,
      fileSize: (this.currentFileEvent?.size)?.toString(),
      fileTypeid: this.fileTypeId,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  onPreviewScreen() {
    let currency = (this.currencyList.find((currencyData) => currencyData?.id == this.otherKeyForm?.value?.currency))?.currency_name;
    let opeType = (this.opeTypeList.find((opeData) => opeData?.id == this.otherKeyForm?.value?.opeType))?.value;
    let caseClassification = (this.caseClassList.find((caseClassData) => caseClassData?.id == this.otherKeyForm?.value?.caseClass))?.value;
    let priorityForQc = (this.priorityQcList.find((priorityQc) => priorityQc?.id == this.otherKeyForm?.value?.priorityQc))?.value;
    let team2OwnerDetails = (this.allUserList.find((team2Owner) => team2Owner?.employeeId == this.otherKeyForm?.value?.team2Owner))?.employeeName;
    let mandateTypeList = (this.mandateTypeList.find((mandateType) => mandateType?.id == this.otherKeyForm?.value?.mandateType))?.value;
    let initType = (this.initiationTypeList.find((initType) => initType?.id == this.entityDetailsForm?.value?.initType))?.value;
    let sfMandateValidityData = (this.mandateValidityList.find((validity) => validity?.id == this.otherKeyForm?.value?.sfMandateValidity))?.sf_mandate_validity;
    let previewDtata = {
      "triPartyNormal": this.entityDetailsForm?.value?.triNormal,
      "sfMandateValidity":sfMandateValidityData,
      "initType": initType,
      "opportunityStatus": this.oppGridDetials?.opportunityStatus,
      "oppDataArray": this.oppGridDetials,
      "oppDate": this.oppGridDetials?.reportedDate,
      "opportunityId": this.oppGridDetials?.opportunityId,
      "crmCode": this.oppGridDetials?.companyCode,
      "product": this.oppGridDetials?.product,
      "companyName": this.oppGridDetials?.companyName,
      "entityCrmContact": this.entityCrmContact,
      "entitySalutation": this.entityCrmContact?.salutation || this.contactForm?.value?.salutation,
      "entityFirstName": this.entityCrmContact?.firstName || this.contactForm?.value?.firstName,
      "entityLastName": this.entityCrmContact?.lastName || this.contactForm?.value?.lastName,
      "entityDesignation": this.entityCrmContact?.designation || this.contactForm?.value?.designation,
      "entityMobileNo": this.entityCrmContact?.mobileNum || this.contactForm?.value?.mobile,
      "entityLandlineNo": this.entityCrmContact?.phoneNum || this.contactForm?.value?.landline,
      "entityEmail": this.entityCrmContact?.email || this.contactForm?.value?.email1,
      "email2": this.contactForm?.value?.email2,
      "email3": this.contactForm?.value?.email3,
      "entityGstType": this.entityCrmContact?.gstType || this.contactForm?.value?.gstType,
      "entityGstNumber": this.entityCrmContact?.gstNumber || this.contactForm?.value?.gstNo,
      "entityPanNumber": this.entityCrmContact?.pan || this.contactForm?.value?.panNo,
      "entityPinCode": this.entityCrmContact?.pin || this.contactForm?.value?.pinCode,
      "enityCity": this.entityCrmContact?.city || this.contactForm?.value?.city,
      "entityState": this.entityCrmContact?.state || this.contactForm?.value?.state,
      "enityAdressLine1": this.entityCrmContact?.address1 || this.contactForm?.value?.address1,
      "enityAdressLine2": this.entityCrmContact?.address2 || this.contactForm?.value?.address2,
      "enityAddressLine3": this.entityCrmContact?.address3 || this.contactForm?.value?.address3,
      "enityAddressLine4": this.entityCrmContact?.address4 || this.contactForm?.value?.address4,
      "tripartyContact": this.triContactForm?.value,
      "triCrmContact": this.triCrmContact,
      "triSalutation": this.triCrmContact?.salutation || this.triContactForm?.value?.salutation,
      "triFirstName": this.triCrmContact?.firstName || this.triContactForm?.value?.firstName,
      "triLastName": this.triCrmContact?.lastName || this.triContactForm?.value?.lastName,
      "triDesignation": this.triCrmContact?.designation || this.triContactForm?.value?.designation,
      "triMobileNo": this.triCrmContact?.mobileNum || this.triContactForm?.value?.mobile,
      "triLandlineNo": this.triCrmContact?.phoneNum || this.triContactForm?.value?.landline,
      "triEmail": this.triCrmContact?.email || this.triContactForm?.value?.email1,
      "triGstType": this.triCrmContact?.gstType || this.triContactForm?.value?.gstType,
      "triGstNumber": this.triCrmContact?.gstNumber || this.triContactForm?.value?.gstNo,
      "triPanNumber": this.triCrmContact?.pan || this.triContactForm?.value?.panNo,
      "triPincode": this.triCrmContact?.pin || this.triContactForm?.value?.pinCode,
      "triCity": this.triCrmContact?.city || this.triContactForm?.value?.city,
      "triState": this.triCrmContact?.state || this.triContactForm.value.state,
      "triAddressLine1": this.triCrmContact?.address1 || this.triContactForm?.value?.address1,
      "triAddressLine2": this.triCrmContact?.address2 || this.triContactForm?.value?.address2,
      "triAddressLine3": this.triCrmContact?.address3 || this.triContactForm?.value?.address3,
      "triAddressLine4": this.triCrmContact?.address4 || this.triContactForm?.value?.address4,
      "triPartyCompanyName":this.entityDetailsForm?.value?.triCrm,
      "triCrmCode":this.entityDetailsForm?.value?.crmCode,
      "masterService": this.otherKeyForm?.value?.masterService,
      "subService": this.otherKeyForm?.value?.subService,
      "ratableUnit": this.otherKeyForm?.value?.ratableUnit,
      "mandateDate": this.otherKeyForm?.value?.mandateDate,
      "clientSignedDate": this.otherKeyForm?.value?.clientSignedDate,
      "crisilSignedDate": this.otherKeyForm?.value?.crisilSignedDate,
      "effectiveMisDate": this.otherKeyForm?.value?.effectiveMisDate,
      "mandateSentClientDate": this.otherKeyForm?.value?.sentToClient,
      "mandateReceivedClientDate": this.otherKeyForm?.value?.recievedFromClient,
      "mandateType": mandateTypeList,
      "feedbackForQc": this.otherKeyForm?.value?.feedbackQc,
      "priorityForQc": priorityForQc,
      "currency": currency,
      "invoiceValue":this.otherKeyForm?.value?.invoiceVal,
      "opeType":opeType,
      "opeAmount":this.otherKeyForm?.value?.opeAmount,
      "caseClassification":caseClassification,
      "proposedSplit":this.otherKeyForm?.value?.split ? 'Yes' : 'No',
      "proposedSplit1":this.otherKeyForm?.value?.proposedSplit,
      "irfActualFee":this.otherKeyForm?.value?.irfActualFee,
      "team1":this.otherKeyForm?.value?.team1,
      "splitTeam1":this.otherKeyForm?.value?.splitTeam1,
      "splitTeam2":this.otherKeyForm?.value?.splitTeam2,
      "agreementClientClassification": this.agreementClass?.value?.clientTypeName,
      "clientClassification": this.clientClass?.value,
      "triClientClassification": this.triClass,
      "comboDetailsForm":this.comboDetailsForm?.value,
      "comboSecondOpportunity":this.comboDetailsForm?.value.comboOppName,
      "comboProductSelected":this.comboDetailsForm?.value.comboProduct,
      "comboMainOpportunityName":this.comboDetailsForm?.value.mainOppName,
      "comboMainProduct":this.comboDetailsForm?.value.mainProduct,
      "comboMainIrfFee":this.comboDetailsForm?.value.mainIrfFee,
      "comboMainSfFee":this.comboDetailsForm?.value.mainSfFee,
      "comboIrfFee":this.comboDetailsForm?.value.comboIrfFee,
      "comboTotalIrfFee":this.comboDetailsForm?.value.comboTotalIrfFee,
      "comboSfFee":this.comboDetailsForm?.value.comboSfFee,
      "comboTotalSfFee":this.comboDetailsForm?.value.comboTotalSfFee,
      "comboBdUser":this.comboDetailsForm?.value.comboBdUser,
      "comboReportedOn":this.comboDetailsForm?.value.comboReportedOn,
      "outstandingAmount":this.otherKeyForm?.get('outstandingAmount').value,
      "inprogressAmount":this.otherKeyForm?.get('inprogressAmount').value,
      "previousLimit":this.otherKeyForm?.get('previousLimit').value?.label,
      "currentLimit":this.otherKeyForm?.get('currentLimit').value,
      "applicableAmount":this.otherKeyForm?.get('applicableAmount').value,
      "otherform":this.otherKeyForm?.value,
      "Owner":this.oppGridDetials?.nexeraUser,
      "bdHierarchy":this.bdHierarchy,
      "team2Owner":team2OwnerDetails,
      "teamOwner":this.otherKeyForm?.value?.team2Owner,
      "team2Hierarchy":this.team2Hierarchy,
      "isFeeCapTagged":this.feeCapForm.get('tagMandate')?.value != null ? 
        this.feeCapForm.get('tagMandate').value 
      : null,
      "feeCapData":this.feeCapData,
      "group":this.feeCapData?.length > 0 ? this.feeCapData[0]?.group : null,
     "validTo":this.feeCapData?.length > 0 ? this.feeCapData[0]?.validTo : null,
    "mandates":this.feeCapData?.length > 0 ? this.feeCapData[0]?.mandates : null,
    "FeeCapCompanyName":this.oppGridDetials?.name,
    "amcName":this.otherKeyForm?.value?.amcName?.value,
    "amcCode":this.otherKeyForm?.value?.amcCode,
    "mfSchemeName":this.otherKeyForm?.value?.mfSchemeName?.value,
    "businessAction": this.businessTypeList?.find((businessAction) => businessAction?.id == this.businessActionForm?.value?.businessAction)?.property,
    "reason1": this.businessAction?.value?.reason,
    "comment": this.businessAction?.value?.comment,
    }
    const encPreviewData = this.encDec.encryptLocalObject(previewDtata);
    localStorage.setItem('previewData', encPreviewData);
    let previewUrl = DOMAIN_ROUTE+"#/backoffice/pages/preview";
    const newWindow = window.open(previewUrl, '_blank');
  }
  onDeletePopup(item) {
    this.deleteFile = true;
    this.deleteFileData = item;
    if(this.deleteFileData?.id == 44 || this.deleteFileData?.id == 46){
      this.deleteFileData.deleteId = item?.fileId;
    }
  }

  onDelete() {
    if(this.doRevision) {
      this.showRevisionAddButton = true;
      this.revisionForm.patchValue({attachment : null});
      this.documentList = this.documentList.filter(item => !item.newRevisionFileitem);
      this.deleteFile = false;
      return;
    }
    this.attachToDelete.push(this.deleteFileData?.fileId);
    //split revenue - 45
    //case close - 42
    if(this.deleteFileData?.id == 45 || this.deleteFileData?.id == 42){
      this.documentList.filter((d) => {
        if (d.type == this.deleteFileData.type) {
          d.file = '';
          d.fileId = null;
          d.isDisabled = false;
          d.fileName = '';
          d.thumbNail = '';
        }
      });
    } else {
      this.initiationService.deleteFileById(this.deleteFileData?.deleteId).subscribe((res) => {
        if(res?.status == "success"){
          this.documentList.filter((d) => {
            if (d.type == this.deleteFileData.type) {
              d.file = '';
              d.fileId = null;
              d.isDisabled = false;
              d.fileName = '';
              d.thumbNail = '';
            }
          });
          let index = this.uploadedFileIds.findIndex((r) => r == this.deleteFileData?.deleteId);
          this.uploadedFileIds.splice(index, 1);
        }
      }, (_err) => {
        this.apiFail = true;
      });
    }
    this.deleteFile = false;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  getThumbnail(type) {
    if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') {
      return this.imgThumb;
    } else if (type == 'application/pdf') {
      return this.pdfThumb;
    } else if (type.startsWith('application/vnd.openxmlformats') || type.startsWith('application/msword')) {
      return this.wordThumb;
    } else {
      return this.mailThumb;
    }
  }

  dataURLtoFile(dataurl: any, filename: any): any {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imageCropEvent(event: any): any {
    // this.croppedImageBase64 = event.base64;
    // this.returnBase64 = event.base64; //Added

    fetch(event.objectUrl)
       .then(response => response.blob())
       .then(blob => {
           const reader = new FileReader();
           reader.onloadend = () => {
               this.returnBase64 = reader.result;
               this.croppedImageBase64 = this.returnBase64;
           };
           reader.readAsDataURL(blob);
    });
  }

  checkValue(data) {
    if (data != null && data != undefined) {
      return true;
    }
    return false;
  }


  loaderCheck() {
    this.initiationService.isLoading.subscribe((res) => {
      this.pageLoader = res;
    });
  }

  ngOnDestroy() {
    this.apiCallList.forEach((a) => {
      a.unsubscribe();
    });
  }

  getCaseCloseReasonList() {
    this.initiationService.getCaseCloseReason().subscribe((res) => {
      if (res && res.length > 0) {
        this.reasonList = res;
      }
    });
  }

  businessActionSelect() {
    if (this.businessActionForm.value.businessAction == 131) {
      this.messageService.clear();
      if(this.revisionInProgress) {
        this.messageService.add({key : 'tabAckMsg', severity: 'warn', sticky: true, summary: "Revision in progress"});
        return;
      } 
      if (this.enableSplitOta) {
        this.messageService.add({key : 'tabAckMsg', severity: 'warn', sticky: true, summary: "Split revenue in progress"});
        return;
      } 
      this.disableReason = false;
      this.enableOta = true;
      this.enableOtaAttachmentMsg = true;
      this.disableSaveBtn = true;
      this.disableComment = false;
      this.businessActionForm.get('reason').addValidators([Validators.required]);
      this.businessActionForm.get('opsReaseon').addValidators([Validators.required]);
      let obj = this.documentList.find(d => d.id == 42);
      obj.is_mandatory = 'M';
      obj.isDisabled = false;
    } else {
      this.disableReason = true;
      this.enableOta = false;
      this.enableOtaAttachmentMsg = false;
      if(!this.otherKeyForm.value?.split){
        this.enableSendOtaBtn = false;
        this.disableSaveBtn = false;
      }
      this.businessActionForm.get('reason').clearValidators();
      this.businessActionForm.get('reason').reset();
      this.businessActionForm.get('opsReaseon').clearValidators();
      this.businessActionForm.get('opsReaseon').reset();
      let obj = this.documentList.find(d => d.id == 42);
      obj.is_mandatory = 'O';
      obj.isDisabled = true;
    }
    this.businessActionForm.get('reason').updateValueAndValidity();
  }

  generateOta() {
    if(this.doRevision) {
      
      this.errorAttachment = false;
      this.showRevAttachmentAlertPopUp = false;

      if(this.isIrfInitiated) {
        this.resetLimitValidators();
      }
      Object.values(this.revisionForm.controls).forEach(item => {
        item.markAsDirty();
        item.markAllAsTouched();
      });

      if(!this.revisionForm.get('attachment').valid) {
        this.showRevAttachmentAlertPopUp = true;
        this.errorMsgPopup = true;
        this.errorAttachment = true;
        this.errorRevisionMsgs = new Set();
        this.finalRevErrorMsg = "";
        this.errorRevision = !!Object.entries(this.revisionForm.controls).find((item) =>
          item[1].invalid && item[0] !== 'attachment');
        
        this.errorAttachment = true;
        
        if(this.errorRevision) {
          this.finalRevErrorMsg = this.REV_ALL_FIELDS_MSG + `${this.errorAttachment ? ' & add an attachment for revision' : ''}`;
        }else {
          this.finalRevErrorMsg = this.REV_ATTCH_ERROR_MSG;
        }
        
        return;
      }

      const errorModules = new Set();

      if(!this.revisionForm.valid) {
        this.errorMessageSave = "Revision";
        this.errorMsgPopup = true;
        this.errorRevision = true;
        return;
      }

      Object.values(this.revisionForm.controls).forEach((item, index) => {
        if(item.invalid) {
          errorModules.add(item?.value?.name);
        }
      });
      if(errorModules.size === 0) {
        this.initiationService.getAllApproverNamesForRevisionOTA().subscribe((res : any) => {
          this.irfApproverList = res;
          this.approverPopup = true;
        }, (err) => {
          this.messageService.add({ severity: 'error', summary: 'Unable to fetch approver name list' });
        })
      }
      return;
    }
    this.isSaveClicked = true;
    this.errorMessageSave = [];
    let otherKeyOta = false;
    let tempArray = [];
    this.documentList.forEach((d) => {
      if (d.is_mandatory == 'M' && d.file == '') {
        tempArray.push(d)
      }
    });

    if(this.businessActionForm.value.businessAction != '131'){
      this.otherKeyForm.markAllAsTouched();
      this.markFormDirty(this.otherKeyForm);
      this.disableBusinessAction = true;

      if (this.otherKeyForm.invalid || this.otherKeyForm?.value?.comment == null) {
        otherKeyOta = true;
        this.errorOtherKey = true;
        let index = this.errorMessageSave.findIndex(e => e == 'Other Key Details');
        if (index == -1) {
          this.errorMessageSave.push('Other Key Details');
        }
        this.otherKeyForm.controls.proposedSplit.markAsDirty();
        this.otherKeyForm.controls.team1.markAsDirty();
        this.otherKeyForm.controls.team2Owner.markAsDirty();
        this.otherKeyForm.controls.comment.markAsDirty();
      } else {
        this.errorOtherKey = false;
        let index = this.errorMessageSave.findIndex(e => e == 'Other Key Details');
        if (index != -1) {
          this.errorMessageSave.splice(index, 1);
        }
      }
    }

    if (tempArray?.length > 0) {
      this.errorAttachment = true;
      let index = this.errorMessageSave.findIndex(e => e == 'Attachment Details');
      if (index == -1) {
        this.errorMessageSave.push('Attachment Details');
      }
    } else {
      this.errorAttachment = false;
      let index = this.errorMessageSave.findIndex(e => e == 'Attachment Details');
      if (index != -1) {
        this.errorMessageSave.splice(index, 1);
      }
    }

    if (this.errorAttachment || otherKeyOta) {
      this.otaErrorMsgPopup = true;
    }
    if (!this.errorAttachment&&!otherKeyOta) {
      this.enableSendOtaBtn = false;
      this.enableCancelOtaBtn = false;
      this.approverPopup = true;
    }
    
  }

  reasonSelect() {
    if ((this.businessActionForm.value.reason != null && this.businessActionForm.value.reason != undefined && this.businessActionForm.value.reason != "") && (this.businessActionForm.value.opsReaseon != null && this.businessActionForm.value.opsReaseon != undefined && this.businessActionForm.value.opsReaseon != "")) {
      this.enableSendOtaBtn = true;
      this.enableResubmit = false;
      this.showSubmit = true;
    }
  }

  navigateToFeeCap() {
    // this.router.navigate(['/masters/feecap/grid']);
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/masters/feecap/grid`;
  }

  createPayload(approvermailId: any, approvedBy: any) {
    let revisedPayload = null;
    let moduleName = null;
    let noteToManager = null;
    if (this.businessActionForm.value != null && this.businessActionForm.value.businessAction != undefined && this.businessActionForm.value.businessAction == '131') {
      this.sendCaseCloseOta = true;
      let reasonId = this.businessActionForm.value.reason;
      let caseCloseReason = this.reasonList.find((reason) => reason?.id == reasonId).property;
      let expirationTime = new Date().getTime() + 3600000; // 60mins expiry
      let opsReasonId = this.businessActionForm.value.opsReaseon;
      let opsReasonData = this.opsReasonList.find((opsReason) => opsReason?.id == opsReasonId).name;
      this.otaAttachment = this.attachmentResult.filter(item => item.fileTypeid == 42);

      if(this.otaAttachment?.length == 0){
        this.otaAttachment = this.documentList.filter(item => item.id == 42);
      }
      let agreementId = this.oppGridDetials?.opportunityId;
      if(this.agreementId != null && this.agreementId != undefined){
        agreementId = this.agreementId;
      }
      revisedPayload = {
        "agreementId":agreementId,
        "opsUserName": this.empName,
        "reason": caseCloseReason,
        "opportunityStatus": this.oppGridDetials?.opportunityStatus,
        "irfBusinessAction": "Case Close",
        "businessAction":"Case Close",
        "reasonId": reasonId,
        "companyName": this.oppGridDetials?.name,
        "otaExpireTime": this.otaExpiryTime,
        "opsReasonId":opsReasonId,
        "opsReason":opsReasonData
      };
      moduleName = 123;
      noteToManager = this.businessActionForm.value.comment;
    } else {
      this.sendSplitOta = true;
      let splitValue = null;
      this.otaAttachment = this.attachmentResult.filter(item => item.fileTypeid == 45);
      if(this.otaAttachment?.length == 0){
        this.otaAttachment = this.documentList.filter(item => item.id == 45);
      }
      let proposedSplitType = (this.proposedSplitList.find((splitType) => splitType?.id == this.otherKeyForm?.value?.proposedSplit))?.value;

      let team2Split;
      if(this.otherKeyformControls?.proposedSplit?.value == 129){
        team2Split = 100 - Number(this.otherKeyForm?.value?.team1);
      } else {
        team2Split = Number(this.otherKeyForm?.value?.irfActualFee) - Number(this.otherKeyForm?.value?.team1);
      }

      if (this.otherKeyForm?.value?.split) {
        splitValue = [
          {
            "opportunityId": this.oppGridDetials?.opportunityId,
            "isSplit": this.otherKeyForm?.value?.split,
            "splitType": this.otherKeyForm?.value?.proposedSplit,
            "splitValue": Number(this.otherKeyForm?.value?.team1),
            "splitTeamId": 1,
            "proposedSplit": this.otherKeyForm?.value?.splitTeam1,
            "createdBy": this.empId,
            "modifiedBy": this.empId,
            "teamDetails": this.bdHierarchyData,
            "teamOwnerId":this.oppGridDetials?.nexeraUser
          }, {
            "opportunityId": this.oppGridDetials?.opportunityId,
            "isSplit": this.otherKeyForm?.value?.split,
            "splitType": this.otherKeyForm?.value?.proposedSplit,
            "splitValue": team2Split,
            "splitTeamId": 2,
            "proposedSplit": this.otherKeyForm?.value?.splitTeam2,
            "createdBy": this.empId,
            "modifiedBy": this.empId,
            "teamDetails": this.team2HierarchyData,
            "teamOwnerId":this.otherKeyForm?.value?.team2Owner
          }
        ]
      }
      revisedPayload = {
        "opsUserName": this.empName,
        "companyName": this.oppGridDetials?.name,
        "irfActual": this.otherKeyForm?.value?.irfActualFee,
        "existingTeam": this.bdHierarchy,
        "splitValue": proposedSplitType,
        "proposedSplitExistingTeam": this.otherKeyForm?.value?.splitTeam1,
        "newTeam": this.team2Hierarchy,
        "proposedSplitNewTeam": this.otherKeyForm?.value?.splitTeam2,
        "splitRevenue": splitValue,
      }
      moduleName = 122;
      noteToManager = this.otherKeyForm?.value?.comment;
    }

    this.otaRequestPayload = {
      "opportunityId": this.oppGridDetials?.opportunityId,
      "moduleName": moduleName,
      "noteToManager": noteToManager,
      "approvedBy": approvedBy,
      "refNo": null,
      "oldValues": null,
      "revisedValues": revisedPayload,
      "approvalMailId": approvermailId,
      "sentBy": this.empId,
      "attachmentsDataList": this.otaAttachment
    };

    // this.otaAttachment = this.attachmentResult;
  }

  getUserHierarchys() {
    this.initiationService.getUserHierarchy(this.empId).subscribe((res: any) => {
      let data = JSON.parse(res.upwardHierarchyUsers);
      let list = data?.uppwardhierarchy.splice(1);
      this.hierarchyList = list.filter((l) => {
        if (!l.levelName?.toLowerCase()?.startsWith('sd')) {
          return l;
        }
      });
    })
  }

  sendOta() {
    if(this.doRevision) {
      const formVal = this.revisionForm.value;
      const contact = formVal.newContact || formVal.oldContact;
      const revisedVal = formVal?.currentInvoiceVal;
      const oldvNew = this.getOldVsNew();
      const isNonOFA = this.determineIfOFA(oldvNew);
      const reqBody = this.createRevisionPayload(formVal, contact, isNonOFA, revisedVal, oldvNew)
      this.initiationService.saveRevision(reqBody).subscribe((res) => {
        if(res) {
          this.approverPopup = false;
          this.resetRevisionValidation();
          this.documentList = this.documentList.filter(item => !item?.fileTypeName?.includes("Revision"));
          this.revisionService$.next();
          
          this.messageService.add({ severity: 'success', summary: 'OTA Sent Successfully' });
        }
      }, (err) => {
        this.apiFail = true;
      });
      return;
    }
    this.createPayload(this.approverName.emailid, this.approverName.employeeid);
    this.initiationService.generateOta(this.otaRequestPayload).subscribe((res) => {
      this.otaResponse = res;
      this.approverPopup = false;
      this.enableCancelOtaBtn = true;
      this.disableBusinessAction = true;
      this.enableOtaAttachmentMsg = false;
      this.disableAllFileds = true;
      this.disableSaveBtn = false;
      this.disableRevisionGlobal = true;
      
      if(this.sendSplitOta){
        this.enableSplitOta = true;
        this.refNo = res?.refNo;
        this.showRefNo = true;
      } else{
        this.disableReason = true;
        this.enableOta = true;
        this.businessActionForm.get('comment').disable();
        this.disableOta = false;
        this.refNo = res?.refNo;
        this.showRefNo = false;
      }

      if (this.enableSplitOta) {
        this.disableRevision = true;
        this.disableRevisionGlobal = true;
        this.disableTabMessage = "Split revenue is in progress";
      } else if (this.enableOta) {
        this.disableRevision = true;
        this.disableRevisionGlobal = true;
        this.disableTabMessage = "Case close is in progress";
      } 
      this.messageService.add({ severity: 'success', summary: 'OTA Sent Successfully' });
    }, (_err) => {
      this.messageService.add({ severity: 'error', summary: 'Something went wrong, please try again.' });
    });
    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
  }
  
  determineIfOFA(oldvNew: {}) {
    const nonOFAFields = new Set(["invoice detail","salutation", "firstName", "lastName", "designation", "addressLine1", "addressLine2", "addressLine3", "addressLine4", "city", "id"]);

    return Object.keys(oldvNew).every(item => nonOFAFields.has(item));
  }
  
  getOldVsNew() {
    const res = {};
    const formVal = this.revisionForm?.value;
    if(formVal?.billDetail != formVal?.oldBillDetail) {
      res['invoice detail'] = [formVal.oldBillDetail  ?? "", formVal.billDetail  ?? ""];
    }
    if(formVal?.isCancel) {
      res['value'] = [formVal?.currentInvoiceVal, 0];
    }
    if(this.crmRevisionContactSelected) {
      Object.entries(formVal?.oldContact).forEach(item => {
        const key = item[0];
        if(formVal?.oldContact[key] != null && formVal?.newContact[key]&& formVal?.oldContact[key] != formVal?.newContact[key] && key !== 'id' && key !== 'crmContactId' && key !== "email" && key !== "landlineNo" && key !== "mobileNo" && key !== "panNumber")
          res[key] = [formVal?.oldContact[key] ?? "", (formVal?.newContact && formVal?.newContact[key]) ?? ""];
      });
    }
    return res;
  }

  cancelOta() {
    if(this.doRevision) {
      const reqBody = {
        userId : this.initiationService.empId,
        revisionId : this.refNo
      };
      this.runtimeCancel = false;
      this.resetRevisionValidation();
      this.revisionForm.reset();
      this.revisionForm.get('ota').removeValidators([Validators.required, Validators.minLength(6)]);
      this.revisionForm.get('ota').updateValueAndValidity();
      this.initiationService.cancelRevisionOta(reqBody).pipe(
        switchMap(() => {
          this.documentList = this.documentList.filter(item => !item?.fileTypeName?.includes("Revision"));
          this.doRevision = false;
          this.revisionService$.next(); // refresh data
          return of(undefined);
        })
      ).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'OTA Cancelled Successfully' });
      }, (err) => {
        this.apiFail = true;
      })
      return;
    }

    let refNo = null;
    let moduleId = null;
    if (this.businessActionForm.value.businessAction == '131') {
      refNo = this.refNo;
      moduleId = 123;
    } else {
      refNo = this.refNo;
      moduleId = 122;
    }
    this.cancelOtaRequestPayload = {
      "opportunityId": this.oppGridDetials?.opportunityId,
      "refNo": refNo,
      "actionedBy": null,
      "moduleId": moduleId
    }
    this.disableSaveBtn = true;
    this.initiationService.cancelOta(this.cancelOtaRequestPayload).subscribe((res) => {
      this.otaResponse = res;
      this.enableCancelOtaBtn = false;
      this.enableSendOtaBtn = true;
      if (this.businessActionForm.value.businessAction == '131') {
        this.disableReason = false;
      }
      this.sendCaseCloseOta = false;
      this.sendSplitOta = false;
      this.disableBusinessAction = false;
      this.businessActionForm.get('comment').enable();
      this.enableOtaAttachmentMsg = false;
      this.disableOta = false;
      this.enableOta = false;
      this.disableAllFileds = false;
      this.refNo = null;
      this.enableSplitOta = false;

      if(this.otherKeyForm.value?.split){
        //if split revenue selected then remove split attachment
        let uploadedId = this.irfDetails?.attachments?.find(x => x.fileTypeid == 45)?.fileId;
        this.initiationService.deleteFileById(uploadedId).subscribe((res) => {
          if(res?.status == "success"){
            let obj = this.documentList.find(d => d.id == 45);
            if(obj){
              obj.file = '';
              obj.fileId = null;
              obj.fileName = '';
              obj.thumbNail = '';
              obj.isDisabled = false;
            }
          }
        }, (_err) => {
          this.apiFail = true;
        });
      }

      if (this.businessActionForm.value.businessAction == 131) {
        //if case close then remove case close attachment
        let uploadedId = this.irfDetails?.attachments?.find(x => x.fileTypeid == 42)?.fileId;
        this.initiationService.deleteFileById(uploadedId).subscribe((res) => {
          if(res?.status == "success"){
            let obj = this.documentList.find(d => d.id == 42);
            if(obj){
              obj.file = '';
              obj.fileId = null;
              obj.fileName = '';
              obj.thumbNail = '';
              obj.isDisabled = false;
            }
          }
        }, (_err) => {
          this.apiFail = true;
        });
      }
      this.messageService.add({ severity: 'success', summary: 'OTA Cancelled Successfully' });
    }, (_err) => {
      this.messageService.add({ severity: 'error', summary: 'Something went wrong, please try again.' });
    });

    setTimeout(() => {
      this.messageService.clear();
    }, 3000);

  }

  private resetRevisionValidation() {
    this.errorMessageSave = "";
    this.errorRevision = false;
    this.errorMsgPopup = false;
    Object.values(this.revisionForm.controls).forEach(item => {
      item.markAsUntouched();
      item.markAsPristine();
    });
  }

  getBusinessType() {
    this.scopeList = this.encDec.decrypt(localStorage.getItem('scopes'));
    let scope = this.scopeList.split(',');

    if (scope.indexOf('CASE_CLOSE_CREATE') == -1) {
      this.businessTypeList = [{ "id": 132, "name": "IrfBusinessAction", "property": "Initiated" }];
    } else {
      this.initiationService.getBusinessType().subscribe((res) => {
        if (res && res.length > 0) {
          this.businessTypeList = res;
          this.businessTypeList.push({ "id": 132, "name": "IrfBusinessAction", "property": "Initiated" });
        }
      });
    }
  }

  validateOta() {
    if(this.doRevision) {
      Object.values(this.revisionForm.controls).forEach(item => {
        item.markAsDirty();
        item.markAllAsTouched();
      });

      if(this.revisionForm.get('ota').valid) {
        this.showRevInvalidAlertPopUp = false;
        const reqBody = {
          refNo: this.refNo,
          otaKey: this.revisionForm.value.ota,
          opportunityOwnedBy: this.oppOwnerId,
          opportunityOwnedByName: this.oppOwnerName,
          agreementId: this.agreementId,
          userId: this.initiationService.empId
        };
        this.initiationService.verifyRevisionOta(reqBody).pipe(
          switchMap((res : any) => {
            this.resetRevisionValidation();
            if(res.Otp_Status !== "Not A Match") {
              this.revisionService$.next(); // refresh data
            } else if (res.Otp_Status === "Not A Match") {
              this.messageService.add({ severity: 'warn', summary: 'Please enter correct OTA' });
              return of(undefined);
            };
            return of(res);
          })
        ).subscribe((res: any) => {
          if(res?.Otp_Status) {
            this.ref.close("data saved successfully");
          }
        }, (err) => {
          this.apiFail = true;
        })
      } else {
        this.errorMessageSave = "Revision";
        this.errorMsgPopup = true;
        this.errorRevision = true;
      }
      return;
    }
    if(this.refNo){
      let payload = {};
      this.isSaveClicked = true;
      this.errorMessageSave = [];
      let otherKeyError = false;
      if (this.sendSplitOta) {
        if (this.otherKeyForm?.value?.ota == null || this.otherKeyForm?.value?.ota == "") {
          otherKeyError = true;
          let index = this.errorMessageSave.findIndex(e => e == 'Other Key Details');
          if (index == -1) {
            this.errorMessageSave.push('Other Key Details');
          }
          this.otherKeyForm.get('ota').addValidators(Validators.required);
          this.otherKeyForm.get('ota').updateValueAndValidity();
          this.otherKeyForm.controls.ota.markAsTouched();
          this.otherKeyForm.controls.ota.markAsDirty();
          

        } else {
          otherKeyError = false;
          let index = this.errorMessageSave.findIndex(e => e == 'Other Key Details');
          if (index != -1) {
            this.errorMessageSave.splice(index, 1);
          }
        }
        payload = {
          "opportunityId": this.oppGridDetials?.opportunityId,
          "ota": this.otherKeyForm?.value?.ota,
          "refNo": this.refNo,
          "employeeId": this.empId
        };
      }
      if (this.sendCaseCloseOta) {
        this.businessActionForm.markAllAsTouched();
        this.markFormDirty(this.businessActionForm);
        if (this.businessActionForm.invalid || this.businessActionForm?.value?.ota == null || this.businessActionForm?.value?.ota == "") {
          this.errorBusinessAction = true;
          let index = this.errorMessageSave.findIndex(e => e == 'Action');
          if (index == -1) {
            this.errorMessageSave.push('Action');
          }
          this.businessActionForm?.controls?.ota.markAsDirty();
          this.businessActionForm.get('ota').addValidators(Validators.required);
          this.businessActionForm.get('ota').updateValueAndValidity();
          this.businessActionForm.controls.ota.markAsTouched();
          this.businessActionForm.controls.ota.markAsDirty();
        } else {
          this.errorBusinessAction = false;
          let index = this.errorMessageSave.findIndex(e => e == 'Action');
          if (index != -1) {
            this.errorMessageSave.splice(index, 1);
          }
        }
        if(this.isExpired(this.otaExpiryTime)){
          this.otaExpiryAction = true;
          this.otaExpire();
          this.messageService.add({ severity: 'error', summary: 'OTA Expired. Resend OTA' });
          setTimeout(() => {
            this.messageService.clear();
          }, 3000);
        }
        payload = {
          "opportunityId": this.oppGridDetials?.opportunityId,
          "ota": this.businessActionForm?.value?.ota,
          "refNo": this.refNo,
          "employeeId": this.empId
        };
      }

      if (this.errorBusinessAction || otherKeyError) {
        this.otaErrorMsgPopup = true;
      }

      if(!this.errorBusinessAction && !otherKeyError && !this.otaExpiryAction){
        this.initiationService.validateOta(payload).subscribe((res) => {
          if (res) {
            if (res?.otaMsg == "Success") {
              this.messageService.add({ severity: 'success', summary: 'OTA Validated Successfully' });
              this.saveInitiation();
              this.disableSplit = true;
              this.enableSplitOta = false;
              this.enableCancelOtaBtn = false;
              
              //success msg pop
            } else {
              //error msg popup
              this.messageService.add({ severity: 'error', summary: 'OTA Did Not Match' });
            }
            setTimeout(() => {
              this.messageService.clear();
            }, 3000);
          }
        });
      }
      
    } else{
      this.saveInitiation();
    }
    
  }

  isExpired(expirationTime: number): boolean {
    const currentTime = new Date().getTime();
    return currentTime >= expirationTime;
  }

  otaExpire(){
    let refNo = null;
    let moduleId = null;
    if (this.businessActionForm.value.businessAction == '131') {
      refNo = this.refNo;
      moduleId = 123;
    }
    this.cancelOtaRequestPayload = {
      "opportunityId": this.oppGridDetials?.opportunityId,
      "refNo": refNo,
      "actionedBy": null,
      "moduleId": moduleId
    }
    this.disableSaveBtn = true;
    this.initiationService.cancelOta(this.cancelOtaRequestPayload).subscribe((res) => {
      this.otaResponse = res;
      this.enableCancelOtaBtn = false;
      this.enableSendOtaBtn = true;
      if (this.businessActionForm.value.businessAction == '131') {
        this.disableReason = false;
      }
      this.sendCaseCloseOta = false;
      this.sendSplitOta = false;
      this.disableBusinessAction = false;
      this.businessActionForm.get('comment').enable();
      this.enableOtaAttachmentMsg = false;
      this.disableOta = false;
      this.enableOta = false;
      this.disableAllFileds = false;
      this.disableComment = false;
      this.refNo = null;
      this.enableSplitOta = false;
    });
  }

  createSavePayload() {

    let attachmentPayload = this.attachmentResult.filter(item => !this.otaAttachment.includes(item));
    //create payload
    let reasonId = this.businessActionForm?.value?.reason;
    let businessActionId = this.businessActionForm?.value?.businessAction;
    let reasonData = this.reasonList.find((reason) => reason?.id == reasonId);
    let businessAction = this.businessTypeList.find((businessAction) => businessAction?.id == businessActionId);
    let comboOpportunityDetails = null;

    //129 - percent
    //130 - value
    let team2Split;
    if(this.otherKeyformControls?.proposedSplit?.value == 129){
      team2Split = 100 - Number(this.otherKeyForm?.value?.team1);
    } else {
      team2Split = Number(this.otherKeyForm?.value?.irfActualFee) - Number(this.otherKeyForm?.value?.team1);
    }

    if (this.secondaryOpportunityId) {
      comboOpportunityDetails = {
        "primaryOpportunityId": this.oppGridDetials?.opportunityId,
        "secondaryOpportunityId": this.secondaryOpportunityId,
        "primaryIrfActualFee": this.comboDetailsForm?.value?.mainIrfFee,
        "secondaryIrfActualFee": this.comboDetailsForm?.value?.comboIrfFee,
        "totalIrfActualFee": this.comboDetailsForm?.value?.comboTotalIrfFee,
        "primarySfActualFee": this.comboDetailsForm?.value?.mainSfFee,
        "secondarySfActualFee": this.comboDetailsForm?.value?.comboSfFee,
        "totalSfActualFee": this.comboDetailsForm?.value?.comboTotalSfFee,
        "createdBy": this.empId,
        "modifiedBy": this.empId,
        "id":this.comboProductId
      }
    }

    let splitValue = null;

    if (this.otherKeyForm?.value?.split) {
      splitValue = [
        {
          "opportunityId": this.oppGridDetials?.opportunityId,
          "isSplit": this.otherKeyForm?.value?.split,
          "splitType": this.otherKeyForm?.value?.proposedSplit,
          "splitValue": Number(this.otherKeyForm?.value?.team1),
          "splitTeamId": 1,
          "proposedSplit": this.otherKeyForm?.value?.splitTeam1,
          "createdBy": this.empId,
          "modifiedBy": this.empId,
          "teamDetails": this.bdHierarchyData,
          "teamOwnerId":this.oppOwnerId,
          "id": this.splitTeam1Id,
        }, {
          "opportunityId": this.oppGridDetials?.opportunityId,
          "isSplit": this.otherKeyForm?.value?.split,
          "splitType": this.otherKeyForm?.value?.proposedSplit,
          "splitValue": team2Split,
          "splitTeamId": 2,
          "proposedSplit": this.otherKeyForm?.value?.splitTeam2,
          "createdBy": this.empId,
          "modifiedBy": this.empId,
          "teamDetails": this.team2HierarchyData,
          "teamOwnerId":this.otherKeyForm?.value?.team2Owner,
          "id":this.splitTeam2Id
        }
      ]
    }

    this.initiationSavePayload = {
      "opportunityId": this.oppGridDetials?.opportunityId,
      "secondaryOpportunityId": this.secondaryOpportunityId,
      "deleteAttachmentIds": this.attachToDelete,
      "qcStatus": null,
      "createdBy": this.empId,
      "modifiedBy": this.empId,
      "otherKeyDetails": {
        "mandateDate": this.otherKeyForm?.value?.mandateDate,
        "originalMandateDate": null,
        "clientSignedDate": this.otherKeyForm?.value?.clientSignedDate,
        "crisilSignedDate": this.otherKeyForm?.value?.crisilSignedDate,
        "mandateSentClientDate": this.otherKeyForm?.value?.sentToClient,
        "mandateReceivedClientDate": this.otherKeyForm?.value?.recievedFromClient,
        "mandateType": this.otherKeyForm?.value?.mandateType,
        "feedbackForQC": this.otherKeyForm?.value?.feedbackQc,
        "priorityForQC": this.otherKeyForm?.value?.priorityQc,
        "irfActualFees": this.otherKeyForm?.value?.irfActualFee,
        "masterServiceId": this.productServiceList?.masterServiceId, // send id
        "ratableUnitId": this.productServiceList?.ratableUnitId, // send id
        "subServiceId": this.productServiceList?.subServiceId, // send id
        "ope": this.otherKeyForm?.value?.opeType,
        "caseClassificationId": this.otherKeyForm?.value?.caseClass,
        "comment": this.otherKeyForm?.value?.comment,
        "businessAction": businessAction?.property,
        "secondaryOppProductId": this.secondaryOpportunityId,
        "opeAmount": Number(this.otherKeyForm?.value?.opeAmount),
        "currencyId": this.otherKeyForm?.value?.currency,
        "invoiceValue": this.otherKeyForm?.value?.invoiceVal,
        "previousAgreementId": ((this.entityDetailsForm.get('initType').value != 119 && this.otherKeyForm.get('previousLimit').value.type=='INPROGRESS')?this.limitDetails.prevInprogressAgreementId:null),
        // "applicableAmount": ((this.entityDetailsForm.get('initType').value != 119)?this.otherKeyForm?.get('applicableAmount').value:null),
        "currentLimit": ((this.entityDetailsForm.get('initType').value != 119)?this.otherKeyForm?.get('currentLimit').value:null),
        "previousLimit": ((this.entityDetailsForm.get('initType').value != 119)?this.otherKeyForm.get('previousLimit').value.label:null),
        "amcCode" : this.otherKeyForm?.value?.amcCode, // MF related
        "amcName": this.otherKeyForm?.value?.amcName?.value, // MF related
	      "parentId": this.otherKeyForm?.value?.mfParentId, // MF related
      	"commonSchemeName" :this.otherKeyForm?.value?.mfSchemeName?.value,  // MF related
      },
      "splitRevenue": splitValue,
      "comboProductDetails": comboOpportunityDetails,
      "agreementClassificationId": this.agreementClass?.value?.id,
      "crmContactid": this.entityCrmContact?.contactId,
      "tripartyCrmContactId": this.triCrmContact?.contactId || null,
      "isFeeCap": this.feeCapForm.get('tagMandate')?.value != null ? (
        this.feeCapForm.get('tagMandate').value == 'Yes' ? true : false) 
      : null,
      "tdsNumber": this.entityCrmContact?.tdsNumber,
      "triPartyTdsNumber": this.triCrmContact?.tdsNumber || null,
      "attachments":[],
      "reason": reasonData?.property || null,
      "comment": this.businessActionForm?.controls?.comment?.value,
      "reason1": this.businessAction?.value?.reason,
      "opportunityStatus": businessAction?.property,
      "opportunityStatusId": businessActionId,
      "reasonID": reasonId,
      "billType": this.entityDetailsForm?.value?.initType,
      "companyCode": this.oppGridDetials?.companyCode,
      "companyName": this.oppGridDetials?.name,
      "opsUserName": this.empName,
      "product": this.productName,
      "sfMandateValidityId": this.otherKeyForm?.value?.sfMandateValidity,
      "opsReasonId": this.businessActionForm?.value?.opsReaseon
    };
    if(this.enableResubmit && this.isQcRejected){
      this.initiationSavePayload.qcStatus = 135;
    }
  }

  downloadFile() {
    let linkSource: any;
    if (this.openedFile?.fileContentType?.includes('image')) {
      let mime = this.openedFile?.file?.split(',')[0];
      linkSource = `${mime},${this.urlSafe?.split(',')[1]}`;
    } else {
      linkSource = this.urlSafe;
    }
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this.openedFile?.fileName?.split('.')[0];
    downloadLink.click();
  }
  
  showAttachmentPreview(item) {
    this.openedFile = item;
    this.currentPdf = undefined;
    this.zoomValue = 1;
    this.rotate = 0;

    if (item?.fileId) {
      this.previewLoader = true;
      let payload = {
        id: item.fileId
      }
      this.pageService.findPDF(payload).subscribe((res) => {
        if (res && res?.file) {
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          let isPreviewable = true;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else if ( "msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
            isPreviewable = false;
          } else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.urlSafe = `${mime}${res.file}`
          if (!this.isPdf) {
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.displayPreview = isPreviewable;
          if(!isPreviewable) {
            this.pageService.downloadFile(res, this.urlSafe);
          }
          this.previewLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      });
    }
  }

  enableAllAttach(){
    this.documentList?.forEach((d) => {
      d.isDisabled = false;
    });
  }

  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    if(el){
      el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
    }
  }

  cancelApproverPop(){
    this.approverPopup=false;
    this.enableSendOtaBtn = true;
  }

  getMFScheme(event) {
    if(event?.value) {    
      this.initiationService.showLoader('getMFNames');
      const amcCode = event?.value?.code || event?.value;
      if(amcCode) {
        this.otherKeyForm.patchValue({amcCode})
        this.apiCallList.push(this.initiationService.getMFName(amcCode).subscribe({
          next : (response : Array<any>) => {
            if(response?.length > 0) {
              this.mfList = [];
              if(!this.selectedMFParentId)  // first time mf scheme name
                response.forEach(item => {
                  this.mfList.push({label : item.commonSchemeName, value : item.commonSchemeName, code : item.parentId })
                });
              else {
                // already saved mf parentid before
                // if not rejected, then dont filter
                let prevFields = [];
                if(this.qcRejectMap['mutualFundSchemeName']) {
                  prevFields = response.filter(item => item.parentId !== this.selectedMFParentId)
                }
                response = response.filter(item => item.parentId === this.selectedMFParentId);

                response.forEach(item => {
                  this.mfList.push({label : item.commonSchemeName, value : item.commonSchemeName, code : item.parentId })
                });
                if(this.mfList.length > 0)
                  this.otherKeyForm.patchValue({mfSchemeName : this.mfList[0]})
                this.mfList = this.mfList.concat(prevFields.map(item => ({label : item.commonSchemeName, value : item.commonSchemeName, code : item.parentId })));
              }
            }
            this.initiationService.hideLoader('getMFNames');
          },
          error : (err) => {
            this.initiationService.hideLoader('getMFNames');
            this.messageService.add({ severity: 'error', summary: 'Can`t fetch Mf scheme Names' });
            setTimeout(() => {
              this.messageService.clear();
            }, 4000);
          }
        }));
      }
    }
  }

  setMFParentId(event) {
    if(event?.value) {
        this.otherKeyForm.patchValue({mfParentId : event.value.code});
    }
  }

  downloadInvoice(invoiceNo : string) {
    if(this.cachedInvoiceDoc?.[invoiceNo]) {
      this.downloadPdfUrl(this.cachedInvoiceDoc?.[invoiceNo]?.url, this.cachedInvoiceDoc?.[invoiceNo]?.name);
      return;
    }
    this.previewLoader = true;
    this.pageService.getInvoiceDocForActionables(invoiceNo, "IRF").subscribe((res : any) => {
      if(res) {
        this.previewLoader = false;
        this.downloadPdfUrl(res?.invoiceBase64, res?.invoiceName);
        this.cachedInvoiceDoc = {...this.cachedInvoiceDoc, [res?.invoiceNumber] : {url : res?.invoiceBase64, name : res?.invoiceName}}
      }
    }, (err) => {
      this.previewLoader = false;
      this.apiFail = true;
    });
    
  }

  downloadPdfUrl(url, name) {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = this.pdfMime + url;
    downloadLink.target = '_self';
    downloadLink.download = name;
    downloadLink.click(); 
    this.messageService.add({key : 'invoiceAckMsg', severity: 'success', detail: 'Invoice downloaded successfully' });
    setTimeout(() => {
        this.messageService.clear('invoiceAckMsg');
    }, 2000);
  }
  
  previewAttachment(attachData: any) {
    let invoiceNo = attachData?.invoiceNumber;
    this.currentPdf = attachData;
    
    this.zoomValue = 1;
    this.rotate = 0;
    this.isPdf = true;

    if(this.cachedInvoiceDoc?.[invoiceNo]) {
      this.urlSafe = this.pdfMime + this.cachedInvoiceDoc?.[invoiceNo]?.url;
      this.displayPreview = true;
      return;
    }
    this.previewLoader = true;
    this.pageService.getInvoiceDocForActionables(invoiceNo, "IRF").subscribe((res : any) => {
      if(res) {
        this.cachedInvoiceDoc = {...this.cachedInvoiceDoc, [res?.invoiceNumber] : {url : res?.invoiceBase64, name : res?.invoiceName}};
        this.urlSafe = this.pdfMime + this.cachedInvoiceDoc?.[res?.invoiceNumber]?.url;
        this.displayPreview = true;
      }
    }, (err) => {
      this.apiFail = true;
      this.displayPreview = false;

    });
  }
  
  mapContact(contact: any): any {
    if(!contact)  return null;
    return {
      salutation: contact?.salutation,
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      designation: contact?.designation,
      addressLine1: contact?.address1 || contact?.addressLine1,
      addressLine2 : contact?.address2 || contact?.addressLine2,
      addressLine3: contact?.address3 || contact?.addressLine3,
      addressLine4 : contact?.address4 || contact?.addressLine4,
      pincode :contact?.pincode,
      city : contact?.city,
      state : contact?.state,
      gstNo : contact?.gstNo,
      gstType : contact?.gstType,
      crmContactId: contact?.crmContactId,
      mobileNo : contact?.mobileNo || contact?.mobileNumber || contact?.mobileNum,
      landlineNo : contact?.landlineNo || contact?.landlineNumber,
      email : contact?.email,
      panNumber : contact?.panNumber,
      id : contact?.id
    }
  }

  cancelAllChange() {

  }

  noteToManagerRevisionEntered() {
    this.enableSendOtaBtn = true;
    this.doRevision = true;
    this.showRevisionAddButton = true;
  }

  cancelInvoice(ev) {
    if(ev?.checked) {
      this.runtimeCancel = true;
      this.revisionForm.patchValue({revisedInvoiceVal : '0', newContact : null, billDetail : this.revisionForm.get('oldBillDetail').value});
      this.revisionForm.get('billDetail').removeValidators([Validators.required, Validators.minLength(6)]);
      
    } else {
      this.revisionForm.patchValue({revisedInvoiceVal : null});  
      this.runtimeCancel = false;
      this.revisionForm.get('billDetail').addValidators([Validators.required, Validators.minLength(6)]);
    }
    this.revisionForm.get('billDetail').updateValueAndValidity();
  }

  showAttachmentModal() {
    this.dynamicDialogdialogRef = this.dialogService.open(AttachmentModelComponent, {
      contentStyle: { 'max-height': '550px', 'overflow': 'visible' },
      header: 'Upload Attachment',
      closable: true,
      data: { list: this.revisionAttachmentList, type : '.msg' },
      styleClass: 'sm',
    });
    this.dynamicDialogdialogRef.onClose.subscribe(response => {
      if (response?.length > 0) {
        this.revisionFileItem = {...response[0], newRevisionFileitem : true, moduleId : this.agreementId, moduleName : "Revision"};
        this.documentList.push(this.revisionFileItem);
        this.revisionForm.patchValue({ attachment: this.revisionFileItem });
        this.showRevisionAddButton = false;      
        this.errorAttachment = false;
      }
    });
  }

  getMandateValidityList(){
    this.initiationService.mandateValidity().subscribe((res) => {
      this.mandateValidityList = res;
    });
  }

  private createRevisionPayload(formVal: any, contact: any, isNonOFA: boolean, revisedVal: any, oldvNew: {}) {
    let isProduct = null;
    if(this.caseProduct == 154 || this.caseProduct == 157){
      isProduct = "MA";
    } else{
      isProduct = "Non-MA";
    }
    return {
      isCancel: formVal?.isCancel,
      // agreementId: this.agreementId,
      agreementId: this.agreementId,
      advInvoiceNo: formVal?.invoiceNo + "",
      advIRFBillId: formVal?.irfBillId,
      contactDetails: {
        crmContactId: contact?.crmContactId,
        addressLine1: contact?.addressLine1,
        addressLine2: contact?.addressLine2,
        gstType: contact?.gstType,
        gstNo: contact?.gstNo,
        salutation: contact?.salutation,
        city: contact?.city,
        state: contact?.state,
        pincode: contact?.pincode,
        email: contact?.email,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        addressLine3: contact?.address3,
        addressLine4: contact?.address4,
        mobileNo: contact?.mobileNo,
        landlineNo: contact?.landlineNo,
        designation: contact?.designation,
        panNumber: contact?.panNumber
      },
      lineDescription: formVal?.billDetail,
      reasonForRevisionId: formVal?.reasonForrevision,
      noteToManager: formVal?.noteToManager,
      isNonOFA: isNonOFA,
      revisedAmount: revisedVal,
      attachmentsDataList: [this.revisionFileItem],
      opsUserId: this.initiationService.empId,
      oldvsNew: oldvNew,
      approverUserId: this.approverName,
      companyName : this.oppGridDetials?.name,
      isMARevision: isProduct
    };
  }

  deleteMultipleFile(){
    this.pageService.deleteMultipleFiles(this.uploadedFileIds).subscribe((res) => {
    });
  }

  uploadSingleFile(data){
    this.uploadLoader = false;
    if(data?.fileTypeid == 45 || data?.fileTypeid == 42){
      return;
    }
    this.initiationService.singleFileUpload(data).subscribe((response) => {
      let obj = this.documentList?.find((d) => d?.fileName == data?.fileName);
      obj.deleteId = response?.fileId;
      obj.attachmentId = response?.fileId;
      obj.fileId = response?.fileId;
      this.uploadedFileIds.push(response?.fileId);
    }, (_err) => {
      let obj = this.documentList?.find((d) => d?.fileName == data?.fileName);
      obj.file = '';
      obj.deleteId = null;
      obj.isDisabled = false;
      obj.fileName = '';
      obj.thumbNail = '';
      this.messageService.add({ severity: 'error', summary: 'something went wrong please try again!' });
    });
  }

  getOpsReasonList() {
    this.apiCallList.push(this.initiationService.opsReasonMaster().subscribe((res) => {
      if (res && res.length > 0) {
        this.opsReasonList = res;
      }
    }));
  }
  
}

