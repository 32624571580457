import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportscopeGuard  {

  constructor(private encryptDecryptInterceptor : EncryptDecryptInterceptor, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let scopes = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
      if ((scopes.indexOf('REPORT_CREATE') != -1) || scopes.indexOf('BILLING_REPORT_READ') != -1 || scopes.indexOf('BANKER_MEETINGS_SHADOW_PORTFOLIO_READ') != -1 || scopes.indexOf('BANKER_MEETINGS_PORTFOLIO_READ') != -1 || scopes.indexOf('ANALYTICAL_REPORT_READ') != -1) {
        // if ((scopes.indexOf('REPORT_CREATE') != -1) || scopes.indexOf('BILLING_REPORT_READ') != -1 ) {

      return true;
      } else {
        this.router.navigate(['/empty']);
        return false;
      }
  }
  
}
