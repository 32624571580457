import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DOMAIN_ROUTE } from 'app/app.constants';
import {APIService} from '../api.service';
import { Subject, forkJoin, of } from 'rxjs';
import { takeUntil, switchMap, tap } from 'rxjs/operators';
import { PagesService } from '../../pages/pages.service';
import { InitiationService } from 'app/initiation/initiation.service';
@Component({
  selector: 'backoffice-qc-details',
  templateUrl: './qc-details.component.html',
  styleUrls: ['./qc-details.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class QcDetailsComponent implements OnInit, OnDestroy {
  readonly QC_APPROVED = "QC Approved";
  readonly QC_REJECTED = "QC Rejected";
  readonly CASE_CLOSED = "Case Closed";

  // global - modal variables -- start
  checkList: any; // master checklist data
  opportunityDetails; // oppr table data
  crmContact; // crm contact details
  agreementDetails; // agreement table data
  previousOpportunity; // previous opprotunity details
  limit
  allClientType: Array<any>; // master - all client types
  triPartyOppr; 
  triPartyCRMContact;
  userHierarchy;
  comboDetails;
  clientClassification;
  mandateValidityList = []; //SF Mandate Validity details list

  activeIndex: number = 0;  // tab activated index
  opportunityId;
  agreementId;
  qcStatus = "";
  initiatedDate ;
  crmCode;
  BDUser ;
  submittedData;
  sendData = false;
  disableTriPartyTab: boolean = false;
  disableComboTab: boolean = false;
  
  disableAllFields = false;
  readOnlyState = false;
  // -- end
  
  // --start error popup on final save
  showAlertPopUp = false;
  alertFinalSavePopUpmsg : string;
  errorModules : Set<any>;
  // -- end


  // globalListItem structure
  listItem = {isApproved : null, comments : null};
  allTabKeys = [];

  globalForm = this.fb.group({
    dateDetails : this.fb.group({
      id : ['dateDetails'],
      name : ['Date details'],
      list : this.fb.array([]),
    }),
    mandateDetails : this.fb.group({
      id : ['mandateDetails'],
      name : ['Mandate details'],
      list : this.fb.array([]),
    }),
    triPartyDetails : this.fb.group({
      id : ['triPartyDetails'],
      name : ['Tri-Party details'],
      list : this.fb.array([]),
    }),
    productDetails : this.fb.group({
      id : ['productDetails'],
      name : ['Product details'],
      list : this.fb.array([]),
    }),
    paymentDetails : this.fb.group({
      id : ['paymentDetails'],
      name : ['Payment details'],
      list : this.fb.array([]),
      comboList: this.fb.array([]),
      primaryOpportunityName:[null as any],
      secondaryOpportunityName:[null as any]
    }),
    costCenterDetails : this.fb.group({
      id : ['costCenterDetails'],
      name : ['Cost Center'],
      list : this.fb.array([]),
    }),
    portfolioDetails : this.fb.group({
      id : ['portfolioDetails'],
      name : ['Portfolio details'],
      list : this.fb.array([]),
    }),
    comboProductDetails : this.fb.group({
      id : ['comboProductDetails'],
      name : ['Combo Product'],
      list : this.fb.array([]),
    }),
    feeCapDetails : this.fb.group({
      id : ['feeCapDetails'],
      name : ['Fee Cap details'],
      list : this.fb.array([]),
    }),
    auditLogDetails : this.fb.group({
      id : ['auditLogDetails'],
      name : ['Audit Log'],
      list : this.fb.array([]),
    })
  });

  private ngOnUnSubscibe : Subject<any>;
  savedData: any = [];
  opprStatus: any;
  highPriority: boolean = true;
  limitData: any;
  rowfeedback: any;
  mandateStatus: string;
  sfFeeRuleList: any;
  sfFeeRuleName: any;
  apiFail: boolean;
  showSaveAlertPopUp: boolean;
  savePayload;
  currentCaseStatus = false;  // QC reject
  attachmentList: any;
  mandateAttachmentType: string;
  alertMsg = "";
  disableSave = false;
  isSendToROW = true;
  /* UPDATED DOCUMENT / ADDITIONAL PAYMENT SCENARIO related variables  */
  isAdditionalData = true;
  
  constructor(private router: Router, private pagesService: PagesService, private initiationService : InitiationService, private fb: FormBuilder, public config: DynamicDialogConfig, private messageService: MessageService, private encDec: EncryptDecryptInterceptor, private apiService: APIService, private ref: DynamicDialogRef, private cd : ChangeDetectorRef) { }


  ngOnInit(): void {
    this.ngOnUnSubscibe = new Subject();
    this.apiFail = false;
    this.readOnlyState = false;
    console.log("this.config.data",this.config.data);
    
    if(this.config.data.qcDetails==null || this.config.data.qcDetails==undefined){
      this.opportunityId = this.config.data?.opportunityId;
      this.agreementId = this.config.data?.agreementId;
    }else{
      this.opportunityId = this.config.data?.qcDetails?.opportunityId;
      this.agreementId = this.config.data?.qcDetails?.agreementId;
    }
    this.qcStatus = this.config.data?.qcDetails?.qcStatus;
    this.opprStatus = this.config.data?.qcDetails?.caseStatus;
    this.isAdditionalData = this.config?.data?.additionalScreen;
    this.disableAllFields = this.qcStatus === this.QC_APPROVED || this.qcStatus === this.QC_REJECTED || this.opprStatus === this.CASE_CLOSED || this.isAdditionalData;
    if(!this.config.data.qcDetails){
      this.disableAllFields = true;
    }
    if(this.disableAllFields)
      this.disableSave = true;

    let scope = this.apiService.scopes;
    if (scope.indexOf('QC_CREATE') == -1 || this.opprStatus === this.CASE_CLOSED || (this.disableAllFields && !this.isAdditionalData)) {  // make everything disabled if
      // 1 - only read scope
      // 2 - user actioning QC Approved case from Normal QC listing
      // 3 - case close
      this.disableAllFields = true;
      this.disableSave = true;
      this.readOnlyState = true;
    }

    this.submittedData = false;  
    
    this.highPriority = !!((this.qcStatus === "QC Pending" || this.qcStatus === "Resubmitted for QC") && this.config?.data?.qcDetails?.priorityForQc === "High");

    
    this.initiationService.showLoader('getQCDetails');
    // this.getQcDetails();
    this.getQCAgreementDetails();
    this.getMandateValidityList();
  }
  
  ngOnDestroy(): void {
      this.ngOnUnSubscibe.next();
      this.ngOnUnSubscibe.complete();
  }

  getMandateValidityList(){
    this.apiService.mandateValidity().subscribe((res) => {
      this.mandateValidityList = res;
    });
  }

  getQCAgreementDetails(){
    this.apiService.getQCDetailsAgreement(this.agreementId).subscribe((qcAgreementDetails)=>{
      this.agreementDetails = qcAgreementDetails;
      if(this.config.data?.qcDetails?.opportunityId != undefined && this.config.data?.qcDetails?.opportunityId != null && this.config.data?.qcDetails?.opportunityId == this.agreementDetails.opportunityIdSecondary){
        this.opportunityId = this.agreementDetails?.opportunityIdPrimary;
      }

      this.getQcDetails();
    });
  }

  //removed the called for this.apiService.getQCDetailsAgreement(this.agreementId), in getQCDetails

  getQcDetails() {
    this.sendData = false;
    forkJoin([
      this.apiService.getMasterData(this.agreementId),
      this.apiService.getQCDetailsOpportunity(this.opportunityId),
      this.apiService.getAllClientType(),
      this.pagesService.getCombineMaster()
    ])
    .pipe(switchMap((res : Array<any>) => {
      [this.checkList, this.opportunityDetails, this.allClientType, this.sfFeeRuleList] = res;
      this.initiatedDate = this.agreementDetails?.initiatedDate;
      this.qcStatus = this.qcStatus || this.agreementDetails?.qcStatus
      this.allTabKeys = Object.keys(this.globalForm.value);
      this.crmCode = this.opportunityDetails?.entity?.companyCode;
      this.sfFeeRuleName = this.sfFeeRuleList?.find((r) => r.id === this.opportunityDetails?.feeDetails?.sfFeeRuleId)?.value;
      this.BDUser = this.opportunityDetails?.opportunity?.opportunitynexeraUser;
      

      const apiList = [];

      this.isSendToROW = !(this.qcStatus === this.QC_APPROVED && this.agreementDetails?.sentToRow == '116'); 
      console.log('issendtorow' + this.isSendToROW)
      // DONT CHANGE ORDER OF API-PUSH BELOW -
      // 1 - crm contact
      if(this.opportunityDetails?.opportunity?.crmContactId) {
        apiList.push(this.pagesService.getCrmCotactById({contactId: this.opportunityDetails?.opportunity?.crmContactId}));
      } else apiList.push(of(undefined));

      // 2 - triparty type oppr
      if(this.opportunityDetails?.opportunity?.triPartyEntityId) {
        this.disableTriPartyTab = false;
        apiList.push(this.apiService.getEntityDetails(this.opportunityDetails?.opportunity?.triPartyEntityId, this.opportunityDetails?.opportunity?.userId));
      } else {
        this.disableTriPartyTab = true;
        apiList.push(of(undefined));
      }

      // 3 - triparty crmContact
      if(this.opportunityDetails?.opportunity?.tripartyContactId) {
        apiList.push(this.pagesService.getCrmCotactById({contactId: this.opportunityDetails?.opportunity?.tripartyContactId}));
      } else apiList.push(of(undefined));

      // 4 - client classification
      if(this.opportunityDetails?.entity?.companyCode) {
        apiList.push(this.apiService.getClientClassificationData(this.opportunityDetails?.entity?.companyCode));
      } else apiList.push(of(undefined));

      // 5 - user hierarchy
      apiList.push(this.initiationService.getUpdatedUserHierarchy(this.opportunityDetails?.opportunity?.id, "Business Won"));
    

      // 6 - combo - get secondary opportunity details
      if(this.agreementDetails?.opportunityIdSecondary) {
        this.disableComboTab = false;
        let secondaryOppr = this.agreementDetails.opportunityIdSecondary != this.opportunityId ? this.agreementDetails.opportunityIdSecondary : this.agreementDetails.opportunityIdPrimary;
        apiList.push(this.apiService.getQCDetailsOpportunity(secondaryOppr));
      } else {
        this.disableComboTab = true;
        apiList.push(of(undefined));
      }

      // 7 - previous opportunity details
      if(this.agreementDetails?.previousOpportunityId) {
        apiList.push(this.apiService.getQCDetailsOpportunity(this.agreementDetails?.previousOpportunityId, 'Opportunity'));
      } else apiList.push(of(undefined));

      // 8 - Limit details
      // if case alternate, dont show limit details
      if(this.agreementDetails?.initiationType?.toLowerCase() !== "alternate" && this.opportunityDetails?.entity?.companyCode && this.agreementDetails?.masterServiceId && this.agreementDetails?.subServiceId && this.agreementDetails?.ratableUnitId) {
        apiList.push(this.apiService.getLimitData(this.opportunityDetails?.entity?.companyCode, this.agreementDetails?.masterServiceId, this.agreementDetails?.subServiceId, this.agreementDetails?.ratableUnitId, this.agreementId, this.opportunityDetails?.opportunity?.instrumentId));
      } else apiList.push(of(undefined));

      // 8 - Limit details
      // if case alternate, dont show mandate status of row feedback
      if(this.agreementDetails?.initiationType?.toLowerCase() !== "alternate" && this.agreementId && this.opportunityDetails?.entity?.companyCode) {
        apiList.push(this.apiService.getRowFeedBack("" + this.agreementId , this.opportunityDetails?.entity?.companyCode));
      } else apiList.push(of(undefined));

      // 9 - attachment list (why not in attach-preview ? => cus mandate type needs attachment list)
      if(this.opportunityDetails?.entity?.id && this.opportunityId) {
        apiList.push(this.apiService.getAttachmentList(this.opportunityId, this.opportunityDetails?.entity?.id))
      } else apiList.push(undefined);

      return forkJoin(apiList);
    }), tap((res : Array<any>) => {

      [this.crmContact, this.triPartyOppr, this.triPartyCRMContact, this.clientClassification, this.userHierarchy, this.comboDetails, this.previousOpportunity, this.limitData, this.rowfeedback, this.attachmentList] = res;

      // set client type
      if(this.clientClassification) {
        this.clientClassification = this.allClientType['SUCCESS'].find(res => res.id === this.clientClassification.clientTypeId);
      }

      // format hierarchy
      this.userHierarchy = this.userHierarchy?.finalHierarchyString?.split('->').join(' -> ');

      // set limit data
      if(this.agreementDetails?.initiationType?.toLowerCase() !== "alternate") {
        if(this.previousOpportunity?.opportunity?.id) { // if previous oppr exists
          this.limitData = {
            previousCaseApplicableAmount : this.previousOpportunity?.opportunity?.quantum,
            inProgressAmt : this.previousOpportunity?.currentLimit,
            previousLimit : this.agreementDetails?.previousLimit,
            outStandingAmt : this.limitData?.data?.outstandingAmount,
            clientRatingStatus : this.limitData?.data?.mandateStatus
          };
        } else {
          var difference = Number(this.opportunityDetails?.opportunity?.quantum) - Number(this.agreementDetails?.applicableAmount);
          if(typeof difference === "number") {
            this.limitData = {
              previousCaseApplicableAmount : 0,
              inProgressAmt : 0,
              previousLimit : this.agreementDetails?.previousLimit,
              outStandingAmt : this.limitData?.data?.outstandingAmount,
              clientRatingStatus : this.limitData?.data?.mandateStatus
            };
          }
        }
      }
      
      //set mandate status
      if(this.rowfeedback && this.rowfeedback.statusCode==200) {
        this.mandateStatus = this.rowfeedback.data.mandateStatus;
      }

      let opsAttachmentList = this.attachmentList?.Response["Ops Related Attachment"];
      // set original/fax mandate - mandate type
      if(opsAttachmentList?.length > 0) {
        const mandateAttachment = opsAttachmentList.find(item => item.fileTypeName === "Original Mandate");
        this.mandateAttachmentType = mandateAttachment ? "Original Mandate" : "Fax Mandate";
      }

    }) ,takeUntil(this.ngOnUnSubscibe)).subscribe({
      next : () => {

        this.sendData = true;
        this.mergeData();
        
        this.initiationService.hideLoader('getQCDetails');
      },
      error : (err) => {
        this.apiFail = true;
        console.error("Error while fetching details", err);
        this.initiationService.hideLoader('getQCDetails');
      }
    })
  }
  // preview screen feature
  onPreviewScreen(){
    console.log("Preview button is clicked for QC");
    let previewDtata ={
      "opportunityId":this.opportunityId,
      "opportunityStatus":this.opprStatus,
      "businessAction":this.opprStatus,
    }
    const encPreviewData = this.encDec.encryptLocalObject(previewDtata);

    localStorage.setItem('previewData', encPreviewData);
    let previewUrl = DOMAIN_ROUTE+"#/backoffice/pages/preview";
    const newWindow = window.open(previewUrl, '_blank');
  }
  
  closeDetailPopup(){
    this.ref.close();
  }

  sentToROWButton(){
    
    
  }

  closeSaveAlertPopUp() {
    this.showSaveAlertPopUp = false;
  }
  
  generateOta(){}

  cancelOta(){}

  finalSaveQCDetails(){
    this.cd.detectChanges();
    this.submittedData = true;
    this._markFormDirty();
    this.errorModules = new Set();
    this.showAlertPopUp = false;
    this.alertFinalSavePopUpmsg = "";

    Object.values(this.globalForm.controls).forEach((item, index) => {
      if(item.invalid) {
        this.errorModules.add(item?.value?.name);
      }
    });

    if(this.errorModules.size > 0) {
      this.showAlertPopUp = true;
      this.alertFinalSavePopUpmsg = "There are few details are missing in : " + (Array.from(this.errorModules)).join(", ");
      return;
    }

    this.currentCaseStatus = true;
    let primaryOppPayment = this.globalForm?.value?.paymentDetails?.list.map((item: any, index) => {
      if(item['paymentApprove' + index] === "Rejected"  || item['chequeApprove' + index] === "Rejected"  || item['depositApprove' + index] === "Rejected" )
        this.currentCaseStatus = false;

      return {
        paymentId: item.key,
        isApproved: item['paymentApprove' + index],
        comment: item.paymentComment,
        chequeAttachmentApproved: item['chequeApprove' + index],
        chequeAttachmentComment: item.chequeAttachmentComment,
        depositeSlipApproved: item['depositApprove' + index],
        depositeSlipComment: item.depositSlipComment
      };
    });
    let secondaryOppPayment = this.globalForm?.value?.paymentDetails?.comboList.map((item: any, index) => {
      if(item['paymentApprove0' + index] === "Rejected"  || item['chequeApprove0' + index] === "Rejected"  || item['depositApprove0' + index] === "Rejected" )
        this.currentCaseStatus = false;

      return {
        paymentId: item.key,
        isApproved: item['paymentApprove0' + index],
        comment: item.paymentComment0,
        chequeAttachmentApproved: item['chequeApprove0' + index],
        chequeAttachmentComment: item.chequeAttachmentComment0,
        depositeSlipApproved: item['depositApprove0' + index],
        depositeSlipComment: item.depositSlipComment0
      };
    });
    let paymentDetails = [...primaryOppPayment,...secondaryOppPayment];
    this.savePayload = {
      agreementId: Number(this.agreementId),
      fieldQcActions: [].concat(...Object.values(this.globalForm.value).map((item: any) => item.list))?.filter(item => !!item.checklistId)?.map(item => {
        if(item.isApprove === "Rejected")
          this.currentCaseStatus = false;
        return {
          checklistId: item.checklistId,
          qcFieldStatus: item.isApprove,
          comment: item.comment
        };
      }),
      actionedBy: this.apiService.empId,
      companyName : this.opportunityDetails?.entity?.name,
      product : this.opportunityDetails?.opportunity?.product,
      paymentQcActions: paymentDetails || []
    };

    if(!this.isAdditionalData) {
      this.alertMsg = `${this.currentCaseStatus ? this.QC_APPROVED : this.QC_REJECTED}`;
    } else {
      this.alertMsg = `${this.currentCaseStatus ? "Approved" : "Rejected"}`;
    }
    this.showSaveAlertPopUp = true;
  }

  confirmSaveData() {
    this.showSaveAlertPopUp = false;
    this.saveActualData();
  }
  
  // save QC data
  private saveActualData() {
    this.apiService.postQCData(this.savePayload).pipe(takeUntil(this.ngOnUnSubscibe)).subscribe({
      next: () => {
        this.ref.close({msg : 'success', caseStatus : this.alertMsg?.replace("QC ", "")});
      },
      error: (err) => {
        this.ref.close('error');
      }
    });
  }

  openAttachmentList() {
    localStorage.setItem('attachmentData', this.encDec.encryptLocalObject(this.attachmentList));
    
    let previewUrl = DOMAIN_ROUTE+"#/backoffice/qc/listing/attachment-list-preview";
    window.open(previewUrl, '_blank');
  }

  _markFormDirty() {
    this.globalForm.markAsDirty();
    this.globalForm.markAllAsTouched();

    // mark each tab dirty for validations to be activated
    this.allTabKeys?.forEach(tabName => {
      this._markNestedFormArrayDirty(tabName);
    });
  }

  // mark formArray of list as dirty
  _markNestedFormArrayDirty(key) {
    this.globalForm.get(key).markAsDirty();

    (<any>this.globalForm.get(key).get('list'))?.controls.forEach(item => {
      item.markAsDirty();
      item.markAllAsTouched();
    });
  }

  
  mergeData() {
    let globalData = {};
    
    this.checkList?.qcActions.forEach(item => {
      const it = item?.checklistField;
      globalData[it.id] = {
        key : it.fieldName,
        label : it.appFieldName,
        id : it.id,
        isApprove : item.qcFieldStatus, 
        comment : item.comment
      }
    });


    globalData = this.inputMapper(globalData);

    // map all details in form
    this._mapToForm('dateDetails', globalData['dateDetails'].list);
    this._mapToForm('mandateDetails', globalData['mandateDetails'].list);
    this._mapToForm('triPartyDetails', globalData['triPartyDetails'].list);
    this._mapToForm('productDetails', globalData['productDetails'].list);
    this._mapToForm('costCenterDetails', globalData['costCenterDetails'].list);
    this._mapToForm('portfolioDetails', globalData['portfolioDetails'].list);
    this._mapToForm('comboProductDetails', globalData['comboProductDetails'].list);
    this._mapToForm('feeCapDetails', globalData['feeCapDetails'].list);
    this._mapPaymentToForm();
    
    // {
    //   1 : {
    //     id: 1,
    //     key : 'crisilSignedDate',
    //     label : 'Crisil Signed Date',
    //     isApprove : 'Approved',
    //     comment : 'Ok',
    //     val : ''
    //   },
    // }
  }

  inputMapper(idData) {
    let sfMandateValidityData = (this.mandateValidityList.find((validity) => validity?.id == this.agreementDetails?.sfMandateValidityId))?.sf_mandate_validity;
    let feeData = this.opportunityDetails?.feeDetails?.revisedSfFee==null?this.opportunityDetails?.feeDetails?.sfActualFees:this.opportunityDetails?.feeDetails?.revisedSfFee;
    if(this.opportunityDetails?.feeDetails?.sfFeeRuleId == 91){
      feeData = this.opportunityDetails?.feeDetails?.sfActualFees;
    }
    return {
      'dateDetails' : {
        list : [
          {key : 'feedbackForQC', label : 'Feedback for QC', val : this.agreementDetails.qcFeedback},
          // mandate date
          this._getObjData(60, idData, this.agreementDetails.mandateDate),
          // crisilSignedDate
          this._getObjData(10, idData, this.agreementDetails.crisilSignedDate),
          // date of agreement sent to client
          this._getObjData(11, idData, this.agreementDetails.sentToClientDate),
          // client signed date
          this._getObjData(12, idData,  this.agreementDetails.clientSignedDate),
          // mandate received date
          this._getObjData(13, idData, this.agreementDetails.mandateReceivedDate),
          // reporting date
          // removing it as per #313438
          // this._getObjData(47, idData, this.opportunityDetails?.opportunity?.businessReportedDate),
        ]
      },
      'mandateDetails' : {
        list : [
          // mandate type
          this._getObjData(1, idData, this.mandateAttachmentType),

          // mandate options
          this._getObjData(14, idData, this.agreementDetails.mandateOption),
          
          // initiation type
          this._getObjData(15, idData, this.agreementDetails.initiationType),
          
          // ref no
          this._getObjData(48, idData,this.opportunityDetails.opportunity.referenceNo),
          
          // company name
          this._getObjData(49, idData, this.opportunityDetails.entity.name),
          
          // client contact name
          this._getObjData(16, idData, `${this.crmContact?.salutation || ''} ${this.crmContact?.firstName || ''} ${this.crmContact?.lastName || ''}`), 
          
          // address
          this._getObjData(17, idData, `${this.crmContact?.address1 || ''} ${this.crmContact?.address2 || ''} ${this.crmContact?.address3 || ''} ${this.crmContact?.city || ''} ${this.crmContact?.state || ''} ${this.crmContact?.pin || ''}`),
          
          // designation
          this._getObjData(18, idData, this.crmContact?.designation),
          
          // email
          this._getObjData(19, idData, this.crmContact?.email),
          
          // mobile no
          {key : 'mobileNo', label : 'Mobile No.', val : this.crmContact?.mobileNum},

          // landline No
          {key : 'landlineNo', label : 'Landline No.', val : this.crmContact?.phoneNum},
          
          // template confirmation and version
          this._getObjData(2, idData, null),
          
          // client signature
          this._getObjData(3, idData, null),

          // crisil signature
          this._getObjData(4, idData, null),
          
          // product instrument headline signature
          this._getObjData(5, idData, ''),

          // withdrawal case
          this._getObjData(6, idData, ''),

          // travle ope type
          this._getObjData(20, idData, this.agreementDetails?.opeType),

          // travel ope amount
          this._getObjData(21, idData, this.agreementDetails?.opeAmount),

          // travel ope remark
          this._getObjData(7, idData, null),

          // changes in agreement
          this._getObjData(8, idData, null),

          // gst category
          this._getObjData(22, idData, this.crmContact?.gstType),

          // gst No
          this._getObjData(23, idData, this.crmContact?.gstNumber),

          // tds no
          // this._getObjData(3, idData, null),  // ASK

          // pan
          this._getObjData(24, idData, this.crmContact?.pan),

        ]
      },
      'triPartyDetails' : {
        list : !this.disableTriPartyTab ?  [
          // tri company name
          this._getObjData(39, idData, this.triPartyOppr?.name),

          // tri finrisk
          this._getObjData(50, idData, this.opportunityDetails?.reportingDetails?.tripartyFinRisk),
          {key : 'triPartyFinRiskComment', label : 'FIN Risk Comments', val : this.opportunityDetails?.reportingDetails?.tripartyFinRiskComment}, // CHECK - about val
          
          // tri contact name
          this._getObjData(58, idData, `${this.triPartyCRMContact?.salutation  || ''} ${this.triPartyCRMContact?.firstName  || ''} ${this.triPartyCRMContact?.middleName  || ''} ${this.triPartyCRMContact?.lastName  || ''}`),

          // tri address
          {key : 'triPartyAddress', label : 'Adddress', val : `${this.triPartyCRMContact?.address1 || ''} ${this.triPartyCRMContact?.address2 || ''} ${this.triPartyCRMContact?.address3 || ''} ${this.triPartyCRMContact?.city || ''} ${this.triPartyCRMContact?.state || ''} ${this.triPartyCRMContact?.pin || ''}` },

          // designation
          this._getObjData(25, idData, this.triPartyCRMContact?.designation),
          // tri contact email id
          this._getObjData(26, idData, this.triPartyCRMContact?.email),
          // mobile no
          {key : 'triPartyMobileNo', label : 'Mobile No', val : this.triPartyCRMContact?.mobileNum},
          // landline no
          {key : 'triPartyLandlineNo', label : 'Landline No', val : this.triPartyCRMContact?.phoneNum},
          // GST category
          this._getObjData(27, idData, this.triPartyCRMContact?.gstType),
          // GST NO
          this._getObjData(28, idData, this.triPartyCRMContact?.gstNumber),
          // GST Pan
          this._getObjData(29, idData, this.triPartyCRMContact?.pan),
        ] : []
      },
      'productDetails' : {
        list : [
          // business type
          this._getObjData(40, idData, this.opportunityDetails?.opportunity?.businessType),

          // business type
          this._getObjData(64, idData, this.opportunityDetails?.opportunity?.isExistingArc),

          // MA case // CHECK
          this._getObjData(51, idData, this.opportunityDetails?.opportunity?.marketingAssociate),
          
          // Product name
          this._getObjData(41, idData, this.opportunityDetails?.opportunity?.product),
          
          // Master service
          {key : 'masterService', label : 'Master Service', val : this.agreementDetails?.masterService},

          // sub service
          {key : 'subService', label : 'Sub Service', val : this.agreementDetails?.subService},

          // ratable unit
          {key : 'ratableUnit', label : 'Ratable Unit', val : this.agreementDetails?.ratableUnit},

          // AMC Name
          this._getObjData(30, idData, this.agreementDetails?.amcName),
          
          // MF Name
          this._getObjData(31, idData, this.agreementDetails?.mfParentName),
          
          // AMC Code
          this._getObjData(32, idData, this.agreementDetails?.amcCode),
          
          // Instrument
          this._getObjData(42, idData, this.opportunityDetails?.opportunity?.instrument),
          
          // Quantum
          this._getObjData(43, idData, this.opportunityDetails?.opportunity?.quantum),
          
          // outstanding amount..
          {key : 'outstandingAmount', label : 'Outstanding Amount', val : `${this.limitData?.outStandingAmt ?? ''}`},
          // in progress amount
          // {key : 'inProgressAmount', label : 'In Progress Amount', val : `${this.limitData?.inProgressAmt ?? ''}`},
          // previous limit
          {key : 'previousLimit', label : 'Previous Limit', val : `${this.limitData?.previousLimit ?? ''}`},
          // current limit
          {key : 'currentLimit', label : 'Current Limit', val : this.agreementDetails?.currentLimit },
          // Applicable amount
          {key : 'applicableAmount', label : 'Applicable Amount', val : this.opportunityDetails?.opportunity?.quantum},
          // Client rating status
          {key : 'clientRatingStatus', label : 'Mandate Status', val : `${this.mandateStatus ?? ''}`},
          
          // IRF EXPECTED
          {key : 'irfExpectedFee', label : 'Standard IRF', val : this.opportunityDetails.feeDetails?.idealFeeExpected},
          // IRF Actual
          this._getObjData(44, idData, this.opportunityDetails?.feeDetails?.irfActualFees),
          // difference
          {key : 'irfDifference', label : 'Difference', val : this.opportunityDetails.feeDetails?.irfDiscount},
          // discount %
          {key : 'irfDiscount', label : 'Discount / Premium %', val : this.opportunityDetails?.feeDetails?.irfDiscountPercentage},
          // discount reason
          {key : 'irfDiscountReason', label : 'IRF Discount Reason', val : this.opportunityDetails?.feeDetails?.irfDiscountReason},
          // sf fee rule
          this._getObjData(52, idData, this.sfFeeRuleName),

          // SF EXPECTED
          {key : 'sfExpectedFee', label : 'SF Expected Fee', val : this.opportunityDetails.feeDetails?.idealFeeSfExpected},
          // SF Actual
          this._getObjData(45, idData, feeData.toString()),
          // SF difference
          {key : 'sfDifference', label : 'SF Discount / Premium', val : this.opportunityDetails.feeDetails?.sfDiscount},
          // SF discount %
          {key : 'sfDiscount', label : 'SF Discount / Premium %', val : this.opportunityDetails?.feeDetails?.sfDiscountPercentage},
          // SF Actual %
          this._getObjData(53, idData, this.opportunityDetails?.feeDetails?.sfActualPercent),
          // SF Excepted %
          {key : 'sfExpectedPercent', label : 'SF Expected %', val : this.opportunityDetails?.feeDetails?.expectedSfPercent},
          // discount reason
          {key : 'sfDiscountReason', label : 'SF Discount Reason', val : this.opportunityDetails?.feeDetails?.sfDiscountReason},
          // SF Mandate Validity
          this._getObjData(63, idData, sfMandateValidityData),
          //minimum value
          this._getObjData(61, idData, this.opportunityDetails?.feeDetails?.minimumValue),
          //maximum value
          this._getObjData(62, idData, this.opportunityDetails?.feeDetails?.maximumValue),
        ]
      },
      'costCenterDetails' : {
        list : [
          // cost center
          this._getObjData(54, idData, this.opportunityDetails.reportingDetails.costCenter),
          // case classification
          this._getObjData(33, idData, this.agreementDetails.caseClassification),
          // client classification
          this._getObjData(34, idData, this.clientClassification?.clientTypeName),
          // agreeement classification
          this._getObjData(35, idData, this.agreementDetails?.agreementClassification),
          // fin risk
          this._getObjData(55, idData, this.opportunityDetails?.reportingDetails?.finRisk),
          // fin risk comment
          {key : 'finRiskComment', label : 'Fin Risk Comment', val : this.opportunityDetails?.reportingDetails?.finRiskComment},
          // rpt in overall
          this._getObjData(56, idData, (this.opportunityDetails?.reportingDetails?.ccRptStatus=='Yes'|| this.opportunityDetails?.reportingDetails?.crmRptStatus =='Yes')?'Yes': 'No'),
          // rpt in cc
          {key : 'rptInCC', label : 'RPT in CC', val : this.opportunityDetails?.reportingDetails?.ccRptStatus},
          // rpt in crm
          {key : 'rptInCRM', label : 'RPT in CRM', val : this.opportunityDetails?.reportingDetails?.crmRptStatus},
          // mca check
          this._getObjData(9, idData, null),
          // tan No
          this._getObjData(59, idData, this.opportunityDetails?.reportingDetails?.tanNo),
        ]
      },
      'portfolioDetails' : {
        list : [
          this._getObjData(46, idData,  null)
        ]
      },
      'comboProductDetails' : {
        list : !this.disableComboTab ? [
          // first oppr name
          {key : 'firstOpprName', label : 'First Opportunity Name', val : this.opportunityDetails?.opportunity?.name},
          // product
          {key : 'productFirstOppr', label : 'Product', val : this.opportunityDetails?.opportunity?.product},
          // irf fee
          {key : 'irfFeeFirstOppr', label : 'IRF Fee', val : this.opportunityDetails?.feeDetails?.irfActualFees},
          // sf fee
          {key : 'sfFeeFirstOppr', label : 'SF Fee', val : this.opportunityDetails?.feeDetails?.sfActualFees.toString()},
          // second oppr name
          {key : 'secondOpprName', label : 'Second Opportunity Name', val : this.comboDetails?.opportunity?.name},
          // product
          this._getObjData(57, idData, this.comboDetails?.opportunity?.product),
          // irf fee
          {key : 'irfFeeSecondOppr', label : 'IRF Fee', val : this.comboDetails?.feeDetails?.irfActualFees},
          // sf fee
          {key : 'sfFeeSecondOppr', label : 'SF Fee', val : this.comboDetails?.feeDetails?.sfActualFees.toString()},
          // total IRF fee
          this._getObjData(36, idData, this.agreementDetails?.totalIrfActualFee.toString()),
          // total SF fee
          this._getObjData(37, idData, this.agreementDetails?.totalSfActualFee.toString())
        ] : []
      },
      'feeCapDetails' : {
        list : [
          this._getObjData(38, idData, this.agreementDetails?.mandateFeeCap)
        ]
      }
          
    };
  }

  _getObjData(id : number, idData, value) {
    return idData[id] ? {checklistId : id, key : idData[id].key, label : idData[id].label, val : value, isApprove : idData[id].isApprove|| null, comment : idData[id].comment || null} : null;
  }

  _mapToForm(key, value: Array<any>) {
    const formArray = this.globalForm.get(key).get('list') as FormArray;
    if(value?.length > 0) {
      value.forEach(item => {
        if(item != null) {
          const data = {key : [item.key], label : [item.label], val : [item.val], checklistId : [item.checklistId]};
          if(item.isApprove !== undefined) {
            data['isApprove'] = [item.isApprove, Validators.required];
            data['comment'] = [item.comment];
          }
          // special check for mandate type - for UPDATED DOCS US
          if(this.isAdditionalData &&  !this.readOnlyState && item['key'] === "mandateType" && item['isApprove'] == null) {
            data['disable'] = [false];
            this.disableSave = false;
          } else if (this.readOnlyState) {
            data['disable'] = true;
          }
  
          formArray.push(this.fb.group(data));
        }
      });

      this.globalForm.get(key).patchValue({'list' : formArray?.controls});
    }
  }

  _mapPaymentToForm() {
    // {
    //   data : {item data of payment},
    //   paymentApprove1 : bool,
    //   paymentComment : '',
    //   chequeApprove1 : Boolean,
    //   chequeAttachmentComment : '',
    //   depositApprove1 : Boolean,
    //   depositSlipComment : '',
    // }
    const savedPaymentQCData = {};
    this.checkList.paymentQcAction?.forEach(item => {
      savedPaymentQCData[item?.paymentId] = {...item};
    });

    let opportunityName = this.opportunityDetails?.opportunity?.name;
    let comboOpportunityName = this.comboDetails?.opportunity?.name;
    const formArray = this.globalForm.get('paymentDetails').get('list') as FormArray;
    const comboformArray = this.globalForm.get('paymentDetails').get('comboList') as FormArray;
    if(this.opportunityDetails?.payments?.length > 0) {
      
      this.opportunityDetails?.payments?.forEach((item, index) => {
        // payment validation needed, if its not in set of already saved payments,
        
        let additionalPaymentValidation = savedPaymentQCData[item.id] == null;  // variable to know if this payment was actioned before, if not, its an additional payment
        
        // payment qc approval status present
        if(!additionalPaymentValidation) {
          item = { ...savedPaymentQCData[item.id], ...item}; // add its detail
        }

        // special check for additional payment - for ADDITIONAL PAYMENTS
        if(this.isAdditionalData && !additionalPaymentValidation && !this.readOnlyState) { // if not an additional payment(was actioned before)
          if(item.isApproved === null || item.chequeAttachmentApproved === null || item.depositeSlipApproved === null) { // and was previously rejected
            this.disableSave = false;
            additionalPaymentValidation = true;  // make this payment fields enabled
            if(item.isApproved == null) item.isApproved = "Rejected";
            if(item.chequeAttachmentApproved == null) item.chequeAttachmentApproved = "Rejected";
            if(item.depositeSlipApproved == null) item.depositeSlipApproved = "Rejected";
          }
        } else if (additionalPaymentValidation && !this.readOnlyState) {  // if an additional payment
          this.disableSave = false;
        }

        const data = {
          key : [item.id], 
          data : [item],
          ['paymentApprove' + index] : [item.isApproved,  !additionalPaymentValidation ? null : Validators.required],
          paymentComment : [item.comment],
          ['chequeApprove' + index] : [item.chequeAttachmentApproved,  !additionalPaymentValidation ? null : Validators.required],
          chequeAttachmentComment : [item.chequeAttachmentComment],
          ['depositApprove' + index] : [item.depositeSlipApproved,  !additionalPaymentValidation ? null : Validators.required],
          depositSlipComment : [item.depositeSlipComment],
          isDisabled : [(this.disableAllFields && !additionalPaymentValidation) || this.readOnlyState]
        };
        formArray.push(this.fb.group(data));
      });
    }

    if(this.comboDetails?.payments?.length > 0){

      this.comboDetails?.payments?.forEach((item, index) => {
        console.log("items data: ",item);
        console.log("index details: ",index);
        // payment validation needed, if its not in set of already saved payments,
        
        let additionalPaymentValidation = savedPaymentQCData[item.id] == null;  // variable to know if this payment was actioned before, if not, its an additional payment
        
        // payment qc approval status present
        if(!additionalPaymentValidation) {
          item = { ...savedPaymentQCData[item.id], ...item}; // add its detail
        }

        // special check for additional payment - for ADDITIONAL PAYMENTS
        if(this.isAdditionalData && !additionalPaymentValidation && !this.readOnlyState) { // if not an additional payment(was actioned before)
          if(item.isApproved === null || item.chequeAttachmentApproved === null || item.depositeSlipApproved === null) { // and was previously rejected
            this.disableSave = false;
            additionalPaymentValidation = true;  // make this payment fields enabled
            if(item.isApproved == null) item.isApproved = "Rejected";
            if(item.chequeAttachmentApproved == null) item.chequeAttachmentApproved = "Rejected";
            if(item.depositeSlipApproved == null) item.depositeSlipApproved = "Rejected";
          }
        } else if (additionalPaymentValidation && !this.readOnlyState) {  // if an additional payment
          this.disableSave = false;
        }

        const data = {
          key : [item.id], 
          data : [item],
          ['paymentApprove0' + index] : [item.isApproved,  !additionalPaymentValidation ? null : Validators.required],
          paymentComment0 : [item.comment],
          ['chequeApprove0' + index] : [item.chequeAttachmentApproved,  !additionalPaymentValidation ? null : Validators.required],
          chequeAttachmentComment0 : [item.chequeAttachmentComment],
          ['depositApprove0' + index] : [item.depositeSlipApproved,  !additionalPaymentValidation ? null : Validators.required],
          depositSlipComment0: [item.depositeSlipComment],
          isDisabled : [(this.disableAllFields && !additionalPaymentValidation) || this.readOnlyState]
        };
        comboformArray.push(this.fb.group(data));
      });
    }
    // ['paymentDetails'],
    // name : ['Payment details'],
    this.globalForm.get('paymentDetails').patchValue({'id': 'paymentDetails', 'name': 'Payment details', 'list' : formArray?.controls,'comboList':comboformArray?.controls,primaryOpportunityName: opportunityName,secondaryOpportunityName: comboOpportunityName});
  }

  navigateToFeeCap() {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/masters/feecap/grid`;
  }

  sendDataToRow(){
    let payload = {
      "agreementId":this.agreementId,
      "actionedBy":this.apiService.empId
    }

    this.apiService.pushDataToRow(payload).subscribe((res)=>{
      if(res?.statusCode=="200"){
        this.ref.close({msg : 'rowSuccess', caseStatus : res?.msg});
      } else{
        this.ref.close({msg : 'rowError', caseStatus : res?.msg});
      }
    },(err)=>{
      this.apiFail = true;
      console.log("Error while pushing Data to ROW ",err);
    });
  }

}

