<div class="entityDetailsModal">
    <p-toast class="custom-toast"></p-toast>
    <form [formGroup]="ReportingScreenDetailsForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-p-0">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0">
                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid p-mb-2 entityDetailsHeader">
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-text-left p-pl-0">
                                        <div class="p-d-flex p-ai-center"> 
                                            <label class="p-mr-2 p-mb-2 label_light">Opportunity Status:</label>
                                            <h6>{{ReportingScreenDetailsForm.value.opportunityStatus}}</h6>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-text-left">
                                        <div class="p-d-flex p-ai-center"> 
                                            <label class="p-mr-2 p-mb-2 label_light">Date:</label>
                                            <h6>{{ReportingScreenDetailsForm.value.businessWonDate}}</h6>
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-text-left">
                                        <div class="p-d-flex p-ai-center"> 
                                            <label class="p-mr-2 p-mb-2 label_light">CRM Code:</label>
                                            <h6>{{ReportingScreenDetailsForm.value.entityCompanyCode}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 verticalTabContainer p-p-0"
                                [ngClass]="{'smallTabContainer': toggle}">

                                <p-tabView [(activeIndex)]="ReportingScreenDetailsForm.value.EntityDetailstabview"
                                    (onChange)="handleChangeEntityDetailstabview($event)"
                                    styleClass="p-tabview-vertical">
                                    <p-tabPanel>
                                        <ng-template pTemplate="header"><span
                                                [ngClass]="{'errorTab': errorEntityDetails}">Entity Details</span>
                                        </ng-template>
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class=" p-fluid   p-field   ">
                                                            <label>
                                                                Company Name (CRM)<span class='invalid-feedback-astrik'
                                                                    *ngIf="!otherOpportunityStatusFlag">*</span>
                                                            </label>
                                                            <div *ngIf="companyNameShowFlag" class="companyName">
                                                                <div class="inputtext-medium">
                                                                    <span class="p-input-icon-left">
                                                                        <i class="pi pi-search"></i>
                                                                        <p-autoComplete id="companyName" name="companyName"
                                                                            [dropdown]="false" placeholder="Enter Company Name (CRM)"
                                                                            [suggestions]="companyNameList"
                                                                            field="companyName" optionLabel="companyName"
                                                                            optionValue="name" formControlName="companyName"
                                                                            (completeMethod)="loadcompanyNameLazy($event)"
                                                                            (onClear)="companyNameOnClear($event)"
                                                                            class="p-autocomplete-items"
                                                                            [showEmptyMessage]="emptyMsgFlag"
                                                                            [emptyMessage]="emptyMsgVal"
                                                                            (onSelect)="companyNameOnSelection($event)"
                                                                            [ngClass]="{error:(ReportingScreenDetailsForm.get('companyName')?.errors?.required && isCompanyName) || (ReportingScreenDetailsForm.get('companyName').invalid && (ReportingScreenDetailsForm.get('companyName').dirty || ReportingScreenDetailsForm.get('companyName').touched) && !otherOpportunityStatusFlag), show:showCrmLoader}">
                                                                        </p-autoComplete>
                                                                    </span>
                                                                </div>
                                                                <div class="invalid-feedback "
                                                                    *ngIf="isCompanyName || (ReportingScreenDetailsForm.get('companyName')!.invalid && (ReportingScreenDetailsForm.get('companyName')!.dirty || ReportingScreenDetailsForm.get('companyName')!.touched))">
                                                                    <div
                                                                        *ngIf="ReportingScreenDetailsForm.get('companyName')?.errors?.required && !otherOpportunityStatusFlag">
                                                                        Company Name is required.
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="!companyNameShowFlag">
                                                                <input pInputText type="text" id="companyName"
                                                                    formControlName="companyName" name="companyName"
                                                                    disabled class="p-inputtext  " />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                CRM Code
                                                            </label>
                                                            <input type="text" id="CRMCodetextField_id"
                                                                name="CRMCodetextField_name"
                                                                formControlName="CRMCodetextField" pInputText
                                                                placeholder="Enter CRM Code" class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Fin Risk
                                                            </label>
                                                            <input disabled type="text" id="FinRisktextField_id"
                                                                name="FinRisktextField_name"
                                                                formControlName="FinRisktextField" pInputText
                                                                placeholder="Enter Fin Risk" class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                REF No <span class='invalid-feedback-astrik'
                                                                    *ngIf="RefNoValidationFlag">*</span>
                                                            </label>
                                                            <div>
                                                                <input id="RefNotextField_id"
                                                                    [readonly]="(!qcRejectMap['refNo'] && businessReportedStatusFlagA) || otherOpportunityStatusFlagA"
                                                                    name="RefNotextField_name"
                                                                    formControlName="RefNotextField" type="text"
                                                                    slotChar="" maxlength="100" class="p-inputtext"
                                                                    (input)="refNumberVal()" placeholder="Enter REF No"
                                                                    [ngClass]="{error: (ReportingScreenDetailsForm.get('RefNotextField')?.errors?.required) && (ReportingScreenDetailsForm.get('RefNotextField').dirty && ReportingScreenDetailsForm.get('RefNotextField').invalid) || isREF || qcRejectMap['refNo']}"
                                                                    [style]="{'text-transform': 'uppercase'}" />
                                                            </div>
                                                            <div class="invalid-feedback"
                                                                *ngIf="(ReportingScreenDetailsForm.get('RefNotextField')?.errors?.required && (ReportingScreenDetailsForm.get('RefNotextField').dirty && ReportingScreenDetailsForm.get('RefNotextField').invalid)) || isREF">
                                                                Ref No is required.
                                                            </div>
                                                            <div class="invalid-feedback"
                                                                *ngIf="refDuplicateFlag && ReportingScreenDetailsForm.value?.RefNotextField?.length > 0">
                                                                Ref No is duplicate.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Group Company
                                                            </label>
                                                            <input disabled type="text" id="GroupCompanytextField_id"
                                                                name="GroupCompanytextField_name"
                                                                formControlName="GroupCompanytextField" pInputText
                                                                placeholder="Enter Group Company" class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Company Type
                                                            </label>
                                                            <p-dropdown id="CompanyTypeselectid" scrollHeight="120px"
                                                                name="CompanyTypeselect"
                                                                placeholder="Select a Company Type" [filter]="true"
                                                                resetFilterOnHide="true"
                                                                [options]="CompanyTypeselectList" optionLabel="type"
                                                                optionValue="type" [autoDisplayFirst]="false"
                                                                [disabled]="otherOpportunityStatusFlag"
                                                                formControlName="CompanyTypeselect">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label for="rptCC">
                                                                RPT in CC
                                                            </label>
                                                            <input [readonly]="true" type="text" id="rptCC"
                                                                formControlName="rptCC" pInputText placeholder="Enter RPT in CC"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label for="rptCRM">
                                                                RPT in CRM
                                                            </label>
                                                            <input [readonly]="true" type="text" id="rptCRM"
                                                                formControlName="rptCRM" pInputText placeholder="Enter RPT in CRM"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label for="rptOverall">
                                                                RPT Status Overall
                                                            </label>
                                                            <input [readonly]="true" type="text" id="rptOverall"
                                                                formControlName="rptOverall" pInputText placeholder="Enter RPT Status Overall"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label for="finComment">
                                                                Fin Risk Comment
                                                            </label>
                                                            <textarea pInputTextarea disabled="true" id="finComment"
                                                                formControlName="finComment" rows="3" cols="3"
                                                                placeholder="Enter Fin Risk Comment"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label class="p-d-flex p-jc-between p-ai-center p-mr-0" for="finComment">
                                                                <span>
                                                                    TAN Details
                                                                </span>
                                                                <button pButton style="height: 20px; width: fit-content;"
                                                                
                                                                class="p-element p-button p-button-secondary p-button-sm" 
                                                                label="Get TAN" (click)="getTan()" [disabled]="!companyCodeForTan"
                                                                >
                                                            </button>
                                                            </label>
                                                            <input [readonly]="true" type="text" id="tanDetails"
                                                                formControlName="tanDetails" pInputText placeholder="Enter TAN Details"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-mt-3">
                                                        <h6>Tri party Details</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label for="triParty">
                                                                Tri Party
                                                            </label>
                                                            <input [readonly]="true" type="text" id="triParty"
                                                                formControlName="triParty" pInputText placeholder="Enter Tri Party"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label for="triCrm">
                                                                Tri Party Company Name (CRM)
                                                            </label>
                                                            <input [readonly]="true" type="text" id="triCrm"
                                                                formControlName="triCrm" pInputText placeholder="Enter Tri Party Company Name (CRM)"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label for="triCrmCode">
                                                                CRM Code
                                                            </label>
                                                            <input [readonly]="true" type="text" id="triCrmCode"
                                                                formControlName="triCrmCode" pInputText placeholder="Enter CRM Code"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label for="triFinRisk">
                                                                Fin Risk
                                                            </label>
                                                            <input [readonly]="true" type="text" id="finRisk"
                                                                formControlName="triFinRisk" pInputText placeholder="Enter Fin Risk"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label for="triFinComment">
                                                                Fin Risk Comment
                                                            </label>
                                                            <textarea pInputTextarea disabled="true" id="triFinComment"
                                                                formControlName="triFinComment" rows="3" cols="3"
                                                                placeholder="Enter Fin Risk Comment"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p-tabPanel>
                                    <p-tabPanel>
                                        <ng-template pTemplate="header"><span
                                                [ngClass]="{'errorTab': errorEntityContacts}">Entity Contacts</span>
                                        </ng-template>
                                        <div class="p-col-12 entity-contact-details">
                                            <p-accordion  [activeIndex]="0" collapseIcon="pi pi-minus">
                                                <p-accordionTab header="Company Contact">
                                                    <div class="p-fluid p-formgrid p-grid">
                                                        <div class=" p-col-12">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                                    <div class="   ">
                                                                        <h6>Basic Info</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            Salutation <span
                                                                                class='invalid-feedback-astrik'
                                                                                [ngClass]="{'hide': otherOpportunityStatusFlag || isNoPayment}">*</span>
                                                                        </label>
                                                                        <p-dropdown id="salutationselectid"
                                                                            name="salutationselect"
                                                                            [options]="salutationselectList"
                                                                            optionLabel="name" optionValue="name"
                                                                            [autoDisplayFirst]="false" [disabled]="true"
                                                                            formControlName="salutationselect"
                                                                            [ngClass]="{error:isSalutationSelect || (ReportingScreenDetailsForm.get('salutationselect')!.invalid && (ReportingScreenDetailsForm.get('salutationselect')!.dirty || ReportingScreenDetailsForm.get('salutationselect')!.touched))}">
                                                                        </p-dropdown>
                                                                        <div class="invalid-feedback"
                                                                            *ngIf=" isSalutationSelect || (ReportingScreenDetailsForm.get('salutationselect')!.invalid && (ReportingScreenDetailsForm.get('salutationselect')!.dirty || ReportingScreenDetailsForm.get('salutationselect')!.touched))">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('salutationselect')?.errors?.required">
                                                                                Salutation is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            First Name<span
                                                                                class='invalid-feedback-astrik'
                                                                                [ngClass]="{'hide': otherOpportunityStatusFlag || isNoPayment}">*</span>
                                                                        </label>
                                                                        <input type="text" id="firstNametextField_id"
                                                                            name="firstNametextField_name"
                                                                            formControlName="firstNametextField"
                                                                            pInputText placeholder="Enter First Name"
                                                                            class="p-inputtext"
                                                                            [readonly]="otherOpportunityStatusFlag || isNoPayment"
                                                                            [ngClass]="{error:isFirstName || (ReportingScreenDetailsForm.get('firstNametextField').invalid && (ReportingScreenDetailsForm.get('firstNametextField').dirty && ReportingScreenDetailsForm.get('firstNametextField').touched))}"
                                                                            disabled />
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="isFirstName || (ReportingScreenDetailsForm.get('firstNametextField').invalid && (ReportingScreenDetailsForm.get('firstNametextField').dirty && ReportingScreenDetailsForm.get('firstNametextField').touched))">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('firstNametextField')?.errors?.required">
                                                                                First Name is required.
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            Last Name<span
                                                                                class='invalid-feedback-astrik'
                                                                                [ngClass]="{'hide': otherOpportunityStatusFlag || isNoPayment}">*</span>
                                                                        </label>
                                                                        <input type="text" id="lastNametextField_id"
                                                                            name="lastNametextField_name"
                                                                            formControlName="lastNametextField"
                                                                            pInputText placeholder="Enter Last Name"
                                                                            class="p-inputtext  "
                                                                            [readonly]="otherOpportunityStatusFlag || isNoPayment"
                                                                            [ngClass]="{error:isLastName || (ReportingScreenDetailsForm.get('lastNametextField').invalid && (ReportingScreenDetailsForm.get('lastNametextField').dirty && ReportingScreenDetailsForm.get('lastNametextField').touched))}"
                                                                            disabled />
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="isLastName || (ReportingScreenDetailsForm.get('lastNametextField').invalid && (ReportingScreenDetailsForm.get('lastNametextField').dirty && ReportingScreenDetailsForm.get('lastNametextField').touched))">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('lastNametextField')?.errors?.required">
                                                                                Last Name is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            Designation<span
                                                                                class='invalid-feedback-astrik'
                                                                                [ngClass]="{'hide': otherOpportunityStatusFlag || isNoPayment}">*</span>
                                                                        </label>
                                                                        <p-dropdown id="designationselectid"
                                                                            name="designationselect"
                                                                            placeholder="Select a Designation"
                                                                            [options]="designationselectList"
                                                                            [filter]="true" resetFilterOnHide="true"
                                                                            optionLabel="name"
                                                                            [autoDisplayFirst]="false" [disabled]="true"
                                                                            formControlName="designationselect"
                                                                            optionValue="name"
                                                                            [ngClass]="{error:isDesignation || (ReportingScreenDetailsForm.get('designationselect')!.invalid && (ReportingScreenDetailsForm.get('designationselect')!.dirty || ReportingScreenDetailsForm.get('designationselect')!.touched))}">
                                                                        </p-dropdown>
                                                                        <div class="invalid-feedback"
                                                                            *ngIf=" isDesignation || (ReportingScreenDetailsForm.get('designationselect')!.invalid && (ReportingScreenDetailsForm.get('designationselect')!.dirty || ReportingScreenDetailsForm.get('designationselect')!.touched))">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('designationselect')?.errors?.required">
                                                                                Designation is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class="p-fluid p-field">
                                                                        <label>
                                                                            Mobile No<span
                                                                                class='invalid-feedback-astrik'
                                                                                [ngClass]="{'hide': otherOpportunityStatusFlag || isNoPayment}">*</span>
                                                                        </label>
                                                                        <p-inputMask id="mobileNotextField_id"
                                                                            name="mobileNotextField_name"
                                                                            formControlName="mobileNotextField"
                                                                            mask="9999999999" [readonly]="true"
                                                                            placeholder="Enter Mobile No" slotChar=""
                                                                            [ngClass]="{error: isMobileNo || ReportingScreenDetailsForm.get('mobileNotextField').invalid && (ReportingScreenDetailsForm.get('mobileNotextField').dirty && ReportingScreenDetailsForm.get('mobileNotextField').touched)}">
                                                                        </p-inputMask>

                                                                        <div class="invalid-feedback"
                                                                            *ngIf="isMobileNo || (ReportingScreenDetailsForm.get('mobileNotextField').invalid && (ReportingScreenDetailsForm.get('mobileNotextField').dirty && ReportingScreenDetailsForm.get('mobileNotextField').touched))">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('mobileNotextField')?.errors?.required">
                                                                                Mobile No is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            Landline No
                                                                        </label>
                                                                        <p-inputMask id="landlineNumber_id"
                                                                            name="landlineNumber_name"
                                                                            [autoClear]="multiFieldClear"
                                                                            formControlName="landlineNotextField"
                                                                            mask="9999999999" [readonly]="true"
                                                                            placeholder="Enter Landline No" slotChar=""></p-inputMask>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            Email 1<span class='invalid-feedback-astrik'
                                                                                [ngClass]="{'hide': otherOpportunityStatusFlag || isNoPayment}">*</span>
                                                                        </label>
                                                                        <input type="email" id="emailtextField_id1"
                                                                            name="emailtextField_name"
                                                                            formControlName="emailtextField1" pInputText
                                                                            placeholder="Enter Email 1" class="p-inputtext"
                                                                            maxlength="50"
                                                                            pattern="^\w+[a-zA-Z0-9-._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-_!@#$%^&)(,+=*]{2,10})+$"
                                                                            (change)="duplicateEmail()"
                                                                            [readonly]="otherOpportunityStatusFlag || isNoPayment"
                                                                            [ngClass]="{error:ReportingScreenDetailsForm.get('emailtextField1').invalid && (ReportingScreenDetailsForm.get('emailtextField1').dirty && ReportingScreenDetailsForm.get('emailtextField1').touched)}"
                                                                            disabled />
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="ReportingScreenDetailsForm.get('emailtextField1').invalid && (ReportingScreenDetailsForm.get('emailtextField1').dirty && ReportingScreenDetailsForm.get('emailtextField1').touched)">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('emailtextField1')?.errors?.required">
                                                                                Email address is required.
                                                                            </div>
                                                                        </div>

                                                                        <div class="invalid-feedback"
                                                                            *ngIf="ReportingScreenDetailsForm.get('emailtextField1')!.invalid && (ReportingScreenDetailsForm.get('emailtextField1')!.dirty || ReportingScreenDetailsForm.get('emailtextField1')!.touched)">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('emailtextField1')?.errors?.pattern">
                                                                                Email must be a valid email address.
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            Email 2
                                                                        </label>
                                                                        <input type="email" id="emailtextField_id2"
                                                                            name="emailtextField_name"
                                                                            formControlName="emailtextField2" pInputText
                                                                            placeholder="Enter Email 2" class="p-inputtext  "
                                                                            maxlength="50"
                                                                            pattern="^\w+[a-zA-Z0-9-._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-_!@#$%^&)(,+=*]{2,10})+$"
                                                                            (change)="duplicateEmail()"
                                                                            [readonly]="otherOpportunityStatusFlag || isNoPayment"
                                                                            disabled />
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="isEmail2 || (ReportingScreenDetailsForm.get('emailtextField2')!.invalid && (ReportingScreenDetailsForm.get('emailtextField2')!.dirty || ReportingScreenDetailsForm.get('emailtextField2')!.touched))">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('emailtextField2')?.errors?.pattern">
                                                                                Email must be a valid email address
                                                                            </div>
                                                                        </div>
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="email2Flag">
                                                                                Duplicate Email address is not
                                                                                acceptable.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            Email 3
                                                                        </label>
                                                                        <input type="email" id="emailtextField_id3"
                                                                            name="emailtextField_name"
                                                                            formControlName="emailtextField3" pInputText
                                                                            placeholder="Enter Email 3" class="p-inputtext  "
                                                                            maxlength="50"
                                                                            pattern="^\w+[a-zA-Z0-9-._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-_!@#$%^&)(,+=*]{2,10})+$"
                                                                            (change)="duplicateEmail()"
                                                                            [readonly]="otherOpportunityStatusFlag || isNoPayment"
                                                                            disabled />
                                                                        <div class="invalid-feedback"
                                                                            *ngIf=" isEmail3 || (ReportingScreenDetailsForm.get('emailtextField3')!.invalid && (ReportingScreenDetailsForm.get('emailtextField3')!.dirty || ReportingScreenDetailsForm.get('emailtextField3')!.touched))">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('emailtextField3')?.errors?.pattern">
                                                                                Email must be a valid email address.
                                                                            </div>
                                                                        </div>
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="email3Flag">
                                                                                Duplicate Email address is not
                                                                                acceptable.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12 p-mt-1">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                                    <h6>GST &amp; PAN Details</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12" *ngIf="!otherOpportunityStatusFlag">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 gst-a ">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            GST Type<span
                                                                                class='invalid-feedback-astrik'
                                                                                [ngClass]="{'hide': otherOpportunityStatusFlag || isNoPayment}">*</span>
                                                                        </label>
                                                                        <p-dropdown id="GSTtypeselectid"
                                                                            name="GSTtypeselect"
                                                                            placeholder="Select a GST Type"
                                                                            [options]="GSTtypeselectList"
                                                                            [filter]="true" resetFilterOnHide="true"
                                                                            optionLabel="property"
                                                                            optionValue="property"
                                                                            [autoDisplayFirst]="false"
                                                                            formControlName="GSTtypeselect"
                                                                            [disabled]="true"
                                                                            (onClick)="gstTypeChange()"
                                                                            (onChange)="gstReset()"
                                                                            [ngClass]="{error:isGSTtype || (ReportingScreenDetailsForm.get('GSTtypeselect')!.invalid && (ReportingScreenDetailsForm.get('GSTtypeselect')!.dirty || ReportingScreenDetailsForm.get('GSTtypeselect')!.touched))}">
                                                                        </p-dropdown>
                                                                        <div class="invalid-feedback "
                                                                            *ngIf="isGSTtype || (ReportingScreenDetailsForm.get('GSTtypeselect')!.invalid && (ReportingScreenDetailsForm.get('GSTtypeselect')!.dirty || ReportingScreenDetailsForm.get('GSTtypeselect')!.touched))">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('GSTtypeselect')?.errors?.required">
                                                                                GST Type is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 gst-b left">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            GST No<span class='invalid-feedback-astrik'
                                                                                *ngIf="isGstNoM || !isNoPayment">*</span>
                                                                        </label>
                                                                        <input type="text" id="GSTNotextField_id"
                                                                            name="GSTNotextField_name"
                                                                            formControlName="GSTNotextField"
                                                                            maxlength="15"
                                                                            [readonly]="true"
                                                                            placeholder="Enter GST No" class="p-inputtext capital"
                                                                            (input)="resetPan($event); onCompleteGSTNumber($event)"
                                                                            (Blur)="gstNoCleared($event)"
                                                                            [ngClass]="{error: isGstNoInvalid || isGstNoIncorrect || (ReportingScreenDetailsForm.get('GSTNotextField')?.errors?.required && (ReportingScreenDetailsForm.get('GSTNotextField').touched && ReportingScreenDetailsForm.get('GSTNotextField').invalid))}">
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="isGstNoIncorrect || (ReportingScreenDetailsForm.get('GSTNotextField')?.errors?.required && (ReportingScreenDetailsForm.get('GSTNotextField').touched && ReportingScreenDetailsForm.get('GSTNotextField').invalid))">
                                                                            GST Number is required.
                                                                        </div>
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="isGstNoInvalid">
                                                                            GST Number is invalid.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="panCondition"
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 pan-a left">
                                                                    <div class=" p-fluid p-field ">
                                                                        <label>
                                                                            PAN Number
                                                                        </label>
                                                                        <p-inputMask id="PANnotextField_id"
                                                                            name="PANnotextField_name"
                                                                            formControlName="PANnotextField"
                                                                            mask="aaaaa9999a" placeholder="Enter PAN Number" slotChar=""
                                                                            [readonly]="true">
                                                                        </p-inputMask>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="panMandate"
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 pan-b left">
                                                                    <div class=" p-fluid p-field ">
                                                                        <label
                                                                            [ngClass]="{'field-required': ReportingScreenDetailsForm.get('PANnotextField')?.errors?.required}">
                                                                            PAN Number<span
                                                                                class='invalid-feedback-astrik'
                                                                                [ngClass]="{'hide': otherOpportunityStatusFlag || isNoPayment}">*</span>
                                                                        </label>
                                                                        <p-inputMask id="PANnotextField_id"
                                                                            name="PANnotextField_name"
                                                                            formControlName="PANnotextField"
                                                                            mask="aaaaa9999a" placeholder="Enter PAN Number" slotChar=""
                                                                            [readonly]="true"
                                                                            [ngClass]="{error:isPanNo || (ReportingScreenDetailsForm.get('PANnotextField')!.invalid && (ReportingScreenDetailsForm.get('PANnotextField')!.dirty || ReportingScreenDetailsForm.get('PANnotextField')!.touched))}">
                                                                        </p-inputMask>
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="isPanNo || (ReportingScreenDetailsForm.get('PANnotextField')!.invalid && (ReportingScreenDetailsForm.get('PANnotextField')!.dirty || ReportingScreenDetailsForm.get('PANnotextField')!.touched))">
                                                                            <div
                                                                                *ngIf=" ReportingScreenDetailsForm.get('PANnotextField')?.errors?.required">
                                                                                PAN Number is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12" *ngIf="otherOpportunityStatusFlag">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            GST Type
                                                                        </label>
                                                                        <p-dropdown id="GSTtypeselectid"
                                                                            name="GSTtypeselect"
                                                                            placeholder="Select a GST Type"
                                                                            [options]="GSTtypeselectList"
                                                                            optionLabel="property"
                                                                            optionValue="property"
                                                                            [autoDisplayFirst]="false"
                                                                            formControlName="GSTtypeselect"
                                                                            [disabled]="otherOpportunityStatusFlag || isNoPayment"
                                                                            [readonly]="otherOpportunityStatusFlag || isNoPayment">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            GST No
                                                                        </label>
                                                                        <input type="text" id="GSTNotextField_id"
                                                                            name="GSTNotextField_name"
                                                                            formControlName="GSTNotextField"
                                                                            maxlength="15"
                                                                            [readonly]="otherOpportunityStatusFlag || reportingReadScope || isNoPayment" [disabled]="otherOpportunityStatusFlag || reportingReadScope || isNoPayment"
                                                                            placeholder="Enter GST No" class="p-inputtext capital">
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class=" p-fluid p-field ">
                                                                        <label>
                                                                            PAN Number
                                                                        </label>
                                                                        <p-inputMask id="PANnotextField_id"
                                                                            name="PANnotextField_name"
                                                                            formControlName="PANnotextField"
                                                                            mask="aaaaa9999a"
                                                                            [readonly]="otherOpportunityStatusFlag || isNoPayment" [disabled]="otherOpportunityStatusFlag || isNoPayment"
                                                                            placeholder="Enter PAN Number" slotChar="">
                                                                        </p-inputMask>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12 p-mt-1">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                                    <h6>Address Details</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12" *ngIf="!otherOpportunityStatusFlag">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            Pin Code<span
                                                                                class='invalid-feedback-astrik'
                                                                                [ngClass]="{'hide': otherOpportunityStatusFlag || isNoPayment}">*</span>
                                                                        </label>
                                                                        <p-inputMask id="pinCodetextField_id"
                                                                            name="pinCodetextField_name"
                                                                            formControlName="pinCodetextField"
                                                                            mask="999999"
                                                                            (onComplete)="onCompletepincode()"
                                                                            [readonly]="true" (onInput)="resetPin()"
                                                                            placeholder="Enter Pin Code" slotChar=""
                                                                            [ngClass]="{error:isPinCode || (ReportingScreenDetailsForm.get('pinCodetextField')!.invalid && (ReportingScreenDetailsForm.get('pinCodetextField')!.dirty || ReportingScreenDetailsForm.get('pinCodetextField')!.touched))}">
                                                                        </p-inputMask>
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="isPinCode || (ReportingScreenDetailsForm.get('pinCodetextField')!.invalid && (ReportingScreenDetailsForm.get('pinCodetextField')!.dirty || ReportingScreenDetailsForm.get('pinCodetextField')!.touched))">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('pinCodetextField')?.errors?.required">
                                                                                Pin Code is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            City<span class="invalid-feedback-astrik"
                                                                                [ngClass]="{'hide': isNoPayment}">*</span>
                                                                        </label>
                                                                        <p-dropdown id="cityid" name="city"
                                                                            placeholder="Select" [options]="cityList"
                                                                            [autoDisplayFirst]="false"
                                                                            formControlName="CitytextField"
                                                                            optionLabel="cityName"
                                                                            optionValue="cityName" [disabled]="true"
                                                                            [ngClass]="{error:isCity || (ReportingScreenDetailsForm.get('CitytextField')!.invalid && (ReportingScreenDetailsForm.get('CitytextField')!.dirty || ReportingScreenDetailsForm.get('CitytextField')!.touched))}">
                                                                        </p-dropdown>
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="isCity || (ReportingScreenDetailsForm.get('CitytextField')!.invalid && (ReportingScreenDetailsForm.get('CitytextField')!.dirty || ReportingScreenDetailsForm.get('CitytextField')!.touched))">
                                                                            <div
                                                                                *ngIf="ReportingScreenDetailsForm.get('CitytextField')?.errors?.required">
                                                                                City is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class="p-fluid p-field">
                                                                        <label>
                                                                            State<span class="invalid-feedback-astrik"
                                                                                [ngClass]="{'hide': isNoPayment}">*</span>
                                                                        </label>
                                                                        <input type="text" id="StatetextField_id"
                                                                            name="StatetextField_name"
                                                                            formControlName="StatetextField" pInputText
                                                                            placeholder="Enter State" class="p-inputtext" [readonly]="true" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12" *ngIf="otherOpportunityStatusFlag">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                                    <div class="p-fluid p-field">
                                                                        <label>
                                                                            Pin Code
                                                                        </label>
                                                                        <p-inputMask id="pinCodetextField_id"
                                                                            name="pinCodetextField_name"
                                                                            formControlName="pinCodetextField"
                                                                            mask="999999" [readonly]="true"
                                                                            placeholder="Enter Pin Code" slotChar="">
                                                                        </p-inputMask>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class="p-fluid p-field">
                                                                        <label>
                                                                            City
                                                                        </label>
                                                                        <p-dropdown id="cityid" name="city"
                                                                            placeholder="Select" [options]="cityList"
                                                                            [autoDisplayFirst]="false"
                                                                            formControlName="CitytextField"
                                                                            optionLabel="cityName" [disabled]="true"
                                                                            optionValue="cityName">
                                                                        </p-dropdown>
                                                                    </div>

                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class="p-fluid p-field">
                                                                        <label>
                                                                            State
                                                                        </label>
                                                                        <input type="text" id="StatetextField_id"
                                                                            name="StatetextField_name"
                                                                            formControlName="StatetextField" pInputText
                                                                            placeholder="Enter State" class="p-inputtext" [readonly]="true" />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                                    <div class="p-fluid p-field">
                                                                        <label>
                                                                            Address Line 1
                                                                        </label>
                                                                        <input type="text" id="address1_id"
                                                                            name="address1_name"
                                                                            formControlName="address1" pInputText
                                                                            placeholder="Enter Address Line 1" class="p-inputtext"
                                                                            [readonly]="true" />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class="p-fluid p-field">
                                                                        <label>
                                                                            Address Line 2
                                                                        </label>
                                                                        <input type="text" id="address2_id"
                                                                            name="address2_name"
                                                                            formControlName="address2" pInputText
                                                                            placeholder="Enter Address Line 2" class="p-inputtext"
                                                                            [readonly]="true" />
                                                                    </div>
                                                                </div>
                                                                <div 
                                                                    class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                                    <div class="p-fluid p-field">
                                                                        <label>
                                                                            Address Line 3
                                                                        </label>
                                                                        <input type="text" id="address3_id"
                                                                            name="address3_name"
                                                                            formControlName="address3" pInputText
                                                                            placeholder="Enter Address Line 3" class="p-inputtext"
                                                                            [readonly]="true" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class=" p-col-12">
                                                            <div class="ui-fluid p-formgrid p-grid">
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                                    <div class=" p-fluid   p-field    ">
                                                                        <label>
                                                                            Address Line 4
                                                                        </label>
                                                                        <input type="text" id="textField_id"
                                                                            name="textField_name"
                                                                            formControlName="textField" pInputText
                                                                            placeholder="Enter Address Line 4" class="p-inputtext"
                                                                            [readonly]="true" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p-accordionTab>
                                                <p-accordionTab header="Tri Party Contact">
                                                    <div class="p-col-12">
                                                        <div class="ui-fluid p-formgrid p-grid">
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <h6>Basic Info</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12">
                                                        <div class="ui-fluid p-formgrid p-grid">
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Salutation
                                                                    </label>
                                                                    <input type="text" formControlName="triSalutation"
                                                                        pInputText placeholder="Select" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        First Name
                                                                    </label>
                                                                    <input type="text" formControlName="triFirstName"
                                                                        pInputText placeholder="Enter First Name" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Last Name
                                                                    </label>
                                                                    <input type="text" formControlName="triLastName"
                                                                        pInputText placeholder="Enter Last Name" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12">
                                                        <div class="ui-fluid p-formgrid p-grid">
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Designation
                                                                    </label>
                                                                    <input type="text" formControlName="triDesignation"
                                                                        pInputText placeholder="Enter Designation" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Mobile No
                                                                    </label>
                                                                    <input type="text" formControlName="triMobile"
                                                                        pInputText placeholder="Enter Mobile No" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Landline No
                                                                    </label>
                                                                    <input type="text" formControlName="triLandline"
                                                                        pInputText placeholder="Enter Landline No" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12">
                                                        <div class="ui-fluid p-formgrid p-grid">
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Email 1
                                                                    </label>
                                                                    <input type="text" formControlName="triEmail1"
                                                                        pInputText placeholder="Enter Email 1" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Email 2
                                                                    </label>
                                                                    <input type="text" formControlName="triEmail2"
                                                                        pInputText placeholder="Enter Email 2" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Email 3
                                                                    </label>
                                                                    <input type="text" formControlName="triEmail3"
                                                                        pInputText placeholder="Enter Email 3" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-mt-1">
                                                        <div class="ui-fluid p-formgrid p-grid">
                                                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                                <h6>GST &amp; PAN Details</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12">
                                                        <div class="ui-fluid p-formgrid p-grid">
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        GST Type
                                                                    </label>
                                                                    <input type="text" formControlName="triGstType"
                                                                        pInputText placeholder="Enter GST Type" class="p-inputtext"
                                                                        [readonly]="true" [disabled]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        GST No
                                                                    </label>
                                                                    <input type="text" formControlName="triGstNo"
                                                                        pInputText placeholder="Enter GST No" class="p-inputtext"
                                                                        [readonly]="true" [disabled]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Pan No
                                                                    </label>
                                                                    <input type="text" formControlName="triPanNo"
                                                                        pInputText placeholder="Enter Pan No" class="p-inputtext"
                                                                        [readonly]="true" [disabled]="true" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-mt-1">
                                                        <div class="ui-fluid p-formgrid p-grid">
                                                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                                <h6>Address Details</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12">
                                                        <div class="ui-fluid p-formgrid p-grid">
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Pin Code
                                                                    </label>
                                                                    <input type="text" formControlName="triPinCode"
                                                                        pInputText placeholder="Enter Pin Code" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        City
                                                                    </label>
                                                                    <input type="text" formControlName="triCity"
                                                                        pInputText placeholder="Enter City" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        State
                                                                    </label>
                                                                    <input type="text" formControlName="triState"
                                                                        pInputText placeholder="Enter State" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12">
                                                        <div class="ui-fluid p-formgrid p-grid">
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Address Line 1
                                                                    </label>
                                                                    <input type="text" formControlName="triAddress1"
                                                                        pInputText placeholder="Enter Address Line 1" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Address Line 2
                                                                    </label>
                                                                    <input type="text" formControlName="triAddress2"
                                                                        pInputText placeholder="Enter Address Line 2" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Address Line 3
                                                                    </label>
                                                                    <input type="text" formControlName="triAddress3"
                                                                        pInputText placeholder="Enter Address Line 3" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12">
                                                        <div class="ui-fluid p-formgrid p-grid">
                                                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                <div class="p-fluid p-field">
                                                                    <label>
                                                                        Address Line 4
                                                                    </label>
                                                                    <input type="text" formControlName="triAddress4"
                                                                        pInputText placeholder="Enter Address Line 4" class="p-inputtext"
                                                                        [readonly]="true" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p-accordionTab>
                                            </p-accordion>
                                        </div>
                                    </p-tabPanel>
                                    <p-tabPanel>
                                        <ng-template pTemplate="header"><span
                                                [ngClass]="{'errorTab': errorOpportunityDetails}">Opportunity
                                                Details</span></ng-template>
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                        <h6>Opportunity Information</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class=" p-fluid p-field">
                                                            <label>
                                                                Opportunity Id
                                                            </label>
                                                            <input type="text" id="opportunityId_id"
                                                                name="opportunityId_name"
                                                                formControlName="opportunityId" pInputText
                                                                placeholder="Enter Opportunity Id" class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Agreement Id
                                                            </label>
                                                            <input type="text" id="agreementId_id"
                                                                name="agreementId_name" formControlName="agreementId"
                                                                pInputText placeholder="Enter Agreement Id" class="p-inputtext"
                                                                [readonly]="true" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                QC Status
                                                            </label>
                                                            <input type="text" id="qcStatus_id" name="qcStatus_name"
                                                                formControlName="qcStatus" pInputText placeholder="Enter QC Status"
                                                                class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Business Type
                                                            </label>
                                                            <p-dropdown id="businessTypeid" name="businessType"
                                                                placeholder="Select" [options]="businessTypeList"
                                                                [autoDisplayFirst]="false"
                                                                formControlName="businessType" optionLabel="property"
                                                                optionValue="property" [disabled]="true">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Product
                                                            </label>
                                                            <p-dropdown id="productselectid" name="productselect"
                                                                placeholder="Select a Product"
                                                                [options]="productselectList" [filter]="true"
                                                                resetFilterOnHide="true" optionLabel="product_name"
                                                                optionValue="product_name" [autoDisplayFirst]="false"
                                                                formControlName="productselect" [disabled]="true">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left"
                                                        *ngIf="!quantumNA">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Instrument
                                                            </label>
                                                            <p-dropdown id="instrumentselectid" name="instrumentselect"
                                                                placeholder="NA" [options]="instrumentselectList"
                                                                [autoDisplayFirst]="false"
                                                                formControlName="instrumentselect" [disabled]="true">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left"
                                                        *ngIf="quantumNA">
                                                        <div class="p-fluid p-field">
                                                            <label>Instrument</label>
                                                            <p-dropdown id="instrumentselectidNA"
                                                                name="instrumentselectNA" placeholder="NA"
                                                                [autoDisplayFirst]="false" [disabled]="true">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left"
                                                        *ngIf="!quantumNA">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Quantum (CR)
                                                            </label>
                                                            <input type="text" id="quantumtextField1_id"
                                                                name="quantumtextField1_name"
                                                                formControlName="quantumtextField1" pInputText
                                                                placeholder="Enter Quantum (CR)" class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left"
                                                        *ngIf="quantumNA">
                                                        <div class="p-fluid p-field">
                                                            <label>Quantum (CR)</label>
                                                            <input type="text" id="quantumtextField1NA_id"
                                                                name="quantumtextField1NA_name" pInputText
                                                                placeholder="NA" class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>  
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                MA Case
                                                            </label>
                                                            <input type="text" id="maCase_id" name="maCase_name"
                                                                formControlName="maCase" pInputText placeholder="Enter MA Case"
                                                                class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Contact
                                                            </label>
                                                            <input type="text" id="tripartyContactName_id"
                                                                name="tripartyContactName_name"
                                                                formControlName="tripartyContactName" pInputText
                                                              class="p-inputtext" disabled />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Category
                                                            </label>
                                                            <input type="text" id="category_id"
                                                                name="category_name"
                                                                formControlName="categoryName" pInputText
                                                                placeholder="Enter Category" class="p-inputtext" readonly />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Exisiting ARC
                                                            </label>
                                                            <input type="text" id="existingArc" name="existingArc"
                                                                formControlName="existingArc" pInputText placeholder="Enter Exisitng Arc"
                                                                class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mt-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                        <h6>Fee Details</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mt-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex">
                                                        <div class="p-d-flex">
                                                            <p>Realized Amount: </p>
                                                            <h6>-</h6>
                                                        </div>
                                                        <div class="p-d-flex p-ml-3">
                                                            <p>Realization Status:</p>
                                                            <h6>-</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="p-col-12">
                                                    <p-accordion  [activeIndex]="0" collapseIcon="pi pi-minus">
                                                        <p-accordionTab header="IRF Fee Details">
                                                            <div class="p-col-12">
                                                                <div class="ui-fluid p-formgrid p-grid">
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                IRF Expected Fee
                                                                            </label>
                                                                            <input type="text" id="irfexpectedFee_id"
                                                                                name="irfexpectedFee_name"
                                                                                formControlName="irfexpectedFee"
                                                                                pInputText placeholder="Enter IRF Expected Fee"
                                                                                class="p-inputtext" readonly />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                IRF Actual Fee
                                                                            </label>
                                                                            <input type="text" id="irfactualFee_id"
                                                                                name="irfactualFee_name"
                                                                                formControlName="irfactualFee"
                                                                                pInputText placeholder="Enter IRF Actual Fee"
                                                                                class="p-inputtext" readonly />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                IRF Discount/Premium
                                                                            </label>
                                                                            <input type="text" id="irfDiscount_id"
                                                                                name="irfDiscount_name"
                                                                                formControlName="irfDiscount" pInputText
                                                                                placeholder="Enter IRF Discount/Premium" class="p-inputtext" readonly />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="p-col-12">
                                                                <div class="ui-fluid p-formgrid p-grid">
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                IRF Discount/Premium %
                                                                            </label>
                                                                            <input type="text"
                                                                                id="irfDiscountPercent_id"
                                                                                name="irfDiscountPercent_name"
                                                                                formControlName="irfDiscountPercent"
                                                                                pInputText placeholder="Enter IRF Discount/Premium %"
                                                                                class="p-inputtext" readonly />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                IRF Discount Reason
                                                                            </label>
                                                                            <p-dropdown id="irfDiscountReasonid"
                                                                                name="irfDiscountReason"
                                                                                placeholder="Select a IRF Discount Reason"
                                                                                [options]="irfDiscountReasonList"
                                                                                [filter]="true" resetFilterOnHide="true"
                                                                                optionLabel="name" optionValue="name"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="irfDiscountReason"
                                                                                [disabled]="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </p-accordionTab>
                                                        <p-accordionTab header="SF Fee Details">
                                                            <div class="p-col-12">
                                                                <div class="ui-fluid p-formgrid p-grid">
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                SF Fee Rule
                                                                            </label>
                                                                            <p-dropdown [options]="sfFeeRuleList"
                                                                                optionLabel="value"
                                                                                (onChange)="fixedFeeChanged($event)"
                                                                                optionValue="id"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="sfFeeRule"
                                                                                [ngClass]="{error: qcRejectMap['survFeeRule']}"
                                                                                name="sfFeeRule"
                                                                                [disabled]="(!qcRejectMap['survFeeRule'] && businessReportedStatusFlagA) || otherOpportunityStatusFlagA ||  disableSfFeeRule">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4"
                                                                        *ngIf="showMinMax">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Minimum</label>
                                                                            <input type="text" formControlName="minimum"
                                                                                name="minimum" pInputText
                                                                                (keyup)="validateMin()"
                                                                                class="p-inputtext"
                                                                                [ngClass]="{error: ReportingScreenDetailsForm.get('minimum')?.errors?.required || qcRejectMap['minimumValue']}"
                                                                                [readonly]="(!qcRejectMap['minimumValue'] && businessReportedStatusFlagA)" />
                                                                            <div class="invalid-feedback" *ngIf="ReportingScreenDetailsForm.get('minimum')?.errors?.required">
                                                                                Minimum is required
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4"
                                                                        *ngIf="showMinMax">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Maximum</label>
                                                                            <input type="text" formControlName="maximum"
                                                                                name="maximum" pInputText
                                                                                (keyup)="validateMax()"
                                                                                class="p-inputtext"
                                                                                [ngClass]="{error: ReportingScreenDetailsForm.get('maximum')?.errors?.required || qcRejectMap['maximumValue']}"
                                                                                [readonly]="(!qcRejectMap['maximumValue'] && businessReportedStatusFlagA)" />
                                                                            <div class="invalid-feedback" *ngIf="ReportingScreenDetailsForm.get('maximum')?.errors?.required">
                                                                                Maximum is required
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4"
                                                                        *ngIf="!sfIdealFeeNA">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                SF Expected Fee
                                                                            </label>
                                                                            <input type="text" id="sfExpectedFee_id"
                                                                                name="sfExpectedFee_name"
                                                                                formControlName="sfExpectedFee"
                                                                                pInputText placeholder="Enter SF Expected Fee"
                                                                                class="p-inputtext" [readonly]="true" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4"
                                                                        *ngIf="sfIdealFeeNA">
                                                                        <div class="p-fluid p-field">
                                                                            <label>SF Expected Fee</label>
                                                                            <input type="text" id="sfExpectedFeeNA_id"
                                                                                name="sfExpectedFeeNA_name" pInputText
                                                                                placeholder="NA" class="p-inputtext"
                                                                                [readonly]="true" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>SF Expected %</label>
                                                                            <input type="text"
                                                                                formControlName="sfExpectedPercent"
                                                                                name="sfExpectedpercent" pInputText
                                                                                placeholder="Enter SF Expected %" class="p-inputtext"
                                                                                disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4"
                                                                        *ngIf="!sfActualFeesNA">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                SF Actual Fee
                                                                            </label>
                                                                            <input type="text" id="sfActualFee_id"
                                                                                name="sfActualFee_name"
                                                                                formControlName="sfActualFee" pInputText
                                                                                placeholder="Enter SF Actual Fee" class="p-inputtext" [readonly]="true" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4"
                                                                        *ngIf="showMinMax">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                Revised SF Actual
                                                                            </label>
                                                                            <input type="text" id="sfActualFee_id"
                                                                                name="sfActualFee_name"
                                                                                formControlName="revisedSfActual" pInputText
                                                                                placeholder="Enter Revised SF Actual" class="p-inputtext" [readonly]="true" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4"
                                                                        *ngIf="sfActualFeesNA">
                                                                        <div class="p-fluid p-field">
                                                                            <label>SF Actual Fee</label>
                                                                            <input type="text" id="sfActualFeeNA_id"
                                                                                name="sfActualFeeNA_name" pInputText
                                                                                placeholder="NA" class="p-inputtext" [readonly]="true" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>SF Actual %</label>
                                                                            <input type="text"
                                                                                formControlName="sfActualPercent"
                                                                                name="sfActualPercent" pInputText
                                                                                (input)="validateSfActual()"
                                                                                class="p-inputtext" placeholder="Enter SF Actual %"
                                                                                [ngClass]="{error: qcRejectMap['survActualPercent']}"
                                                                                [readonly]="(!qcRejectMap['survActualPercent'] && businessReportedStatusFlagA) || disabledSfAct" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                SF Discount/Premium
                                                                            </label>
                                                                            <input type="text" id="sfDiscountPremium_id"
                                                                                name="sfDiscountPremium_name"
                                                                                formControlName="sfDiscountPremium"
                                                                                pInputText placeholder="Enter SF Discount/Premium"
                                                                                class="p-inputtext" [readonly]="true" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                SF Discount/Premium %
                                                                            </label>
                                                                            <input type="text"
                                                                                id="sfDiscountPremiumPercent_id"
                                                                                name="sfDiscountPremiumPercent_name"
                                                                                [readonly]="true"
                                                                                formControlName="sfDiscountPremiumPercent"
                                                                                pInputText placeholder="Enter SF Discount/Premium %"
                                                                                class="p-inputtext" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                        <div class="p-fluid p-field">
                                                                            <label>
                                                                                SF Discount Reason
                                                                            </label>
                                                                            <p-dropdown id="sfDiscountReasonid"
                                                                                name="sfDiscountReason"
                                                                                placeholder="Select a SF Discount Reason"
                                                                                [options]="irfDiscountReasonList"
                                                                                [filter]="true" resetFilterOnHide="true"
                                                                                optionLabel="name" optionValue="name"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="sfDiscountReason"
                                                                                [disabled]="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </p-accordionTab>
                                                    </p-accordion>
                                                </div>
                                            </div>
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-fluid p-field">
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-fluid p-field">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" p-col-12 p-mt-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                        <h6>Payment Details</h6>
                                                        <span *ngIf="grossAmountMoreFlag"
                                                            style="color: red; font-size: 12px;">Gross Amount is more
                                                            than
                                                            Actual IRF fee; please check</span>
                                                        <span *ngIf="grossAmountLessFlag"
                                                            style="color: red; font-size: 12px;">Gross Amount is less
                                                            than
                                                            Actual IRF fee; please check</span>
                                                        <span *ngIf="paymentDetailsMandatoryFlag"
                                                            style="color: red; font-size: 12px;">Few details are missing
                                                            in
                                                            payment details
                                                        </span>
                                                        <div class="p-d-flex">
                                                            <label class="nowrap">Payment Collected : </label>
                                                            <h1 class="p-medium display-4">{{paymentCollected}}%</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="p-col-12 paymentAccordion">
                                                    <p-accordion collapseIcon="pi pi-minus" id="p_accordion" (onOpen)="currentPayment($event)"
                                                        (onClose)="openedPaymentData?.clear();">
                                                        <p-accordionTab class="p-accordiantab"
                                                            *ngFor="let paymentData of payments.controls; let i = index;"
                                                            header="Payment Mode {{i+1}}">
                                                            <form [formGroup]="paymentData">
                                                                <div class="p-fluid p-grid">
                                                                    <div class="p-col-12 p-d-flex">
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Payment Mode</label>
                                                                                <p-dropdown
                                                                                    placeholder="Select a Payment"
                                                                                    formControlName="paymentMode"
                                                                                    [disabled]="true"
                                                                                    [options]="paymentModeList"
                                                                                    [filter]="true"
                                                                                    resetFilterOnHide="true"
                                                                                    optionLabel="property"
                                                                                    optionValue="property"
                                                                                    [autoDisplayFirst]="false">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>NEFT/Cheque No</label>
                                                                                <input pInputText type="text"
                                                                                    class="textUppercase"
                                                                                    maxlength="{{ isCheque ? 6 : 100 }}"
                                                                                    (keypress)="numberOnlyCheque($event)"
                                                                                    formControlName="chequeNo"
                                                                                    [ngClass]="{error:((paymentData.get('chequeNo').invalid) && (paymentData.get('chequeNo').dirty || paymentData.get('chequeNo').touched) || qcRejectMap[paymentData.get('id').value])}"
                                                                                    [readonly]="otherOpportunityStatusFlagA || ((businessReportedStatusFlagA || firstPayType) && (paymentData.get('isAdditionalPayment')?.value == null || (isQcApproved && paymentData.get('isAdditionalPayment')?.value === 'Yes' && allQCPayments[paymentData.get('id')?.value]?.isApproved === 'Approved'))) && !qcRejectMap[paymentData.get('id').value]" />
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="(paymentData.get('chequeNo').invalid) && (paymentData.get('chequeNo').dirty || paymentData.get('chequeNo').touched)">
                                                                                    Please enter correct value for field
                                                                                    “NEFT/cheque no".
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Bank</label>
                                                                                <p-dropdown placeholder="Select a Bank"
                                                                                    formControlName="bank"
                                                                                    [options]="bankList"
                                                                                    [disabled]="otherOpportunityStatusFlagA || ((businessReportedStatusFlagA || firstPayType) && (paymentData.get('isAdditionalPayment')?.value == null || (isQcApproved && paymentData.get('isAdditionalPayment')?.value === 'Yes' && allQCPayments[paymentData.get('id')?.value]?.isApproved === 'Approved'))) && !qcRejectMap[paymentData.get('id').value]"
                                                                                    [filter]="true"
                                                                                    resetFilterOnHide="true"
                                                                                    optionLabel="name"
                                                                                    optionValue="name"
                                                                                    [ngClass]="{error:(paymentData.get('bank').invalid) && (paymentData.get('bank').dirty || paymentData.get('bank').touched) || qcRejectMap[paymentData.get('id').value]}"
                                                                                    [autoDisplayFirst]="false">
                                                                                </p-dropdown>
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="(paymentData.get('bank').invalid) && (paymentData.get('bank').dirty || paymentData.get('bank').touched)">
                                                                                    Please select a bank.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-fluid p-grid p-mt-3">
                                                                    <div class="p-col-12 p-d-flex">
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Payment Date </label>
                                                                                <p-calendar dateFormat="dd-mm-yy"
                                                                                    id="dateCalendar_id"
                                                                                    placeholder="Date"
                                                                                    formControlName="paymentDat"
                                                                                    [hideOnDateTimeSelect]="true"
                                                                                    [showIcon]="true"
                                                                                    yearRange="2000:2030"
                                                                                    [ngClass]="{error: (paymentData.get('paymentDat')?.invalid && paymentData.get('paymentDat')?.errors?.required) && (paymentData.get('paymentDat').dirty || paymentData.get('paymentDat').touched) || qcRejectMap[paymentData.get('id').value]}"
                                                                                    [disabled]="otherOpportunityStatusFlagA || ((businessReportedStatusFlagA || firstPayType) && (paymentData.get('isAdditionalPayment')?.value == null || (isQcApproved && paymentData.get('isAdditionalPayment')?.value === 'Yes' && allQCPayments[paymentData.get('id')?.value]?.isApproved === 'Approved'))) && !qcRejectMap[paymentData.get('id').value]">
                                                                                </p-calendar>
                                                                                <div class="invalid-feedback"
                                                                                    *ngIf="(paymentData.get('paymentDat').invalid) && (paymentData.get('paymentDat').dirty || paymentData.get('paymentDat').touched)">
                                                                                    Payment Date is required.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Payment Type</label>
                                                                                <p-dropdown
                                                                                    placeholder="Select a Payment"
                                                                                    formControlName="paymentType"
                                                                                    [options]="paymenttypeList"
                                                                                    [filter]="true"
                                                                                    resetFilterOnHide="true"
                                                                                    optionLabel="property"
                                                                                    optionValue="property"
                                                                                    [autoDisplayFirst]="false"
                                                                                    [disabled]="true">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>TDS Type</label>
                                                                                <input type="text" pInputText
                                                                                    placeholder="Enter TDS Type"
                                                                                    formControlName="tdsType" [readonly]="true"
                                                                                    class="p-inputtext" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-fluid p-grid p-mt-3">
                                                                    <div class="p-col-12 p-d-flex">
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>TDS Amount</label>
                                                                                <input type="text" pInputText
                                                                                    placeholder="Enter TDS Amount"
                                                                                    formControlName="tdsAmount" [readonly]="true"
                                                                                    class="p-inputtext" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Amount Collected</label>
                                                                                <input type="text" pInputText
                                                                                    placeholder="Enter Amount Collected"
                                                                                    formControlName="amount" [readonly]="true"
                                                                                    class="p-inputtext" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-d-flex p-flex-column">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Gross Amount</label>
                                                                                <input type="text" pInputText
                                                                                    placeholder="Enter Gross Amount"
                                                                                    formControlName="grossAmount"
                                                                                    [readonly]="true" class="p-inputtext" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-fluid p-grid p-mt-3">
                                                                    <div class="p-col-12 p-d-flex">
                                                                        <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Finance Comment</label>
                                                                                <textarea pInputTextarea
                                                                                    name="fin_comment"
                                                                                    formControlName="finComment"
                                                                                    rows="3" cols="3" placeholder="Enter Finance Comment"
                                                                                    disabled="true">
                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Payment Status</label>
                                                                                <input type="text" pInputText
                                                                                    placeholder="Enter Payment Status"
                                                                                    formControlName="payStatus" [readonly]="true"
                                                                                    class="p-inputtext" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                                                            <div class="p-fluid p-field">
                                                                                <label>Rejected Reason</label>
                                                                                <textarea pInputTextarea
                                                                                    name="fin_comment"
                                                                                    formControlName="rejectedReason"
                                                                                    rows="3" cols="3" placeholder="Enter Rejected Reason" disabled="true">
                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </p-accordionTab>
                                                    </p-accordion>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mt-1">
                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                    <h6>3rd Party Details</h6>
                                                </div>
                                            </div>
                                            <div class=" p-col-12" *ngIf="!otherOpportunityStatusFlag">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class=" p-fluid   p-field    ">
                                                            <label>
                                                                3rd Party Payment Approval
                                                            </label>
                                                            <div class="thirdPartyApprovalDropdown">
                                                                <p-dropdown id="PartyApprovalid" name="PartyApproval"
                                                                    placeholder="Select a 3rd Party Payment Approval"
                                                                    [options]="PartyApprovalList"
                                                                    [autoDisplayFirst]="false"
                                                                    (onChange)="ThirdPartyApprovalCommentControl()"
                                                                    formControlName="PartyApproval">
                                                                </p-dropdown>
                                                            </div>
                                                            <!-- <div class="invalid-feedback "
                                                                *ngIf="ReportingScreenDetailsForm.get('PartyApproval')?.errors?.required && !isPartyAppSelected">
                                                                <div>
                                                                    3rd Party Payment Approval is required.
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                3rd Party Approval Comment <span *ngIf="thirdPartyFlag"
                                                                    class='invalid-feedback-astrik'>*</span>
                                                            </label>
                                                            <div *ngIf="thirdPartyFlag">
                                                                <textarea pInputTextarea id="partyApprovalComment_id"
                                                                    name="partyApprovalComment_name"
                                                                    formControlName="partyApprovalComment" rows="3"
                                                                    cols="3" placeholder="Enter 3rd Party Approval Comment" minlength="2"
                                                                    [ngClass]="{error:ReportingScreenDetailsForm.get('partyApprovalComment')?.errors?.required && isPartyAppComment || (ReportingScreenDetailsForm.get('partyApprovalComment')!.invalid && (ReportingScreenDetailsForm.get('partyApprovalComment')!.dirty || ReportingScreenDetailsForm.get('partyApprovalComment')!.touched)) }">
                                                                </textarea>
                                                                <div class="invalid-feedback"
                                                                    *ngIf="ReportingScreenDetailsForm.get('partyApprovalComment')?.errors?.required && isPartyAppComment || (ReportingScreenDetailsForm.get('partyApprovalComment')!.invalid && (ReportingScreenDetailsForm.get('partyApprovalComment')!.dirty || ReportingScreenDetailsForm.get('partyApprovalComment')!.touched))">
                                                                    3rd Party Approval Comment is required
                                                                </div>
                                                            </div>
                                                            <div *ngIf="!thirdPartyFlag">
                                                                <textarea pInputTextarea id="partyApprovalComment_id"
                                                                    name="partyApprovalComment_name"
                                                                    formControlName="partyApprovalComment" rows="3"
                                                                    cols="3" placeholder="Enter"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class=" p-fluid   p-field  p-d-flex p-flex-column  ">
                                                            <label>
                                                                Comment
                                                            </label>
                                                            <textarea pInputTextarea id="comment_id" name="comment_name"
                                                                formControlName="comment" rows="3" placeholder="Enter Comment"
                                                                disabled="true"></textarea>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" p-col-12" *ngIf="otherOpportunityStatusFlag">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class=" p-fluid   p-field    ">
                                                            <label>
                                                                3rd Party Payment Approval
                                                            </label>
                                                            <p-dropdown id="PartyApprovalid" name="PartyApproval"
                                                                placeholder="Select a 3rd Party Approval"
                                                                [options]="PartyApprovalList" [autoDisplayFirst]="false"
                                                                [disabled]="otherOpportunityStatusFlag || partyApprovalComment"
                                                                formControlName="PartyApproval">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class=" p-fluid   p-field    ">
                                                            <label>
                                                                3rd Party Approval Comment
                                                            </label>
                                                            <textarea pInputTextarea id="partyApprovalComment_id"
                                                                name="partyApprovalComment_name"
                                                                formControlName="partyApprovalComment" rows="3"
                                                                placeholder="Enter 3rd Party Approval Comment" disabled="true"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class=" p-fluid   p-field  p-d-flex p-flex-column  ">
                                                            <label>
                                                                Comment
                                                            </label>
                                                            <textarea pInputTextarea id="comment_id" name="comment_name"
                                                                formControlName="comment" rows="3" placeholder="Enter Comment"
                                                                disabled="true"></textarea>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p-tabPanel>
                                    <p-tabPanel>
                                        <ng-template pTemplate="header"><span
                                                [ngClass]="{'errorTab': errorMcaCheck}">Exposure
                                                Check</span></ng-template>
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class=" p-col-12" *ngIf="!otherOpportunityStatusFlag">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-fluid p-field">
                                                            <label>Client Type</label>
                                                            <input type="text" pInputText placeholder="Enter Client Type"
                                                                formControlName="clientType" [readonly]="true"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-fluid p-field">
                                                            <label> Cost Center<span
                                                                    class='invalid-feedback-astrik'>*</span>
                                                            </label>
                                                            <p-dropdown id="costCenterselectid" name="costCenterselect"
                                                                [disabled]="partyApprovalComment"
                                                                placeholder="Select a Cost Center"
                                                                [options]="costCenterselectList" [filter]="true"
                                                                resetFilterOnHide="true" optionLabel="name"
                                                                optionValue="id" [autoDisplayFirst]="false"
                                                                formControlName="costCenterselect"
                                                                (onChange)="onExposureChange($event)"
                                                                [ngClass]="{error:ReportingScreenDetailsForm.get('costCenterselect')?.errors?.required}">
                                                            </p-dropdown>
                                                            <div class="invalid-feedback"
                                                                *ngIf="isCostCenter || (ReportingScreenDetailsForm.get('costCenterselect')!.invalid && (ReportingScreenDetailsForm.get('costCenterselect')!.dirty || ReportingScreenDetailsForm.get('costCenterselect')!.touched))">
                                                                <div
                                                                    *ngIf="ReportingScreenDetailsForm.get('costCenterselect')?.errors?.required">
                                                                    Cost Center is required
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Exposure As Per MCA (CR)<span
                                                                    class='invalid-feedback-astrik'>*</span>
                                                            </label>
                                                            <input type="text" id="exposureperMCAtextField_id"
                                                                name="exposureperMCAtextField_name"
                                                                formControlName="exposureperField" pInputText
                                                                placeholder="Enter Exposure As Per MCA (CR)" class="p-inputtext"
                                                                (input)="onExposureChange()"
                                                                (keypress)="PressNumbers($event)"
                                                                [readonly]="disabledExposureMca"
                                                                [ngClass]="{error:isExposureperMCA || (ReportingScreenDetailsForm.get('exposureperField').invalid && (ReportingScreenDetailsForm.get('exposureperField').dirty && ReportingScreenDetailsForm.get('exposureperField').touched))}" />
                                                            <div class="invalid-feedback"
                                                                *ngIf="isExposureperMCA || (ReportingScreenDetailsForm.get('exposureperField').invalid && (ReportingScreenDetailsForm.get('exposureperField').dirty && ReportingScreenDetailsForm.get('exposureperField').touched))">
                                                                <div
                                                                    *ngIf="ReportingScreenDetailsForm.get('exposureperField')?.errors?.required">
                                                                    Exposure is required
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Status
                                                            </label>
                                                            <input type="text" id="MCAstatus_id" name="MCAstatus_name"
                                                                formControlName="MCAstatus" pInputText placeholder="Enter Status"
                                                                class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" p-col-12" *ngIf="otherOpportunityStatusFlag">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-fluid p-field">
                                                            <label>Client Type</label>
                                                            <input type="text" pInputText placeholder="Enter Client Type"
                                                                formControlName="clientType" [readonly]="true"
                                                                class="p-inputtext" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Cost Center
                                                            </label>
                                                            <p-dropdown id="costCenterselectid" name="costCenterselect"
                                                                placeholder="Select a Cost Center"
                                                                [options]="costCenterselectList" optionLabel="name"
                                                                optionValue="id" [autoDisplayFirst]="false"
                                                                formControlName="costCenterselect"
                                                                [ngClass]="{error: qcRejectMap['costCenter']}"
                                                                (onChange)="onExposureChange($event)"
                                                                [disabled]="otherOpportunityStatusFlagA || (businessReportedStatusFlagA && !qcRejectMap['costCenter'])">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Exposure As Per MCA (CR)<span
                                                                    class='invalid-feedback-astrik'
                                                                    [ngClass]="{'hide': otherOpportunityStatusFlag}">*</span>
                                                            </label>
                                                            <input type="text" id="exposureperMCAtextField_id"
                                                                name="exposureperMCAtextField_name"
                                                                formControlName="exposureperField" pInputText
                                                                placeholder="Enter Exposure As Per MCA (CR)" class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>
                                                                Status
                                                            </label>
                                                            <input type="text" id="MCAstatus_id" name="MCAstatus_name"
                                                                formControlName="MCAstatus" pInputText placeholder="Enter Status"
                                                                class="p-inputtext" [readonly]="true" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p-tabPanel>
                                    <p-tabPanel>
                                        <ng-template pTemplate="header"><span
                                                [ngClass]="{'errorTab': errorAttachments}">Attachments</span>
                                        </ng-template>
                                        <div class="p-fluid p-formgrid p-grid attachmentSectionReporting">
                                            <div class=" p-field p-col-12 p-px-3 horizontalTabView">
                                                <p-tabView
                                                    [(activeIndex)]="ReportingScreenDetailsForm.value.AttachmentInnertabview"
                                                    (onChange)="handleChangeAttachmentInnertabview($event)">
                                                    <p-tabPanel header="Uploaded Document">
                                                        <div class="p-fluid p-formgrid p-grid">
                                                            <div class=" p-col-12 p-pl-0 p-pr-0">
                                                                <div class="ui-fluid p-formgrid p-grid">
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-left p-pl-0 p-pr-0">
                                                                        <div class="p-field p-d-flex p-flex-wrap"
                                                                            style="justify-content: center;"
                                                                            *ngIf="displayAttachData.length===0">
                                                                            <div class="p-mt-3 p-mb-3"
                                                                                style="text-align: center; color: rgba(41, 41, 41, 0.6);">
                                                                                <div
                                                                                    class="p-mb-1 p-d-flex p-jc-center">
                                                                                    <svg width="204" height="124"
                                                                                        class="illustrationLight"
                                                                                        viewBox="0 0 204 124"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                                                                            fill="#EDEFF6" />
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984"
                                                                                            fill="white" />
                                                                                        <path
                                                                                            d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM50.1996 28.4313L51.4063 28.2832L50.1996 28.4313ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z"
                                                                                            fill="#9DC0FB" />
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711"
                                                                                            fill="#EBF3FE" />
                                                                                        <path
                                                                                            d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z"
                                                                                            fill="white"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="2.43137" />
                                                                                        <path
                                                                                            d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M86.3735 21.8823H117.981"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M86.3735 80.2354H117.981"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M86.3735 36.4707H138.648"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M86.3735 51.0586H138.648"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M86.3735 65.647H138.648"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z"
                                                                                            fill="#F5F9FF"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="1.45882" />
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44"
                                                                                            fill="white" />
                                                                                        <path
                                                                                            d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="1.45882"
                                                                                            stroke-linecap="round" />
                                                                                        <path
                                                                                            d="M152.335 102.501L156.943 107.169"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="1.45882" />
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z"
                                                                                            fill="#EBF3FE"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="1.45882" />
                                                                                        <path
                                                                                            d="M158.991 107.688L164.623 113.393"
                                                                                            stroke="white"
                                                                                            stroke-width="1.31692"
                                                                                            stroke-linecap="round" />
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z"
                                                                                            fill="#EBF3FE" />
                                                                                        <path
                                                                                            d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="1.45882"
                                                                                            stroke-linecap="round" />
                                                                                    </svg>

                                                                                    <svg width="204" height="124"
                                                                                        class="illustrationDark"
                                                                                        viewBox="0 0 204 124"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                                                                            fill="#2C2C2C" />
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984"
                                                                                            fill="#141414" />
                                                                                        <path
                                                                                            d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z"
                                                                                            fill="#9DC0FB" />
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711"
                                                                                            fill="#233655" />
                                                                                        <path
                                                                                            d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z"
                                                                                            fill="#141414"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="2.43137" />
                                                                                        <path
                                                                                            d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M86.3735 21.8823H117.981"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M86.3735 80.2354H117.981"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M86.3735 36.4707H138.648"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M86.3735 51.0586H138.648"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M86.3735 65.647H138.648"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="2.43137"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round" />
                                                                                        <path
                                                                                            d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z"
                                                                                            fill="#233655"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="1.45882" />
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44"
                                                                                            fill="#141414" />
                                                                                        <path
                                                                                            d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="1.45882"
                                                                                            stroke-linecap="round" />
                                                                                        <path
                                                                                            d="M152.335 102.501L156.943 107.169"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="1.45882" />
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z"
                                                                                            fill="#233655"
                                                                                            stroke="#76A7F9"
                                                                                            stroke-width="1.45882" />
                                                                                        <path
                                                                                            d="M158.991 107.688L164.623 113.393"
                                                                                            stroke="#141414"
                                                                                            stroke-width="1.31692"
                                                                                            stroke-linecap="round" />
                                                                                        <path fill-rule="evenodd"
                                                                                            clip-rule="evenodd"
                                                                                            d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z"
                                                                                            fill="#233655" />
                                                                                        <path
                                                                                            d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738"
                                                                                            stroke="#9DC0FB"
                                                                                            stroke-width="1.45882"
                                                                                            stroke-linecap="round" />
                                                                                    </svg>
                                                                                </div>
                                                                                <p class="p-mt-3 p-mb-0">No document
                                                                                    available</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-field p-d-flex p-flex-wrap p-d-flex p-jc-end p-col-12 p-p-0 p-my-1"
                                                                            *ngIf="enablePDF">
                                                                            <div class="p-p-0">
                                                                                <button pButton
                                                                                    class="p-button-secondary"
                                                                                    style="text-align: center;"
                                                                                    (click)="showCombinePDFPopUp=true">Combine as PDF</button>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="p-field p-d-flex p-flex-wrap p-col-12 displayEditTypeModal p-p-0">
                                                                            <div class="uploadedDocumentAttachment p-col-12 p-md-4 p-lg-4 p-flex-wrap"
                                                                                *ngFor="let item of displayAttachData; let i = index;">
                                                                                <p-card class="attachmentCard">
                                                                                    <div
                                                                                        class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-jc-between p-flex-wrap">
                                                                                        <div
                                                                                            class="p-col-10 p-d-flex p-pl-0 p-pr-0 p-ai-center">
                                                                                            <div class="p-col-1 p-pl-0 p-pr-0 p-d-flex p-align-items-center"
                                                                                                style="margin-right: 3px"
                                                                                                *ngIf="(businessWonStatusFlagA || businessReportedStatusFlagA) && (item.fileTypeName == 'Mandate,Page 1' || item.fileTypeName == 'Mandate,Page 2' 
                                                                                            || item.fileTypeName == 'Mandate,Page 3' || item.fileTypeName == 'Mandate,Page 4' 
                                                                                            || item.fileTypeName == 'Mandate,Page 5' || item.fileTypeName == 'Mandate,Page 6' 
                                                                                            || item.fileTypeName == 'Mandate,Page 7' || item.fileTypeName == 'Mandate,Page 8' 
                                                                                            || item.fileTypeName == 'Mandate,Page 9' || item.fileTypeName == 'Mandate,Page 10')">
                                                                                                <div
                                                                                                    class="p-mb-0 p-field-checkbox">
                                                                                                    <p-checkbox
                                                                                                        (onChange)="checkedMandate(i,item)"
                                                                                                        *ngIf="!reportingReadScope"
                                                                                                        binary="true"
                                                                                                        [value]="item"
                                                                                                        formControlName="attachmentSelected">
                                                                                                    </p-checkbox>
                                                                                                </div>
                                                                                                <!-- <input type="checkbox"
                                                                                                    id="checkBoxs{{i}}"
                                                                                                    class="cursor-pointer p-m-1"
                                                                                                    *ngIf="!reportingReadScope"
                                                                                                    (click)="checkedMandate($event.target.checked,item)" /> -->
                                                                                            </div>
                                                                                            <div
                                                                                                style="padding-right: 9px;">
                                                                                                <img class="uploadedThumbnail"
                                                                                                    *ngIf="item.fileContentType == 'application/pdf'"
                                                                                                    [src]="pdfThumb"
                                                                                                    alt="">
                                                                                                <img class="uploadedThumbnail"
                                                                                                    *ngIf="item.fileContentType == 'image/png' || item.fileContentType == 'image/jpg' || item.fileContentType == 'image/jpeg'"
                                                                                                    [src]="imgThumb"
                                                                                                    alt="">
                                                                                                <img class="uploadedThumbnail"
                                                                                                    *ngIf="item.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || item.fileContentType == 'application/msword' "
                                                                                                    [src]="wordThumb"
                                                                                                    alt="">
                                                                                                <img class="uploadedThumbnail"
                                                                                                    *ngIf="item.fileContentType == ''"
                                                                                                    [src]="mailThumb"
                                                                                                    alt="">
                                                                                            </div>
                                                                                            <div
                                                                                                class="p-col-10 p-pl-0 p-pr-0">
                                                                                                <a
                                                                                                    (click)="showAttachmentPreview(item)">
                                                                                                    <div class="p-col-11 p-d-flex p-flex-column p-flex-wrap p-ai-start p-pl-0"
                                                                                                        style="text-align:left;">
                                                                                                        <div
                                                                                                            class="limit-text-length attachmentName cursor-pointer">
                                                                                                            {{item.fileName}}
                                                                                                        </div>
                                                                                                        <label
                                                                                                            class="attachmentFileType">Type:
                                                                                                            {{item.fileTypeName}}</label>
                                                                                                    </div>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div *ngIf="checkCombineDeleteCond() && !reportingReadScope"
                                                                                            class="p-col-2 p-d-flex p-jc-end p-pr-0 attachmentAction">
                                                                                            <svg width="16" height="16"
                                                                                                viewBox="0 0 16 16"
                                                                                                (click)="showEditTypeDialog(item)"
                                                                                                class="cursor-pointer attachmentIconSvg"
                                                                                                fill="none"
                                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                                <path
                                                                                                    d="M7.33594 2.66699H2.66927C2.31565 2.66699 1.97651 2.80747 1.72646 3.05752C1.47641 3.30756 1.33594 3.6467 1.33594 4.00033V13.3337C1.33594 13.6873 1.47641 14.0264 1.72646 14.2765C1.97651 14.5265 2.31565 14.667 2.66927 14.667H12.0026C12.3562 14.667 12.6954 14.5265 12.9454 14.2765C13.1955 14.0264 13.3359 13.6873 13.3359 13.3337V8.66699"
                                                                                                    stroke="#3E5B8C"
                                                                                                    stroke-linecap="round"
                                                                                                    stroke-linejoin="round" />
                                                                                                <path
                                                                                                    d="M12.3359 1.66714C12.6012 1.40193 12.9609 1.25293 13.3359 1.25293C13.711 1.25293 14.0707 1.40193 14.3359 1.66714C14.6012 1.93236 14.7502 2.29207 14.7502 2.66714C14.7502 3.04222 14.6012 3.40193 14.3359 3.66714L8.0026 10.0005L5.33594 10.6671L6.0026 8.00048L12.3359 1.66714Z"
                                                                                                    stroke="#3E5B8C"
                                                                                                    stroke-linecap="round"
                                                                                                    stroke-linejoin="round" />
                                                                                            </svg>
                                                                                        </div>

                                                                                        <div class="p-col-2 p-d-flex p-jc-end p-pr-0 attachmentAction"
                                                                                            *ngIf="(((isQcApproved && ogMandateRejected && item?.fileTypeid == '41') || attachmentRejected) && !reportingReadScope)">
                                                                                            <!-- <div class="p-col-1 p-pr-0 p-d-flex p-jc-end"> -->
                                                                                            <i class="pi pi-trash cursor-pointer attachmentIcon"
                                                                                                (click)="onDeletePopup(item.fileTypeName, item.fileId, item.fileTypeid, item.fileId)"
                                                                                                aria-hidden="true"></i>
                                                                                            <!-- </div> -->
                                                                                        </div>
                                                                                    </div>
                                                                                </p-card>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-tabPanel>
                                                    <p-tabPanel header="Document To Be Uploaded">
                                                        <form [formGroup]="documentToBeUploaded">
                                                            <div
                                                                class="p-grid p-d-flex p-flex-wrap p-col-12 p-p-0 documentToBeUploaded">
                                                                <div class="p-col-12 p-md-4 p-lg-4 attachmentCard attachmentContainer p-flex-wrap"
                                                                    *ngFor="let attach of docList; let i = index"
                                                                    [ngClass]="{'attachmentError': saveButtonClicked && attach.is_mandatory == 'M' && attach.file == '' && isAttachmentM == true}">
                                                                    <p-card
                                                                        [ngClass]="{'error' : saveButtonClicked && attach.is_mandatory == 'M' && attach.file == '' && isAttachmentM == true}">
                                                                        <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center"
                                                                            *ngIf="!attach.file">
                                                                            <div class="p-col-7 p-pl-0"><label
                                                                                    class="attachmentType lableToBeUploaded">{{attach.type}}
                                                                                    <span
                                                                                        class='invalid-feedback-astrik'
                                                                                        [ngClass]="{'hide': otherOpportunityStatusFlagA}"
                                                                                        *ngIf="attach.is_mandatory == 'M' && isAttachmentM == true">*</span>
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                class="p-col-5 p-pl-0 p-d-flex p-jc-end p-pr-0">
                                                                                <p-fileUpload customUpload="true"
                                                                                    id="fileUpload1" name="file"
                                                                                    accept="image/*, .pdf, .jpeg , .jpg, .png, .doc, .docx, .msg"
                                                                                    maxFileSize=20000000 mode="advanced"
                                                                                    [showUploadButton]="false"
                                                                                    [showCancelButton]="false"
                                                                                    [disabled]="attach.isDisabled || reportingReadScope"
                                                                                    #fileUploadfileUpload13
                                                                                    (onSelect)="onSelectfileUpload1($event, attach.type, attach.id)"
                                                                                    chooseLabel="Choose File">
                                                                                </p-fileUpload>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap"
                                                                            *ngIf="attach.file">
                                                                            <div
                                                                                class="p-col-11 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                                                                <div class="p-col-2 p-p-0">
                                                                                    <img [src]="attach.thumbNail"
                                                                                        alt="">
                                                                                </div>
                                                                                <div
                                                                                    class="p-col-10 p-p-1 p-d-flex p-flex-column p-flex-wrap">
                                                                                    <div
                                                                                        class="attachmentName limit-text-length">
                                                                                        {{ attach.fileName }}</div>
                                                                                    <label
                                                                                        class="attachmentFileType">Type:
                                                                                        {{attach.type}}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="p-col-1 p-pr-0 p-d-flex p-jc-end">
                                                                                <i class="pi pi-trash cursor-pointer attachmentIcon"
                                                                                    (click)="onDeletePopup(attach.type, attach.id, attach.fileTypeid, attach.deleteId)"
                                                                                    aria-hidden="true"></i>
                                                                            </div>
                                                                        </div>
                                                                    </p-card>
                                                                    <div class="required invalid-feedback p-d-flex p-ai-center"
                                                                        *ngIf="saveButtonClicked && attach.is_mandatory == 'M' && attach.file == '' && isAttachmentM == true">
                                                                        <i class="pi pi-exclamation-circle p-mr-1"
                                                                            aria-hidden="true"></i>Attachment Required
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </form>
                                                    </p-tabPanel>
                                                    <p-tabPanel header="Combined Document">
                                                        <div class="p-fluid p-formgrid p-grid">
                                                            <div
                                                                class="p-col-12 p-mt-0 p-d-flex p-flex-wrap">
                                                                <div class="p-col-4 p-mb-3 attachmentCard attachmentContainer combinedPdfView"
                                                                    *ngFor="let pdf of combinedPdfView">
                                                                    <p-card>
                                                                        <div
                                                                            class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center">
                                                                            <div class="p-col-11 p-p-0 p-d-flex"
                                                                                style="align-items: center;">
                                                                                <div class="p-pl-0 p-d-flex p-ai-center"
                                                                                    style="padding-right: 9px;">
                                                                                    <img [src]="pdfThumb" alt="">
                                                                                </div>
                                                                                <label
                                                                                    class="limit-text-length attachmentName cursor-pointer"
                                                                                    style="cursor:pointer;"
                                                                                    title={{pdf.fileName}}
                                                                                    (click)="showAttachmentPreview(pdf)">
                                                                                    <span
                                                                                        class="combinePdf">{{pdf.fileName}}</span>
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                class="p-col-1 p-pl-0 p-pr-0 p-d-flex p-jc-end">
                                                                                <i class="pi pi-trash cursor-pointer attachmentIcon"
                                                                                    *ngIf="checkCombineDeleteCond() || this.isQcRejected"
                                                                                    aria-hidden="true"
                                                                                    (click)="showDelCombineFilePop(pdf)"></i>
                                                                            </div>
                                                                        </div>
                                                                    </p-card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-tabPanel>
                                                    <p-tabPanel header="Migrated Document">
                                                        <div class="p-fluid p-formgrid p-grid">
                                                            <div class=" p-col-12 p-pl-0 p-pr-0">
                                                                <div class="ui-fluid p-formgrid p-grid">
                                                                    <div
                                                                        class="p-field p-d-flex p-flex-wrap p-col-12 displayEditTypeModal p-p-0">
                                                                        <div class="uploadedDocumentAttachment p-col-12 p-md-4 p-lg-4 p-flex-wrap"
                                                                            *ngFor="let item of migratedDocs">
                                                                            <p-card class="attachmentCard">
                                                                                <div
                                                                                    class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center">
                                                                                    <div class="p-col-11 p-p-0 p-d-flex"
                                                                                        style="align-items: center;">
                                                                                        <div
                                                                                            style="padding-right: 9px;">
                                                                                            <img class="uploadedThumbnail"
                                                                                                *ngIf="item.fileContentType == 'application/pdf'"
                                                                                                [src]="pdfThumb" alt="">
                                                                                            <img class="uploadedThumbnail"
                                                                                                *ngIf="item.fileContentType == 'image/png' || item.fileContentType == 'image/jpg' || item.fileContentType == 'image/jpeg'"
                                                                                                [src]="imgThumb" alt="">
                                                                                            <img class="uploadedThumbnail"
                                                                                                *ngIf="item.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || item.fileContentType == 'application/msword' "
                                                                                                [src]="wordThumb"
                                                                                                alt="">
                                                                                            <img class="uploadedThumbnail"
                                                                                                *ngIf="item.fileContentType == '' || !item.fileContentType"
                                                                                                [src]="mailThumb"
                                                                                                alt="">
                                                                                        </div>
                                                                                        <div
                                                                                            class="p-col-10 p-pl-0 p-pr-0">
                                                                                            <a
                                                                                                (click)="showAttachmentPreview(item)">
                                                                                                <div class="p-col-11 p-d-flex p-flex-column p-flex-wrap p-ai-start p-pl-0"
                                                                                                    style="text-align:left;">
                                                                                                    <div
                                                                                                        class="limit-text-length attachmentName cursor-pointer">
                                                                                                        {{item.fileName}}
                                                                                                    </div>
                                                                                                    <label
                                                                                                        class="attachmentFileType">Type:{{item.fileTypeName}}
                                                                                                    </label>
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </p-card>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-tabPanel>
                                                </p-tabView>
                                            </div>
                                        </div>
                                    </p-tabPanel>
                                    <p-tabPanel>
                                        <ng-template pTemplate="header"><span
                                                [ngClass]="{'errorTab': errorApprovals}">Approvals</span></ng-template>
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class=" p-col-12 p-mb-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                        <h6>Standard Pricing Approval</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mb-3">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-d-flex">
                                                            <p-inputSwitch id="approvalNeededinputSwitchid"
                                                                formControlName="standardApprovalNeeded"
                                                                (onChange)="SPANeededCliked($event)"
                                                                [disabled]="businessReportedStatusFlagA || disableStdPricing || reportingReadScope || caseCloseFlag"
                                                                name="approvalNeededinputSwitch"></p-inputSwitch>
                                                            <label class="p-ml-2">
                                                                Approval Needed
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-d-flex">
                                                            <p-inputSwitch id="ApprovalReceivedinputSwitchid"
                                                                formControlName="standardApprovalReceived"
                                                                [disabled]="businessReportedStatusFlagA || disableStdPricing || reportingReadScope || disableStdPricingReceived || caseCloseFlag"
                                                                (onChange)="handleStandardPricingReceived($event)"
                                                                name="ApprovalReceivedinputSwitch"></p-inputSwitch>
                                                            <label class="p-ml-2">
                                                                Approval Received
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" p-col-12 p-mb-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                        <h6>Internal Pricing Approval</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class="p-d-flex">
                                                            <p-inputSwitch id="InternalApprovalNeededinputSwitchid"
                                                                formControlName="InternalApprovalNeeded"
                                                                [disabled]="businessReportedStatusFlagA || approvalNeededFlag || caseCloseFlag"
                                                                name="InternalApprovalNeededinputSwitch"
                                                                class="inputSwitch">
                                                            </p-inputSwitch>
                                                            <label class="p-ml-2">
                                                                Approval Needed
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-d-flex">
                                                            <p-inputSwitch id="internalApprovalReceivedinputSwitchid"
                                                                formControlName="InternalApprovalReceived"
                                                                (onChange)="handleInternalApprovalReceived($event)"
                                                                [disabled]="businessWonStatusFlagA || otherOpportunityStatusFlagA || businessReportedPermanentFlag || caseCloseFlag"
                                                                name="internalApprovalReceivedinputSwitch">
                                                            </p-inputSwitch>
                                                            <label class="p-ml-2">
                                                                Approval Received
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class="p-d-flex">
                                                            <p-inputSwitch id="criticalDocReceivedinputSwitchid"
                                                                formControlName="criticalDocReceived"
                                                                name="criticalDocReceivedinputSwitch"
                                                                [disabled]="COARoleFlag || caseCloseFlag">
                                                            </p-inputSwitch>
                                                            <label class="p-ml-2">
                                                                Critical Doc Received
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p-tabPanel>
                                    <p-tabPanel>
                                        <ng-template pTemplate="header"><span
                                                [ngClass]="{'errorTab': errorAction}">Action</span></ng-template>
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 ">
                                                        <div class=" p-fluid   p-field    ">
                                                            <label>
                                                                Business Action
                                                                <span
                                                                    [ngClass]="{'hide': otherOpportunityStatusFlag || businessReportedStatusFlagA}"
                                                                    class='invalid-feedback-astrik'>*</span>
                                                            </label>
                                                            <div class="businessAction">
                                                                <p-dropdown id="businessActionselectid"
                                                                    name="businessActionselect" id="businessAction"
                                                                    placeholder="Select a Business Action"
                                                                    [options]="businessActionselectList"
                                                                    [autoDisplayFirst]="false" optionLabel="property"
                                                                    optionValue="property"
                                                                    [disabled]="businessReportedStatusFlagA || otherOpportunityStatusFlagA || caseCloseFlag"
                                                                    formControlName="businessActionselect"
                                                                    (onChange)="onChangeBusinessAction($event)"
                                                                    [ngClass]="{error:isBAaction || ((ReportingScreenDetailsForm.get('businessActionselect')?.errors?.required && ReportingScreenDetailsForm.get('businessActionselect').invalid) && (ReportingScreenDetailsForm.get('businessActionselect').dirty || ReportingScreenDetailsForm.get('businessActionselect').touched))}">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="invalid-feedback"
                                                                *ngIf="isBAaction || (ReportingScreenDetailsForm.get('businessActionselect')!.invalid && (ReportingScreenDetailsForm.get('businessActionselect')!.dirty || ReportingScreenDetailsForm.get('businessActionselect')!.touched))">
                                                                <div>
                                                                    Business Action is required.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class=" p-fluid   p-field    ">
                                                            <label>
                                                                Reason<span
                                                                    *ngIf="this.ReportingScreenDetailsForm.value.businessActionselect && !(otherOpportunityStatusFlagA)"
                                                                    class='invalid-feedback-astrik'
                                                                    [ngClass]="{'hide': otherOpportunityStatusFlag || businessReportedStatusFlagA}">*</span>
                                                            </label>
                                                            <div *ngIf="showReasonFlag">
                                                                <p-dropdown id="reasonselectid" name="reasonselect"
                                                                    placeholder="Select a Reason"
                                                                    [options]="reasonselectList" [filter]="true"
                                                                    resetFilterOnHide="true" optionLabel="name"
                                                                    optionValue="name" [autoDisplayFirst]="false"
                                                                    [disabled]="actionReasonFlag || otherOpportunityStatusFlagA || caseCloseFlag"
                                                                    (onChange)="businessReasonValidation()"
                                                                    formControlName="reasonselect"
                                                                    [ngClass]="{error:isBAreason || isActionReasonTouched || (ReportingScreenDetailsForm.get('reasonselect').invalid && (ReportingScreenDetailsForm.get('reasonselect').dirty && ReportingScreenDetailsForm.get('reasonselect').touched))}">
                                                                </p-dropdown>
                                                                <div class="invalid-feedback "
                                                                    *ngIf="isBAreason || isActionReasonTouched || (ReportingScreenDetailsForm.get('reasonselect').invalid && (ReportingScreenDetailsForm.get('reasonselect').dirty && ReportingScreenDetailsForm.get('reasonselect').touched))">
                                                                    <div>
                                                                        Reason is required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="!showReasonFlag">
                                                                <p-dropdown id="reasonselectid" name="reasonselect"
                                                                    placeholder="Select a Reason"
                                                                    [options]="reasonselectList" [filter]="true"
                                                                    resetFilterOnHide="true" optionLabel="name"
                                                                    optionValue="name" [autoDisplayFirst]="false"
                                                                    [disabled]="actionReasonFlag || otherOpportunityStatusFlagA || caseCloseFlag"
                                                                    formControlName="reasonselect">
                                                                </p-dropdown>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                        <div class=" p-fluid   p-field   p-d-flex p-flex-column  ">
                                                            <div class="p-d-flex">
                                                                <label>
                                                                    Comment<span *ngIf="actionCommentFlag"
                                                                        class='invalid-feedback-astrik'>*</span>
                                                                </label>
                                                            </div>
                                                            <div *ngIf="actionCommentFlag">
                                                                <textarea pInputTextarea id="actionComment_id"
                                                                    name="actionComment_name"
                                                                    formControlName="actionComment" rows="3"
                                                                    [readonly]="actionReasonFlag || otherOpportunityStatusFlagA || caseCloseFlag"
                                                                    placeholder="Enter Comment" (keypress)="actionComment($event)"
                                                                    maxlength="255"
                                                                    [ngClass]="{error: isBAComment || (ReportingScreenDetailsForm.get('actionComment')!.invalid && (ReportingScreenDetailsForm.get('actionComment')!.dirty || ReportingScreenDetailsForm.get('actionComment')!.touched))}">
                                                                </textarea>
                                                                <div class="invalid-feedback "
                                                                    *ngIf="isBAComment || (ReportingScreenDetailsForm.get('actionComment')!.invalid && (ReportingScreenDetailsForm.get('actionComment')!.dirty || ReportingScreenDetailsForm.get('actionComment')!.touched))">
                                                                    <div
                                                                        *ngIf="ReportingScreenDetailsForm.get('actionComment')?.errors?.required">
                                                                        Comment is required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="!actionCommentFlag">
                                                                <textarea pInputTextarea id="actionComment_id"
                                                                    name="actionComment_name"
                                                                    formControlName="actionComment" rows="3"
                                                                    maxlength="255"
                                                                    [readonly]="actionReasonFlag || otherOpportunityStatusFlagA || caseCloseFlag"
                                                                    (keypress)="actionComment($event)"
                                                                    placeholder="Enter Comment"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p-tabPanel>
                                </p-tabView>
                            </div>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 popupFooterBtnContainer">
                            <div
                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-between p-ai-center popupFooterBtnContent p-pr-3 p-mt-2 p-mb-2">
                                <!-- <div  class="p-element p-button-secondary p-mr-2 p-button p-component">
                                    <button pButton type="button"
                                        (click)="onPreviewScreen()" label="previewDetails">
                                    </button>
                                </div> -->
                                <div>
                                    <button _ngcontent-xuq-c136="" pbutton="" type="button" (click)="onPreviewScreen()"
                                        label="Preview Details"
                                        class="p-element p-button-secondary p-ml-2 p-button p-component">
                                        <span class="p-button-label">Preview Data</span>
                                    </button>
                                </div>

                                <div>
                                    <div class="btnwrap">
                                        <button pButton class="p-button-brand2 p-button" type="button"
                                            (click)="onCancel()" label="Cancel">
                                        </button>
                                    </div>
                                    <div class="btnwrap">
                                        <button pButton class="p-button-primary p-button p-mr-0"
                                            [disabled]="reportingReadScope || caseCloseFlag" type="submit"
                                            (click)="validations();" label="Save">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="no_data_div" *ngIf="uploadLoader">
            <div class="app-loading" id="loader">
                <div class="loadbg"></div>
                <div class="bdload">
                    <div class="spinner">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                        <div class="rect5"></div>
                        <span>Loading...</span>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>
<p-dialog header="Crop Image" [(visible)]="displayModal" (onHide)="uploadLoader=false;" [modal]="true" [style]="{width: '60vw', height: '80vh'}"
    [draggable]="true" [resizable]="false" maskStyleClass="negativemask">
    <div class="p-col-12 p-text-center popupAttachmentBodyScrollContainer">
        <image-cropper styleClass="imageCropper" class="crop-content" [imageChangedEvent]="imgChangeEvt"
            [canvasRotation]="canvasRotation" [imageFile]="imageChangedEventfileUpload1" [maintainAspectRatio]="false"
            format="jpeg" id="image_cropper" (imageCropped)="imageCroppedfileUpload1($event)">
        </image-cropper>
        <i class="pi pi-replay p-mr-3 p-pt-2" style="cursor:pointer;" (click)="rotateLeft()" aria-hidden="true"></i>
        <i class="pi pi-refresh p-pt-2" style="cursor:pointer;" (click)="rotateRight()" aria-hidden="true"></i>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="displayModal=false" label="Cancel" class="p-button-brand2"></button>
        <button pButton type="button" label="Upload" class="p-button-primary" (click)="onCroppedUploadBtn();"></button>
    </ng-template>
</p-dialog>


<p-dialog header="Change Attachment Type" [(visible)]="displayEditTypeModal"
    class="displayEditTypeModal attachmentTypeChange" [modal]="true" [style]="{width: '35vw', overflow: 'visible'}"
    [draggable]="false" [closable]="false" [resizable]="false">
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-px-0">
            <label>Attachment Type</label>
            <p-dropdown [options]="newAttachmentTypeList" [(ngModel)]="updatedAttachmentType" placeholder="Select Type"
                optionLabel="type"></p-dropdown>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton (click)="closeAttachmentTypeModal()" label="Cancel" class="p-button-brand2"></button>
        <button pButton label="Save" (click)="onUpdateAttachmentType()" class="p-button-primary p-mr-0"></button>
    </ng-template>
</p-dialog>
<p-dialog header="Preview" [(visible)]="displayPreview" class="docPreview previewModal" [modal]="true"
    [style]="{width: '95vw', height: '100vh'}" [draggable]="false" [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="dataLoader">
        <div class="app-loading " id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label>Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer id="pdf_viewer" *ngIf="isPdf" [src]="urlSafe" [rotation]="rotate" [original-size]="false"
        [show-all]="true" [fit-to-page]="false" [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height:100%;"></pdf-viewer>
    <div class="imagePreviewContainer">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Download" (click)="downloadFile()" class="p-button-secondary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="showCombinePDFPopUp" [modal]="true" [style]="{width: '40vw', height: '30vh'}"
    [draggable]="false" [resizable]="false">
    <label>Are you sure you want to combine selected document as PDF?</label>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="showCombinePDFPopUp=false" label="No"
            class="p-button-secondary"></button>
        <button pButton type="button" (click)="combinePDF()" label="Yes" class=" p-button-primary"></button>
    </ng-template>
</p-dialog>
<p-dialog header="Delete" [(visible)]="deleteFile" class="DeleteFileModal" [modal]="true" [style]="{width: '27vw'}"
    [draggable]="false" [resizable]="false">
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-p-0">
            <p>Are you sure you want to delete?</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton (click)="deleteFile=false" label="No" class="p-button-secondary"></button>
        <button pButton label="Yes" (click)="onDelete()" class="p-button-primary"></button>
    </ng-template>
</p-dialog>
<p-dialog header="Delete" [(visible)]="deleteCombinedF" class="DeleteFileModal" [modal]="true" [style]="{width: '27vw'}"
    [draggable]="false" [resizable]="false">
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-p-0">
            <p>Are you sure you want to delete?</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton (click)="deleteCombinedF=false" label="No" class="p-button-secondary"></button>
        <button pButton label="Yes" (click)="deleteCombinedFile()" class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="showCompanyMergePopup" [modal]="true" [style]="{width: '40vw'}"
    [draggable]="false" [closeOnEscape]="false" [closable]="false" [resizable]="false">
    <p>Entity already exist in Nexera. Are you sure you want to merge?</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="blankCompanyname()" label="No" class="p-button-secondary"></button>
        <button pButton type="button" (click)="mergeCompany()" label="Yes" class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="showSameTripartyPopup" [modal]="true" [style]="{width: '40vw'}"
    [draggable]="false" [closeOnEscape]="false" [closable]="false" [resizable]="false">
    <p>Company name (CRM) and Tri-Party name cannot be same.</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="sameTriparty()" label="Ok" class="p-button-primary"></button>
    </ng-template>
</p-dialog>
<p-dialog header="Alert" [(visible)]="multipleCompany" [modal]="true" [style]="{width: '40vw'}" [draggable]="false"
    [closeOnEscape]="false" [closable]="false" [resizable]="false">
    <p>Multiple company exists for this company code</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="multiCompanyNo()" label="Ok" class="p-button-primary"></button>
    </ng-template>
</p-dialog>
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="previewFailed" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Unable to preview attachment, Please try again.</p>
</p-dialog>
<p-dialog header="Alert" [(visible)]="multipleCompany" [modal]="true" [style]="{width: '40vw'}" [draggable]="false"
    [closeOnEscape]="false" [closable]="false" [resizable]="false">
    <p>Multiple company exists for this company code</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="multiCompanyNo()" label="Ok" class="p-button-primary"></button>
    </ng-template>
</p-dialog>
<p-dialog header="Alert" [(visible)]="redirectPopup" [modal]="true" [style]="{width: '40vw'}" [draggable]="false"
    [closeOnEscape]="false" [closable]="false" [resizable]="false">
    <p>After saving do you want to be redirected to Irf Initiation Screen?</p>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="noRedirect()" label="No" class="p-button-secondary"></button>
        <button pButton type="button" (click)="redirect()" label="Yes" class="p-button-primary"></button>
    </ng-template>
</p-dialog>