import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KmAgreementsRoutingModule } from './km-agreements-routing.module';
import { KmAgreementsComponent } from './km-agreements/km-agreements.component';
import { BackofficeSharedModule } from 'app/shared/shared.module';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';

@NgModule({
  declarations: [
    KmAgreementsComponent
  ],
  imports: [
    CommonModule,
    KmAgreementsRoutingModule,
    BackofficeSharedModule,
    DialogModule,
    CardModule
  ],
  providers:[
    DialogService,
    DialogModule
  ],
})
export class KmAgreementsModule { }
